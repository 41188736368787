import { Route, Switch } from 'react-router-dom';
import Index from '../pages/publications_v2/views/Index';
import Edit from '../pages/publications_v2/views/Edit';
import React, { memo } from 'react';
import { ReactTitle } from '../components/ReactTitle';
import Constants, { Permissions } from '../config/Constants';
import RestrictedContent from '../pages/global/RestrictedContent';
import { Settings } from '../pages/publications_v2/views/Settings';
import { useTranslation } from 'react-i18next';
import { Kiosk } from '../pages/publications_v2/views/Kiosk';
import { Index as Guard } from '../pages/publications_v2/views/guard/Index';

export const BASE_PATH = '/publicaties';
export const INDEX_PATH = `${BASE_PATH}/:folder(folder|list)?/:folderId?/:publicationId?`;
export const INDEX_FOLDER_PATH = `${BASE_PATH}/:folder/:folderId`;
export const INDEX_FOLDER_SELECTION_PATH = `${BASE_PATH}/:folder/:folderId/:publicationId`;
export const EDIT_PATH = `${BASE_PATH}/edit/:publicationId/:action/:groupId?`;
export const USER_GROUPS_PATH = `${BASE_PATH}/publication-user-groups/:page/:id?/:publicationId?`;
export const GUARD_PATH = `${BASE_PATH}/guard`;
export const PUBLICATION_GROUP_PATH = `${BASE_PATH}/publication-user-groups/view-publication-group/:id/publication/:publicationId`;
export const USER_GROUPS_VIEW_PATH = `${BASE_PATH}/publication-user-groups/:page/view/:id`;
export const KIOSK_PATH = `${BASE_PATH}/kiosk/:page?`;

const PublicationsV2 = () => {
    const { t } = useTranslation('publications');

    return (
        <>
            <ReactTitle title={t('breadcrumb')} />

            <Switch>
                <Route
                    path={USER_GROUPS_PATH}
                    exact
                    strict
                    render={() => (
                        <RestrictedContent module={Constants.modules.publication_access_control}>
                            <Settings />
                        </RestrictedContent>
                    )}
                />

                <Route
                    path={EDIT_PATH}
                    exact
                    strict
                    render={() => (
                        <RestrictedContent permission={Permissions.Publication['Write.All']}>
                            <Edit />
                        </RestrictedContent>
                    )}
                />

                <Route
                    path={KIOSK_PATH}
                    exact
                    strict
                    render={() => (
                        <RestrictedContent permission={Permissions.Kiosk.Create}>
                            <Kiosk />
                        </RestrictedContent>
                    )}
                />

                <Route
                    path={GUARD_PATH}
                    exact
                    strict
                    render={() => (
                        <RestrictedContent module={Constants.modules.guard}>
                            <Guard />
                        </RestrictedContent>
                    )}
                />

                <Route path={INDEX_PATH} exact strict render={({ match }) => <IndexMemo {...match.params} />} />
            </Switch>
        </>
    );
};

const IndexMemo = memo(({ folderId, publicationId }) => {
    return <Index folderId={folderId ? parseInt(folderId) : undefined} publicationId={publicationId} />;
});

export default PublicationsV2;
