import { useTranslation } from 'react-i18next';
import { AreaTitleMetadata } from 'pages/documents_v2/views/view/AreaTitleRow';
import { useGetArea } from 'pages/documents_v2/hooks/useGetArea';

export function AreaMetadata({ areaId }) {
    const { t } = useTranslation();
    const area = useGetArea(areaId);
    const metadata = area?.metadata ?? [];

    return (
        <div className="mt-3 p-1">
            <div className="border-bottom pb-1 mb-2">
                <div className="font-weight-bolder">{t('global:settingsSidebar.metadata.title')}</div>
            </div>

            {metadata.map((item, index) => (
                <MetadataRow keyName={item.key} value={item.value} key={`meta-row-${index}`} />
            ))}

            {!!area && metadata.length === 0 && (
                <div className="text-muted">{t('global:settingsSidebar.metadata.noMetadata')}</div>
            )}
        </div>
    );
}

function MetadataRow({ keyName, value }) {
    return (
        <div className="row mb-1">
            <div className="col-5 text-muted">{keyName}</div>
            <div className="col-7 text-body">{value}</div>
        </div>
    );
}

export function TitleMetadata({ areaId }) {
    const area = useGetArea(areaId);

    if (!area) {
        return null;
    }

    return <AreaTitleMetadata area={area} />;
}
