import cx from 'classnames';
import { Editor } from 'components/Editor';
import { set } from 'features/app/appSlice';
import { documentApi } from 'features/documents/documents';
import { useGetTinyConfig } from 'hooks/useGetTinyConfig';
import Parser from 'html-react-parser';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { useGetDocumentTags } from 'pages/tags/hooks/useGetDocumentTags';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TagFill } from 'react-bootstrap-icons';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import striptags from 'striptags';
import Constants from '../../../../config/Constants';
import EditorHelper from '../../../global/EditorHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetAreaBlocks } from '../../hooks/useGetAreaBlocks';
import { useGetDocument } from '../../hooks/useGetDocument';
import _ from 'lodash';

export const TinymceEditor = React.memo(TinymceEditorRendered, isEqual);

function TinymceEditorRendered({ block, handleBlockChange, index, searchTerm, selectedSearchBlock }) {
    const tags = useGetDocumentTags();
    const [showEditor, setShowEditor] = useState(false);
    const [initialValue, setInitialValue] = useState(undefined);

    const latestContent = block.content || block.latestContent || '';

    useEffect(() => {
        if (initialValue === undefined) {
            setInitialValue(latestContent);
        }
    }, [latestContent, initialValue]);

    const initialValuesPrepared = useMemo(() => {
        if (initialValue === undefined) {
            return '';
        }

        return initialValue;
    }, [initialValue]);

    return (
        <div
            className={cx('editor-wrapper', {
                'cursor-pointer': !showEditor,
                active: showEditor,
            })}
            onClick={() => {
                if (!showEditor) {
                    setShowEditor(true);
                }
            }}
        >
            <TinymceEditorInner
                block={block}
                handleBlockChange={handleBlockChange}
                tags={tags}
                initialValue={initialValuesPrepared}
                showEditor={showEditor}
                setShowEditor={setShowEditor}
                index={index}
                searchTerm={searchTerm}
                selectedSearchBlock={selectedSearchBlock}
            />
        </div>
    );
}

function TinymceEditorInner({
    block,
    handleBlockChange,
    tags,
    initialValue,
    showEditor,
    setShowEditor,
    index,
    searchTerm,
    selectedSearchBlock,
}) {
    const document = useGetDocument(undefined, true);
    const baseVariant = useGetBaseVariant();
    const { t, i18n } = useTranslation();
    const newTextBlockPlaceholder = `<p className='newTextBlockPlaceholder'>${t(
        'documents:document.navbar.main.editor.left.blocks.editBlockText',
    )}...</p>`;
    const dispatch = useDispatch();
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [options, setOptions] = useState(false);
    const editorRef = useRef(null);
    const areaData = useGetAreaBlocks();
    const [getAreaBlocks] = documentApi.useLazyGetAreaBlocksQuery();
    const tinyConfig = useGetTinyConfig();

    const readOnly = !showEditor || !options || !editorLoaded;
    const latestContent = block.content || block.latestContent || '';
    const hasVariants = block.documentVariants.length > 1;
    const isLinkedArea = areaData.area.linkedAreas.length > 0;
    const documentIsTemplate = document?.type === Constants.documentType.model;
    const documentIsLibrary = document?.type === Constants.documentType.library;

    useEffect(() => {
        if (showEditor && !options && document) {
            getTinyMceOptions().then((tinyMceOptions) => {
                setOptions({
                    ...tinyMceOptions,
                    ...tinyConfig,
                });
            });
        }
    }, [showEditor, document]);

    useEffect(() => {
        if (editorLoaded && showEditor && editorRef.current) {
            // Move cursor to end of content
            editorRef.current.selection.select(editorRef.current.getBody(), true);
            editorRef.current.selection.collapse(false);
        }
    }, [showEditor, editorLoaded]);

    const latestContentPrepared = useMemo(() => {
        if (!document) {
            return latestContent;
        }

        if (latestContent === '') {
            return newTextBlockPlaceholder;
        }

        return HelperFunctions.prepareTags(latestContent, { ...document, tags }, 'base', true, true);
    }, [latestContent, document, tags]);

    const highlightedContent = useMemo(() => {
        if (!searchTerm || showEditor) {
            return latestContentPrepared; // No highlighting needed
        }

        const classes = cx('highlight-search', {
            'highlight-search-selected': selectedSearchBlock?.key === block.key,
        });

        return latestContentPrepared.replace(
            new RegExp(searchTerm, 'gi'),
            (match) => `<mark className="${classes}">${match}</mark>`,
        );
    }, [latestContentPrepared, searchTerm, selectedSearchBlock, block.key]);

    return (
        <>
            <div
                className={cx('tiny-mce-content-body ', {
                    'd-none': !readOnly,
                    'opacity-5': !documentIsTemplate && !hasVariants && !isLinkedArea && !documentIsLibrary,
                })}
            >
                {Parser(highlightedContent, {
                    replace: ({ attribs }) => {
                        if (attribs && attribs.class === 'tags-button') {
                            const tagValue = attribs['data-tag'];

                            return (
                                <span
                                    data-uk-tooltip={t(
                                        'documents:document.navbar.main.editor.left.blocks.tooltips.editTag',
                                    )}
                                    className="tags-button"
                                    onClick={(e) => handleTagButtonClick(e, tagValue)}
                                >
                                    <TagFill size={12} className="mr-1" />
                                    {tagValue}
                                </span>
                            );
                        }
                    },
                })}
            </div>
            <div
                className={cx('tiny-mce-content-body ', {
                    'd-none': readOnly,
                })}
            >
                {!!options && (
                    <Editor
                        id={`jsTinymceEditor${block.key}`}
                        init={options}
                        initialValue={initialValue}
                        onEditorChange={(content) => handleBlockChange(content.replace(/\r?\n|\r/g, ''), index)}
                        onBlur={() => setShowEditor(false)}
                        onInit={(event, editor) => {
                            setEditorLoaded(true);
                            editorRef.current = editor;
                        }}
                    />
                )}
            </div>
        </>
    );

    async function getTinyMceOptions() {
        // define block type specific config sets or configs that need dynamic content/functions
        return {
            setup: (editor) => {
                if (tags) {
                    editor.ui.registry.addMenuButton('tags', {
                        text: t('global:editor.btn.tags'),
                        fetch: function (callback) {
                            const items = EditorHelper.generateTagMenu(tags, editor);
                            callback(items);
                        },
                    });
                }
                if (document.type !== Constants.documentType.library) {
                    editor.ui.registry.addButton('ref', {
                        text: t('global:editor.btn.crossRef'),
                        onAction: async () => editor.windowManager.open(await refDialogConfig(editor)),
                    });
                }
                editor.ui.registry.addButton('startNumber', {
                    text: t('global:editor.btn.startListWith'),
                    onAction: function () {
                        const parent = editor.dom.getParent(editor.selection.getNode(), 'ol');

                        parent !== null &&
                            editor.windowManager.open({
                                title: t('global:editor.title.startNumber'),
                                body: {
                                    type: 'panel',
                                    items: [
                                        {
                                            type: 'input',
                                            inputMode: 'text',
                                            name: 'startNumber',
                                            label: t('global:editor.title.startNumber'),
                                        },
                                    ],
                                },
                                buttons: [
                                    {
                                        type: 'submit',
                                        text: 'OK',
                                    },
                                ],
                                onSubmit: function (dialog) {
                                    const data = dialog.getData();
                                    const number = HelperFunctions.filterNumbers(data.startNumber);
                                    parent.setAttribute('start', number);

                                    dialog.close();
                                },
                            });
                    },
                    onSetup: (buttonApi) => {
                        // Make button disabled when no "ol" element is selected
                        editor.on('NodeChange', function (e) {
                            const isOrderedList =
                                e.element.nodeName.toLowerCase() === 'li' &&
                                e.parents[1] &&
                                e.parents[1].nodeName.toLowerCase() === 'ol';
                            buttonApi.setEnabled(isOrderedList);
                        });
                    },
                });
            },
        };
    }

    function handleTagButtonClick(e, tagValue) {
        e.stopPropagation();

        dispatch(
            set({
                key: 'blockEditModal',
                value: {
                    eventKey: 'editTagModal',
                    block,
                    blockIndex: index,
                    tag: tagValue,
                },
            }),
        );
    }

    async function refDialogConfig(editor, data) {
        return {
            title: t('global:editor.btn.crossRef'),
            size: 'medium',
            body: {
                type: 'panel',
                items: await getRefBodyItems(data),
            },
            onChange: async (dialogApi, details) => {
                dialogApi.redial(await refDialogConfig(editor, dialogApi.getData()));
            },
            buttons: [
                {
                    type: 'cancel',
                    name: 'closeButton',
                    text: 'Cancel',
                },
                {
                    type: 'submit',
                    name: 'submitButton',
                    text: 'OK',
                    buttonType: 'primary',
                    enabled: !_.isEmpty(data?.section),
                },
            ],
            initialData: {
                section: data?.section ?? '',
                area: data?.area ?? '',
            },
            onSubmit: (api) => {
                const tag = createTag(api.getData());

                if (!tag) {
                    api.close();
                }

                editor.execCommand('mceInsertContent', false, tag);
                api.close();
            },
        };
    }

    async function getRefBodyItems(data) {
        let items = [];

        items.push({
            type: 'selectbox',
            name: 'section',
            label: 'Hoofdstuk*',
            items: [
                {
                    value: '',
                    text: 'Selecteer hoofdstuk...',
                },
            ].concat(
                document.sections
                    .filter((section) => section.deletedAt === null)
                    .map((section) => ({
                        value: `${section.id}`,
                        text: section.title,
                    })),
            ),
        });

        items.push({
            type: 'selectbox',
            name: 'area',
            label: 'Artikel (optioneel)',
            items: [
                {
                    value: '',
                    text: 'Selecteer artikel...',
                },
            ].concat(getAreas(data?.section)),
            enabled: !_.isEmpty(data?.section),
        });

        if (!_.isEmpty(data?.area)) {
            const areaId = parseInt(data.area);

            const results = await getAreaBlocks({ areaId, variantId: baseVariant.id }, true);
            const areaBlocks = results?.data?.areaBlocks ?? [];

            const blocksWithNumbering = areaBlocks.filter((block) => {
                const blockPrefixSetting = block.properties?.blockPrefixSetting;
                const blockPrefix = block.properties?.blockPrefix;

                return blockPrefixSetting === 'auto' || !_.isEmpty(blockPrefix);
            });

            if (blocksWithNumbering.length > 0) {
                items.push({
                    type: 'label',
                    label: 'Blokken (optioneel)',
                    for: 'blocks',
                    items: [
                        {
                            type: 'panel',
                            items: blocksWithNumbering.map((block) => {
                                const blockName = block.properties?.name;
                                let contentPreview = striptags(block.latestContent);
                                let partLength = 110;

                                if (!_.isEmpty(blockName)) {
                                    partLength = partLength - blockName.length;

                                    if (partLength < 20) {
                                        partLength = 30;
                                    }
                                }

                                if (contentPreview.length > partLength + 5) {
                                    const startStr = contentPreview.substring(0, partLength / 2);
                                    const endStr = contentPreview.substring(contentPreview.length - partLength / 2);

                                    contentPreview = startStr.trim() + ' ... ' + endStr.trim();
                                }

                                let label = '';

                                if (!_.isEmpty(blockName)) {
                                    label = `[${blockName}] `;
                                }

                                label = label + contentPreview;

                                return {
                                    type: 'checkbox',
                                    name: `block-${block.id}`,
                                    label,
                                };
                            }),
                        },
                    ],
                });
            }
        }

        return items;
    }

    function createTag(data) {
        let blocks = [];
        const blockKeys = Object.keys(data).filter((key) => key.startsWith('block-'));

        if (blockKeys.length > 0) {
            blockKeys.forEach((blockKey) => {
                if (data[blockKey] === true) {
                    blocks.push(parseInt(blockKey.split('-')[1]));
                }
            });
        }

        if (blocks.length > 0) {
            return `[Ref id&#61;${blocks.join('|')} areaId&#61;${parseInt(data.area)} type&#61;block document&#61;${document.id}]`;
        }

        if (!_.isEmpty(data.area)) {
            return `[Ref id&#61;${parseInt(data.area)} type&#61;area document&#61;${document.id}]`;
        } else if (!_.isEmpty(data.section)) {
            return `[Ref id&#61;${parseInt(data.section)} type&#61;section document&#61;${document.id}]`;
        }

        return undefined;
    }

    function getAreas(sectionId) {
        if (!sectionId) {
            return [];
        }

        const sectionIdAsInt = parseInt(sectionId);
        const section = document.sections.find((section) => section.id === sectionIdAsInt);

        if (!section) {
            return [];
        }

        return section.areas
            .filter((area) => area.deletedAt === null)
            .map((area) => {
                const headingLevel = parseInt(area.exportProperties?.headingLevel ?? 2);
                let prefix = '';

                if (headingLevel > 2) {
                    for (let i = 0; i < headingLevel - 2; i++) {
                        prefix += '-- ';
                    }
                }

                return {
                    value: `${area.id}`,
                    text: prefix + area.title,
                };
            });
    }
}
