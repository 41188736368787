import {
    Category,
    ReimbursementCode,
    ReimbursementProduct,
    ReimbursementProductCode as ReimbursementProductCodeModel,
    ReimbursementProductCode,
    ReimbursementProductField,
    ReimbursementProductHistory,
} from '../../../../models/reimbursement.models';
import { FormikContext } from 'formik';
import Constants from '../../../../config/Constants';
import { Col, Form, Row } from 'react-bootstrap';
import { InputSelect } from '../../../publications_v2/helpers/FieldHelper';
import FieldHistory from './FieldHistory';
import React, { useContext } from 'react';
import BasicEditor from '../../../documents/misc/_BlockParser/BasicEditor';
import Tooltip from '../../../global/Tooltip';
import { QuestionCircle } from 'react-bootstrap-icons';
import cx from 'classnames';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import { useGetReimbursementCodesQuery } from '../../../../features/reimbursements/reimbursements';
import HelperFunctions from '../../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';

export function Coverage({
    reimbursementProduct,
    history,
    reimbursementId,
}: {
    reimbursementProduct: Partial<ReimbursementProduct>;
    history?: ReimbursementProductHistory;
    reimbursementId: number;
}) {
    const { t } = useTranslation('reimbursements');

    return (
        <div className="pt-4 px-3">
            <Row>
                <Col xs={3}>
                    <div className="font-weight-bold pt-1" style={{ color: '#344054', fontSize: 13 }}>
                        {t('coverage')}
                    </div>
                </Col>
                <Col>
                    <Row>
                        <RestrictedReimbursementContent
                            roles={[
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                                Constants.reimbursementTeamRoles.manager,
                                Constants.reimbursementTeamRoles.admin,
                            ]}
                        >
                            <Col>
                                <InputSelect
                                    name="coverage"
                                    options={[
                                        {
                                            label: 'N.v.t.',
                                            value: Constants.coverage['n/a'],
                                        },
                                        {
                                            label: t('reimbursement.covered'),
                                            value: Constants.coverage['coverage'],
                                        },
                                        {
                                            label: t('reimbursement.noCoverage'),
                                            value: Constants.coverage['no_coverage'],
                                        },
                                    ]}
                                />
                            </Col>
                        </RestrictedReimbursementContent>
                        <Col>
                            <FieldHistory
                                className="ReimbursementProduct"
                                reimbursementProductUri={reimbursementProduct['@id']}
                                reimbursementId={reimbursementId}
                                entity={reimbursementProduct}
                                propName="coverage"
                                history={history}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export function Description({
    category,
    history,
    reimbursementId,
}: {
    category: Category;
    history?: ReimbursementProductHistory;
    reimbursementId: number;
}) {
    const { t } = useTranslation('reimbursements');

    return (
        <div>
            <div className="pt-4 pb-3">
                <span className="text-primary" style={{ fontSize: '1.2rem' }}>
                    {t('reimbursement.editModal.description')}
                </span>
            </div>

            {category.reimbursementProductFields.map((_field, index) => (
                <DescriptionField
                    field={_field}
                    fieldIndex={index}
                    history={history}
                    reimbursementId={reimbursementId}
                    categoryUri={category['@id']}
                    key={`field-${_field.slug}`}
                />
            ))}
        </div>
    );
}

function DescriptionField({
    field,
    fieldIndex,
    history,
    reimbursementId,
    categoryUri,
}: {
    field: ReimbursementProductField;
    fieldIndex: number;
    history?: ReimbursementProductHistory;
    reimbursementId: number;
    categoryUri: string;
}) {
    const { values, setFieldValue } = useContext(FormikContext);
    const {
        reimbursementProductFields = [],
        reimbursementProductCodes = [],
    }: {
        reimbursementProductFields: Partial<ReimbursementProductField>[];
        reimbursementProductCodes: Partial<ReimbursementProductCode>[];
    } = values;
    const { name, slug, max_length = undefined, placeholder = '' } = field;
    const { t } = useTranslation('reimbursements');

    const existingFieldIndex = reimbursementProductFields.findIndex(
        (_item: Partial<ReimbursementProductField>) => _item.slug === slug
    );

    const existingCodeUris = reimbursementProductCodes.map(
        (_item: Partial<ReimbursementProductCodeModel>) => _item.reimbursementCode
    );

    const { reimbursementCodes } = useGetReimbursementCodesQuery(categoryUri, {
        selectFromResult: ({ data }) => ({
            reimbursementCodes: data
                ? data
                      .filter((_item: ReimbursementCode) => existingCodeUris.includes(_item['@id']))
                      .sort(HelperFunctions.sortByString('code'))
                      .map((_item: ReimbursementCode) => ({ name: _item.code }))
                : [],
        }),
    });

    const existingProductField =
        existingFieldIndex >= 0
            ? reimbursementProductFields[existingFieldIndex]
            : ({
                  text: '',
                  forceEmpty: false,
              } as ReimbursementProductField);

    const value = existingProductField.text ?? '';
    const forceEmpty = existingProductField.forceEmpty ?? false;

    const textLength = (input: string) => {
        return input
            .replace(/(<([^>]+)>)/gi, '')
            .replaceAll('&nbsp;', ' ')
            .trim().length;
    };

    let historyEntity = undefined;

    if (history) {
        historyEntity = history.reimbursementProductFields.find((_field) => _field.slug === slug);
    }

    return (
        <div className="py-4 px-3" style={{ backgroundColor: fieldIndex % 2 ? undefined : '#f7fbff' }}>
            <Row>
                <Col xs={3}>
                    <div className="pt-1">
                        <div className="font-weight-bold" style={{ color: '#344054', fontSize: 13 }}>
                            {name}

                            {placeholder !== '' && (
                                <Tooltip
                                    tooltip={`${t('reimbursement.product.tooltip.standardField')}: "${placeholder}"`}
                                    key={`tooltip-${slug}`}
                                >
                                    <QuestionCircle size={14} className="text-secondary ml-2" />
                                </Tooltip>
                            )}
                        </div>

                        {field.description !== '' && <div className="text-secondary small">{field.description}</div>}
                    </div>
                </Col>
                <Col>
                    <Row>
                        <RestrictedReimbursementContent
                            roles={[
                                Constants.reimbursementTeamRoles.editor,
                                Constants.reimbursementTeamRoles.finalEditor,
                                Constants.reimbursementTeamRoles.manager,
                                Constants.reimbursementTeamRoles.admin,
                            ]}
                        >
                            <Col>
                                <div className="textarea mb-1">
                                    <BasicEditor
                                        id={`${reimbursementId}-${fieldIndex}`}
                                        name={slug}
                                        tags={reimbursementCodes}
                                        onChange={({
                                            target,
                                        }: {
                                            target: {
                                                name: string;
                                                value: string;
                                            };
                                        }) => {
                                            if (existingFieldIndex >= 0) {
                                                if (
                                                    target.value === '' &&
                                                    reimbursementProductFields[existingFieldIndex].id === undefined
                                                ) {
                                                    // Delete
                                                    setFieldValue(
                                                        'reimbursementProductFields',
                                                        reimbursementProductFields.filter(
                                                            (_item) => _item.slug !== slug
                                                        )
                                                    );
                                                } else {
                                                    // Edit
                                                    setFieldValue(`reimbursementProductFields.${existingFieldIndex}`, {
                                                        ...existingProductField,
                                                        text: target.value,
                                                        forceEmpty: false,
                                                    });
                                                }
                                            } else {
                                                if (target.value !== '') {
                                                    const newField: Partial<ReimbursementProductField> = {
                                                        slug,
                                                        text: target.value,
                                                        forceEmpty: false,
                                                    };

                                                    setFieldValue(
                                                        'reimbursementProductFields',
                                                        reimbursementProductFields.concat([newField])
                                                    );
                                                }
                                            }
                                        }}
                                        value={value}
                                        placeholder={forceEmpty ? '' : placeholder}
                                    />
                                </div>

                                <div className="d-flex justify-content-between">
                                    {max_length !== undefined && max_length > 0 && (
                                        <ValueCounter length={textLength(value)} maxLength={max_length} />
                                    )}

                                    {placeholder !== '' && (
                                        <div className="form-check small">
                                            <Form.Check
                                                name={`force_empty-${fieldIndex}`}
                                                id={`force_empty-${fieldIndex}`}
                                                type="checkbox"
                                                disabled={value !== ''}
                                                checked={forceEmpty}
                                                onChange={() => {
                                                    if (existingFieldIndex >= 0) {
                                                        // Edit
                                                        setFieldValue(
                                                            `reimbursementProductFields.${existingFieldIndex}`,
                                                            {
                                                                ...existingProductField,
                                                                forceEmpty: !forceEmpty,
                                                            }
                                                        );
                                                    } else {
                                                        // Create
                                                        setFieldValue(
                                                            'reimbursementProductFields',
                                                            reimbursementProductFields.concat([
                                                                {
                                                                    slug,
                                                                    text: value,
                                                                    forceEmpty: !forceEmpty,
                                                                },
                                                            ])
                                                        );
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor={`force_empty-${fieldIndex}`}>
                                                {t('reimbursement.product.misc.leaveEmpty')}
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </RestrictedReimbursementContent>
                        <Col>
                            {historyEntity && (
                                <FieldHistory
                                    reimbursementProductUri={historyEntity.reimbursementProduct}
                                    reimbursementId={reimbursementId}
                                    className="ReimbursementProductField"
                                    entity={historyEntity}
                                    propName="text"
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

function ValueCounter({ length, maxLength }: { length: number; maxLength: number }) {
    const { t } = useTranslation('reimbursements');

    return (
        <div
            className={cx('small', {
                'text-danger': length > maxLength,
                'text-muted': length <= maxLength,
            })}
        >
            {t('reimbursement.product.misc.maxChars')}:&nbsp;{length}/{maxLength}
        </div>
    );
}
