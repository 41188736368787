import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { IconButton, LightOutlineButton, PrimaryButton, WarningButton } from 'components/Buttons';
import {
    useCreateGuardAliasMutation,
    useCreateGuardAuditMutation,
    useUpdateGuardEntityMutation,
} from 'features/publications/guardApi';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { CloudPlus, PauseCircleFill, Pencil, PlayCircle, ShieldShaded } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { GuardEntityStatusIcon } from './GuardEntityStatusIcon';
import { useGetGuardEntity } from './hooks/useGetGuardEntity';
import { DateTime } from 'luxon';
import { Form as FForm, Formik } from 'formik';
import { FormFieldWithTitle } from '../../helpers/FieldHelper';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { useGetPublication } from '../../hooks/useGetPublication';
import { Auditables } from './views/Auditables';
import { Skeleton } from 'components/Skeleton';
import { useGetGuardAliases } from 'pages/publications_v2/views/guard/hooks/useGetGuardAliases';
import { NftStatus } from 'pages/publications_v2/views/guard/views/NftStatus';

export function GuardPublicationModal() {
    const [guardModal] = useQueryParam('guardModal', StringParam);

    if (!guardModal) {
        return null;
    }

    return <ModalContent guardEntityId={guardModal} />;
}

function ModalContent({ guardEntityId }) {
    const guardEntity = useGetGuardEntity(guardEntityId);
    const { t } = useTranslation();
    const [, setGuardModal] = useQueryParam('guardModal', StringParam);
    const [, setPage] = useQueryParam('page', NumberParam);
    const publication = useGetPublication(guardEntity?.publicationId);

    const defaultTitle = publication?.name;

    return (
        <Modal show={true} onHide={onHide} size="xl">
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    <div className="flex-center">
                        <ShieldShaded className="mr-2" />
                        {t('publications:guard.guardIntegrity')} -&nbsp;
                        {publication ? (
                            publication.name
                        ) : (
                            <div style={{ width: 160 }}>
                                <Skeleton />
                            </div>
                        )}
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {guardEntity && <ModalBody guardEntity={guardEntity} defaultTitle={defaultTitle} />}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="btn btn-secondary" onClick={onHide}>
                    {t('global:btn.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function onHide() {
        setGuardModal(undefined);
        setPage(undefined);
    }
}

function ModalBody({ guardEntity, defaultTitle = '' }) {
    const { t, i18n } = useTranslation();
    const [updateGuardEntity] = useUpdateGuardEntityMutation();
    const [createGuardAudit] = useCreateGuardAuditMutation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [showRenameForm, setShowRenameForm] = useState(false);
    const [showCreateAliasForm, setShowCreateAliasForm] = useState(false);
    const guardAliases = useGetGuardAliases(guardEntity['@id']);

    const currentDescription = _.isEmpty(guardEntity.description) ? defaultTitle : guardEntity.description;

    return (
        <>
            <div className="d-flex mb-3">
                <GuardEntityStatusIcon guardEntity={guardEntity} />

                <div>
                    <div className="flex-center">
                        <div className="font-weight-bold mr-2" style={{ fontSize: '1rem' }}>
                            {currentDescription}
                        </div>

                        <IconButton
                            tooltip={t('publications:guard.tooltips.editFriendlyName')}
                            icon={<Pencil className="flex-shrink-0" size={12} />}
                            onClick={() => setShowRenameForm(!showRenameForm)}
                        />
                    </div>
                    <div className="small text-muted">
                        {t('publications:guard.messages.integrityCheckFor')}{' '}
                        <a href={guardEntity.url} target="_blank">
                            {guardEntity.url}
                        </a>
                    </div>
                </div>
            </div>

            <div className="small flex-center mb-4">
                {renderCreatedTimestamp()}&nbsp;&bull;&nbsp;
                <div>{guardEntity.contentHash}</div>
            </div>

            <div className="mb-4">
                <div className="flex-center">
                    {guardEntity.watchEnabled ? (
                        <LightOutlineButton size="sm" onClick={pause} className="pl-2">
                            <PauseCircleFill size={14} className="flex-shrink-0 mr-1" />
                            {t('publications:guard.buttons.pause')}
                        </LightOutlineButton>
                    ) : (
                        <LightOutlineButton size="sm" onClick={resume} className="pl-2">
                            <PlayCircle size={14} className="flex-shrink-0 mr-1" />
                            {t('publications:guard.buttons.resume')}
                        </LightOutlineButton>
                    )}

                    <LightOutlineButton
                        size="sm"
                        onClick={() => setShowCreateAliasForm(!showCreateAliasForm)}
                        className="ml-2 pl-2"
                        disabled={guardAliases.totalItems >= 2}
                    >
                        <CloudPlus size={16} className="flex-shrink-0 mr-1" />
                        {t('publications:guard.buttons.addAlias')}
                    </LightOutlineButton>

                    <LightOutlineButton size="sm" onClick={addCheck} className="ml-2 pl-2">
                        <ShieldShaded size={14} className="flex-shrink-0 mr-1" />
                        {t('publications:guard.buttons.checkNow')}
                    </LightOutlineButton>

                    {renderLastCheckDate()}
                </div>

                {showRenameForm && (
                    <RenameForm
                        currentDescription={currentDescription}
                        guardEntity={guardEntity}
                        hideForm={() => setShowRenameForm(false)}
                    />
                )}
                {showCreateAliasForm && (
                    <CreateAliasForm guardEntity={guardEntity} hideForm={() => setShowCreateAliasForm(false)} />
                )}
            </div>

            <NftStatus guardEntity={guardEntity} />
            <Auditables guardEntity={guardEntity} />
        </>
    );

    function renderLastCheckDate() {
        if (!guardEntity.lastCheckDate) {
            return null;
        }

        const timestamp = DateTime.fromISO(guardEntity.lastCheckDate);

        return (
            <div className="ml-2 text-muted small">
                {t('publications:guard.messages.lastCheckDate', {
                    timestamp: timestamp.setLocale(i18n.language).toRelative(),
                })}
            </div>
        );
    }

    function renderCreatedTimestamp() {
        const timestamp = DateTime.fromISO(guardEntity.createdAt);

        return (
            <div>
                {t('publications:guard.messages.addedOnTimestamp', {
                    timestamp: timestamp.setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED),
                })}
            </div>
        );
    }

    function resume() {
        updateGuardEntity({ uri: guardEntity['@id'], body: { watchEnabled: true }, token: guardApiToken });
    }

    function pause() {
        updateGuardEntity({ uri: guardEntity['@id'], body: { watchEnabled: false }, token: guardApiToken });
    }

    function addCheck() {
        createGuardAudit({ uri: guardEntity['@id'], body: {}, token: guardApiToken });
    }
}

function RenameForm({ guardEntity, hideForm, currentDescription = '' }) {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [updateGuardEntity] = useUpdateGuardEntityMutation();

    return (
        <div className="mt-3">
            <Formik initialValues={{ description: currentDescription }} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <FForm>
                        <FormFieldWithTitle label={t('publications:guard.labels.description')} name="description" />

                        <PrimaryButton disabled={isSubmitting} type="submit">
                            {t('global:btn.save')}
                        </PrimaryButton>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function handleSubmit(values) {
        updateGuardEntity({ uri: guardEntity['@id'], body: values, token: guardApiToken }).then(() => {
            hideForm();
        });
    }
}

function CreateAliasForm({ guardEntity, hideForm }) {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [createGuardAlias] = useCreateGuardAliasMutation();

    return (
        <div className="mt-3">
            <Formik initialValues={{ guardEntity: guardEntity['@id'], url: '' }} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <FForm>
                        <FormFieldWithTitle label={t('publications:guard.labels.alias')} name="url" />

                        <WarningButton disabled={isSubmitting} type="submit">
                            {t('publications:guard.buttons.addAlias')}
                        </WarningButton>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function handleSubmit(values) {
        createGuardAlias({ uri: guardEntity['@id'], body: values, token: guardApiToken }).then(() => {
            hideForm();
        });
    }
}
