import { useGetTranslationLanguageExportFilterQuery } from 'features/translations/translationApi';
import { JsonParam, NumberParam, useQueryParam } from 'use-query-params';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useContext } from 'react';
import { EditContext } from 'pages/translation/views/view/EditContextWrapper';

export function useGetTranslationLanguageExportFilter(id) {
    const [page = 1] = useQueryParam('page', NumberParam);
    const [filterOptions] = useQueryParam('filters', JsonParam);
    const { selectedEntities } = useContext(EditContext);

    const params = {
        page,
    };

    const body = {
        ...defaultFilterOptions,
        ...filterOptions,
        entities: selectedEntities.map(({ entityReference }) => entityReference.uri),
    };

    return useGetTranslationLanguageExportFilterQuery(
        { id, params, body },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result),
            skip: !id,
        },
    );
}

export const defaultFilterOptions = {
    content_verified: false,
    content_unverified: true,
    content_not_translated_yet: true,
    content_excluded: false,
};
