import HelperFunctions from 'pages/global/HelperFunctions';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';
import { useParams } from 'react-router-dom';

export function useGetTranslationLanguage() {
    const { language } = useParams();
    const translationContainer = useGetTranslationContainer();

    if (!translationContainer || !language) {
        return undefined;
    }

    return HelperFunctions.getByValue(translationContainer.translationLanguages, 'languageIso', language);
}
