import { useAddTranslationMutation, useUpdateTranslationMutation } from 'features/translations/translationApi';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { LoadingSpinner } from 'pages/global/Spinner';
import { HtmlInputField, TextInputField } from '../../forms/FieldHelper';
import _ from 'lodash';
import { EditTableTranslationContent } from './document/blocks/TableBlock';
import HelperFunctions from '../../../global/HelperFunctions';
import { useSelector } from 'react-redux';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import { useState } from 'react';
import { entityTypes, translationContainerTypes } from '../../config/Constants';
import Modal from '../../../global/Modal';
import TagList from '../../../documents/misc/_ReviewDocument/TagList';
import { WarningButton } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { EditFileTranslationContent } from 'pages/translation/views/translate/document/blocks/FileBlock';

export default function EditTranslation({
    translation = {},
    close,
    languageIso,
    translationKey,
    isHtml = false,
    handleTranslationMutation,
    content,
    translationContainer,
    contentHashMatch = true,
}) {
    const [addTranslation] = useAddTranslationMutation();
    const [updateTranslation] = useUpdateTranslationMutation();
    const { variantId } = useSelector((state) => state.translation.sidebarFilters);
    const { t } = useTranslation('translations');

    const { unverified = true } = translation;
    const { translationPerVariant = false } = translationKey;

    return (
        <Formik
            initialValues={{
                translation: getInitialTranslationValue(),
            }}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {({ dirty, isSubmitting }) => (
                <Form autoComplete="off">
                    <EditTranslationContent
                        isHtml={isHtml}
                        translationKey={translationKey}
                        content={content}
                        translationContainer={translationContainer}
                    />

                    <div className="mt-3">
                        <WarningButton type="submit" disabled={disableSubmitButton(dirty)} className="mr-2">
                            {t('btn.save')}
                        </WarningButton>

                        <RestrictedTranslationContent
                            id={translationContainer.id}
                            roles={[
                                Constants.translationContainerTeamRoles.manager,
                                Constants.translationContainerTeamRoles.finalEditor,
                            ]}
                        >
                            <Button
                                type="submit"
                                variant={unverified ? 'success' : 'danger'}
                                disabled={!translation.hasOwnProperty('id') || isSubmitting}
                                onClick={handleUpdate}
                            >
                                {unverified
                                    ? t('translation.navbar.dashboard.translation.btn.approve')
                                    : t('translation.navbar.dashboard.translation.btn.reject')}
                            </Button>
                        </RestrictedTranslationContent>

                        <Button variant="link" onClick={close}>
                            {t('btn.close')}
                        </Button>

                        <LoadingSpinner isSubmitting={isSubmitting} />
                    </div>
                </Form>
            )}
        </Formik>
    );

    function disableSubmitButton(dirty) {
        if (dirty) {
            return false;
        }

        if (!unverified && !contentHashMatch) {
            return false;
        }

        return true;
    }

    function getInitialTranslationValue() {
        const translationValue = translation?.translation ?? '';

        const { entityType, property } = translationKey;

        if (entityType === 'block' && property === 'data') {
            return HelperFunctions.tryParseJSON(translationValue, {
                title: '',
                data: [],
            });
        }

        return translationValue
            .replaceAll('&#039;', "'")
            .replaceAll('€', '&euro;')
            .replace(/\u00A0/g, '&nbsp;');
    }

    function handleSubmit(values, { setSubmitting }) {
        let translationValue = values.translation;

        if (_.isObject(translationValue)) {
            translationValue = JSON.stringify(translationValue);
        }

        // Process cross-references, replacing '=' with '&#61;'
        translationValue = translationValue.replaceAll(/\[(Ref )(.*?)]/gm, (p1) => p1.replace(/=/g, '&#61;'));

        if (translation.hasOwnProperty('id')) {
            // Update
            updateTranslation({
                uri: translation['@id'],
                body: {
                    translation: translationValue,
                    contentSource: content,
                    contentHashChanged: false,
                },
            }).then(({ data }) => {
                setSubmitting(false);

                if (_.isFunction(handleTranslationMutation)) {
                    handleTranslationMutation(data);
                }
            });
        } else {
            // Create
            addTranslation({
                translation: translationValue,
                contentSource: content,
                languageIso,
                translationKey: translationKey['@id'],
                documentVariantId: translationPerVariant ? variantId : undefined,
            }).then(({ data }) => {
                setSubmitting(false);

                if (_.isFunction(handleTranslationMutation)) {
                    handleTranslationMutation(data);
                }
            });
        }
    }

    function handleUpdate(event) {
        event.preventDefault();

        const closeAfterSave = unverified;

        updateTranslation({
            uri: translation['@id'],
            body: {
                unverified: !unverified,
                contentHashChanged: false,
            },
        }).then(({ data }) => {
            if (_.isFunction(handleTranslationMutation)) {
                handleTranslationMutation(data);
            }

            if (closeAfterSave) {
                close();
            }
        });
    }
}

function EditTranslationContent({ isHtml, translationKey, content, translationContainer }) {
    const { entityType, property } = translationKey;

    if (entityType === entityTypes.BLOCK && property === 'data') {
        return (
            <EditTableTranslationContent
                translationKey={translationKey}
                content={content}
                translationContainer={translationContainer}
            />
        );
    }

    if (entityType === entityTypes.BLOCK && property === 'uri') {
        return <EditFileTranslationContent content={content} />;
    }

    return (
        <>
            {isHtml ? (
                <HtmlInputField
                    name="translation"
                    tags={[...content.matchAll(/\[(Tag |)(.*?)]/gm)].map((_match) => _match[2])}
                />
            ) : (
                <div className="d-flex align-items-center">
                    <TextInputField name="translation" />

                    {translationContainer['@type'] === translationContainerTypes.DOCUMENT && (
                        <InsertTagModal documentId={translationContainer.documentId} />
                    )}
                </div>
            )}
        </>
    );
}

function InsertTagModal({ documentId }) {
    const [showTagModal, setShowTagModal] = useState(false);
    const { t } = useTranslation('translations');

    return (
        <>
            <div className="uk-link ml-2" style={{ minWidth: 90 }} onClick={() => setShowTagModal(true)}>
                {t('translation.navbar.dashboard.translation.insertTag')}
            </div>

            {showTagModal && (
                <Modal modalStyle="centeredAutoWidth" opened={true}>
                    <TagList close={() => setShowTagModal(false)} documentId={documentId} />
                </Modal>
            )}
        </>
    );
}
