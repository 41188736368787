import MainContentNav from '../../Navbar';
import { useParams } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { Sso } from './Sso';
import { Profile } from './Profile';
import { Users } from './Users';
import { Billing } from 'pages/account/views/Billing';
import { useTranslation } from 'react-i18next';
import { Guard } from './Guard';

export function Settings() {
    const { t: tGlobal } = useTranslation('global');
    const { t } = useTranslation('users');

    return (
        <>
            <MainContentNav pages={[{ title: tGlobal('avatar.manageAccount') }, { title: t('profile.settings') }]} />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <Sidebar />
                    <View />
                </div>
            </div>
        </>
    );
}

const RenderViews = {
    profile: Profile,
    users: Users,
    sso: Sso,
    billing: Billing,
    guard: Guard,
};

function View() {
    const { view = 'profile' } = useParams();
    const ViewToRender = RenderViews[view] ?? DefaultView;

    return (
        <div className="flex-grow-1 overflow-auto">
            <div id="content" className="px-5 pt-5 pb-3">
                <div className="dr-container p-4">
                    <ViewToRender />
                </div>
            </div>
        </div>
    );
}

function DefaultView() {
    return <div>no view</div>;
}
