import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
    translationApi,
    translationDocumentApi,
    translationReimbursementApi,
    useSyncTranslationLanguageMutation,
} from 'features/translations/translationApi';
import { Button, Col, Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import Spinner from '../../../global/Spinner';
import { EDIT_PATH, VIEW_PATH } from 'scenes/Translations';
import { useEffect, useState } from 'react';
import {
    translationContainerTypes,
    translationLanguageStatus,
    translationLanguageStatusValues,
    views,
} from '../../config/Constants';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { ArrowRepeat, ExclamationTriangle } from 'react-bootstrap-icons';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import { DownloadXliffModal } from '../../modals/DownloadXliffModal';
import { UploadXliffModal } from '../../modals/UploadXliffModal';
import { getLanguageName } from '../../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';
import { useGetTranslationLanguages } from 'pages/translation/hooks/useGetTranslationLanguages';
import { ActionLinkButton } from 'components/Buttons';

export default function Dashboard() {
    const { translationContainerId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [downloadXliffFile, setDownloadXliffFile] = useState(null);
    const [uploadXliffFile, setUploadXliffFile] = useState(null);
    const { t } = useTranslation('translations');
    const translationContainer = useGetTranslationContainer();
    const { translationLanguages, isLoading } = useGetTranslationLanguages();

    const reloadList = () => {
        dispatch(
            translationApi.endpoints.getTranslationLanguages.initiate(translationContainerId, {
                subscribe: false,
                forceRefetch: true,
            }),
        );
    };

    return (
        <div className="h-100 pt-4">
            <Container>
                <Row>
                    <Col>
                        <div className="data-table data-table-translations">
                            <div className="dr-container mb-2">
                                <div className="data-table-header d-flex align-items-center pl-3 py-3">
                                    <div className="item-col-title d-flex align-items-center">
                                        {t('translation.navbar.dashboard.columnTItles.name')}
                                    </div>

                                    <div className="item-col-name d-flex align-items-center">
                                        {t('translation.navbar.dashboard.columnTItles.progress')}
                                    </div>

                                    <div className="item-col-languages d-flex align-items-center">
                                        {t('translation.navbar.dashboard.columnTItles.status')}
                                    </div>

                                    <div className="item-col-actions">&nbsp;</div>
                                </div>

                                {translationLanguages.map((_language) => (
                                    <TranslationLanguage
                                        translationContainer={translationContainer}
                                        translationContainerId={translationContainerId}
                                        translationLanguage={_language}
                                        reloadList={reloadList}
                                        setDownloadXliffFile={setDownloadXliffFile}
                                        setUploadXliffFile={setUploadXliffFile}
                                        key={`language-${_language.id}`}
                                    />
                                ))}

                                {!isLoading && translationLanguages.length === 0 && (
                                    <div className="text-center small text-secondary py-5">
                                        <p>{t('translation.navbar.dashboard.noLanguages')}</p>

                                        <RestrictedTranslationContent
                                            id={translationContainerId}
                                            roles={[Constants.translationContainerTeamRoles.manager]}
                                        >
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                onClick={() => {
                                                    history.push(
                                                        generatePath(VIEW_PATH, {
                                                            translationContainerId,
                                                            view: 'properties',
                                                            detail: 'languages',
                                                        }),
                                                    );
                                                }}
                                            >
                                                {t('translation.navbar.dashboard.btn.addLanguage')}
                                            </Button>
                                        </RestrictedTranslationContent>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {downloadXliffFile && (
                <DownloadXliffModal translationLanguage={downloadXliffFile} hide={() => setDownloadXliffFile(null)} />
            )}

            {uploadXliffFile && (
                <UploadXliffModal translationLanguage={uploadXliffFile} hide={() => setUploadXliffFile(null)} />
            )}
        </div>
    );
}

function TranslationLanguage({
    translationLanguage,
    translationContainer,
    translationContainerId,
    reloadList,
    setDownloadXliffFile,
    setUploadXliffFile,
}) {
    const history = useHistory();
    const { t, i18n } = useTranslation('translations');

    const { id, languageIso, progress, status, syncedAt = null } = translationLanguage;
    const languageName = getLanguageName(languageIso);

    useEffect(() => {
        const interval = setInterval(() => {
            if (status === translationLanguageStatus.SYNC_IN_PROGRESS) {
                // Refresh every 5 seconds when there are preview tokens
                reloadList();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [status]);

    const createdAtDateTime = DateTime.fromISO(syncedAt);

    return (
        <div className="data-table-item">
            <div className="data-table-item-header d-flex align-items-stretch p-3">
                <div className="item-col-title item-col-title-content d-flex align-items-center">
                    <div>
                        <ActionLinkButton
                            onClick={() => {
                                history.push(
                                    generatePath(EDIT_PATH, {
                                        translationContainerId: translationContainer.id,
                                        translationLanguageId: id,
                                        view:
                                            translationContainer['@type'] === translationContainerTypes.DOCUMENT
                                                ? views.DOCUMENT
                                                : undefined,
                                    }),
                                );
                            }}
                            className="font-weight-bold px-0"
                        >
                            {languageName}
                        </ActionLinkButton>

                        {syncedAt !== null && (
                            <div className="small text-muted mt-1">
                                {createdAtDateTime.setLocale(i18n.language).toRelative()} gesynchroniseerd
                            </div>
                        )}
                    </div>
                </div>

                <div className="item-col-name item-col-name-content d-flex align-items-center">{progress}%</div>

                <div className="item-col-languages item-col-title-languages d-flex align-items-center">
                    {status === translationLanguageStatus.SYNC_IN_PROGRESS && <Spinner className="mr-2" />}
                    {(status === translationLanguageStatus.CREATED ||
                        status === translationLanguageStatus.SYNC_FAILED) && (
                        <ExclamationTriangle size={18} className="text-danger mr-1" />
                    )}
                    {status === translationLanguageStatus.TRANSLATION_IN_PROGRESS && (
                        <ArrowRepeat size={18} className="text-warning mr-1" style={{ transform: 'rotate(45deg)' }} />
                    )}

                    <div
                        className={cx({
                            'text-danger':
                                status === translationLanguageStatus.CREATED ||
                                status === translationLanguageStatus.SYNC_FAILED,
                            'text-secondary': status === translationLanguageStatus.SYNC_IN_PROGRESS,
                            'text-warning': status === translationLanguageStatus.TRANSLATION_IN_PROGRESS,
                        })}
                    >
                        {t(translationLanguageStatusValues[status]) ?? status}
                    </div>
                </div>

                <div className="item-col-actions item-col-actions-content d-flex align-items-center">
                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[
                            Constants.translationContainerTeamRoles.manager,
                            Constants.translationContainerTeamRoles.finalEditor,
                            Constants.translationContainerTeamRoles.editor,
                        ]}
                    >
                        <TranslationLanguageActionButton
                            translationLanguage={translationLanguage}
                            translationContainer={translationContainer}
                            setDownloadXliffFile={setDownloadXliffFile}
                            setUploadXliffFile={setUploadXliffFile}
                        />
                    </RestrictedTranslationContent>

                    <RestrictedTranslationContent
                        id={translationContainerId}
                        roles={[Constants.translationContainerTeamRoles.spectator]}
                    >
                        <Button
                            onClick={() => {
                                history.push(
                                    generatePath(EDIT_PATH, {
                                        translationContainerId,
                                        translationLanguageId: id,
                                        view:
                                            translationContainer['@type'] === translationContainerTypes.DOCUMENT
                                                ? views.DOCUMENT
                                                : undefined,
                                    }),
                                );
                            }}
                        >
                            {t('translation.navbar.dashboard.btn.open')}
                        </Button>
                    </RestrictedTranslationContent>
                </div>
            </div>
        </div>
    );
}

function TranslationLanguageActionButton({
    translationLanguage,
    translationContainer,
    setDownloadXliffFile,
    setUploadXliffFile,
}) {
    const { id, status, migrationCompleted } = translationLanguage;
    const [syncTranslationLanguage] = useSyncTranslationLanguageMutation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    if (translationContainer === undefined) {
        return null;
    }

    if (translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT && migrationCompleted === false) {
        return (
            <Button variant="warning" onClick={handleSync}>
                {t('translation.navbar.dashboard.btn.migrate')}
            </Button>
        );
    }

    return (
        <>
            {status === translationLanguageStatus.CREATED ? (
                <Button variant="primary" onClick={handleSync}>
                    {t('translation.navbar.dashboard.btn.synchronize')}
                </Button>
            ) : (
                <SplitButton
                    id={`${id}-btn`}
                    disabled={status === translationLanguageStatus.SYNC_IN_PROGRESS}
                    title={t('translation.navbar.dashboard.btn.open')}
                    variant="primary"
                    className="mr-3"
                    menuAlign="right"
                    onClick={() => {
                        history.push(
                            generatePath(EDIT_PATH, {
                                translationContainerId: translationContainer.id,
                                translationLanguageId: id,
                                view:
                                    translationContainer['@type'] === translationContainerTypes.DOCUMENT
                                        ? views.DOCUMENT
                                        : undefined,
                            }),
                        );
                    }}
                >
                    <Dropdown.Item
                        eventKey="sync"
                        onSelect={(eventKey, e) => {
                            e.stopPropagation();
                            handleSync();
                        }}
                    >
                        {t('translation.navbar.dashboard.splitMenu.synchronize')}
                    </Dropdown.Item>
                    {/*<Dropdown.Divider />*/}
                    {/*<Dropdown.Item*/}
                    {/*    eventKey="download_xliff"*/}
                    {/*    onSelect={(eventKey, e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        setDownloadXliffFile(translationLanguage);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {t('translation.navbar.dashboard.splitMenu.downloadXLIFF')}&hellip;*/}
                    {/*</Dropdown.Item>*/}
                    {/*<Dropdown.Item*/}
                    {/*    eventKey="upload_xliff"*/}
                    {/*    onSelect={(eventKey, e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        setUploadXliffFile(translationLanguage);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {t('translation.navbar.dashboard.splitMenu.uploadXLIFF')}&hellip;*/}
                    {/*</Dropdown.Item>*/}
                </SplitButton>
            )}
        </>
    );

    function handleSync() {
        syncTranslationLanguage(translationLanguage['@id']).then(() => {
            if (translationContainer['@type'] === translationContainerTypes.DOCUMENT) {
                const { documentId } = translationContainer;

                dispatch(
                    translationDocumentApi.util.invalidateTags([
                        { type: 'Document', id: documentId },
                        { type: 'DocumentVariant', id: documentId },
                        { type: 'Tag', id: 'LIST' },
                        { type: 'Area' },
                    ]),
                );
            } else if (translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT) {
                dispatch(translationReimbursementApi.util.invalidateTags([{ type: 'ReimbursementProduct' }]));
            }
        });
    }
}
