import { createContext, useContext, useState } from 'react';
import { RightSideViewSwitch } from '../../documents_v2/views/edit/RightSideViewSwitch';
import { Button } from 'react-bootstrap';
import { CaretLeftFill, CaretRightFill, X } from 'react-bootstrap-icons';
import DocumentHelper from '../../global/DocumentHelper';
import AreaHelper from '../../global/AreaHelper';
import Tooltip from '../../global/Tooltip';
import cx from 'classnames';
import { AreaContext } from '../../documents_v2/views/Area';
import { useGetDocument } from '../../documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';
import { AreaDisplayMode } from 'pages/documents_v2/views/edit_area/AreaDisplayMode';

export const VariantFilterContext = createContext({
    selectedVariants: [],
    setSelectedVariants: (variants = []) => {},
});

export function AreaLayout({ justifyCenter = false, children }) {
    const [selectedVariants, setSelectedVariants] = useState([]);

    return (
        <VariantFilterContext.Provider value={{ selectedVariants, setSelectedVariants }}>
            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto py-4">
                        <div
                            className={cx('d-flex px-5', {
                                'justify-content-center': justifyCenter,
                                'justify-content-between': !justifyCenter,
                            })}
                            style={{ gap: 20 }}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </VariantFilterContext.Provider>
    );
}

export function AreaLayoutPageLeft({ area, showRightSide = true, changesDetected = false, children }) {
    return (
        <div
            style={{
                transition: 'flex-basis 300ms ease-in-out',
                flexBasis: showRightSide ? '50%' : '95%',
            }}
        >
            <div
                className="bg-white"
                style={{
                    overflow: 'hidden',
                    boxShadow: '0 1px 3px 1px rgb(60 64 67 / 15%)',
                }}
            >
                <AreaDisplayMode area={area} dirty={changesDetected} />
                <div className="area-edit-page">{children}</div>
            </div>
        </div>
    );
}

export function AreaLayoutPageRight({ showRightSide = true, showPageNav = true, children }) {
    return (
        <div
            style={{
                transition: 'width 300ms ease-in-out',
                width: showRightSide ? '50%' : '5%',
            }}
        >
            <div
                className={cx('bg-white', {
                    'h-100': !showRightSide,
                })}
                style={{
                    boxShadow: '0 1px 3px 1px rgb(60 64 67 / 15%)',
                }}
            >
                {showRightSide && showPageNav && (
                    <div className="mb-3">
                        <RightSideViewSwitch showRightSide={showRightSide} />
                    </div>
                )}
                <div className="area-edit-page">{children}</div>
            </div>
        </div>
    );
}

export function AreaLayoutNav({ area, redirectToArea, handleClose, children }) {
    const document = useGetDocument(undefined, true);
    const { activeVariantId } = useContext(AreaContext);

    const prefix = DocumentHelper.getPrefix(area, activeVariantId);
    const nextArea = AreaHelper.getNextArea(document, area);
    const previousArea = AreaHelper.getPreviousArea(document, area);
    const { t } = useTranslation('documents');

    return (
        <nav
            className="bg-white border-bottom py-3 px-4 navbar navbar-expand navbar-light fixed-top"
            style={{ height: 73 }}
        >
            <button
                className="uk-button uk-button-light mr-2 px-2 flex-shrink-0"
                onClick={() => redirectToArea(previousArea.id)}
                data-uk-tooltip={t('document.navbar.main.editor.navbar.previewsArticle')}
                disabled={!previousArea}
                style={{ lineHeight: '30px' }}
            >
                <CaretLeftFill size={12} />
            </button>

            <button
                className="uk-button uk-button-light mr-3 px-2 flex-shrink-0"
                onClick={() => redirectToArea(nextArea.id)}
                data-uk-tooltip={t('document.navbar.main.editor.navbar.nextArticle')}
                disabled={!nextArea}
                style={{ lineHeight: '30px' }}
            >
                <CaretRightFill size={12} />
            </button>

            {prefix && <div className="mr-2">{prefix}</div>}

            <div
                className="font-weight-bolder"
                style={{
                    fontSize: '1.2rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {area.title}
            </div>

            <div className="d-flex align-items-center ml-auto flex-shrink-0">
                {children}

                <Tooltip tooltip={t('btn.close')} placement="bottom">
                    <Button onClick={handleClose} variant="link" className="text-secondary flex-shrink-0 p-0">
                        <X size={26} />
                    </Button>
                </Tooltip>
            </div>
        </nav>
    );
}
