import { useGetBlockAtTimestampQuery } from 'features/documents/documents';

export function useGetBlockAtTimestamp(blockId, timestamp) {
    const { block } = useGetBlockAtTimestampQuery(
        { blockId, timestamp },
        {
            selectFromResult: ({ data }) => ({
                block: data,
            }),
        },
    );

    return block;
}
