import { Navbar } from 'react-bootstrap';
import { generatePath, Link, useParams } from 'react-router-dom';
import cx from 'classnames';
import { VIEW_PATH } from 'scenes/Translations';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import Constants from '../../../../config/Constants';
import { useTranslation } from 'react-i18next';

export default function SubNav({ translationContainer }) {
    const { view } = useParams();
    const { t } = useTranslation('translations');

    return (
        <Navbar fixed="top" className="subnav bg-light border-bottom px-3 py-0">
            <div className="nav w-100">
                <div className="nav-item">
                    <Link
                        to={generatePath(VIEW_PATH, {
                            translationContainerId: translationContainer.id,
                        })}
                        className={cx('nav-link', {
                            active: view === undefined,
                        })}
                    >
                        {t('translation.navbar.dashboard.title')}
                    </Link>
                </div>
                <RestrictedTranslationContent id={translationContainer.id} roles={[Constants.translationContainerTeamRoles.manager]}>
                    <div className="nav-item">
                        <Link
                            to={generatePath(VIEW_PATH, {
                                translationContainerId: translationContainer.id,
                                view: 'properties',
                            })}
                            className={cx('nav-link', {
                                active: view === 'properties',
                            })}
                        >
                            {t('translation.navbar.settings.title')}
                        </Link>
                    </div>
                </RestrictedTranslationContent>
            </div>
        </Navbar>
    );
}
