import { useCurrentOrganisation } from '../../../../../hooks/useCurrentOrganisation';
import { useGetGuardEntitiesQuery } from '../../../../../features/publications/guardApi';

const emptyArray = [];

export function useGetGuardEntities(publicationId, publicationVersionId, skip = false) {
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;

    let params = {};

    if (publicationId) {
        params.publicationId = publicationId;
    }

    if (publicationId) {
        params.publicationVersionId = publicationVersionId;
        params.pagination = false;
    }

    return useGetGuardEntitiesQuery(
        { token: guardApiToken, params },
        {
            selectFromResult: ({ data, isUninitialized, isFetching }) => {
                const guardEntities = data ?? emptyArray;

                return {
                    guardEntities,
                    isLoading: isUninitialized || (isFetching && guardEntities.length === 0),
                };
            },
            skip,
        },
    );
}
