import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { useTranslation } from 'react-i18next';
import {
    useCreatePublicationGroupMutation,
    useEditPublicationGroupMutation,
} from '../../../features/publications/publicationApi';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../features/publications/publicationsSlice';
import { generatePath, useHistory } from 'react-router-dom';
import { INDEX_PATH } from '../../../scenes/PublicationsV2';
import Constants from '../../../config/Constants';

export default function EditFolderModal() {
    const { t } = useTranslation('global');
    const dispatch = useDispatch();
    const { group } = useSelector((state) => state.publications.modals);

    const showModal = !!group;

    return (
        <FormModal
            show={showModal}
            onHide={handleClose}
            title={group.hasOwnProperty('id') ? t('contentSidebar.map.title.edit') : t('contentSidebar.map.title.new')}
        >
            {showModal && <ModalContent group={group} handleClose={handleClose} />}
        </FormModal>
    );

    function handleClose() {
        dispatch(closeModal('group'));
    }
}

function ModalContent({ group, handleClose }) {
    const history = useHistory();
    const { t } = useTranslation('global');
    const organisationId = useActiveOrganisation();
    const [createPublicationGroup] = useCreatePublicationGroupMutation();
    const [editPublicationGroup] = useEditPublicationGroupMutation();

    return (
        <Formik
            initialValues={{
                name: group.name ?? '',
                parent: group.parent ?? null,
                rootLevel: group.rootLevel ?? false,
                sortOrder: group.sortOrder ?? null,
                organisationId,
            }}
            validationSchema={NewFolderSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, errors, touched, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <Form.Group>
                            <Field
                                as={Form.Control}
                                id="name"
                                name="name"
                                placeholder={t('contentSidebar.map.titleField')}
                                isInvalid={errors.name && touched.name}
                                isValid={!errors.name && touched.name}
                            />
                        </Form.Group>
                    </Modal.Body>

                    <ModalFooter dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values, { setSubmitting }) {
        if (group.hasOwnProperty('id')) {
            editPublicationGroup({ uri: group['@id'], body: values }).then(() => {
                setSubmitting(false);
                handleClose();
            });
        } else {
            createPublicationGroup(values).then(({ data }) => {
                setSubmitting(false);
                handleClose();

                // Navigate to new group
                history.push(
                    generatePath(INDEX_PATH, {
                        folder: Constants.folders.folder,
                        folderId: data.id,
                    }),
                );
            });
        }
    }
}

const NewFolderSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});
