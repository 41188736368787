import { isRejectedWithValue } from '@reduxjs/toolkit';
import { showError } from './helpers/errorHelper';
import _ from 'lodash';
import { getI18n } from 'react-i18next';

export const errorLogger = (api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 400 || action.payload.status === 401) {
            return;
        }

        const i18n = getI18n();
        const message = _.get(action.payload, 'data.error.exception.0.message', '');

        if (message !== 'AreaLockedByUser') {
            showError(i18n.t('global:error.genericErrorMessage'));
        }
    }

    return next(action);
};
