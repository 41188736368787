import { Button, Col, Form, FormControl } from 'react-bootstrap';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { setFilter, setSelectedRows } from 'features/publications/publicationsSlice';
import { BulkActionsButton, BulkActionsText } from './BulkActions';
import { DocRevSelect } from '../../helpers/FieldHelper';
import RestrictedContent from '../../../global/RestrictedContent';
import { AccountRestrictions, Permissions } from 'config/Constants';
import { generatePath, useHistory } from 'react-router-dom';
import { EDIT_PATH } from 'scenes/PublicationsV2';
import HelperFunctions from '../../../global/HelperFunctions';
import { debounce } from 'lodash';
import { useGetDocuments } from 'pages/documents_v2/hooks/useGetDocuments';
import { useTranslation } from 'react-i18next';
import { useHasAccountRestriction } from 'hooks/useHasAccountRestriction';

export default function Filters({ folderId }) {
    const dispatch = useDispatch();
    const documents = useGetDocuments();
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const history = useHistory();
    const { t } = useTranslation('publications');

    const allowOnlyFromDocumentTemplate = useHasAccountRestriction(
        AccountRestrictions.document.allowOnlyFromDocumentTemplate,
    );

    const [searchValue, setSearchValue] = useState(search);

    function setStoreFilter(key, value) {
        dispatch(setFilter({ key, value }));
    }

    const handleChange = (newValue) => {
        setStoreFilter('search', newValue);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        debouncedResults(searchValue);
    }, [searchValue]);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    useEffect(() => {
        dispatch(setSelectedRows([]));
    }, [folderId]);

    return (
        <Form
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <div className="row align-items-center" style={{ marginTop: '5px' }}>
                <Col sm={3} md={5} lg={4} xl={3}>
                    <FormControl
                        type="search"
                        placeholder={`${t('publications.searchBar')}...`}
                        className={cx('nav-form-search mt-0 mr-2', { 'has-content': search !== '' })}
                        aria-label="Zoeken"
                        style={{ marginLeft: '15px' }}
                        onChange={(event) => setSearchValue(event.target.value)}
                        value={searchValue}
                    />
                </Col>

                <Col sm={3} md={5} lg={4} xl={3}>
                    <DocRevSelect
                        options={HelperFunctions.prepareDropdownData(documents)}
                        name="documentId"
                        selectedValue={documentId}
                        onChange={(selection) => setStoreFilter('documentId', selection?.value ?? '')}
                        props={{
                            isClearable: true,
                            placeholder: 'Document',
                        }}
                    />
                </Col>

                <div className="col-auto ml-auto d-flex align-items-center">
                    <BulkActionsText />

                    <RestrictedContent
                        permission={[Permissions.Publication['Write.All'], Permissions.Publication['Publish.All']]}
                    >
                        <BulkActionsButton />
                    </RestrictedContent>

                    {!allowOnlyFromDocumentTemplate && (
                        <RestrictedContent permission={Permissions.Publication.Create}>
                            <Button
                                variant="info"
                                className="ml-3"
                                style={{ marginRight: '25px' }}
                                onClick={() => {
                                    history.push(
                                        generatePath(EDIT_PATH, {
                                            publicationId: '0',
                                            action: 'properties',
                                            groupId: folderId,
                                        }),
                                    );
                                }}
                            >
                                {t('publications.btn.newPublication')}
                            </Button>
                        </RestrictedContent>
                    )}
                </div>
            </div>
        </Form>
    );
}
