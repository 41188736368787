import { useState } from 'react';
import LoadingSpinner from '../../../../pages/global/LoadingSpinner';
import cx from 'classnames';
import { EditComment } from './EditComment';
import { NewComment } from './NewComment';
import Tags from './Tags';
import { Dropdown } from 'react-bootstrap';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { DateTime } from 'luxon';
import RestrictedDocumentContent, {
    useCurrentDocumentUserRole,
} from '../../../../pages/documents_v2/RestrictedDocumentContent';
import Constants from '../../../../config/Constants';
import { SecondaryButton, WarningButton } from '../../../Buttons';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import {
    useAddCommentMutation,
    useDeleteCommentMutation,
    useResolveCommentMutation,
} from 'features/comments/commentApi';
import { entityTypes } from 'pages/translation/config/Constants';
import { useTranslation } from 'react-i18next';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { useUserProfile } from 'hooks/useUserProfile';
import { useSidebarContext } from 'components/SettingsSidebar/hooks/useSidebarContext';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useGetEntityComments } from 'pages/comments/hooks/useGetEntityComments';
import HelperFunctions from 'pages/global/HelperFunctions';

export function Comments() {
    const { uri, parentUri, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const currentOrganisation = useCurrentOrganisation();

    const userDocumentRole = document?.currentUserRole;
    const isSpectator = userDocumentRole === Constants.userDocumentRole.spectator;
    const readOnly = entityType === entityTypes.OP_ENTRY || entityType === entityTypes.OP_ENTRY_VARIANT || isSpectator;
    const requireTagInComment = currentOrganisation?.requireTagInComment ?? false;

    return (
        <EntityComments parentUri={parentUri} readOnly={readOnly} uri={uri} requireTagInComment={requireTagInComment} />
    );
}

export function EntityComments({ uri, parentUri, readOnly = false, requireTagInComment = false }) {
    const { t } = useTranslation('documents');
    const { comments, isLoading } = useGetEntityComments(uri);

    if (comments === undefined || isLoading) {
        return <LoadingSpinner size="sm" />;
    }

    return (
        <div className="pb-3">
            {!readOnly && (
                <>
                    <div
                        className="small pr-2 font-weight-bold mb-2 d-flex justify-content-between"
                        style={{ marginLeft: '83px' }}
                    >
                        {t('document.navbar.main.settingsSidebar.articles.comments.titleCreate')}
                    </div>
                    <div className="mb-3">
                        <NewComment uri={uri} parentUri={parentUri} requireTagInComment={requireTagInComment} />
                    </div>
                </>
            )}

            <RenderedComments
                parentUri={parentUri}
                comments={comments}
                readOnly={readOnly}
                requireTagInComment={requireTagInComment}
            />
        </div>
    );
}

// Showing new comments on top of existing comments
function RenderedComments({ parentUri, comments = [], readOnly, requireTagInComment }) {
    const { t } = useTranslation('documents');

    if (comments.length === 0) {
        return (
            <div className="text-secondary">
                {t('document.navbar.main.settingsSidebar.articles.comments.comment.noComments')}
            </div>
        );
    }

    const sortedComments = [...comments].reverse();

    return (
        <div>
            {sortedComments.map((comment) => (
                <Comment
                    comment={comment}
                    parentUri={parentUri}
                    readOnly={readOnly}
                    requireTagInComment={requireTagInComment}
                    key={`comment-${comment.id}`}
                />
            ))}
        </div>
    );
}

function Comment({ comment, parentUri, readOnly, requireTagInComment }) {
    const creatorName = useGetUserFullName(comment.createdBy, false);
    const [isToggled, setToggled] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { t, i18n } = useTranslation('documents');

    const isReply = comment.parent !== null;
    const isResolved = comment.resolved;
    const [isRepliesVisible, setIsRepliesVisible] = useState(!isResolved);

    // Resolves if the current user is a documentManager for the document
    // This obviously only applies if the parentUri is of a document.
    let documentId;
    if (parentUri && parentUri.match(/\/api\/document\/\d+/)) {
        const segments = parentUri.split('/');
        documentId = segments[segments.length - 1];
    }

    const currentUserRole = useCurrentDocumentUserRole(parseInt(documentId));
    const isDocumentManager = 'documentManager' === currentUserRole;

    const fancyCount = (str) => {
        return Array.from(str.split(/[\ufe00-\ufe0f]/).join('')).length;
    };
    const output = comment.text.replace(
        /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
        '',
    );
    const charCount = fancyCount(output);
    const emojiCount = fancyCount(comment.text) - charCount;
    const showAsLarge = charCount === 0 && emojiCount <= 3;
    const textClass = cx({
        'extra-large': showAsLarge,
    });

    const translatedUnit = DateTime.fromISO(comment.createdAt).setLocale(i18n.language).toRelative();

    return (
        <div
            className={cx('position-relative pt-3 pl-3 pr-3 mb-4 comment-item', {
                'comment-resolved': isResolved,
                'cursor-pointer': !readOnly,
                'bg-white': comment.parent === null && !isResolved && !isReply,
                hovered: isHovered,
            })}
            onClick={toggleEditMode}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="d-flex justify-content-between">
                <div>
                    {creatorName && (
                        <div className="d-flex">
                            <div>
                                <NavBarAvatar />
                            </div>
                            <div className="font-weight-bold ml-3 mt-2">{creatorName}</div>
                        </div>
                    )}
                </div>
                <div className="small mt-2 ml-4">
                    {comment.resolved && (
                        <span className="mr-2 font-weight-bold">
                            {t('document.navbar.main.settingsSidebar.articles.comments.comment.resolved')}
                        </span>
                    )}
                    <span className="text-muted flex-end mr-2">{translatedUnit}</span>
                </div>
            </div>

            {editMode ? (
                <EditComment
                    cancelEdit={cancelEdit}
                    comment={comment}
                    isDocumentManager={isDocumentManager}
                    textClass={textClass}
                    requireTagInComment={requireTagInComment}
                />
            ) : (
                <>
                    <div className="pt-2 my-2">
                        <span className={textClass}>{comment.text}</span>
                        {comment.updatedAt !== null && (
                            <span className="text-muted small ml-2">
                                {t('document.navbar.main.settingsSidebar.articles.comments.comment.edited')}
                            </span>
                        )}
                    </div>
                </>
            )}

            {isHovered && !readOnly && (
                <CommentMenu
                    comment={comment}
                    editComment={editComment}
                    setEditMode={setEditMode}
                    documentId={documentId}
                    currentUserRole={currentUserRole}
                />
            )}

            <div className="mt-4 d-flex">
                {comment.hasOwnProperty('replies') && comment.replies.length > 0 && (
                    <div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleReplies();
                            }}
                            className="mt-2 d-flex justify-content-between"
                        >
                            {comment.replies.length}&nbsp;
                            {comment.replies.length === 1
                                ? t('document.navbar.main.settingsSidebar.articles.comments.comment.reply.reply')
                                : t('document.navbar.main.settingsSidebar.articles.comments.comment.reply.replies')}
                            <MdOutlineArrowDropDown
                                size={20}
                                className={cx('mb-2', {
                                    'rotate-180': isRepliesVisible,
                                })}
                            />
                        </div>
                    </div>
                )}

                {(comment.hasOwnProperty('tags') && comment.tags.length) > 0 && (
                    <div className="ml-auto pb-3">
                        <Tags comment={comment} />
                    </div>
                )}
            </div>

            {isRepliesVisible && <Replies comments={comment.replies} />}

            {isToggled && <Reply parentComment={comment} cancelReply={cancelReply} />}
        </div>
    );

    function cancelEdit() {
        setEditMode(false);
    }

    function cancelReply(e = undefined) {
        if (e !== undefined) {
            e.stopPropagation();
        }

        setToggled(false);
    }

    function editComment(e = undefined) {
        if (e !== undefined) {
            e.stopPropagation();
        }

        setEditMode(true);
        setToggled(false);
    }

    function toggleReplies() {
        setIsRepliesVisible(!isRepliesVisible);
    }

    function toggleEditMode() {
        if (comment.parent === null && editMode === false && !readOnly) {
            setToggled(true);
        }
    }

    function NavBarAvatar() {
        const names = creatorName.split(' '); // split the name into an array of names
        const initials = names.slice(0, 2).map((name) => name.charAt(0).toUpperCase()); // get the first two initials
        const initialsString = initials.join(''); // join the initials into a string

        return (
            <Dropdown className="avatar">
                <Dropdown.Toggle as="span" variant="link" id="user-dropdown">
                    {initialsString}
                </Dropdown.Toggle>
            </Dropdown>
        );
    }
}

function Reply({ parentComment, cancelReply }) {
    const [text, setText] = useState('');
    const [saveInProgress, setSaveInProgress] = useState(false);
    const currentOrganisationId = useActiveOrganisation();
    const [addComment] = useAddCommentMutation();
    const { t } = useTranslation('documents');

    const handleFormChange = (e) => {
        setText(e.target.value);
    };

    const handleFormSubmit = () => {
        setSaveInProgress(true);

        addComment({
            context: parentComment.context,
            parentContext: parentComment.parentContext,
            parent: parentComment['@id'],
            text: text,
            organisationId: currentOrganisationId,
        }).then(() => {
            setSaveInProgress(false);
            setText('');
            cancelReply();
        });
    };

    return (
        <div className="pt-4">
            <div className="mb-3">
                <textarea
                    className="form-control"
                    id="text"
                    name="text"
                    maxLength={500}
                    onChange={handleFormChange}
                    placeholder={`${t(
                        'document.navbar.main.settingsSidebar.articles.comments.comment.reply.message',
                    )}...`}
                    value={text}
                    autoFocus={true}
                    readOnly={saveInProgress}
                />
            </div>

            <div className="d-flex justify-content-end mb-3">
                <SecondaryButton disabled={saveInProgress} className="mr-2" onClick={(e) => cancelReply(e)}>
                    {t('btn.cancel')}
                </SecondaryButton>
                <WarningButton disabled={saveInProgress} onClick={() => handleFormSubmit()}>
                    {t('document.navbar.main.settingsSidebar.articles.comments.comment.reply.btn.send')}
                </WarningButton>
            </div>
        </div>
    );
}

function Replies({ comments }) {
    if (comments.length === 0) {
        return null;
    }

    return (
        <>
            {comments.map((comment) => {
                return (
                    <div
                        key={comment.id}
                        style={{
                            marginBottom: '-26px',
                            marginLeft: '-18px',
                            marginRight: '-18px',
                            backgroundColor: '#f2f3f6',
                        }}
                    >
                        <Comment comment={comment} key={comment.id} />
                    </div>
                );
            })}
        </>
    );
}

function CommentMenu({ comment, editComment, setEditMode, documentId = undefined, currentUserRole }) {
    const { t } = useTranslation();
    const userProfile = useUserProfile();
    const isCreator = comment.createdBy === userProfile.userId;
    const [deleteComment] = useDeleteCommentMutation();
    const [resolveComment] = useResolveCommentMutation();

    if (documentId !== undefined && currentUserRole === Constants.userDocumentRole.spectator && isCreator === false) {
        return null;
    }

    return (
        <div className="position-absolute" style={{ top: 21, right: 8 }} onClick={(e) => e.stopPropagation()}>
            <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle bsPrefix="docrev-dropdown-toggle" as="span" id="dropdown-basic">
                    <ThreeDotsVertical />
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight align="right">
                    {isCreator && (
                        <Dropdown.Item eventKey="edit">
                            {t('documents:document.navbar.main.settingsSidebar.articles.comments.comment.menu.edit')}
                        </Dropdown.Item>
                    )}

                    <RestrictedDocumentContent
                        documentId={documentId}
                        roles={[
                            Constants.userDocumentRole.documentManager,
                            Constants.userDocumentRole.finalEditor,
                            Constants.userDocumentRole.editor,
                        ]}
                    >
                        <Dropdown.Item eventKey="resolve" disabled={comment.resolved}>
                            {t('documents:document.navbar.main.settingsSidebar.articles.comments.comment.menu.resolve')}
                        </Dropdown.Item>
                    </RestrictedDocumentContent>

                    {isCreator && (
                        <>
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="delete">
                                <span className="text-danger">
                                    {t(
                                        'documents:document.navbar.main.settingsSidebar.articles.comments.comment.menu.delete',
                                    )}
                                    &hellip;
                                </span>
                            </Dropdown.Item>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'edit':
            case 'editTags':
                editComment();
                break;
            case 'resolve':
                resolveComment(comment['@id']).then(() => {
                    setEditMode(false);
                });
                break;
            case 'delete':
                handleDelete();
                break;
        }
    }

    function handleDelete() {
        HelperFunctions.confirmModal(
            t('global:comments.confirmDelete'),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deleteComment(comment['@id']);
            })
            .catch(() => {});
    }
}
