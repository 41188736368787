import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList as providesListFromApi } from '../api';
import {
    Attachment,
    Brand,
    Checklist,
    Comment,
    CustomerJourney,
    Department,
    ManagementDecision,
    RejectionReason,
    TargetAudience,
    Task,
    TaskTeamAssignment,
    TaskTeamResult,
    TaskType,
    TaskUser,
    TaskVersion,
    Team,
    TeamRule,
    TeamUser,
    Topic,
} from '../../models/comments.models';
import queryString from 'query-string';
import HelperFunctions from '../../pages/global/HelperFunctions';

const baseUrl = getEnv('COMMENT_API_BASE_URL');
const uriPrefix = '/api/comment-api/api';

const commentsApiQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
});

interface ApiResponse<T> {
    'hydra:member': T[];
}

export const commentsApi = createApi({
    reducerPath: 'commentsApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, commentsApiQuery),
    tagTypes: [
        'Comment',
        'Team',
        'TargetAudience',
        'Topic',
        'Brand',
        'TeamUser',
        'Department',
        'CustomerJourney',
        'TaskType',
        'Checklist',
        'Task',
        'TaskTeamAssignment',
        'TaskVersion',
        'RejectionReason',
        'File',
        'ManagementDecision',
        'TeamRule',
        'TaskUser',
    ],
    endpoints: (builder) => ({
        getComments: builder.query({
            query: (uri) => {
                return {
                    url: `${uriPrefix}/comments`,
                    method: 'GET',
                    params: { context: uri, 'exists[parent]': 'false' },
                };
            },
            transformResponse(response: ApiResponse<Comment>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result, error, arg) => {
                if (!result) {
                    return [];
                }
                return providesList(result, 'Comment', 'context', arg);
            },
        }),
        getParentComments: builder.query({
            query: (uri) => {
                return {
                    url: `${uriPrefix}/comments`,
                    method: 'GET',
                    params: { parentContext: uri, 'exists[parent]': 'false' },
                };
            },
            transformResponse(response: ApiResponse<Comment>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result, error, arg) => {
                if (!result) {
                    return [];
                }
                return providesParentList(result, 'Comment', 'context', arg);
            },
        }),
        addComment: builder.mutation<Comment, Partial<Comment>>({
            query: (body) => ({
                url: `${uriPrefix}/comments`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Comment', id: `${arg.context}-list` },
                { type: 'Comment', id: `${arg.context}` },
                {
                    type: 'Comment',
                    id: `${arg.parentContext}-list`,
                },
            ],
        }),
        updateComment: builder.mutation<Comment, { uri: string; body: Partial<Comment> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result) => [{ type: 'Comment', id: result?.context }],
        }),
        resolveComment: builder.mutation<Comment, string>({
            query: (uri) => ({
                url: `${uri}/resolve`,
                method: 'PUT',
                body: {},
            }),
            invalidatesTags: (result) => [{ type: 'Comment', id: result?.context }],
        }),
        deleteComment: builder.mutation<Comment, Partial<Comment>>({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Comment', id: arg.context }],
        }),
        getTeams: builder.query({
            query: ({ myTeams = false, params }) => {
                const uri = myTeams ? `${uriPrefix}/teams/my` : `${uriPrefix}/teams`;
                return HelperFunctions.prepareQuery(uri, params);
            },
            transformResponse(response: ApiResponse<Team>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'Team');
            },
        }),
        getTeam: builder.query({
            query: (id) => `${uriPrefix}/teams/${id}`,
            providesTags: (result) => [{ type: 'Team', id: result['@id'] }],
        }),
        getTeamUsers: builder.query({
            query: (id) => `${uriPrefix}/teams/${id}/team_users`,
            transformResponse(response: ApiResponse<TeamUser>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TeamUser');
            },
        }),
        getTeamRules: builder.query({
            query: (id) => `${uriPrefix}/teams/${id}/team_rules`,
            transformResponse(response: ApiResponse<TeamRule>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TeamRule');
            },
        }),
        getAllTeamRules: builder.query({
            query: ({ params }) =>
                `${uriPrefix}/team_rules?` + queryString.stringify(params, { arrayFormat: 'bracket' }),
            transformResponse(response: ApiResponse<TeamRule>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TeamRule');
            },
        }),
        addTeamRule: builder.mutation<TeamRule, { uri: string; body: Partial<TeamRule> }>({
            query: (body) => ({
                url: `${uriPrefix}/team_rules`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['TeamRule'],
        }),
        deleteTeamRule: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'TeamRule' }],
        }),
        getTeamRule: builder.query({
            query: (id) => `${uriPrefix}/team_rules/${id}`,
            providesTags: (result) => [{ type: 'TeamRule', id: result['@id'] }],
        }),
        updateTeamRule: builder.mutation<TeamRule, { uri: string; body: Partial<TeamRule> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TeamRule',
                    id: arg.uri,
                },
            ],
        }),
        getMyTeamUsers: builder.query({
            query: () => `${uriPrefix}/team_users/my`,
            transformResponse(response: ApiResponse<TeamUser>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TeamUser');
            },
        }),
        updateTeam: builder.mutation<Team, { uri: string; body: Partial<Team> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Team',
                    id: arg.uri,
                },
                { type: 'Topic' },
                { type: 'TargetAudience' },
                { type: 'Brand' },
            ],
        }),
        addTeam: builder.mutation<Team, { uri: string; body: Partial<Team> }>({
            query: (body) => ({
                url: `${uriPrefix}/teams`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Team'],
        }),
        addTeamUser: builder.mutation<TeamUser, { uri: string; body: Partial<TeamUser> }>({
            query: (body) => ({
                url: `${uriPrefix}/team_users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'TeamUser' }, { type: 'Team', id: 'LIST' }],
        }),
        deleteTeamUser: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'TeamUser' }, { type: 'Team', id: 'LIST' }],
        }),
        updateTeamUser: builder.mutation<TeamUser, { uri: string; body: Partial<TeamUser> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Task' },
                {
                    type: 'TeamUser',
                    id: arg.uri,
                },
            ],
        }),
        getTargetAudiences: builder.query({
            query: () => `${uriPrefix}/target_audiences`,
            transformResponse(response: ApiResponse<TargetAudience>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TargetAudience');
            },
        }),
        getTopics: builder.query({
            query: () => `${uriPrefix}/topics`,
            transformResponse(response: ApiResponse<Topic>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'Topic');
            },
        }),
        getBrands: builder.query({
            query: () => `${uriPrefix}/brands`,
            transformResponse(response: ApiResponse<Brand>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'Brand');
            },
        }),
        getRejectionReasons: builder.query({
            query: () => `${uriPrefix}/rejection_reasons`,
            transformResponse(response: ApiResponse<RejectionReason>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'RejectionReason');
            },
        }),
        getDepartments: builder.query({
            query: () => `${uriPrefix}/departments`,
            transformResponse(response: ApiResponse<Department>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'Department');
            },
        }),
        addDepartment: builder.mutation<Department, { uri: string; body: Partial<Department> }>({
            query: (body) => ({
                url: `${uriPrefix}/departments`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Department'],
        }),
        addBrand: builder.mutation<Brand, { uri: string; body: Partial<Brand> }>({
            query: (body) => ({
                url: `${uriPrefix}/brands`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Brand'],
        }),
        addRejectionReason: builder.mutation<RejectionReason, { uri: string; body: Partial<RejectionReason> }>({
            query: (body) => ({
                url: `${uriPrefix}/rejection_reasons`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['RejectionReason'],
        }),
        addTopic: builder.mutation<Topic, { uri: string; body: Partial<Topic> }>({
            query: (body) => ({
                url: `${uriPrefix}/topics`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Topic'],
        }),
        addTargetAudience: builder.mutation<TargetAudience, { uri: string; body: Partial<TargetAudience> }>({
            query: (body) => ({
                url: `${uriPrefix}/target_audiences`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['TargetAudience'],
        }),
        updateDepartment: builder.mutation<Department, { uri: string; body: Partial<Department> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Department',
                    id: arg.uri,
                },
            ],
        }),
        updateBrand: builder.mutation<Brand, { uri: string; body: Partial<Brand> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Brand',
                    id: arg.uri,
                },
            ],
        }),
        updateTopic: builder.mutation<Topic, { uri: string; body: Partial<Topic> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Topic',
                    id: arg.uri,
                },
            ],
        }),
        updateTargetAudience: builder.mutation<TargetAudience, { uri: string; body: Partial<TargetAudience> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TargetAudience',
                    id: arg.uri,
                },
            ],
        }),
        updateRejectionReason: builder.mutation<RejectionReason, { uri: string; body: Partial<RejectionReason> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'RejectionReason',
                    id: arg.uri,
                },
            ],
        }),
        deleteDepartment: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'Department' }],
        }),
        deleteBrand: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'Brand' }],
        }),
        deleteTargetAudience: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'TargetAudience' }],
        }),
        deleteTopic: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'Topic' }],
        }),
        deleteRejectionReason: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'RejectionReason' }],
        }),
        getCustomerJourneys: builder.query({
            query: () => `${uriPrefix}/customer_journeys`,
            transformResponse(response: ApiResponse<CustomerJourney>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'CustomerJourney');
            },
        }),
        addCustomerJourney: builder.mutation<CustomerJourney, { uri: string; body: Partial<CustomerJourney> }>({
            query: (body) => ({
                url: `${uriPrefix}/customer_journeys`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['CustomerJourney'],
        }),
        updateCustomerJourney: builder.mutation<CustomerJourney, { uri: string; body: Partial<CustomerJourney> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'CustomerJourney',
                    id: arg.uri,
                },
            ],
        }),
        deleteCustomerJourney: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'CustomerJourney' }],
        }),
        getTaskTypes: builder.query({
            query: () => `${uriPrefix}/task_types`,
            transformResponse(response: ApiResponse<TaskType>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TaskType');
            },
        }),
        addTaskType: builder.mutation<TaskType, { uri: string; body: Partial<TaskType> }>({
            query: (body) => ({
                url: `${uriPrefix}/task_types`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['TaskType'],
        }),
        updateTaskType: builder.mutation<TaskType, { uri: string; body: Partial<TaskType> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TaskType',
                    id: arg.uri,
                },
            ],
        }),
        updateTaskVersion: builder.mutation<TaskVersion, { uri: string; body: Partial<TaskVersion> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TaskVersion',
                    id: arg.uri,
                },
            ],
        }),
        deleteTaskType: builder.mutation({
            query: (uri) => {
                return {
                    url: uri,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{ type: 'TaskType' }],
        }),
        addChecklist: builder.mutation<Checklist, { uri: string; body: Partial<Checklist> }>({
            query: (body) => ({
                url: `${uriPrefix}/checklists`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Checklist'],
        }),
        getTeamChecklists: builder.query({
            query: (id) => `${uriPrefix}/teams/${id}/checklists`,
            transformResponse: (response: ApiResponse<Checklist>) => (response ? response['hydra:member'] : []),
            providesTags: (result) => (result ? providesListFromApi(result, 'Checklist') : []),
        }),
        getChecklists: builder.query({
            query: () => `${uriPrefix}/checklists`,
            transformResponse: (response: ApiResponse<Checklist>) => (response ? response['hydra:member'] : []),
            providesTags: (result) => (result ? providesListFromApi(result, 'Checklist') : []),
        }),
        getChecklist: builder.query({
            query: (id) => `${uriPrefix}/checklists/${id}`,
            providesTags: (result) => [{ type: 'Checklist', id: result['@id'] }],
        }),
        updateChecklist: builder.mutation<Checklist, { uri: string; body: Partial<Checklist> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Checklist',
                    id: arg.uri,
                },
            ],
        }),
        deleteChecklist: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Checklist' }],
        }),
        getTaskTeamResults: builder.mutation<TaskTeamResult, Partial<Task>>({
            query: (body) => ({
                url: `${uriPrefix}/tasks/task_team_results`,
                method: 'POST',
                body,
            }),
        }),
        addTask: builder.mutation<Task, { uri: string; body: Partial<Task> }>({
            query: (body) => ({
                url: `${uriPrefix}/tasks`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['Task', 'TaskTeamAssignment'],
        }),
        updateTask: builder.mutation<Task, { uri: string; body: Partial<Task> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Task',
                    id: arg.uri,
                },
                {
                    type: 'Task',
                    id: 'LIST',
                },
            ],
        }),
        deleteTask: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: [
                { type: 'Task', id: 'LIST' },
                { type: 'TaskTeamAssignment', id: 'LIST' },
            ],
        }),
        submitTask: builder.mutation<Task, { uri: string; body: Partial<Task> }>({
            query: ({ uri, body }) => ({
                url: `${uri}/submit`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Task',
                    id: arg.uri,
                },
                {
                    type: 'Task',
                    id: 'LIST',
                },
            ],
        }),
        newTaskVersion: builder.mutation<Task, { uri: string; body: Partial<Task> }>({
            query: ({ uri }) => ({
                url: `${uri}/new-version`,
                method: 'PUT',
                body: {},
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'Task',
                    id: arg.uri,
                },
            ],
        }),
        submitTaskVersion: builder.mutation<TaskVersion, { uri: string; body: Partial<TaskVersion> }>({
            query: ({ uri }) => ({
                url: `${uri}/submit`,
                method: 'PUT',
                body: {},
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TaskVersion',
                    id: arg.uri,
                },
                {
                    type: 'Task',
                    id: result?.task,
                },
                {
                    type: 'TaskTeamAssignment',
                    id: 'LIST',
                },
            ],
        }),
        getTasks: builder.query({
            query: ({ myTasks = false, params = {} }) => {
                const uri = myTasks ? 'tasks/my' : 'tasks';
                return `${uriPrefix}/${uri}?` + queryString.stringify(params, { arrayFormat: 'bracket', sort: false });
            },
            providesTags: (result) => (result ? providesListFromApi(result['hydra:member'], 'Task') : []),
        }),
        getTask: builder.query({
            query: (id) => `${uriPrefix}/tasks/${id}`,
            providesTags: (result) => [{ type: 'Task', id: result['@id'] }],
        }),
        getTaskTeamAssignments: builder.query({
            query: ({ params = {} }) => {
                return (
                    `${uriPrefix}/task_team_assignments?` + queryString.stringify(params, { arrayFormat: 'bracket' })
                );
            },
            providesTags: (result) => (result ? providesListFromApi(result['hydra:member'], 'TaskTeamAssignment') : []),
        }),
        getTaskTeamAssignment: builder.query({
            query: (id) => `${uriPrefix}/task_team_assignments/${id}`,
            providesTags: (result) => [{ type: 'TaskTeamAssignment', id: result['@id'] }],
        }),
        updateTaskTeamAssignment: builder.mutation<
            TaskTeamAssignment,
            {
                uri: string;
                body: Partial<TaskTeamAssignment>;
            }
        >({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'TaskTeamAssignment',
                    id: arg.uri,
                },
                {
                    type: 'TaskTeamAssignment',
                    id: 'LIST',
                },
                {
                    type: 'Task',
                    id: result?.task['@id'],
                },
            ],
        }),
        getTaskVersion: builder.query({
            query: (uri) => uri,
            providesTags: (result, error, arg) => [
                { type: 'TaskVersion', id: arg },
                { type: 'Task', id: result.task },
            ],
        }),
        addAttachment: builder.mutation<Attachment, { url: string; body: Partial<Attachment> }>({
            query: (body) => ({
                url: `${uriPrefix}/files`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['File'],
        }),
        updateAttachment: builder.mutation<Attachment, { uri: string; body: Partial<Attachment> }>({
            query: ({ uri, body }) => ({
                url: uri,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: 'File',
                    id: arg.uri,
                },
            ],
        }),
        getTaskAttachments: builder.query({
            query: (uri) => `${uri}/files`,
            transformResponse: (response: ApiResponse<Attachment>) => (response ? response['hydra:member'] : []),
            providesTags: (result) => (result ? providesListFromApi(result, 'File') : []),
        }),
        deleteAttachment: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'File' }],
        }),
        addManagementDecision: builder.mutation<
            ManagementDecision,
            {
                url: string;
                body: Partial<ManagementDecision>;
            }
        >({
            query: (body) => ({
                url: `${uriPrefix}/management_decisions`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['ManagementDecision', 'TaskTeamAssignment'],
        }),
        getManagementDecision: builder.query({
            query: (uri) => uri,
            providesTags: (result, error, arg) => [{ type: 'ManagementDecision', id: arg }],
        }),
        getFile: builder.query({
            query: (id) => `${uriPrefix}/files/${id}`,
            providesTags: (result) => [{ type: 'File', id: result['@id'] }],
        }),
        getTaskUsers: builder.query({
            query: (role) => ({
                url: `${uriPrefix}/task_users`,
                params: { role },
            }),
            transformResponse(response: ApiResponse<TaskUser>) {
                if (response['hydra:member']) {
                    return response['hydra:member'];
                }
                return [];
            },
            providesTags: (result) => {
                if (!result) {
                    return [];
                }
                return providesListFromApi(result, 'TaskUser');
            },
        }),
        deleteTaskUser: builder.mutation({
            query: (uri) => ({
                url: uri,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'TaskUser' }],
        }),
        addTaskUser: builder.mutation<TaskUser, { url: string; body: Partial<TaskUser> }>({
            query: (body) => ({
                url: `${uriPrefix}/task_users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: () => ['TaskUser'],
        }),
    }),
});

export const {
    useGetCommentsQuery,
    useGetParentCommentsQuery,
    useAddCommentMutation,
    useUpdateCommentMutation,
    useResolveCommentMutation,
    useDeleteCommentMutation,
    useGetTeamsQuery,
    useGetTeamQuery,
    useGetTeamUsersQuery,
    useUpdateTeamMutation,
    useUpdateTeamUserMutation,
    useAddTeamMutation,
    useAddTeamUserMutation,
    useDeleteTeamUserMutation,
    useGetTargetAudiencesQuery,
    useGetTopicsQuery,
    useGetBrandsQuery,
    useGetDepartmentsQuery,
    useAddDepartmentMutation,
    useUpdateDepartmentMutation,
    useDeleteDepartmentMutation,
    useAddCustomerJourneyMutation,
    useDeleteCustomerJourneyMutation,
    useGetCustomerJourneysQuery,
    useUpdateCustomerJourneyMutation,
    useAddTaskTypeMutation,
    useDeleteTaskTypeMutation,
    useGetTaskTypesQuery,
    useUpdateTaskTypeMutation,
    useAddChecklistMutation,
    useGetChecklistsQuery,
    useGetTeamChecklistsQuery,
    useGetChecklistQuery,
    useUpdateChecklistMutation,
    useDeleteChecklistMutation,
    useGetTaskTeamResultsMutation,
    useAddTaskMutation,
    useUpdateTaskMutation,
    useDeleteTaskMutation,
    useSubmitTaskMutation,
    useGetTasksQuery,
    useGetTaskQuery,
    useGetTaskTeamAssignmentsQuery,
    useGetTaskTeamAssignmentQuery,
    useUpdateTaskTeamAssignmentMutation,
    useGetTaskVersionQuery,
    useNewTaskVersionMutation,
    useSubmitTaskVersionMutation,
    useDeleteBrandMutation,
    useUpdateBrandMutation,
    useAddBrandMutation,
    useAddTargetAudienceMutation,
    useAddTopicMutation,
    useDeleteTargetAudienceMutation,
    useDeleteTopicMutation,
    useUpdateTargetAudienceMutation,
    useUpdateTopicMutation,
    useGetRejectionReasonsQuery,
    useDeleteRejectionReasonMutation,
    useAddRejectionReasonMutation,
    useUpdateRejectionReasonMutation,
    useAddAttachmentMutation,
    useUpdateAttachmentMutation,
    useGetTaskAttachmentsQuery,
    useDeleteAttachmentMutation,
    useGetMyTeamUsersQuery,
    useAddManagementDecisionMutation,
    useGetManagementDecisionQuery,
    useUpdateTaskVersionMutation,
    useGetTeamRulesQuery,
    useAddTeamRuleMutation,
    useDeleteTeamRuleMutation,
    useGetTeamRuleQuery,
    useUpdateTeamRuleMutation,
    useGetAllTeamRulesQuery,
    useGetFileQuery,
    useGetTaskUsersQuery,
    useAddTaskUserMutation,
    useDeleteTaskUserMutation,
} = commentsApi;

function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    idProperty: string = '@id',
    uri: string,
) {
    return resultsWithIds
        ? [
              { type: tagType, id: `${uri}-list` },
              ...resultsWithIds.map((_item: any) => ({
                  type: tagType,
                  id: _item[idProperty],
              })),
          ]
        : [{ type: tagType, id: `${uri}-list` }];
}

function providesParentList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T,
    idProperty: string = '@id',
    parentUri: string,
) {
    return resultsWithIds
        ? [
              { type: tagType, id: `${parentUri}-list` },
              ...resultsWithIds.map((_item: any) => ({
                  type: tagType,
                  id: _item[idProperty],
              })),
          ]
        : [{ type: tagType, id: parentUri }];
}
