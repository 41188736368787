import cx from 'classnames';

export function Action({ tooltip, children, disabled = false, handleClick, variant = 'btn-outline-primary' }) {
    const btnClass = cx('d-flex align-items-center btn btn-sm mr-1', variant);

    return (
        <button
            type="button"
            className={btnClass}
            data-uk-tooltip={disabled || !tooltip ? undefined : tooltip}
            disabled={disabled}
            onClick={() => handleClick()}
        >
            {children}
        </button>
    );
}
