import { useGetDocumentsQuery } from 'features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetDocument(container) {
    const { documentId, organisationId } = container;

    const filterDocuments = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, documentId) => documentId,
            (data, documentId) => {
                if (data) {
                    return data.find((_item) => _item.id === documentId);
                }

                return undefined;
            },
        );
    }, []);

    const { document } = useGetDocumentsQuery(organisationId, {
        selectFromResult: (result) => ({
            document: filterDocuments(result, documentId),
        }),
    });

    return document;
}
