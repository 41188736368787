import { generatePath, useHistory } from 'react-router-dom';
import Constants from '../../../../config/Constants';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import CloneApi from '../../../../api/CloneApi';
import { useDispatch } from 'react-redux';
import { documentApi, useDeleteAreaMutation, useUpdateAreaStatusMutation } from 'features/documents/documents';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import HelperFunctions from '../../../global/HelperFunctions';
import { EDIT_AREA_PATH } from 'scenes/DocumentsV2';
import { useUserProfile } from 'hooks/useUserProfile';

export default function AreaOptionsButton({
    area,
    restoreArea,
    document,
    toggleMoveModal,
    toggleFinishArea,
    restrictions,
    isMovable = true,
}) {
    const { t } = useTranslation('documents');
    const history = useHistory();
    const dispatch = useDispatch();
    const [updateAreaStatus] = useUpdateAreaStatusMutation();
    const userProfile = useUserProfile();

    const { currentUserRole } = document;

    const [getArea] = documentApi.useLazyGetAreaQuery();
    const [deleteArea] = useDeleteAreaMutation();

    const isLinkedArea = area.type === Constants.areaTypes.linkedArea;
    const btnTitle = area.scheduledForDeletion
        ? t('document.navbar.main.btn.splitMenu.btnTitle.restore')
        : t('document.navbar.main.btn.splitMenu.btnTitle.edit');
    const skipBtnTitle = area.currentUserStatus.skipped
        ? t('document.navbar.main.btn.splitMenu.skipBtnTitle.unSkip')
        : t('document.navbar.main.btn.splitMenu.skipBtnTitle.skip');
    const deleteBtnTitle = area.scheduledForDeletion
        ? t('document.navbar.main.btn.splitMenu.deleteBtnTitle.permanentDelete')
        : t('document.navbar.main.btn.splitMenu.deleteBtnTitle.delete');
    const allowCheckFinishBtn =
        (currentUserRole === Constants.userDocumentRole.documentManager ||
            currentUserRole === Constants.userDocumentRole.finalEditor) &&
        document.status !== Constants.documentStatus.draft;
    const reviewFinished = document.status === Constants.documentStatus.reviewFinished;
    const isSpectator = currentUserRole === Constants.userDocumentRole.spectator;
    const isEditor = currentUserRole === Constants.userDocumentRole.editor;
    const isDeleted = area.deletedAt !== null;

    const skipClass =
        reviewFinished ||
        document.status === Constants.documentStatus.draft ||
        document.type === Constants.documentType.library ||
        isLinkedArea ||
        area.scheduledForDeletion;

    const cloneClass =
        reviewFinished ||
        isSpectator ||
        isDeleted ||
        area.scheduledForDeletion ||
        (area.properties.isTemplate === false && restrictions?.canDuplicateArticle == false);

    const moveClass = reviewFinished || isSpectator || isDeleted || area.scheduledForDeletion || !isMovable;

    const finishClass =
        reviewFinished ||
        isSpectator ||
        isDeleted ||
        !allowCheckFinishBtn ||
        isLinkedArea ||
        area.scheduledForDeletion ||
        area.unprocessedEdits > 0;

    // Returns true is delete is disabled
    const deleteclass =
        reviewFinished ||
        (area.scheduledForDeletion && isEditor) ||
        isSpectator ||
        restrictions?.canDeleteArticle === false;

    const isReadOnly = area.properties.isTemplate && restrictions?.canCreateFromArticleTemplate;

    const handleClick = () => {
        if (area.scheduledForDeletion) {
            restoreArea();
        } else {
            const reviewLink = generatePath(EDIT_AREA_PATH, {
                documentId: isLinkedArea ? area.linkedTo.document.id : document.id,
                view: 'editArea',
                areaId: isLinkedArea ? area.linkedTo.id : area.id,
            });
            history.push(reviewLink);
        }
    };

    const handleDelete = () => {
        getArea(area.id).then(({ data }) => {
            const areaDetails = data;

            if (areaDetails.hasLinkedAreas === true || areaDetails.hasLinkedBlocks === true) {
                let msg;

                if (areaDetails.hasLinkedAreas) {
                    msg = 'andere artikelen zijn gekoppeld. Verwijder eerst de gekoppelde artikelen.';
                } else {
                    msg = 'bevat gekoppelde blokken. Verwijder eerst de koppelingen.';
                }

                HelperFunctions.alertModal(`Kan artikel niet verwijderen: ${msg}`);
            } else {
                deleteArea({ id: area.id, sectionId: area.sectionId });
            }
        });
    };

    const handleSelect = (eventKey) => {
        switch (eventKey) {
            case 'edit':
                history.push(
                    generatePath(EDIT_AREA_PATH, {
                        documentId: isLinkedArea ? area.linkedTo.document.id : document.id,
                        view: 'editArea',
                        areaId: isLinkedArea ? area.linkedTo.id : area.id,
                    })
                );
                return;
            case 'view':
                const viewAreaLink = generatePath(EDIT_AREA_PATH, {
                    documentId: document.id,
                    view: 'viewArea',
                    areaId: area.id,
                });

                history.push(viewAreaLink);
                return;
            case 'check':
                const reviewChangesLink = generatePath(EDIT_AREA_PATH, {
                    documentId: document.id,
                    view: 'checkArea',
                    areaId: area.id,
                });

                history.push(reviewChangesLink);
                return;
            case 'finish':
                toggleFinishArea();
                return;
            case 'move':
                toggleMoveModal();
                return;
            case 'clone':
                CloneApi.cloneEntity('areas', area.id).then(() => {
                    // Invalidate cache to reload data
                    dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: area.sectionId }]));
                });
                return;
            case 'skip':
                toggleSkipArea();
                return;
            case 'delete':
                handleDelete();
                return;
        }
    };

    const handleTemplateArticleDuplicate = () => {
        handleSelect('clone');
    };

    const handleTemplateArticleReadOnly = () => {
        handleSelect('view');
    };

    if (
        area?.properties?.isTemplate &&
        area?.properties?.parentId &&
        restrictions?.canCreateFromArticleTemplate === true
    ) {
        return (
            <SplitButton
                id={`area-${area.id}-edit-btn`}
                variant="warning"
                title={t(
                    isReadOnly
                        ? 'document.navbar.main.btn.splitMenu.duplicate'
                        : 'document.navbar.main.btn.splitMenu.readAll'
                )}
                onSelect={handleSelect}
                onClick={() => {
                    if (isReadOnly) {
                        handleTemplateArticleDuplicate();
                    } else {
                        handleTemplateArticleReadOnly();
                    }
                }}
                size="sm"
                disabled={isReadOnly && area.scheduledForDeletion}
            >
                <Dropdown.Item eventKey="edit" disabled={!restrictions?.canEditArticleTemplate}>
                    <div
                        className={cx({
                            'text-muted': !restrictions?.canEditArticleTemplate,
                        })}
                    >
                        {t('document.navbar.main.btn.splitMenu.btnTitle.edit')}
                    </div>
                </Dropdown.Item>
                <Dropdown.Item eventKey="delete" disabled={!restrictions?.canRemoveArticleTemplate}>
                    <div
                        className={cx({
                            'text-danger': restrictions?.canRemoveArticleTemplate,
                            'text-muted': !restrictions?.canRemoveArticleTemplate,
                        })}
                    >
                        {deleteBtnTitle}&hellip;
                    </div>
                </Dropdown.Item>
            </SplitButton>
        );
    }

    return (
        <SplitButton
            id={`area-${area.id}-edit-btn`}
            variant="primary"
            title={
                <>
                    {isLinkedArea && !area.scheduledForDeletion && <BoxArrowUpRight size={14} />}
                    {btnTitle}
                </>
            }
            onSelect={handleSelect}
            onClick={handleClick}
            size="sm"
        >
            <Dropdown.Item eventKey="view" disabled={isLinkedArea}>
                <div
                    className={cx({
                        'text-muted': isLinkedArea,
                    })}
                >
                    {t('document.navbar.main.btn.splitMenu.readAll')}
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey="skip" disabled={skipClass}>
                <div
                    className={cx({
                        'text-muted': skipClass,
                    })}
                >
                    {skipBtnTitle}
                </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="move" disabled={moveClass}>
                <div
                    className={cx({
                        'text-muted': moveClass,
                    })}
                >
                    {t('document.navbar.main.btn.splitMenu.moveTo')}&hellip;
                </div>
            </Dropdown.Item>

            <Dropdown.Item eventKey="clone" disabled={cloneClass}>
                <div
                    className={cx({
                        'text-muted': cloneClass,
                    })}
                >
                    {t('document.navbar.main.btn.splitMenu.duplicate')}
                </div>
            </Dropdown.Item>
            <Dropdown.Item eventKey="finish" disabled={finishClass}>
                <div
                    className={cx({
                        'text-muted': finishClass,
                    })}
                >
                    {t('document.navbar.main.btn.splitMenu.finish')}
                </div>
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item eventKey="delete" disabled={deleteclass}>
                <div
                    className={cx({
                        'text-danger': !deleteclass,
                        'text-muted': deleteclass,
                    })}
                >
                    {deleteBtnTitle}
                </div>
            </Dropdown.Item>
        </SplitButton>
    );

    function toggleSkipArea() {
        updateAreaStatus({
            id: area.id,
            body: {
                userId: userProfile.userId,
                skipped: !area.currentUserStatus.skipped,
            },
        }).then(() => {
            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Section', id: area.sectionId }]));
        });
    }
}
