import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, setSelectedRows } from 'features/publications/publicationsSlice';
import { Modal } from 'react-bootstrap';
import HelperFunctions from 'pages/global/HelperFunctions';
import { InputSelect } from '../helpers/FieldHelper';
import { useGetPublicationGroups } from '../hooks/useGetPublicationGroups';
import { useEditPublicationsMutation } from 'features/publications/publicationApi';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';

export function MovePublicationsModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');
    const { movePublication } = useSelector((state) => state.publications.modals);

    const showModal = !!movePublication;

    return (
        <FormModal show={showModal} onHide={onHide} title={t('publications.bulkActions.moveModal.title')}>
            {showModal && <MovePublicationsBody handleClose={onHide} />}
        </FormModal>
    );

    function onHide() {
        dispatch(closeModal('movePublication'));
    }
}

function MovePublicationsBody({ handleClose }) {
    const { t } = useTranslation('publications');
    const selectedRows = useSelector((state) => state.publications.selectedRows);
    const allGroups = useGetPublicationGroups(false);
    const nestedGroups = useGetPublicationGroups();
    const [editPublications] = useEditPublicationsMutation();
    const dispatch = useDispatch();

    const groupOptions = HelperFunctions.prepareDropdownData(nestedGroups).map((group) => {
        return {
            ...group,
            options: HelperFunctions.prepareDropdownData(group.children),
        };
    });

    return (
        <Formik
            initialValues={{
                targetGroup: undefined,
                selectedRows,
            }}
            validationSchema={MoveSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <div>
                            <label htmlFor="targetGroup">{t('publications.bulkActions.moveModal.selectGroup')}</label>

                            <InputSelect
                                name="targetGroup"
                                options={groupOptions}
                                props={{
                                    isClearable: true,
                                }}
                            />
                        </div>
                    </Modal.Body>

                    <ModalFooter
                        isValid={isValid}
                        dirty={dirty}
                        isSubmitting={isSubmitting}
                        onHide={handleClose}
                        btnSubmitText={t('publications.bulkActions.moveModal.move')}
                    />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        const targetGroupObj = allGroups.find((g) => g.id === values.targetGroup);
        const newSortOrder = targetGroupObj.publications.length + 1;

        const changes = values.selectedRows.map((publicationId, index) => ({
            id: publicationId,
            changes: {
                publicationGroup: targetGroupObj['@id'],
                sortOrder: newSortOrder + index,
            },
        }));

        editPublications(changes).then(() => {
            dispatch(setSelectedRows([]));
            handleClose();
        });
    }
}

const MoveSchema = Yup.object().shape({
    targetGroup: Yup.number().required(),
});
