import { useDispatch } from 'react-redux';
import Constants, { Permissions } from '../../../../config/Constants';
import { useHasPermission } from '../../../global/RestrictedContent';
import { useTranslation } from 'react-i18next';
import { Action } from './PublicationRowDetails';
import { CloudArrowUpFill } from 'react-bootstrap-icons';
import { setModal } from '../../../../features/publications/publicationsSlice';

export function PublishActions({ publication, publicationVersions }) {
    const dispatch = useDispatch();
    const hasPublishPermission = useHasPermission(Permissions.Publication['Publish.All']);
    const { t } = useTranslation('publications');

    const hasScheduledVersions = hasScheduledVersion();

    return (
        <div className="d-flex mb-2">
            <Action
                tooltip={t('publication.tooltip.publish')}
                handleClick={showPublishModal}
                variant="btn-warning"
                disabled={hasScheduledVersions || !hasPublishPermission}
            >
                <CloudArrowUpFill size={18} />
                <div>{t('publication.btn.publish')}&hellip;</div>
            </Action>
        </div>
    );

    function showPublishModal() {
        dispatch(
            setModal({
                key: 'publish',
                value: { publication },
            }),
        );
    }

    function hasScheduledVersion() {
        return publicationVersions.some((_version) => {
            return _version.status === Constants.publicationVersionStatus.scheduledForPublishing;
        });
    }
}
