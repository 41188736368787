import _ from 'lodash';

let AreaHelper;

export default AreaHelper = {
    getNextArea(document, area) {
        if (!document || !area) {
            return undefined;
        }

        const sectionAreas = this.getSectionAreas(document, area);
        const currentAreaIndex = _.findIndex(sectionAreas, ['id', area.id]);

        if (currentAreaIndex === -1) {
            return undefined;
        }

        return sectionAreas[currentAreaIndex + 1];
    },
    getPreviousArea(document, area) {
        if (!document || !area) {
            return undefined;
        }

        const sectionAreas = this.getSectionAreas(document, area);
        const currentAreaIndex = _.findIndex(sectionAreas, ['id', area.id]);

        if (currentAreaIndex === -1) {
            return undefined;
        }

        return sectionAreas[currentAreaIndex - 1];
    },
    getSectionAreas(document, area) {
        const section = _.find(document.sections, ['id', area.sectionId]);

        if (section === undefined) {
            return [];
        }

        const areas = section?.areas ?? [];

        // Exclude Linked Areas and Areas scheduled for deletion
        return areas.filter((area) => {
            return area.type === 'area' && area.scheduledForDeletion === false;
        });
    },
};
