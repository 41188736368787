import { useEffect } from 'react';
import LoadingSpinner from '../global/LoadingSpinner';
import { useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { TagList } from './TagList';
import HelperFunctions from '../global/HelperFunctions';
import NewTagModal from './NewTagModal';
import { TagModal } from './TagModal';
import MainContentNav from '../Navbar';
import { EditTag } from './EditTag';
import TagSearchAndReplaceModal from './search_and_replace/TagSearchAndReplaceModal';
import ImportTagsModal from './ImportTagsModal';
import {
    useAddTagMutation,
    useDeleteTagMutation,
    useSyncTagsMutation,
    useUpdateTagMutation,
} from 'features/documents/documents';
import { useGetOrganisationTags } from './hooks/useGetOrganisationTags';
import cx from 'classnames';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useGetTemplateTagsCombined } from '../documents_v2/hooks/useGetTemplateTagsCombined';
import { useGetDocument } from '../documents_v2/hooks/useGetDocument';
import { useGetTemplate } from '../documents_v2/hooks/useGetTemplate';
import { useTranslation } from 'react-i18next';
import { DangerButton, LightOutlineButton, NewItemButton } from 'components/Buttons';
import { ArrowRepeat, Search, Trash3, Upload } from 'react-bootstrap-icons';
import { useGetDocumentTagData } from 'pages/tags/hooks/useGetDocumentTag';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

export default function Index() {
    const { t } = useTranslation('documents');
    const tags = useGetOrganisationTags();

    return (
        <>
            <MainContentNav title={t('document.navbar.tags.title')} />
            <TagContentBody tags={tags} />
        </>
    );
}

export function TagContentBody({ tags = [], hasSubNav = false }) {
    const [editTag, setEditTag] = useQueryParam('editTag', NumberParam);
    const [editTagVariants, setEditTagVariants] = useQueryParam('variants', BooleanParam);
    const { t } = useTranslation('documents');
    const activeOrganisation = useActiveOrganisation();
    let { documentId } = useParams();
    const document = useGetDocument();
    const template = useGetTemplate(document?.modelId, document?.modelSource);
    const restrictions = template?.properties?.templateParameters;
    const canCreateTags = restrictions?.canUserCreateTags ?? true;

    const [updateTag] = useUpdateTagMutation();
    const [addTag] = useAddTagMutation();
    const [deleteTag] = useDeleteTagMutation();
    const [syncTags] = useSyncTagsMutation();

    const [state, setState] = useImmer({
        formData: {},
        saveInProgress: false,
        selectedItem: [],
        showTagModal: false,
        showNewTagModal: false,
        showSearchAndReplaceModal: false,
        ShowImportExcel: false,
    });

    return (
        <>
            <div
                className={cx('content-static-body', {
                    'has-subnav': hasSubNav,
                })}
            >
                <div className="d-flex align-items-stretch h-100">
                    <div
                        className="overflow-auto px-5"
                        style={{ marginTop: 70 }}
                        onClick={(e) => {
                            if (e.target.id === 'content') {
                                handleTagSelect({}, false);
                            }
                        }}
                    >
                        <div className="dr-container px-4 mb-4">
                            {tags === false && <LoadingSpinner />}

                            {tags && (
                                <TagList
                                    handleTagSelect={handleTagSelect}
                                    selectedItem={state.selectedItem}
                                    tags={tags}
                                />
                            )}
                        </div>

                        <NewTagModal
                            handleClose={() =>
                                setState((draft) => {
                                    draft.showNewTagModal = false;
                                })
                            }
                            handleSubmit={handleCreateTag}
                            showModal={state.showNewTagModal}
                        />

                        {state.showSearchAndReplaceModal && (
                            <TagSearchAndReplaceModal
                                showDialog={state.showSearchAndReplaceModal}
                                documentId={documentId}
                                // Dismiss the dialog
                                handleClose={() =>
                                    setState((draft) => {
                                        draft.showSearchAndReplaceModal = false;
                                    })
                                }
                            />
                        )}

                        {state.ShowImportExcel && (
                            <ImportTagsModal
                                showDialog={state.ShowImportExcel}
                                documentId={documentId}
                                documentTags={tags}
                                // Dismiss the dialog
                                handleClose={() =>
                                    setState((draft) => {
                                        draft.ShowImportExcel = false;
                                    })
                                }
                            />
                        )}
                    </div>

                    <TagEditForm
                        state={state}
                        setState={setState}
                        handleFormChange={handleFormChange}
                        handleFormSubmit={handleFormSubmit}
                        tags={tags}
                    />
                </div>
            </div>

            <div
                className={cx('content-static-filters bg-bgLight px-4 py-3', {
                    'has-subnav': hasSubNav,
                })}
            >
                <div className="d-flex justify-content-end pr-4">
                    {document?.modelId && (
                        <LightOutlineButton className="mr-2" onClick={handleTagSync}>
                            <ArrowRepeat />
                            {t('document.navbar.tags.syncTags')}
                        </LightOutlineButton>
                    )}

                    <LightOutlineButton className="mr-2" onClick={searchAndReplace}>
                        <Search />
                        {t('document.navbar.tags.searchModal.search')}
                    </LightOutlineButton>

                    {documentId && (
                        <LightOutlineButton className="mr-2" onClick={ImportExcel}>
                            <Upload />
                            {t('document.navbar.tags.importModal.import')}
                        </LightOutlineButton>
                    )}

                    <DangerButton disabled={!canDelete()} className="mr-2" onClick={handleDeleteTag}>
                        <Trash3 />
                        {t('document.navbar.tags.deleteModal.delete')}&hellip;
                    </DangerButton>

                    {canCreateTags && (
                        <NewItemButton
                            onClick={() =>
                                setState((draft) => {
                                    draft.showNewTagModal = true;
                                })
                            }
                            label={t('document.navbar.tags.newModal.new')}
                        />
                    )}
                </div>
            </div>
        </>
    );

    function handleTagSelect(row, isSelected) {
        if (isSelected) {
            setState((draft) => {
                const formData = { ...row };

                if (documentId && !formData.documentId) {
                    // We need to create a new Document Tag here
                    formData.id = null;
                    formData.isOverride = true;
                }

                draft.formData = { ...formData };
                draft.selectedItem = [row.id];

                setEditTag(row.id);
            });
        } else {
            setState((draft) => {
                draft.formData = {};
                draft.selectedItem = [];
            });

            setEditTag(undefined);
        }
    }

    function searchAndReplace() {
        setState((draft) => {
            draft.showSearchAndReplaceModal = true;
        });
    }

    function ImportExcel() {
        setState((draft) => {
            draft.ShowImportExcel = true;
        });
    }

    function handleTagSync() {
        const templateTags = template?.tags;
        syncTags({ documentId: documentId, body: templateTags });
    }

    function handleFormChange(value, propName, variantId) {
        if (variantId) {
            // Store value for specific Variant
            const index = state.formData.tagItems
                ? state.formData.tagItems.findIndex((item) => item.documentVariantId === variantId)
                : -1;

            if (index === -1) {
                // Create new Variant Tag
                const newVariantTag = {
                    documentVariantId: variantId,
                    text: '',
                    url: '',
                    tooltip: '',
                    crossReference: '',
                };
                newVariantTag[propName] = value;

                setState((draft) => {
                    if (draft.formData.tagItems) {
                        draft.formData.tagItems.push(newVariantTag);
                    } else {
                        draft.formData.tagItems = [newVariantTag];
                    }
                });
            } else {
                // Update existing Variant
                setState((draft) => {
                    draft.formData.tagItems[index][propName] = value;
                });
            }
        } else {
            setState((draft) => {
                if (
                    propName === 'text' ||
                    propName === 'url' ||
                    propName === 'tooltip' ||
                    propName === 'crossReference'
                ) {
                    if (draft.formData.tagValue) {
                        draft.formData.tagValue[propName] = value;
                    } else {
                        draft.formData.tagValue = {
                            [propName]: value,
                        };
                    }
                } else {
                    draft.formData[propName] = value;
                }
            });
        }
    }

    function handleFormSubmit(event) {
        event.preventDefault();

        setState((draft) => {
            draft.saveInProgress = true;
        });

        // Create new Tag for Document Tag?
        if (state.formData.isOverride === true && state.formData.id === null) {
            handleCreateTag({
                name: state.formData.name,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            }).then(({ data }) => {
                setState((draft) => {
                    draft.saveInProgress = false;
                    draft.formData = data;
                    draft.selectedItem = [data.id];
                });
            });

            return;
        }

        updateTag({
            organisationId: activeOrganisation,
            id: state.formData.id,
            body: {
                name: state.formData.name,
                description: state.formData.description,
                tagValue: state.formData.tagValue,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            },
        }).then(() => {
            setState((draft) => {
                draft.saveInProgress = false;
            });
        });
    }

    function handleCreateTag(formData) {
        if (documentId) {
            formData['document'] = parseInt(documentId);
        }

        return addTag({ organisationId: activeOrganisation, body: formData });
    }

    function handleDeleteTag() {
        HelperFunctions.confirmModal(
            t('document.navbar.tags.deleteModal.deleteMessage'),
            'danger',
            false,
            t('documents:document.navbar.tags.deleteModal.confirmDelete'),
            t('document.navbar.tags.deleteModal.cancel'),
        )
            .then(() => {
                deleteTag({
                    organisationId: activeOrganisation,
                    id: state.selectedItem[0],
                }).then(() => {
                    setState((draft) => {
                        draft.formData = {};
                        draft.selectedItem = [];
                    });
                });
            })
            .catch(() => {});
    }

    function canDelete() {
        // Global Tags
        if (documentId === undefined) {
            return state.selectedItem.length === 1;
        }

        // Document Tags
        if (state.selectedItem.length !== 1) {
            return false; // nothing selected
        }

        // Template Tags
        if (state.formData.parentId) {
            return false;
        }

        return state.formData.documentId === parseInt(documentId) && state.formData.isOverride === false;
    }
}

function TagEditForm({ state, setState, handleFormChange, handleFormSubmit, tags }) {
    const [editTag, setEditTag] = useQueryParam('editTag', NumberParam);
    const [editTagVariants] = useQueryParam('variants', BooleanParam);
    let { documentId } = useParams();
    const tagsAndTemplateTags = useGetTemplateTagsCombined(tags);

    const documentTagData = useGetDocumentTagData(parseInt(documentId), editTag, !editTagVariants || !editTag);
    const { isLoading, tagData } = documentTagData;

    useEffect(() => {
        if (tagData && !isLoading) {
            setState((draft) => {
                draft.formData = tagData;
            });
        }
    }, [documentTagData]);

    return (
        <>
            <EditTag
                closeSidebar={() => {
                    setEditTag(undefined);

                    setState((draft) => {
                        draft.selectedItem = [];
                        draft.formData = {};
                    });
                }}
                documentId={documentId ? parseInt(documentId) : undefined}
                formData={state.formData}
                handleFormChange={handleFormChange}
                handleFormSubmit={handleFormSubmit}
                saveInProgress={state.saveInProgress}
                tags={tagsAndTemplateTags}
            />

            {editTag && editTagVariants && (
                <TagModal
                    documentId={parseInt(documentId)}
                />
            )}
        </>
    );
}
