import { useGetGuardAudits } from '../hooks/useGetGuardAudits';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import cx from 'classnames';

export function LastResultsBars({ auditable, numberOfChecks = 10, parentUri }) {
    const { t, i18n } = useTranslation();
    const { items } = useGetGuardAudits(
        auditable['@id'],
        auditable['@type'],
        {
            page: 1,
            itemsPerPage: numberOfChecks,
        },
        parentUri,
    );

    const reversedArray = items.slice(0, numberOfChecks).reverse();
    const unknownCount = numberOfChecks - reversedArray.length;

    return (
        <div className="d-flex last-result-bars">
            {renderLeftOvers()}

            {reversedArray.map((audit) => {
                const timestamp = DateTime.fromISO(audit.processedAt).setLocale(i18n.language);

                return (
                    <div
                        className={cx({
                            'bg-success': audit.result === 'valid',
                            'bg-danger': audit.result !== 'valid',
                        })}
                        data-uk-tooltip={`title: ${timestamp.toLocaleString(DateTime.DATETIME_SHORT)}`}
                        key={`audit-${audit.id}`}
                    >
                        &nbsp;
                    </div>
                );
            })}
        </div>
    );

    function renderLeftOvers() {
        if (unknownCount <= 0) {
            return null;
        }

        return (
            <>
                {[...Array(unknownCount)].map((x, index) => (
                    <div
                        data-uk-tooltip={t('publications:guard.tooltips.noData')}
                        className="bg-light-gray"
                        key={`leftover-row-${index}`}
                    >
                        &nbsp;
                    </div>
                ))}
            </>
        );
    }
}
