import { useGetGuardAudits } from './hooks/useGetGuardAudits';
import { PaginatedTable, TableProvider } from '../../../../components/PaginatedTable';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CircleFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';

export function GuardEntityAudits({ guardEntity, parentUri }) {
    const { t } = useTranslation('publications');
    const { items, totalItems, isEmpty } = useGetGuardAudits(guardEntity['@id'], guardEntity['@type'], {}, parentUri);

    return (
        <div>
            <div className="font-weight-bold">{t('publications:guard.headings.auditLog')}</div>

            <TableProvider>
                <PaginatedTable
                    items={items}
                    totalItems={totalItems}
                    clientSidePagination={false}
                    renderSearch={false}
                    itemsPerPage={10}
                    colSpan={3}
                >
                    {({ items = [] }) => (
                        <>
                            <thead>
                                <tr>
                                    <th>{t('publications:guard.headings.dateTime')}</th>
                                    <th>{t('publications:guard.headings.results')}</th>
                                    <th>{t('publications:guard.headings.auditType')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*{isLoading && <TableRowSkeleton cellCount={my ? 7 : 6} />}*/}

                                {isEmpty ? (
                                    <tr>
                                        <td className="text-muted" colSpan="3">
                                            {t('publications:guard.messages.noAuditsYet')}
                                        </td>
                                    </tr>
                                ) : (
                                    <>
                                        {items.map((guardAudit) => (
                                            <GuardAuditRow guardAudit={guardAudit} key={`audit-${guardAudit.id}`} />
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </>
                    )}
                </PaginatedTable>
            </TableProvider>
        </div>
    );
}

function GuardAuditRow({ guardAudit }) {
    const { t, i18n } = useTranslation();

    const processedAt = DateTime.fromISO(guardAudit.processedAt)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_MED);

    const isValid = guardAudit.result === 'valid';

    return (
        <tr>
            <td className="align-top">
                <div className="flex-center">
                    <CircleFill
                        size={8}
                        className={cx('mr-2', {
                            'text-success': isValid,
                            'text-danger': !isValid,
                        })}
                    />
                    {processedAt}
                </div>
            </td>
            <td>
                <AuditResult result={guardAudit.result} contentHash={guardAudit.contentHash} />
            </td>
            <td>{t('publications:guard.badge.typeHashCheck')}</td>
        </tr>
    );
}

export function AuditResult({ result, contentHash }) {
    const { t } = useTranslation();

    return (
        <div>
            {renderStatus()}
            {contentHash && (
                <>
                    <span>&nbsp;-&nbsp;</span>
                    <span>
                        <span
                            className="text-monospace mr-2"
                            data-uk-tooltip={t('publications:guard.tooltips.hashOfFile')}
                        >
                            {contentHash.substring(0, 7)}
                        </span>
                        <CopyToClipboard text={contentHash} />
                    </span>
                </>
            )}
        </div>
    );

    function renderStatus() {
        if (result === 'inaccessible') {
            return (
                <span className="font-weight-bold text-danger text-uppercase">
                    {t('publications:guard.status.inaccessible')}
                </span>
            );
        }

        if (result === 'hash_invalid') {
            return (
                <span className="font-weight-bold text-danger text-uppercase">
                    {t('publications:guard.status.hashInvalid')}
                </span>
            );
        }

        if (result === 'valid') {
            return (
                <span className="font-weight-bold text-success text-uppercase">
                    {t('publications:guard.status.valid')}
                </span>
            );
        }

        return (
            <span className="font-weight-bold text-danger text-uppercase">
                {t('publications:guard.status.unknown')}
            </span>
        );
    }
}
