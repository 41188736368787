import { useGetTranslationKeyQuery } from '../../../features/translations/translationApi';

export function useGetTranslationKey(translationKeyUri, skip = false) {
    const { translationKey } = useGetTranslationKeyQuery(translationKeyUri, {
        selectFromResult: ({ data }) => ({
            translationKey: data,
        }),
        skip,
    });

    return translationKey;
}
