import { Permissions } from 'config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import cx from 'classnames';
import getEnv from '../../../config/Env';
import { useConnectMutation } from '../../../features/publications/guardApi';
import { useUpdateOrganisationMutation } from '../../../features/security/authApi';
import { PrimaryButton } from '../../../components/Buttons';
import { useState } from 'react';
import Spinner from '../../global/Spinner';
import { useTranslation } from 'react-i18next';

export function Guard() {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();

    return (
        <RestrictedContent permission={Permissions.GuardConfig.Create}>
            <div>
                <div className="subheader">
                    <h3>{t('publications:guard.guardIntegrity')}</h3>
                </div>

                <div>
                    Status:{' '}
                    <span
                        className={cx('font-weight-bold', {
                            'text-success': currentOrganisation.guardConnected,
                            'text-danger': !currentOrganisation.guardConnected,
                        })}
                    >
                        {t(
                            currentOrganisation.guardConnected
                                ? 'publications:guard.admin.connected'
                                : 'publications:guard.admin.notConnected',
                        )}
                    </span>
                </div>

                {!currentOrganisation.guardConnected && <ConnectGuard organisation={currentOrganisation} />}
            </div>
        </RestrictedContent>
    );
}

function ConnectGuard({ organisation }) {
    const { t } = useTranslation();
    const environment = getEnv('DOCREV_ENV');
    const [connect] = useConnectMutation();
    const [updateOrganisation] = useUpdateOrganisationMutation();
    const [isSubmitting, setSubmitting] = useState(false);

    return (
        <div className="mt-3">
            <PrimaryButton className="flex-center" onClick={handleConnect} disabled={isSubmitting}>
                {isSubmitting && <Spinner variant="light" className="flex-shrink-0 mr-2" />}
                {isSubmitting
                    ? `${t('publications:guard.admin.connecting')}...`
                    : t('publications:guard.admin.connectTo')}
            </PrimaryButton>
        </div>
    );

    function handleConnect() {
        setSubmitting(true);

        connect({
            environment,
            organisationName: organisation.name,
            organisationId: organisation.id,
        }).then((result) => {
            if (result?.data?.token) {
                updateOrganisation({
                    id: organisation.id,
                    body: {
                        guardConnected: true,
                        guardApiToken: result.data.token,
                    },
                });
            } else {
                setSubmitting(false);
            }
        });
    }
}
