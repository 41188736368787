import { useTranslation } from 'react-i18next';
import { useGetPublicationDomains } from '../../hooks/useGetPublicationDomains';
import { CopyToClipboard } from 'components/CopyToClipboard';
import _ from 'lodash';
import Constants from '../../../../config/Constants';
import { IconButton, LightOutlineButton } from 'components/Buttons';
import { useEffect, useState } from 'react';
import { DashSquareDotted, Download, PencilSquare, PlusSquareDotted, ShieldPlus } from 'react-bootstrap-icons';
import { useCreateGuardEntityMutation } from 'features/publications/guardApi';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import Spinner from '../../../global/Spinner';
import { downloadVersionFile, useGeneratePublicationVersionHashesMutation } from 'features/publications/publicationApi';
import { StringParam, useQueryParam } from 'use-query-params';
import { FileGuardAliases } from './FileGuardAliases';
import { useGetGuardEntityForProperty } from '../guard/hooks/useGetGuardEntityForProperty';
import { AuditableStatusIcon } from '../guard/AuditableStatusIcon';
import { LastResultsBars } from '../guard/views/LastResultsBars';
import { AuditableInCirculationIcon } from '../guard/AuditableInCirculationIcon';
import { useHasModuleEnabled } from 'pages/global/RestrictedContent';
import { pendingStatusCodes, scheduledStatusCodes } from '../../hooks/useGetPublicationVersions';
import { Skeleton } from 'components/Skeleton';
import { saveAs } from 'file-saver';
import cx from 'classnames';
import HelperFunctions from '../../../global/HelperFunctions';
import { AuditableNftTokenIcon } from 'pages/publications_v2/views/guard/AuditableNftTokenIcon';
import { AuditableInCirculationFrom } from 'pages/publications_v2/views/guard/AuditableInCirculationFrom';

export function PublicationVersionFiles({ publication, version }) {
    const { t } = useTranslation('publications');
    const publicationDomains = useGetPublicationDomains();
    const domain = publicationDomains.find((_domain) => _domain.id === version.domainId);
    const organisation = useCurrentOrganisation();
    const hasGuardModuleEnabled = useHasModuleEnabled(Constants.modules.guard);

    const showGuard = hasGuardModuleEnabled && organisation.guardConnected && publication.guardEnabled;

    return (
        <tr className="expanded-row-details">
            <td colSpan={9}>
                <div className="px-3 pb-3">
                    <div className="dr-container">
                        <table className="table dr-table">
                            <thead>
                                <tr>
                                    {showGuard && <th className="px-0" style={{ minWidth: 26, width: 26 }} />}
                                    <th>{t('publication.columnTitles.type')}</th>
                                    <th>{t('publication.columnTitles.url')}</th>

                                    {showGuard && (
                                        <>
                                            <th>
                                                <div className="flex-center">
                                                    {t('publications:guard.guardIntegrity')}
                                                </div>
                                            </th>
                                            <th>In&nbsp;omloop</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {version.publishedFiles.map((mediaType) => (
                                    <VersionMedia
                                        publication={publication}
                                        version={version}
                                        property={mediaType}
                                        publicationDomain={domain}
                                        showGuard={showGuard}
                                        key={`version-${version.id}-type-${mediaType}`}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
        </tr>
    );
}

function VersionMedia({ publication, property, version, publicationDomain, showGuard = false }) {
    const { t } = useTranslation();
    const { guardEntity } = useGetGuardEntityForProperty(publication.id, version.id, property, !showGuard);
    const [showAliases, setShowAliases] = useState(false);
    const [isDownloading, setDownloading] = useState(false);

    const hasGuardEntity = showGuard && guardEntity;

    useEffect(() => {
        if (hasGuardEntity) {
            setShowAliases(true);
        }
    }, [hasGuardEntity]);

    const linkUrl = getMediaUrl();
    const canAddToGuard = ['html', 'pdf'].includes(property);

    const result = property.replace(/([A-Z])/g, ' $1');
    const title = result.charAt(0).toUpperCase() + result.slice(1);

    const versionIsPending = pendingStatusCodes.includes(version.status);
    const versionIsScheduled = scheduledStatusCodes.includes(version.status);
    const versionFailed = version.status === Constants.publicationVersionStatus.processing_failed;

    const canDownloadFile = [
        Constants.publicationVersionStatus.scheduledForPublishing,
        Constants.publicationVersionStatus.scheduledForUnpublishing,
        Constants.publicationVersionStatus.published,
        Constants.publicationVersionStatus.unpublished,
    ].includes(version.status);

    return (
        <>
            <tr>
                {showGuard && (
                    <td className="text-right px-0">
                        <IconButton
                            disabled={!canAddToGuard || !hasGuardEntity}
                            tooltip={t(`publications:guard.tooltips.${showAliases ? 'hideAliases' : 'showAliases'}`)}
                            icon={
                                showAliases ? (
                                    <DashSquareDotted className="flex-shrink-0" size={12} />
                                ) : (
                                    <PlusSquareDotted className="flex-shrink-0" size={12} />
                                )
                            }
                            onClick={() => setShowAliases(!showAliases)}
                        />
                    </td>
                )}
                <td>{title}</td>
                <td>
                    <div style={{ wordBreak: 'break-all' }}>
                        {_.isEmpty(linkUrl) ? (
                            <span
                                className={cx({
                                    'text-muted': versionFailed,
                                })}
                            >
                                {renderFilename()}
                            </span>
                        ) : (
                            <>{renderUrl()}</>
                        )}

                        {canDownloadFile && (
                            <IconButton
                                tooltip={t('publications:publications.tooltip.downloadFile')}
                                icon={<Download className="flex-shrink-0 ml-2" size={14} />}
                                onClick={downloadFile}
                                disabled={isDownloading}
                            />
                        )}
                    </div>
                </td>

                {showGuard && (
                    <GuardPublicationVersionRow
                        url={linkUrl}
                        version={version}
                        publication={publication}
                        property={property}
                        canAddToGuard={canAddToGuard}
                    />
                )}
            </tr>

            {showAliases && <FileGuardAliases publication={publication} version={version} property={property} />}
        </>
    );

    function renderUrl() {
        const urlObject = new URL(linkUrl);

        return (
            <>
                {versionFailed || versionIsScheduled || versionIsPending ? (
                    <span className="text-muted font-weight-normal mr-2" style={{ fontSize: 12 }}>
                        {`${urlObject.host}${urlObject.pathname}`}
                    </span>
                ) : (
                    <a href={linkUrl} className="font-weight-normal mr-2" style={{ fontSize: 12 }} target="_blank">
                        {`${urlObject.host}${urlObject.pathname}`}
                    </a>
                )}

                {version.status !== Constants.publicationVersionStatus.processing_failed && (
                    <CopyToClipboard text={linkUrl} />
                )}
            </>
        );
    }

    function renderFilename() {
        if (property === 'print') {
            return _.isEmpty(publication.printPdfFileName) ? '-' : publication.printPdfFileName + '.pdf';
        }

        if (property === 'pdf') {
            return _.isEmpty(publication.pdfFileName) ? '-' : publication.pdfFileName + '.pdf';
        }

        if (property === 'html') {
            return _.isEmpty(publication.htmlSlug) ? '-' : publication.htmlSlug + '.html';
        }

        return '-';
    }

    function getMediaUrl() {
        // Show archive URL when version is unpublished
        const showArchive =
            (publication.multiVersions === false &&
                version.status === Constants.publicationVersionStatus.published &&
                version.published === false) ||
            version.status === Constants.publicationVersionStatus.unpublished;

        const propName = property + (showArchive ? 'ArchiveUrl' : 'Url');
        const path = version[propName] ?? '';

        if (path === '') {
            return '';
        }

        if (path.startsWith('http')) {
            return path;
        }

        return `'https'://${publicationDomain.domain}/${path}`;
    }

    function downloadFile() {
        setDownloading(true);

        downloadVersionFile(version['@id'], property)
            .then((response) => {
                saveAs(response.data, decodeURI(response.headers['x-suggested-filename'] ?? 'file'));
            })
            .catch(() => {
                HelperFunctions.alertModal(t('global:error.exportFailed'));
            })
            .finally(() => {
                setDownloading(false);
            });
    }
}

function GuardPublicationVersionRow({ url, version, publication, property, canAddToGuard = true }) {
    const { t } = useTranslation();
    const [, setGuardModal] = useQueryParam('guardModal', StringParam);
    const { guardEntity, isLoading } = useGetGuardEntityForProperty(publication.id, version.id, property);

    if (isLoading) {
        return (
            <>
                <td>
                    <Skeleton />
                </td>
                <td>
                    <Skeleton />
                </td>
            </>
        );
    }

    if (guardEntity) {
        return (
            <>
                <td className="pr-2">
                    <div className="flex-center">
                        {/*<AuditableNftTokenIcon auditable={guardEntity} iconOnly={true} className="mr-3" />*/}
                        <AuditableStatusIcon auditable={guardEntity} guardEntity={guardEntity} iconOnly={true} />
                        <LastResultsBars auditable={guardEntity} />
                        <IconButton
                            tooltip={t('publications:guard.tooltips.moreInfo')}
                            icon={<PencilSquare size={14} />}
                            onClick={showGuardModal}
                            className="flex-shrink-0 ml-2"
                        />
                    </div>
                </td>
                <td>
                    <AuditableInCirculationFrom auditable={guardEntity} />
                </td>
            </>
        );
    }

    return (
        <td colSpan={2}>
            <UploadVersion
                url={url}
                version={version}
                publicationId={publication.id}
                property={property}
                canAddToGuard={canAddToGuard}
            />
        </td>
    );

    function showGuardModal() {
        setGuardModal(guardEntity.id);
    }
}

function UploadVersion({ url, version, publicationId, property, canAddToGuard }) {
    const { t } = useTranslation();
    const [createGuardEntity] = useCreateGuardEntityMutation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [isSubmitting, setSubmitting] = useState(false);
    const [generatePublicationVersionHashes] = useGeneratePublicationVersionHashesMutation();

    const hash = _.get(version, `contentHashes.${property}`);

    return (
        <LightOutlineButton
            size="sm"
            onClick={handleUpload}
            tooltip={
                canAddToGuard
                    ? t('publications:guard.tooltips.enableForFile')
                    : 'Guard beveiliging niet beschikbaar voor dit media type'
            }
            className="pl-2"
            disabled={
                !canAddToGuard ||
                isSubmitting ||
                version.status === Constants.publicationVersionStatus.processing_failed
            }
        >
            {isSubmitting ? (
                <Spinner className="flex-shrink-0 mr-1" />
            ) : (
                <ShieldPlus size={14} className="flex-shrink-0 mr-1" />
            )}
            {t('publications:guard.buttons.enable')}
        </LightOutlineButton>
    );

    function handleUpload() {
        setSubmitting(true);

        new Promise((resolve) => {
            if (_.isEmpty(hash)) {
                // Generate hash first
                generatePublicationVersionHashes(version['@id']).then(({ data }) => {
                    resolve(_.get(data, `contentHashes.${property}`));
                });
            } else {
                resolve(hash);
            }
        }).then((validationHash) => {
            createGuardEntity({
                token: guardApiToken,
                body: {
                    publicationId,
                    publicationVersionId: version.id,
                    contentType: property,
                    url,
                    validationHash,
                },
            }).then(() => {
                setSubmitting(false);
            });
        });
    }
}
