import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export function AuditableInCirculationFrom({ auditable }) {
    const { t, i18n } = useTranslation();

    const inCirculation = auditable.inCirculationSince;

    return (
        <>
            {inCirculation ? (
                <>
                    {DateTime.fromISO(auditable.inCirculationSince)
                        .setLocale(i18n.language)
                        .toLocaleString(DateTime.DATE_SHORT)}
                </>
            ) : (
                <span className="text-danger" data-uk-tooltip={`Startdatum niet ingesteld`}>
                    {t('global:no')}
                </span>
            )}
        </>
    );
}
