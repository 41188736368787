import { useParams } from 'react-router-dom';
import MainContentNav from '../../../Navbar';
import { BASE_PATH } from 'scenes/Translations';
import LoadingSpinner from '../../../global/LoadingSpinner';
import SubNav from './SubNav';
import Dashboard from './Dashboard';
import Properties from './Properties';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';

export default function View() {
    const { view = 'dashboard' } = useParams();
    const { t } = useTranslation('translations');
    const translationContainer = useGetTranslationContainer();

    return (
        <>
            <MainContentNav
                pages={[
                    { title: t('translation.navbar.dashboard.document.breadcrumb'), url: BASE_PATH },
                    { title: translationContainer?.name },
                ]}
            />

            {translationContainer ? <SubNav translationContainer={translationContainer} /> : <LoadingSpinner />}

            <div className="content-static-body" style={{ top: 118 }}>
                <RenderView view={view} />
            </div>
        </>
    );
}

function RenderView({ view }) {
    if (view === 'dashboard') {
        return <Dashboard />;
    }

    if (view === 'properties') {
        return <Properties />;
    }

    return <>View '{view}' is not supported.</>;
}
