import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'features/documents/documents';

/**
 * @param {string|number} [id]
 * @param {boolean} [showAreas=false]
 */
export function useGetDocument(id, showAreas = false) {
    const { documentId } = useParams();
    const documentIdForQuery = id ? parseInt(id) : parseInt(documentId);

    const { document } = useGetDocumentQuery(
        { id: documentIdForQuery, showAreas },
        {
            selectFromResult: ({ data }) => ({
                document: data,
            }),
            skip: !documentIdForQuery,
        }
    );

    return document;
}
