import HelperFunctions from '../../global/HelperFunctions';
import { useGetPublicationDomains } from './useGetPublicationDomains';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import { useMemo } from 'react';

export function useGetPublicationUrls(publication) {
    const currentOrganisation = useCurrentOrganisation();
    const publicationDomains = useGetPublicationDomains();

    const {
        html = false,
        pdf = false,
        printPdf = false,
        htmlSlug = '',
        pdfFileName = '',
        printPdfFileName = '',
        domainId,
    } = publication;
    const domain = domainId ? publicationDomains.find((_domain) => _domain.id === publication.domainId) : undefined;

    const htmlUrl = useMemo(() => {
        return getHtmlDomain();
    }, [html, htmlSlug, domainId]);

    const pdfUrl = useMemo(() => {
        return getPdfDomain(pdf, pdfFileName);
    }, [pdf, pdfFileName, domainId]);

    const printPdfUrl = useMemo(() => {
        return getPdfDomain(printPdf, printPdfFileName);
    }, [printPdf, printPdfFileName, domainId]);

    return {
        htmlUrl,
        pdfUrl,
        printPdfUrl,
    };

    function getHtmlDomain() {
        if (!html || !domain) {
            return '';
        }

        const urlFormat = domain.urlFormat ?? 'https://:domain/:filename';

        const mapObj = {
            ':domain': domain.domain,
            ':organisation': currentOrganisation.slug,
            ':prefix': domain.pathPrefix ?? '',
            ':filename': HelperFunctions.slugify(htmlSlug, true),
        };

        return urlFormat
            .replace(/(:domain|:organisation|:prefix|:filename)/gi, (matched) => mapObj[matched])
            .replace(/(?<!:)\/\//gi, '/');
    }

    function getPdfDomain(enabled = true, filename = '') {
        if (!enabled || !domain || _.isEmpty(filename)) {
            return '';
        }

        const urlFormat = domain.urlFormat ?? 'https://:domain/:filename';

        const mapObj = {
            ':domain': domain.domain,
            ':organisation': currentOrganisation.slug,
            ':prefix': domain.pathPrefix ?? '',
            ':filename': HelperFunctions.slugify(filename, true) + '.pdf',
        };

        return urlFormat
            .replace(/(:domain|:organisation|:prefix|:filename)/gi, (matched) => mapObj[matched])
            .replace(/(?<!:)\/\//gi, '/');
    }
}
