import { useGetGuardEntityForProperty } from '../guard/hooks/useGetGuardEntityForProperty';
import { useGetGuardAliases } from '../guard/hooks/useGetGuardAliases';
import { AuditableStatusIcon } from '../guard/AuditableStatusIcon';
import cx from 'classnames';
import { LastResultsBars } from '../guard/views/LastResultsBars';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'components/Skeleton';
import { AuditableInCirculationFrom } from 'pages/publications_v2/views/guard/AuditableInCirculationFrom';

export function FileGuardAliases({ publication, version, property }) {
    const { t } = useTranslation();
    const { guardEntity } = useGetGuardEntityForProperty(publication.id, version.id, property);
    const { items, isEmpty, totalItems, isLoading } = useGetGuardAliases(guardEntity ? guardEntity['@id'] : undefined);

    if (!guardEntity || isEmpty) {
        return (
            <tr className="guard-alias-row first last">
                <td className="border-cell border-top-0" />
                <td className="border-top-0" colSpan={4}>
                    <div className="font-italic">{t('publications:guard.messages.noAliasesAdded')}</div>
                </td>
            </tr>
        );
    }

    if (isLoading) {
        return (
            <tr className="guard-alias-row first last">
                <td className="border-cell border-top-0" />
                <td className="border-top-0">
                    <Skeleton />
                </td>
                <td className="border-top-0">
                    <Skeleton />
                </td>
                <td className="border-top-0">
                    <Skeleton />
                </td>
                <td className="border-top-0">
                    <Skeleton />
                </td>
            </tr>
        );
    }

    return (
        <>
            {items.map((alias, index) => (
                <FileAlias
                    alias={alias}
                    parentUri={guardEntity['@id']}
                    guardEntity={guardEntity}
                    index={index}
                    last={index >= totalItems - 1}
                    key={`alias-${alias.id}`}
                />
            ))}
        </>
    );
}

function FileAlias({ alias, parentUri, index, last, guardEntity }) {
    const { t } = useTranslation();

    return (
        <tr
            className={cx('guard-alias-row', {
                first: index === 0,
                last: last,
            })}
        >
            <td className="border-cell border-top-0" />
            <td className="border-top-0">{t('publications:guard.alias')}</td>
            <td className="border-top-0">
                <div style={{ wordBreak: 'break-all' }}>{alias.url.replace('https://', '')}</div>
            </td>
            <td className="border-top-0 pr-2">
                <div className="flex-center">
                    <AuditableStatusIcon auditable={alias} guardEntity={guardEntity} iconOnly={true} />
                    <LastResultsBars auditable={alias} parentUri={parentUri} />
                </div>
            </td>
            <td className="border-top-0">
                <AuditableInCirculationFrom auditable={alias} />
            </td>
        </tr>
    );
}
