import { useCurrentOrganisation } from '../../../../../hooks/useCurrentOrganisation';
import { useGetGuardEntityQuery } from '../../../../../features/publications/guardApi';

export function useGetGuardEntity(guardEntityId) {
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;

    const { guardEntity } = useGetGuardEntityQuery(
        { id: guardEntityId, token: guardApiToken },
        {
            selectFromResult: ({ data }) => ({
                guardEntity: data,
            }),
            skip: !guardEntityId,
        },
    );

    return guardEntity;
}
