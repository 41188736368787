import { useGetDocumentGroupsQuery } from '../../../features/documents/documents';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetDocumentGroups(parentId = null) {
    const activeOrganisation = useActiveOrganisation();

    const filterGroups = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (res, organisationId, parentId) => parentId,
            (data, organisationId, parentId) => {
                if (!data) {
                    return emptyArray;
                }

                return data
                    .filter((group) => group.organisationId === organisationId && group.parent === parentId)
                    .sort(HelperFunctions.dynamicSort('sortOrder'));
            },
        );
    }, []);

    const { documentGroups } = useGetDocumentGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            documentGroups: filterGroups(result, activeOrganisation, parentId),
        }),
    });

    return documentGroups;
}
