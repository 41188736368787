import { FieldSelect, FormField, MediaField, Switch, Textarea } from '../helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import { Field, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import Constants from '../../../config/Constants';
import RestrictedContent from '../../global/RestrictedContent';
import { getLanguageOptions } from '../../translation/helpers/LanguageHelper';
import { PublicationProperty, templateProperties } from './PublicationProperty';
import { useGetPublicationVersions } from '../hooks/useGetPublicationVersions';
import { useTranslation } from 'react-i18next';
import { useGetPublications } from '../hooks/useGetPublications';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';
import _ from 'lodash';
import { useGetPublicationUrls } from '../hooks/useGetPublicationUrls';

export default function Layout() {
    const { t } = useTranslation('publications');
    const { values, setFieldValue, errors, touched } = useFormikContext();
    const allPublications = useGetPublications();
    const currentOrganisation = useCurrentOrganisation();

    const publicationDomains = useGetPublicationDomains();
    const documentTemplates = currentOrganisation?.documentTemplates ?? [];

    const { publicationVersions } = useGetPublicationVersions(values.id ?? undefined);
    const hasPublicationVersions = publicationVersions.length > 0;

    const templateOptions = HelperFunctions.prepareDropdownData(documentTemplates, 'name', 'id').sort(
        HelperFunctions.sortByString('name'),
    );
    const domainOptions = HelperFunctions.prepareDropdownData(publicationDomains, 'domain', 'id');

    const { htmlUrl, pdfUrl, printPdfUrl } = useGetPublicationUrls(values);

    // Set html/pdf filename to publication as default
    const {
        html = false,
        pdf = false,
        htmlSlug = '',
        pdfFileName = '',
        title = '',
        multiVersions = false,
        domainId,
    } = values;

    const isPdfFileNameError = _.get(errors, 'pdfFileName') && _.get(touched, 'pdfFileName');
    const isHtmlSlugError = _.get(errors, 'htmlSlug') && _.get(touched, 'htmlSlug');

    const [isHtmlSlugDuplicate, isPdfFileNameDuplicate] = useMemo(() => {
        // Get all publications with the same domain (and not the current)
        const publications = allPublications.filter(
            (publication) => publication.id !== values.id && publication.domainId === domainId,
        );

        const isHtmlSlugDuplicate = publications.some(
            (publication) => publication.htmlSlug && publication.htmlSlug === htmlSlug,
        );
        const isPdfFileNameDuplicate = publications.some(
            (publication) => publication.pdfFileName && publication.pdfFileName === pdfFileName,
        );

        return [isHtmlSlugDuplicate, isPdfFileNameDuplicate];
    }, [allPublications, htmlSlug, pdfFileName, domainId]);

    useEffect(() => {
        if (html && title !== '' && htmlSlug === '') {
            setFieldValue('htmlSlug', HelperFunctions.slugify(title, true));
        }
    }, [html, title]);

    useEffect(() => {
        if (pdf && title !== '' && pdfFileName === '') {
            const cleanTitle = title.replace(/\n/g, ' ');
            setFieldValue('pdfFileName', HelperFunctions.slugify(cleanTitle, true));
        }
    }, [pdf, title]);

    return (
        <>
            <div className="p-4">
                <FieldSelect
                    name="templateId"
                    label={`${t('publication.settings.content.layout.titles.template')}*`}
                    options={templateOptions}
                />

                <FieldSelect
                    name="domainId"
                    label={`${t('publication.settings.content.layout.titles.domain')}*`}
                    options={domainOptions}
                    props={{ isDisabled: hasPublicationVersions && multiVersions }}
                />

                <PublicationProperty property={templateProperties.layout.multiVersions}>
                    <Switch
                        name="multiVersions"
                        label={t('publication.settings.content.layout.titles.moreOptions')}
                        help={t('publication.settings.content.layout.titles.moreOptionsAllow')}
                    />
                </PublicationProperty>

                <RestrictedContent module={Constants.modules.translations}>
                    <PublicationProperty property={templateProperties.layout.languageIso}>
                        <Translation />
                    </PublicationProperty>
                </RestrictedContent>

                <PublicationProperty property={templateProperties.layout.logo}>
                    <MediaField name="logo" label="Logo" />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.layout.html}>
                    <>
                        <span className="font-weight-bolder text-color font-size-sm">HTML / online</span>

                        <Switch name="html" label={t('publication.settings.content.layout.titles.htmlOnline')} />

                        {html && (
                            <>
                                <FormField
                                    label="Url*"
                                    name="htmlSlug"
                                    help={htmlUrl}
                                    alertText={
                                        isHtmlSlugDuplicate
                                            ? t('publication.settings.content.layout.titles.alertText')
                                            : undefined
                                    }
                                    props={{
                                        disabled: hasPublicationVersions && multiVersions,
                                        isInvalid: isHtmlSlugDuplicate || isHtmlSlugError,
                                        onChange: (e) => {
                                            setFieldValue('htmlSlug', HelperFunctions.slugify(e.target.value, true));
                                        },
                                    }}
                                />
                                <FormField
                                    label={t('publication.settings.content.layout.titles.metaTitle')}
                                    name="htmlMeta"
                                />
                                <Textarea
                                    label={t('publication.settings.content.layout.titles.metaDescription')}
                                    name="htmlMetaDescription"
                                    props={{ rows: 3 }}
                                />

                                <PublicationProperty property={templateProperties.layout.htmlMetaFooter}>
                                    <FormField
                                        label={t('publication.settings.content.layout.titles.htmlMetaFooter')}
                                        name="htmlMetaFooter"
                                        help="Formaat: G-XXXXXXXXX"
                                    />
                                </PublicationProperty>

                                <PublicationProperty property={templateProperties.layout.coverImage}>
                                    <MediaField
                                        name="coverImage"
                                        label={t('publication.settings.content.layout.titles.coverPhoto')}
                                    />
                                </PublicationProperty>
                            </>
                        )}
                    </>
                </PublicationProperty>

                <PublicationProperty property={templateProperties.layout.pdf}>
                    <>
                        <span className="font-weight-bolder text-color font-size-sm">Pdf</span>

                        <Switch name="pdf" label={t('publication.settings.content.layout.titles.pdfVersion')} />

                        {pdf && (
                            <>
                                <FormField
                                    label={`${t('publication.settings.content.layout.titles.fileName')}*`}
                                    name="pdfFileName"
                                    help={pdfUrl}
                                    alertText={
                                        isPdfFileNameDuplicate
                                            ? t('publication.settings.content.layout.titles.alertText')
                                            : undefined
                                    }
                                    props={{
                                        disabled: hasPublicationVersions && multiVersions,
                                        isInvalid: isPdfFileNameDuplicate || isPdfFileNameError,
                                        onChange: (e) => {
                                            setFieldValue('pdfFileName', HelperFunctions.slugify(e.target.value, true));
                                        },
                                    }}
                                />

                                <PublicationProperty property={templateProperties.layout.pdfCoverImage}>
                                    <MediaField
                                        name="pdfCoverImage"
                                        label={t('publication.settings.content.layout.titles.coverPhoto')}
                                    />
                                </PublicationProperty>

                                <PublicationProperty property={templateProperties.layout.pdfBackCoverImage}>
                                    <MediaField
                                        name="pdfBackCoverImage"
                                        label={t('publication.settings.content.layout.titles.backCoverPhoto')}
                                    />
                                </PublicationProperty>

                                <FormField
                                    label={t('publication.settings.content.layout.titles.metaTitle')}
                                    name="pdfMetaTitle"
                                />
                                <FormField
                                    label={t('publication.settings.content.layout.titles.metaAuthor')}
                                    name="pdfMetaAuthor"
                                />
                                <FormField
                                    label={t('publication.settings.content.layout.titles.metaSubject')}
                                    name="pdfMetaSubject"
                                />
                                <FormField
                                    label={t('publication.settings.content.layout.titles.metaKeywords')}
                                    name="pdfMetaKeywords"
                                />

                                <PublicationProperty property={templateProperties.layout.pdfProtection}>
                                    <PdfProtection />
                                </PublicationProperty>
                            </>
                        )}

                        <PublicationProperty property={templateProperties.layout.printPdf}>
                            <>
                                <span className="font-weight-bolder text-color font-size-sm">
                                    {t('publication.settings.content.layout.titles.pdfPrinting')}
                                </span>

                                <Switch
                                    name="printPdf"
                                    label={t('publication.settings.content.layout.titles.pdfPrintVersion')}
                                />

                                {values.printPdf && (
                                    <>
                                        <FormField
                                            label={`${t('publication.settings.content.layout.titles.fileName')}*`}
                                            name="printPdfFileName"
                                            help={printPdfUrl}
                                            // alertText={
                                            //     isPdfFileNameDuplicate
                                            //         ? t('publication.settings.content.layout.titles.alertText')
                                            //         : undefined
                                            // }
                                            props={{
                                                // disabled: hasPublicationVersions && multiVersions,
                                                // isInvalid: isPdfFileNameDuplicate || isPdfFileNameError,
                                                onChange: (e) => {
                                                    setFieldValue('printPdfFileName', HelperFunctions.slugify(e.target.value, true));
                                                },
                                            }}
                                        />

                                        <PublicationProperty property={templateProperties.layout.printPdfCoverImage}>
                                            <MediaField
                                                name="printPdfCoverImage"
                                                label={t('publication.settings.content.layout.titles.coverPhotoPrint')}
                                            />
                                        </PublicationProperty>

                                        <PublicationProperty
                                            property={templateProperties.layout.printPdfBackCoverImage}
                                        >
                                            <MediaField
                                                name="printPdfBackCoverImage"
                                                label={t(
                                                    'publication.settings.content.layout.titles.backCoverPhotoPrint',
                                                )}
                                            />
                                        </PublicationProperty>
                                    </>
                                )}
                            </>
                        </PublicationProperty>
                    </>
                </PublicationProperty>
            </div>
        </>
    );
}

function PdfProtection() {
    const { values } = useFormikContext();
    const { pdfProtection = false } = values;
    const { t } = useTranslation('publications');

    const permissions = {
        copy: t('publication.settings.content.layout.pdfProtection.copy'),
        print: t('publication.settings.content.layout.pdfProtection.printLowRes'),
        'print-highres': t('publication.settings.content.layout.pdfProtection.printHighRes'),
        modify: t('publication.settings.content.layout.pdfProtection.editDocument'),
        'annot-forms': t('publication.settings.content.layout.pdfProtection.moreAnnotations'),
        'fill-forms': t('publication.settings.content.layout.pdfProtection.fillForms'),
        extract: t('publication.settings.content.layout.pdfProtection.contentAccessible'),
        assemble: t('publication.settings.content.layout.pdfProtection.assembleDocument'),
    };

    return (
        <>
            <Switch name="pdfProtection" label={t('publication.settings.content.layout.pdfProtection.securePdf')} />

            {pdfProtection && (
                <>
                    <FormField
                        label={t('publication.settings.content.layout.pdfProtection.viewPassword')}
                        name="pdfPassword"
                        help={t('publication.settings.content.layout.pdfProtection.viewPasswordHelp')}
                    />

                    <FormField
                        label={t('publication.settings.content.layout.pdfProtection.editPassword')}
                        name="pdfPermissionPassword"
                        help={t('publication.settings.content.layout.pdfProtection.editPasswordHelp')}
                    />

                    <div className="form-group row mb-3">
                        <label className="form-label col-form-label col-sm-4">
                            {t('publication.settings.content.layout.pdfProtection.additionalRights')}
                        </label>
                        <div className="col-sm-8">
                            <div style={{ marginTop: '0.75rem' }}>
                                {Object.keys(permissions).map((permissionKey) => {
                                    return (
                                        <div className="form-check" key={`pdfPermissions.${permissionKey}`}>
                                            <Field
                                                type="checkbox"
                                                name="pdfPermissions"
                                                value={permissionKey}
                                                id={`pdfPermissions.${permissionKey}`}
                                                className="form-check-input"
                                            />
                                            <label
                                                htmlFor={`pdfPermissions.${permissionKey}`}
                                                className="form-check-label"
                                            >
                                                {permissions[permissionKey]}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

function Translation() {
    const { t } = useTranslation('publications');

    return (
        <FieldSelect
            name="languageIso"
            label={t('publication.settings.content.layout.pdfProtection.language')}
            options={getLanguageOptions()}
            props={{
                placeholder: `${t('publication.settings.content.layout.pdfProtection.selectLanguage')}...`,
                isClearable: true,
            }}
        />
    );
}
