import MainContentNav from '../../Navbar';
import { useEffect, useMemo, useState } from 'react';
import Sidebar from './index/Sidebar';
import Documents from './index/Documents';
import Filters from './index/Filters';
import EditFolderModal from '../modals/EditFolderModal';
import { DragDropContext } from 'react-beautiful-dnd';
import cx from 'classnames';
import _ from 'lodash';
import NewDocumentModal from '../modals/NewDocumentModal';
import {
    useGetDocumentGroupsQuery,
    useUpdateDocumentGroupsMutation,
    useUpdateDocumentsMutation,
} from 'features/documents/documents';
import { arrayMoveImmutable } from 'array-move';
import HelperFunctions from '../../global/HelperFunctions';
import { useGetDocuments } from '../hooks/useGetDocuments';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { useTranslation } from 'react-i18next';
import { updateAbilityForDocuments } from 'ability/Ability';
import { useAbility } from 'ability/useAbility';
import { useUserProfile } from 'hooks/useUserProfile';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useGetDocumentGroups } from '../hooks/useGetDocumentGroups';

const emptyArray = [];

export default function Index() {
    const ability = useAbility();
    const userProfile = useUserProfile();
    const currentOrganisation = useCurrentOrganisation();
    const [isEditFolderModal, setEditFolderModal] = useState(false);
    const [pollingInterval, setPollingInterval] = useState(undefined);
    const { t } = useTranslation('documents');
    const allDocuments = useGetDocuments(undefined, '', pollingInterval);

    const hasDuplicatingDocuments = useMemo(() => {
        return allDocuments.some((document) => document.deletedAt === null && document.cloneTaskId !== null);
    }, [allDocuments]);

    const numberOfDocuments = allDocuments.length;

    useEffect(() => {
        updateAbilityForDocuments(ability, numberOfDocuments, userProfile, currentOrganisation);
    }, [numberOfDocuments, currentOrganisation, userProfile]);

    useEffect(() => {
        // Refresh every 5 seconds when there are duplicating documents
        setPollingInterval(hasDuplicatingDocuments ? 5000 : undefined);
    }, [hasDuplicatingDocuments]);

    return (
        <>
            <MainContentNav title={t('document.navbar.main.mainContentNav')} />

            <Content setEditFolderModal={setEditFolderModal} allDocuments={allDocuments} />
            <Filters />

            <EditFolderModal showModal={isEditFolderModal} handleClose={() => setEditFolderModal(false)} />

            <NewDocumentModal />
        </>
    );
}

function Content({ setEditFolderModal, allDocuments = [] }) {
    const activeOrganisation = useActiveOrganisation();
    const [draggableType, setDraggableType] = useState(null);
    const [isDragging, setDragging] = useState(false);

    const [updateDocumentGroups] = useUpdateDocumentGroupsMutation();
    const [updateDocuments] = useUpdateDocumentsMutation();

    const { allDocumentGroups } = useGetDocumentGroupsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            allDocumentGroups: data ? data.filter((group) => group.organisationId === activeOrganisation) : emptyArray,
        }),
    });

    const onDragEnd = (result) => {
        setDraggableType(null);
        setDragging(false);
        const { source, destination, type } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // Not moved
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }

        // Group or item?
        if (type === 'group') {
            handleGroup(result);
        }

        if (type === 'item') {
            handleItem(result);
        }
    };

    const handleGroup = (result) => {
        const { source, destination, draggableId } = result;
        const payload = {
            draggableId: _.toInteger(draggableId.substring(6)),
            source: {
                index: source.index,
                id: _.toInteger(source.droppableId.substring(6)),
            },
            destination: {
                index: destination.index,
                id: _.toInteger(destination.droppableId.substring(6)),
            },
        };

        if (payload.source.id === payload.destination.id) {
            // Sort within group
            moveGroup(source.index, destination.index, payload.source.id);
        } else {
            // Move to new group
            const sourceGroups = allDocumentGroups
                .filter((_group) => _group.parent === payload.source.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            const destinationGroups = allDocumentGroups
                .filter((_group) => _group.parent === payload.destination.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            // First remove from old group
            const [removed] = sourceGroups.splice(source.index, 1);

            // Insert into destination group
            destinationGroups.splice(destination.index, 0, removed);

            // Update source groups
            const sourceGroupChanges = sourceGroups.map((_group, sortOrder) => ({
                id: _group.id,
                changes: {
                    sortOrder,
                },
            }));

            // Update destination groups
            const destinationGroupChanges = destinationGroups.map((_group, sortOrder) => {
                if (_group.id === removed.id) {
                    return {
                        id: _group.id,
                        changes: {
                            sortOrder,
                            parent: payload.destination.id,
                        },
                    };
                }

                return {
                    id: _group.id,
                    changes: {
                        sortOrder,
                    },
                };
            });

            updateDocumentGroups([...sourceGroupChanges, ...destinationGroupChanges]);
        }
    };

    const handleItem = (result) => {
        const { source, destination, draggableId } = result;
        const sourceId = source.droppableId.substring(15);
        const destinationId = destination.droppableId.substring(15);

        const payload = {
            draggableId: _.toInteger(draggableId.substring(14)),
            source: {
                index: source.index,
                id: sourceId === 'root' ? null : _.toInteger(sourceId),
            },
            destination: {
                index: destination.index,
                id: destinationId === 'root' ? null : _.toInteger(destinationId),
            },
        };

        if (payload.source.id === payload.destination.id) {
            // Sort within group
            moveDocument(source.index, destination.index, payload.source.id);
        } else {
            // Move to new group
            const sourceDocuments = allDocuments
                .filter((_document) => _document.documentGroup === payload.source.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            const destinationDocuments = allDocuments
                .filter((_document) => _document.documentGroup === payload.destination.id)
                .sort(HelperFunctions.dynamicSort('sortOrder'));

            // First remove from old group
            const [removed] = sourceDocuments.splice(source.index, 1);

            // Insert into destination group
            destinationDocuments.splice(destination.index, 0, removed);

            // Update source groups
            const sourceGroupChanges = sourceDocuments.map((_document, sortOrder) => ({
                id: _document.id,
                changes: {
                    sortOrder,
                },
            }));

            // Update destination groups
            const destinationGroupChanges = destinationDocuments.map((_document, sortOrder) => {
                if (_document.id === removed.id) {
                    return {
                        id: _document.id,
                        changes: {
                            sortOrder,
                            documentGroup: payload.destination.id,
                        },
                    };
                }

                return {
                    id: _document.id,
                    changes: {
                        sortOrder,
                    },
                };
            });

            updateDocuments({
                organisationId: activeOrganisation,
                body: [...sourceGroupChanges, ...destinationGroupChanges],
            });
        }
    };

    const onDragStart = ({ type }) => {
        setDraggableType(type);
        setDragging(true);
    };

    const handleMove = (direction, group, index) => {
        if (direction === 'up' && index === 0) {
            return;
        }

        moveGroup(index, direction === 'up' ? index - 1 : index + 1, group.parent);
    };

    const moveGroup = (fromIndex, toIndex, parentId) => {
        const groups = allDocumentGroups
            .filter((_group) => _group.parent === parentId)
            .sort(HelperFunctions.dynamicSort('sortOrder'));

        const newGroups = arrayMoveImmutable(groups, fromIndex, toIndex).map((_group, sortOrder) => ({
            id: _group.id,
            changes: {
                sortOrder,
            },
        }));

        updateDocumentGroups(newGroups);
    };

    const moveDocument = (fromIndex, toIndex, documentGroupId) => {
        const documents = allDocuments
            .filter((_document) => _document.documentGroup === documentGroupId)
            .sort(HelperFunctions.dynamicSort('sortOrder'));

        const newDocuments = arrayMoveImmutable(documents, fromIndex, toIndex).map((_document, sortOrder) => ({
            id: _document.id,
            changes: {
                sortOrder,
            },
        }));

        updateDocuments({
            organisationId: activeOrganisation,
            body: newDocuments,
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div
                className={cx('content-static-body', {
                    'is-dragging-group': isDragging && draggableType === 'group',
                    'is-dragging-item': isDragging && draggableType === 'item',
                })}
            >
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto">
                        <Sidebar
                            setShowModal={setEditFolderModal}
                            draggableType={draggableType}
                            handleMove={handleMove}
                        />
                    </div>
                    <div className="flex-grow-1 overflow-auto" style={{ marginTop: 75 }}>
                        <div className="pb-3 px-4">
                            <Documents handleMove={handleMove} />
                        </div>
                    </div>
                </div>
            </div>
        </DragDropContext>
    );
}
