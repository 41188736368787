import { useGetGuardEntities } from './useGetGuardEntities';

export function useGetGuardEntityForProperty(publicationId, versionId, contentType, skip = false) {
    const { guardEntities, isLoading } = useGetGuardEntities(publicationId, versionId, skip);

    return {
        guardEntity: guardEntities.find((guardEntity) => guardEntity.contentType === contentType),
        isLoading,
    };
}
