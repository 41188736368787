import getEnv from 'config/Env';
import { useEffect } from 'react';
import { commentsApi } from 'features/comments/commentApi';
import { useDispatch } from 'react-redux';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useUserProfile } from 'hooks/useUserProfile';

export function EventListener() {
    const dispatch = useDispatch();
    const hub = getEnv('MERCURE_URL');
    const topicUrl = getEnv('COMMENT_API_BASE_URL') + '/api/comment-api/api';
    const { userId } = useUserProfile();

    useEffect(() => {
        const url = new URL(hub);
        const topics = [
            '/teams/{id}',
            '/team_users/{id}',
            '/task_versions/{id}',
            '/task_team_assignments/{id}',
            '/tasks/{id}',
        ];

        topics.forEach(function (topic) {
            url.searchParams.append('topic', topicUrl + topic);
        });

        const eventSource = new EventSource(url);

        eventSource.onmessage = function (event) {
            update(event);
        };

        return () => eventSource.close();
    }, []);

    return null;

    function update(event) {
        const data = HelperFunctions.tryParseJSON(event.data);

        if (data) {
            if (data.hasOwnProperty('updatedBy') && data.updatedBy === userId) {
                // Event originated from the same user, no need to invalidate the cache
                return;
            }

            if (data.hasOwnProperty('@type') && data.hasOwnProperty('@id')) {
                dispatch(
                    commentsApi.util.invalidateTags([
                        {
                            type: data['@type'],
                            id: data.action === 'create' ? 'LIST' : data['@id'],
                        },
                    ]),
                );
            }
        }
    }
}
