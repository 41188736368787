import { Action } from './PublicationRowDetails';
import { FiletypeHtml, FiletypePdf, FiletypeXml } from 'react-bootstrap-icons';
import { useCreatePreviewTokenMutation } from '../../../../features/publications/publicationApi';
import Constants, { Permissions } from '../../../../config/Constants';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../global/RestrictedContent';
import { setModal } from '../../../../features/publications/publicationsSlice';
import { useDispatch } from 'react-redux';

export function PreviewActions({ publication }) {
    const { t } = useTranslation('publications');
    const [createPreviewToken] = useCreatePreviewTokenMutation();
    const hasEditPermission = useHasPermission(Permissions.Publication['Write.All']);
    const dispatch = useDispatch();

    return (
        <div className="d-flex mb-2">
            <Action
                tooltip={t('publication.tooltip.createHtml')}
                disabled={publication.html === false}
                handleClick={() => createPreview('html')}
                variant="outline-small-button"
            >
                <FiletypeHtml className="mr-1" size={16} />
                <div>{t('publication.btn.createHtml')}</div>
            </Action>
            <Action
                tooltip={t('publication.tooltip.createPdf')}
                disabled={publication.pdf === false}
                handleClick={() => createPreview('pdf')}
                variant="outline-small-button"
            >
                <FiletypePdf className="mr-1" size={16} />
                <div>{t('publication.btn.createPdf')}</div>
            </Action>

            {publication.printPdf && (
                <Action
                    tooltip={t('publication.tooltip.createPdf')}
                    handleClick={() => createPreview('print')}
                    variant="outline-small-button"
                >
                    <FiletypePdf className="mr-1" size={16} />
                    <div>{t('publication.dropdown.printPdf')}</div>
                </Action>
            )}

            {publication?.xmlProperties?.enableXml && (
                <Action
                    tooltip={t('publication.tooltip.createPdf')}
                    handleClick={() => createPreview('xml')}
                    variant="outline-small-button"
                >
                    <FiletypeXml className="mr-1" size={16} />
                    <div>{t('publication.dropdown.xml')}</div>
                </Action>
            )}

            {hasEditPermission && (
                <Action handleClick={showExportChangesModal} variant="outline-small-button">
                    <FiletypePdf className="mr-1" size={16} />
                    <div>{t('publication.dropdown.changes')}&hellip;</div>
                </Action>
            )}
        </div>
    );

    function createPreview(type) {
        createPreviewToken({
            publication: publication['@id'],
            type,
            action: Constants.publicationToken.preview,
        });
    }

    function showExportChangesModal() {
        dispatch(
            setModal({
                key: 'exportChanges',
                value: {
                    publication,
                },
            }),
        );
    }
}
