import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useGetLabelEntitiesQuery } from 'features/metadata/metadata';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

const emptyArray = [];

export function useGetSectionLabelEntities(documentId, sectionId) {
    const organisationId = useActiveOrganisation();

    const filterSection = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, sectionId) => sectionId,
            (data, sectionId) => {
                if (data) {
                    return data.filter((labelEntity) => parseInt(labelEntity.entityId) === sectionId);
                }

                return emptyArray;
            },
        );
    }, []);

    const { entityLabelEntities } = useGetLabelEntitiesQuery(
        {
            entityType: 'section',
            organisationId,
            documentId,
        },
        {
            selectFromResult: (result) => ({
                entityLabelEntities: filterSection(result, sectionId),
            }),
        },
    );

    return entityLabelEntities;
}
