import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getEnv from '../../config/Env';
import { baseQueryWithReauth, prepareHeaders, providesList } from '../api';
import {
    Account,
    KioskCategory,
    KioskCategoryRequestBody,
    Organisation,
    User,
    BannerFile, UpdateOrganisationRequestBody,
} from '../../models/auth.models';
import baseAPI from '../../api/ApiConnector_v2';

const apiUrl = getEnv('AUTH_API_URL');

const authApiQuery = fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders,
});

export const authApi = createApi({
    reducerPath: 'auth',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, authApiQuery),
    tagTypes: ['Organisation', 'KioskCategory', 'User', 'Account', 'Subscription'],
    endpoints: (builder) => ({
        getSsoConfig: builder.query<Account, number>({
            query: (id) => `/accounts/${id}/ssoconfig`,
            providesTags: (result, error, arg) => [{ type: 'Account', id: arg }],
        }),
        deleteSsoConfig: builder.mutation<any, any>({
            query: ({ accountId, id }) => ({
                url: `/accounts/${accountId}/ssoconfigs/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { accountId }) => [
                { type: 'Account', accountId },
                { type: 'Organisation' },
            ],
        }),
        updateSsoConfig: builder.mutation<any, any>({
            query: ({ accountId, id, body }) => ({
                url: `/accounts/${accountId}/ssoconfigs/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { accountId }) => [
                { type: 'Account', accountId },
                { type: 'Organisation' },
            ],
        }),
        createSsoConfig: builder.mutation<any, any>({
            query: ({ accountId, body }) => ({
                url: `/accounts/${accountId}/ssoconfigs`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { accountId }) => [
                { type: 'Account', accountId },
                { type: 'Organisation' },
            ],
        }),
        getOrganisation: builder.query<Organisation, number>({
            query: (id) => `/organisations/${id}`,
            providesTags: (result) => (result ? [{ type: 'Organisation', id: result.id }] : []),
        }),
        getOrganisationUsers: builder.query<User[], number>({
            query: (id) => `/organisations/${id}/users`,
            providesTags: (result, error, arg) => (result ? [{ type: 'Organisation', id: arg }] : []),
        }),
        getUser: builder.query<User, number>({
            query: (id) => `/users/${id}`,
            providesTags: (result) => (result ? [{ type: 'User', id: result.id }] : []),
        }),
        updateUser: builder.mutation<User, any>({
            query: ({ id, organisationId, body }) => ({
                url: '/organisations/' + organisationId + '/users/' + id,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, { id, organisationId }) => [
                { type: 'User', id },
                { type: 'Organisation', organisationId },
                { type: 'Subscription' },
            ],
        }),
        createUser: builder.mutation<User, any>({
            query: ({ organisationId, body }) => ({
                url: '/organisations/' + organisationId + '/users',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { organisationId }) => [
                {
                    type: 'Organisation',
                    organisationId,
                },
                { type: 'Subscription' },
            ],
        }),
        updateOrganisation: builder.mutation<Organisation, UpdateOrganisationRequestBody>({
            query: ({ id, body }) => ({
                url: `/organisations/${id}/update`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Organisation', id }],
        }),
        postOrganisationKioskCategory: builder.mutation<Organisation, KioskCategoryRequestBody>({
            query: (body) => ({
                url: `/organisations/${body.organisationId}/kioskcategories`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, body) => [{ type: 'Organisation', id: body.organisationId }],
        }),
        getKioskCategories: builder.query<KioskCategory[], number>({
            query: (id) => `/organisations/${id}/kioskcategories`,
            providesTags: (result) =>
                result
                    ? result.map((category) => ({
                          type: 'KioskCategory',
                          id: category.id,
                      }))
                    : [],
        }),
        updateKioskCategory: builder.mutation<
            void,
            {
                kioskCategoryId: number;
                activeOrganisation: number;
                body: Partial<KioskCategoryRequestBody>;
            }
        >({
            query: ({ kioskCategoryId, activeOrganisation, body }) => ({
                url: `/kioskcategories/${kioskCategoryId}/update`,
                method: 'PATCH',
                body: body,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Organisation', id: arg.activeOrganisation }],
        }),
        deleteKioskCategory: builder.mutation<
            void,
            {
                kioskCategoryId: number;
                activeOrganisation: number;
            }
        >({
            query: ({ kioskCategoryId, activeOrganisation }) => ({
                url: `/kioskcategories/${kioskCategoryId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Organisation', id: arg.activeOrganisation }],
        }),
        getDomainKioskCategories: builder.query<KioskCategory[], number>({
            query: (id) => `/domains/${id}/kiosks`,
            providesTags: (result) =>
                result
                    ? result.map((category) => ({
                          type: 'KioskCategory',
                          id: category.id,
                      }))
                    : [],
        }),
        getUserSsoStatus: builder.mutation<string[], string>({
            query: (email) => ({
                url: '/user-sso-status',
                method: 'POST',
                body: {
                    username: email,
                },
            }),
        }),
        getSsoToken: builder.query<string, string>({
            query: (code) => ({
                url: '/token/sso/' + code,
            }),
        }),
        userLogin: builder.mutation<string[], string>({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
            }),
        }),
        userPasswordRequest: builder.mutation<string[], string>({
            query: (body) => ({
                url: '/reset/request',
                method: 'POST',
                body,
            }),
        }),
        userPasswordReset: builder.mutation<string[], string>({
            query: (body) => ({
                url: '/reset/reset',
                method: 'POST',
                body,
            }),
        }),
        twoFactorLogin: builder.mutation<string[], string>({
            query: (body) => ({
                url: '/2fa-login',
                method: 'POST',
                body,
            }),
        }),
        verifyRecoveryTwoFactor: builder.mutation<any, string>({
            query: (code) => ({
                url: '/2fa-login/recovery',
                method: 'POST',
                body: { code },
            }),
        }),
        recoverTwoFactor: builder.mutation<any, string>({
            query: () => ({
                url: '/2fa-login/request-recovery',
                method: 'POST',
            }),
        }),
        getAdminAccounts: builder.query<Account[], undefined>({
            query: () => '/admin/accounts',
            providesTags: (result) => providesList(result, 'Account'),
        }),
        getAdminAccount: builder.query<Account, number>({
            query: (id) => `/admin/accounts/${id}`,
            providesTags: (result) => (result ? [{ type: 'Account', id: result.id }] : []),
        }),
        updateAdminAccount: builder.mutation<Account, any>({
            query: ({ id, body }) => ({
                url: `/admin/accounts/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'Account', id: 'LIST' },
                { type: 'Account', id },
            ],
        }),
        createAdminAccount: builder.mutation<Account, any>({
            query: (body) => ({
                url: '/admin/accounts',
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'Account', id: 'LIST' }],
        }),
        createAdminUser: builder.mutation<User, any>({
            query: ({ accountId, body }) => ({
                url: '/admin/accounts/users',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { accountId }) => [{ type: 'Account', id: accountId }],
        }),
        updateAdminUser: builder.mutation<User, any>({
            query: ({ accountId, id, body }) => ({
                url: `/admin/accounts/users/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id, accountId }) => [
                { type: 'Account', id: accountId },
                { type: 'User', id },
            ],
        }),
        resetUserPassword: builder.mutation<User, any>({
            query: ({ accountId, id }) => ({
                url: `/accounts/${accountId}/users/${id}/reset/password`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, { id, accountId }) => [
                { type: 'Account', id: accountId },
                { type: 'User', id },
            ],
        }),
        resetAdminUserPassword: builder.mutation<User, any>({
            query: ({ accountId, id }) => ({
                url: `/admin/accounts/users/${id}/reset-password`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, { id, accountId }) => [
                { type: 'Account', id: accountId },
                { type: 'User', id },
            ],
        }),
        sendUserAdminUsername: builder.mutation<User, any>({
            query: ({ accountId, id }) => ({
                url: `/admin/accounts/users/${id}/send-username`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, { id, accountId }) => [
                { type: 'Account', id: accountId },
                { type: 'User', id },
            ],
        }),
        updateAdminOrganisation: builder.mutation<Organisation, any>({
            query: ({ accountId, id, body }) => ({
                url: `/admin/accounts/organisations/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id, accountId }) => [
                { type: 'Account', id: accountId },
                { type: 'Organisation', id },
            ],
        }),
        createAdminOrganisation: builder.mutation<Organisation, any>({
            query: ({ accountId, body }) => ({
                url: '/admin/accounts/organisations',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { accountId }) => [{ type: 'Account', id: accountId }],
        }),
        createIpidImport: builder.mutation<any, any>({
            query: (body) => ({
                url: '/admin/ipid-import',
                method: 'POST',
                body,
            }),
            invalidatesTags: () => [{ type: 'Account', id: 'LIST' }],
        }),
        createAccountIpidImport: builder.mutation<Organisation, any>({
            query: ({ id, body }) => ({
                url: `/admin/accounts/${id}/ipid-import`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Account', id }],
        }),
        importAccountUsers: builder.mutation<Organisation, any>({
            query: ({ id, body }) => ({
                url: `/admin/accounts/${id}/import-users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Account', id }],
        }),
        getAdminUser: builder.query<User, string>({
            query: (id) => `/admin/accounts/users/${id}`,
            providesTags: (result) => (result ? [{ type: 'User', id: result.id }] : []),
        }),
        getAdminOrganisation: builder.query<Organisation, string>({
            query: (id) => `/admin/accounts/organisations/${id}`,
            providesTags: (result) => (result ? [{ type: 'Organisation', id: result.id }] : []),
        }),
        updateUserPreferences: builder.mutation<
            Organisation,
            { organisationId: string; userId: string; languagePreference: string }
        >({
            query: ({ organisationId, userId, languagePreference }) => ({
                url: `/users/${organisationId}/preferences/${userId}`,
                method: 'PUT',
                params: { languagePreference },
            }),
        }),
        updateUserSeenBanners: builder.mutation<
            Organisation,
            { userId: string; organisationId: string; body: number[] }
        >({
            query: ({ userId, organisationId, body }) => ({
                url: `/users/${organisationId}/seenbanners/${userId}`,
                method: 'PUT',
                body,
            }),
        }),
        getOrganisationsBanners: builder.query<BannerFile[], { organisationId: number }>({
            query: ({ organisationId }) => ({
                url: `/organisations/${organisationId}/banners`,
                method: 'GET',
            }),
        }),
        getStripeSession: builder.mutation<any, any>({
            query: ({ sid }) => ({
                url: `/onboarding/stripe/session/${sid}`,
                method: 'POST',
            }),
        }),
        getStripeSubscription: builder.query<any, any>({
            query: ({ sid, userId }) => ({
                url: `/onboarding/stripe/subscription/${sid}/${userId}`,
            }),
        }),
        getStripeProduct: builder.query<any, any>({
            query: ({ pid, priceId }) => ({
                url: `/onboarding/stripe/product/${pid}/${priceId}`,
                method: 'GET',
            }),
        }),
        validateOnboardingEmail: builder.mutation<any, any>({
            query: (body) => ({
                url: `/onboarding/validate/email`,
                method: 'POST',
                body,
            }),
        }),
        validateOnboardingOrganisation: builder.mutation<any, any>({
            query: (body) => ({
                url: `/onboarding/validate/organisation`,
                method: 'POST',
                body,
            }),
        }),
        createNewAccount: builder.mutation<any, any>({
            query: (body) => ({
                url: `/onboarding/create-account`,
                method: 'POST',
                body,
            }),
        }),
        createNewSubscription: builder.mutation<any, any>({
            query: (body) => ({
                url: `/onboarding/create-subscription`,
                method: 'POST',
                body,
            }),
        }),
        getAccountSubscription: builder.query<any, number>({
            query: (id) => `/accounts/${id}/subscription`,
            providesTags: (result, error, arg) => [{ type: 'Subscription', id: arg }],
        }),
        addExtraProduct: builder.mutation<any, any>({
            query: ({ accountId, type, quantity }) => ({
                url: `/accounts/${accountId}/subscriptions/products`,
                method: 'POST',
                body: {
                    type,
                    quantity,
                },
            }),
            invalidatesTags: (result, error, { accountId }) => [{ type: 'Subscription', accountId }],
        }),
        createCustomerPortalSession: builder.mutation<any, any>({
            query: ({ accountId, returnUrl }) => ({
                url: `/accounts/${accountId}/subscriptions/sessions`,
                method: 'POST',
                body: {
                    returnUrl,
                },
            }),
        }),
        loginUsingRefreshToken: builder.mutation<any, any>({
            query: ({ refreshToken, organisationId }) => ({
                url: 'token/refresh',
                method: 'POST',
                body: {
                    refresh_token: refreshToken,
                    organisationId,
                },
            }),
        }),
        logout: builder.mutation<any, any>({
            query: () => ({
                url: 'logouts',
                method: 'POST',
            }),
        }),
    }),
});

export function getTwoFactorQrCode() {
    return baseAPI.post(`${apiUrl}/2fa-login/get-qr`);
}

export const {
    useGetSsoConfigQuery,
    useDeleteSsoConfigMutation,
    useUpdateSsoConfigMutation,
    useCreateSsoConfigMutation,
    useGetOrganisationQuery,
    useGetOrganisationUsersQuery,
    useGetUserQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useGetKioskCategoriesQuery,
    useGetDomainKioskCategoriesQuery,
    useUpdateOrganisationMutation,
    usePostOrganisationKioskCategoryMutation,
    useUpdateKioskCategoryMutation,
    useDeleteKioskCategoryMutation,
    useGetUserSsoStatusMutation,
    useUserLoginMutation,
    useUserPasswordRequestMutation,
    useUserPasswordResetMutation,
    useGetSsoTokenQuery,
    useTwoFactorLoginMutation,
    useVerifyRecoveryTwoFactorMutation,
    useRecoverTwoFactorMutation,
    useGetAdminAccountsQuery,
    useGetAdminAccountQuery,
    useUpdateAdminAccountMutation,
    useResetUserPasswordMutation,
    useResetAdminUserPasswordMutation,
    useSendUserAdminUsernameMutation,
    useCreateAdminAccountMutation,
    useCreateAdminUserMutation,
    useUpdateAdminUserMutation,
    useGetAdminUserQuery,
    useGetAdminOrganisationQuery,
    useUpdateAdminOrganisationMutation,
    useCreateAdminOrganisationMutation,
    useCreateIpidImportMutation,
    useCreateAccountIpidImportMutation,
    useGetOrganisationsBannersQuery,
    useUpdateUserPreferencesMutation,
    useUpdateUserSeenBannersMutation,
    useGetStripeSessionMutation,
    useGetStripeProductQuery,
    useGetStripeSubscriptionQuery,
    useValidateOnboardingEmailMutation,
    useValidateOnboardingOrganisationMutation,
    useCreateNewAccountMutation,
    useCreateNewSubscriptionMutation,
    useGetAccountSubscriptionQuery,
    useAddExtraProductMutation,
    useCreateCustomerPortalSessionMutation,
    useLoginUsingRefreshTokenMutation,
    useLogoutMutation,
    useImportAccountUsersMutation,
} = authApi;
