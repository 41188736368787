import PublicationApi from '../../../api/PublicationApi';

export const compareVersions = (selectedRows) => {
    const rowsSorted = [...selectedRows].sort((a, b) => a - b);

    return PublicationApi.compareVersions({
        base: rowsSorted[0],
        current: rowsSorted[1],
    });
};

export const comparePublications = (selectedRows) => {
    return PublicationApi.comparePublications({
        base: selectedRows[0],
        current: selectedRows[1],
    });
};
