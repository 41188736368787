import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { CaretLeftFill, CaretRightFill, X } from 'react-bootstrap-icons';
import { useGetDocument } from '../../hooks/useGetDocument';
import { AreaContext } from '../Area';
import AreaHelper from '../../../global/AreaHelper';
import DocumentHelper from '../../../global/DocumentHelper';
import Tooltip from '../../../global/Tooltip';
import { AreaDisplayMode } from './AreaDisplayMode';
import { useFormikContext } from 'formik';
import HelperFunctions from '../../../global/HelperFunctions';
import { generatePath, useHistory } from 'react-router-dom';
import { EDIT_AREA_PATH, VIEW_PATH } from 'scenes/DocumentsV2';
import { useUnlockAreaMutation } from 'features/documents/documents';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useIsLoading } from 'pages/documents_v2/views/edit_area/useIsLoading';
import cx from 'classnames';

export function AreaLayoutPageLeft({ area, showRightSide = true, dirty = false, children }) {
    return (
        <div
            style={{
                transition: 'width 300ms ease-in-out',
                width: showRightSide ? '50%' : '95%',
            }}
        >
            <div
                className="bg-white"
                style={{
                    boxShadow: '0 1px 3px 1px rgb(60 64 67 / 15%)',
                }}
            >
                <AreaDisplayMode area={area} dirty={dirty} />
                <div className="area-edit-page">{children}</div>
            </div>
        </div>
    );
}

export function AreaLayoutNav({ area, children }) {
    const document = useGetDocument(undefined, true);
    const { activeVariantId } = useContext(AreaContext);
    const [unlockArea] = useUnlockAreaMutation();
    const { t } = useTranslation('documents');
    const { t: tGlobal } = useTranslation('global');
    const isLoading = useIsLoading();

    const { dirty } = useFormikContext();

    const history = useHistory();

    const prefix = DocumentHelper.getPrefix(area, activeVariantId);
    const nextArea = document ? AreaHelper.getNextArea(document, area) : undefined;
    const previousArea = document ? AreaHelper.getPreviousArea(document, area) : undefined;

    const unlock = () => {
        return new Promise((resolve) => {
            unlockArea(area.id).then(() => {
                resolve();
            });
        });
    };

    const redirect = (url) => {
        unlock().then(() => {
            history.push(url);
        });
    };

    const close = () => {
        const url = queryString.stringifyUrl({
            url: generatePath(VIEW_PATH, {
                documentId: document.id,
            }),
            query: { sid: area.sectionId },
        });

        redirect(url);
    };

    const handleClose = () => {
        if (dirty) {
            HelperFunctions.confirmModal(
                t('document.navbar.main.editor.navbar.loseUnsavedChanges'),
                'danger',
                false,
                t('document.navbar.main.editor.navbar.btn.confirmClose'),
                tGlobal('btn.cancel'),
            )
                .then(() => {
                    close();
                })
                .catch(() => {});
        } else {
            close();
        }
    };

    const redirectToArea = (id) => {
        redirect(
            generatePath(EDIT_AREA_PATH, {
                documentId: document.id,
                view: 'editArea',
                areaId: id,
            }),
        );
    };

    return (
        <nav
            className="edit-area-navbar bg-white border-bottom py-3 px-4 navbar navbar-expand navbar-light"
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                height: 73,
            }}
        >
            <button
                type="button"
                className="uk-button uk-button-light mr-2 px-2 flex-shrink-0"
                onClick={() => redirectToArea(previousArea.id)}
                data-uk-tooltip={t('document.navbar.main.editor.navbar.previewsArticle')}
                disabled={!previousArea || isLoading}
                style={{ lineHeight: '30px' }}
            >
                <CaretLeftFill size={12} />
            </button>

            <button
                type="button"
                className="uk-button uk-button-light mr-3 px-2 flex-shrink-0"
                onClick={() => redirectToArea(nextArea.id)}
                data-uk-tooltip={t('document.navbar.main.editor.navbar.nextArticle')}
                disabled={!nextArea || isLoading}
                style={{ lineHeight: '30px' }}
            >
                <CaretRightFill size={12} />
            </button>

            {prefix && <div className="mr-2">{prefix}</div>}

            <div
                className={cx('font-weight-bolder pr-3', {
                    'text-muted': isLoading,
                })}
                style={{
                    fontSize: '1.2rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {area.title}
            </div>

            <div className="d-flex align-items-center ml-auto flex-shrink-0">
                {children}

                <Tooltip tooltip={t('btn.close')} placement="bottom">
                    <Button onClick={handleClose} variant="link" className="text-secondary flex-shrink-0 p-0">
                        <X size={26} />
                    </Button>
                </Tooltip>
            </div>
        </nav>
    );
}
