import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Sidebar from '../pages/global/Sidebar';
import Routes from '../pages/Routes';
import { MainSidebarLogo, MainSidebarNav } from 'pages/Nav';
import { RestrictedDocument } from 'scenes/Documents';
import { EDIT_REIMBURSEMENT_PRODUCT_PATH } from 'scenes/Reimbursements';
import { Area } from 'pages/documents_v2/views/Area';
import { EDIT_AREA_PATH } from 'scenes/DocumentsV2';
import useAdjustLanguage from 'hooks/useAdjustLanguage';
import { AbilityContext } from 'ability/Can';
import abilityFor from 'ability/Ability';
import { useMemo } from 'react';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUserProfile } from 'hooks/useUserProfile';
import { BannerPopup } from 'pages/dashboard/BannerPopup';
import { ProductFruits } from 'components/ProductFruits';

export default function AppRouter() {
    const currentOrganisation = useCurrentOrganisation();
    const userProfile = useUserProfile();

    const ability = useMemo(() => {
        if (!userProfile || !currentOrganisation) {
            return undefined;
        }

        return abilityFor(userProfile, currentOrganisation);
    }, [userProfile, currentOrganisation]);

    if (!ability) {
        return null;
    }

    return (
        <AbilityContext.Provider value={ability}>
            <ProductFruits />
            <BannerPopup />
            <Sidebar />
            <Main />
        </AbilityContext.Provider>
    );
}

function Main() {
    useAdjustLanguage();
    return (
        <Switch>
            <Route
                path={EDIT_AREA_PATH}
                render={({ match }) => (
                    <RestrictedDocument documentId={parseInt(match.params.documentId)}>
                        <Area view={match.params.view} />
                    </RestrictedDocument>
                )}
            />
            <Route path="/">
                <MainAppNext />
            </Route>
        </Switch>
    );
}

function MainAppNext() {
    const editReimbursementProductPath = useRouteMatch(EDIT_REIMBURSEMENT_PRODUCT_PATH);
    const hideSidebar = !!editReimbursementProductPath;

    return (
        <div className="vh-100">
            <div className="row h-100 no-gutters">
                {!hideSidebar && <MainSidebar />}
                <MainContent />
            </div>
        </div>
    );
}

function MainSidebar() {
    return (
        <div className="main-sidebar">
            <div className="position-fixed">
                <div className="pt-1 pl-4 mb-4">
                    <MainSidebarLogo />
                </div>
                <MainSidebarNav />
            </div>
        </div>
    );
}

function MainContent() {
    return (
        <main className="d-flex align-items-stretch col" id="page-content-wrapper">
            <Routes />
        </main>
    );
}
