import { useFormikContext } from 'formik';
import { FieldSelect, FormField, Switch } from '../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';

export function Guard() {
    const { t } = useTranslation('publications');
    const { values, initialValues } = useFormikContext();

    // Guard enabled?
    const canDisableGuard = values.id && initialValues.properties?.guard?.enabled !== true;
    const guardEnabled = values.properties?.guard?.enabled === true;

    const identityTypes = [
        {
            label: t('publications:publication.dropdown.organisationIdentityType.afm'),
            value: 'afm',
        },
        {
            label: t('publications:publication.dropdown.organisationIdentityType.dnb'),
            value: 'dnb',
        },
        {
            label: t('publications:publication.dropdown.organisationIdentityType.kvk'),
            value: 'kvk',
        },
        {
            label: t('publications:publication.dropdown.organisationIdentityType.lei'),
            value: 'lei',
        },
    ];

    return (
        <div className="p-4">
            <Switch
                name="properties.guard.enabled"
                label={t('publication.settings.content.features.guardEnabled')}
                props={{
                    disabled: !canDisableGuard,
                }}
            />

            <FieldSelect
                name="properties.guard.organisationIdentityType"
                label={t('publications:guard.labels.organisationIdentityType')}
                options={identityTypes}
                props={{
                    required: guardEnabled,
                    isDisabled: !guardEnabled,
                }}
            />

            <FormField
                name="properties.guard.organisationIdentityId"
                label={t('publications:guard.labels.organisationIdentityId')}
                props={{
                    required: guardEnabled,
                    disabled: !guardEnabled,
                }}
            />
        </div>
    );
}
