import { Check2, Clipboard } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export function CopyToClipboard({ text, tooltip = 'global:btn.copyToClipboard' }) {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (copied === true) {
            // Hide complete message after 2 seconds
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [copied]);

    return (
        <ReactCopyToClipboard text={text} onCopy={() => setCopied(true)}>
            {copied ? (
                <Check2 className="text-success flex-shrink-0" data-uk-tooltip={t('global:btn.copied')} size={14} />
            ) : (
                <Clipboard
                    onClick={(e) => e.stopPropagation()}
                    className="cursor-pointer flex-shrink-0"
                    data-uk-tooltip={t(tooltip)}
                    size={14}
                />
            )}
        </ReactCopyToClipboard>
    );
}
