import { useGetTranslationContainerEntityReferencesQuery } from '../../../features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { entityTypes } from '../config/Constants';
import _ from 'lodash';

export function useGetTagEntityReference(
    containerUri,
    entityId,
    skip = false,
) {
    const findEntityReference = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, entityId) => entityId,
            (data, entityId) => {
                if (data) {
                    return data.find((item) => _.toString(item.entityId) === entityId);
                }

                return undefined;
            },
        );
    }, []);

    const { entityReference } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: containerUri,
            entityType: entityTypes.TAG,
        },
        {
            selectFromResult: (result) => ({
                entityReference: findEntityReference(result, entityId),
            }),
            skip,
        },
    );

    return entityReference;
}
