import getEnv from 'config/Env';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HelperFunctions from 'pages/global/HelperFunctions';
import { guardApi } from '../../../../../features/publications/guardApi';

export function GuardEventListener() {
    const dispatch = useDispatch();
    const hub = getEnv('GUARD_MERCURE_URL');
    const topicUrl = getEnv('GUARD_BASE_URL') + '/api';

    useEffect(() => {
        const url = new URL(hub);
        const topics = ['/guard_entities/{id}', '/guard_aliases/{id}'];

        topics.forEach(function (topic) {
            url.searchParams.append('topic', topicUrl + topic);
        });

        const eventSource = new EventSource(url);

        eventSource.onmessage = function (event) {
            update(event);
        };

        return () => eventSource.close();
    }, []);

    return null;

    function update(event) {
        const data = HelperFunctions.tryParseJSON(event.data);
        console.log(data);

        if (data) {
            if (data.hasOwnProperty('@type') && data.hasOwnProperty('@id')) {
                const isCreate = data?.mercureAction === 'create';

                dispatch(
                    guardApi.util.invalidateTags([
                        {
                            type: data['@type'],
                            id: isCreate ? 'LIST' : data['@id'],
                        },
                    ]),
                );
            }
        }
    }
}
