import { useGetAreasQuery } from 'features/documents/documents';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetAreas(sectionId, documentId) {
    const sortAreas = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (data) => {
                if (data) {
                    return [...data].sort(HelperFunctions.dynamicSort('sortOrder'));
                }

                return emptyArray;
            },
        );
    }, []);

    return useGetAreasQuery(
        { sectionId, documentId },
        {
            selectFromResult: (result) => ({
                ...result,
                areas: sortAreas(result)
            }),
        },
    );
}
