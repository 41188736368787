import { useGetTranslationContainerEntityReferencesQuery } from '../../../features/translations/translationApi';
import { entityTypes } from '../config/Constants';

const emptyArray = [];

export function useGetTagItemEntityReferences(containerUri, entityReference) {
    const { tagItemEntityReferences } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: containerUri,
            entityType: entityTypes.TAG_ITEM,
            parent: entityReference ? entityReference['@id'] : undefined,
        },
        {
            selectFromResult: ({ data }) => ({
                tagItemEntityReferences: data ?? emptyArray,
            }),
            skip: entityReference === undefined,
        },
    );

    return tagItemEntityReferences;
}
