import { FormFieldWithTitle, Switch, TextareaWithTitle } from '../helpers/FieldHelper';
import { PublicationProperty, templateProperties } from './PublicationProperty';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDocumentTemplate } from '../hooks/useDocumentTemplate';
import Constants from '../../../config/Constants';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import { useEffect } from 'react';

export default function Properties() {
    const { values, setFieldValue } = useFormikContext();
    const { multiVersions = false, title, contentDecoded = [] } = values;
    const { t } = useTranslation('publications');

    const documentTemplate = useDocumentTemplate();
    const isIpidVerbondTemplate = documentTemplate?.templateDir === 'ipid_verbond';
    const isIpidTemplate =
        documentTemplate?.templateDir && ['ipid', 'ipid_eu', 'ipid_verbond'].includes(documentTemplate.templateDir);

    // Try to get the Document of the first content section
    const documentId = contentDecoded.length > 0 ? contentDecoded[0].documentId : undefined;
    const document = useGetDocument(documentId);

    const defaultSection =
        contentDecoded.length > 0 && document
            ? document.sections.find((section) => section.id === contentDecoded[0].sectionId)
            : undefined;
    const restrictions = useEntityTemplateParameters(defaultSection, entityTypes.SECTION, document);
    const { canChangeTitle = true } = restrictions ?? {};

    useEffect(() => {
        if (defaultSection && isIpidTemplate && canChangeTitle === false && title !== defaultSection.title) {
            setFieldValue('title', defaultSection.title);
        }
    }, [canChangeTitle, title, isIpidTemplate, defaultSection]);

    return (
        <>
            <div className="p-4">
                <FormFieldWithTitle
                    label={`${t('publication.settings.content.features.name')}`}
                    name="name"
                    props={{ required: true }}
                />
                <TextareaWithTitle
                    label={`${t('publication.settings.content.features.titlePublication')}`}
                    name="title"
                    as="textarea"
                    props={{
                        rows: 1,
                        required: true,
                        disabled: isIpidTemplate && canChangeTitle === false,
                    }}
                />
                <FormFieldWithTitle
                    label={t('publication.settings.content.features.reference')}
                    name="prefix"
                    props={{ required: multiVersions }}
                />

                <PublicationProperty property={templateProperties.properties.titleExtra}>
                    <TextareaWithTitle
                        label={t('publication.settings.content.features.titleExtra')}
                        name="titleExtra"
                        as="textarea"
                        props={{ rows: 1 }}
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.variantName}>
                    <FormFieldWithTitle
                        label={t(
                            isIpidTemplate
                                ? 'publication.settings.content.features_ipid.variantName'
                                : 'publication.settings.content.features.nameCollect',
                        )}
                        name="variantName"
                        props={{
                            required: isIpidTemplate,
                            pattern: isIpidVerbondTemplate
                                ? Constants.publication.patterns.ipidVerbondVariant
                                : undefined,
                        }}
                        help={
                            isIpidVerbondTemplate ? t('publication.settings.content.features_ipid.variantNameHelp') : ''
                        }
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.description}>
                    <TextareaWithTitle
                        label={t(
                            isIpidTemplate
                                ? 'publication.settings.content.features_ipid.description'
                                : 'publication.settings.content.features.titleCaption',
                        )}
                        name="description"
                        as="textarea"
                        props={{
                            required: isIpidTemplate,
                            rows: 1,
                        }}
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.chaptersDocumentTitle}>
                    <TextareaWithTitle
                        label={t('publication.settings.content.features.titleCustomSummary')}
                        name="chaptersDocumentTitle"
                        as="textarea"
                        props={{ rows: 1 }}
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.footerDocumentTitle}>
                    <TextareaWithTitle
                        label={t('publication.settings.content.features.customFooter')}
                        name="footerDocumentTitle"
                        as="textarea"
                        props={{ rows: 1 }}
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.versionNumber}>
                    <FormFieldWithTitle
                        label={t('publication.settings.content.features.versionDate')}
                        name="versionNumber"
                    />
                </PublicationProperty>

                <PublicationProperty property={templateProperties.properties.versionDate}>
                    <FormFieldWithTitle
                        label={t('publication.settings.content.features.versionNumber')}
                        name="versionDate"
                    />
                </PublicationProperty>

                <div className="d-none">
                    <Switch
                        name="informTeamMembers"
                        label="Informeer team"
                        help={t('publication.settings.content.features.informTeam')}
                    />
                </div>
            </div>
        </>
    );
}
