import { useTranslation } from 'react-i18next';

export function useGetTinyConfig() {
    const { t, i18n } = useTranslation();
    const languageIso = getTinyLanguage(i18n.language);

    return {
        advlist_bullet_styles: 'disc',
        advlist_number_styles: 'default,lower-alpha,upper-alpha,lower-roman,upper-roman',
        block_formats: `${t('global:editor.block_formats.paragraph')}=p;${t('global:editor.block_formats.heading')}=h3;${t('global:editor.block_formats.subHeading')}=h4;`,
        style_formats: [
            {
                title: t('global:editor.style_formats.bold'),
                inline: 'span',
                classes: 'font-weight-bold',
                selector: 'p,li',
            },
            { title: t('global:editor.style_formats.explanation'), block: 'p', classes: 'toelichting', selector: 'p' },
            { title: t('global:editor.style_formats.answer'), block: 'p', classes: 'answer', selector: 'p' },
            { title: t('global:editor.style_formats.intro'), block: 'p', classes: 'intro', selector: 'p' },
            { title: t('global:editor.style_formats.marginTop'), block: 'p', classes: 'margin-top', selector: 'p' },
            {
                title: t('global:editor.style_formats.marginBottom'),
                block: 'p',
                classes: 'margin-bottom',
                selector: 'p',
            },
            {
                title: t('global:editor.style_formats.headingMarginTop'),
                block: 'h3',
                classes: 'margin-top',
                selector: 'h3',
            },
            {
                title: t('global:editor.style_formats.headingMarginBottom'),
                block: 'h3',
                classes: 'margin-bottom',
                selector: 'h3',
            },
            {
                title: t('global:editor.style_formats.subHeadingMarginTop'),
                block: 'h4',
                classes: 'margin-top',
                selector: 'h4',
            },
            {
                title: t('global:editor.style_formats.subHeadingMarginBottom'),
                block: 'h4',
                classes: 'margin-bottom',
                selector: 'h4',
            },
        ],
        style_formats_autohide: true,
        branding: false,
        fixed_toolbar_container: '#editor-toolbar',
        inline: true,
        language: languageIso,
        language_url: `/tinymce/${languageIso}.js`,
        menubar: false,
        min_height: 610,
        paste_as_text: true,
        plugins: 'link lists advlist anchor',
        resize: false,
        statusbar: false,
        toolbar:
            'undo redo | blocks styles bold italic underline superscript subscript | anchor link hr tags ref | numlist bullist startNumber | removeformat pastetext',
    };

    function getTinyLanguage(language = 'nl') {
        if (language === 'fr') {
            return 'fr_FR';
        }

        if (language === 'en') {
            return 'en_GB';
        }

        return language;
    }
}
