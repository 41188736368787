import { Pause } from 'react-bootstrap-icons';
import cx from 'classnames';

export function GuardEntityStatusIcon({ guardEntity }) {
    const isValid = guardEntity.lastResult === 'valid';

    if (!guardEntity.watchEnabled) {
        return (
            <div className="d-flex mr-3">
                <div className="icon-pulse-wrapper icon-muted">
                    <div className="icon-pulse" style={{ backfaceVisibility: 'hidden' }}></div>
                    <Pause size={18} />
                </div>
            </div>
        );
    }

    return (
        <div className="d-flex mr-3">
            <div
                className={cx('icon-pulse-wrapper', {
                    'icon-success': isValid,
                    'icon-invalid': !isValid,
                })}
            >
                <div className="icon-pulse has-animation" style={{ backfaceVisibility: 'hidden' }}></div>
                <div className="icon-pulse-caret" />
            </div>
        </div>
    );
}
