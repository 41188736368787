import {
    translationApi,
    useGetEntityReferenceQuery,
    useUpdateTranslationKeyMutation,
} from 'features/translations/translationApi';
import cx from 'classnames';
import EditTranslation from './EditTranslation';
import { Badge, Button } from 'react-bootstrap';
import { useContext, useEffect, useMemo } from 'react';
import { EditContext } from '../view/EditContextWrapper';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getTypeAsString } from '../../helpers/EntityHelper';
import { PencilFill, PencilSquare, PlusCircle, XCircle } from 'react-bootstrap-icons';
import Tooltip from '../../../global/Tooltip';
import TranslationCompleteIcon from '../edit/TranslationCompleteIcon';
import { isEnabledForTranslation } from '../../helpers/TranslationKeyHelper';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent, { useGetCurrentTranslationUser } from '../../RestrictedTranslationContent';
import { TranslationContext } from './Translate';
import { getLanguageName } from '../../helpers/LanguageHelper';
import RenderTranslationContent from './RenderTranslationContent';
import { useTranslation } from 'react-i18next';
import { Code } from 'react-content-loader';
import { md5 } from 'js-md5';
import { useGetTranslation } from 'pages/translation/hooks/useGetTranslation';
import { useGetEntityReferenceForTranslation } from '../../hooks/useGetEntityReferenceForTranslation';
import { useGetTranslationKeyForTranslation } from '../../hooks/useGetTranslationKeyForTranslation';

export default function EntityTranslation({
    isHtml = false,
    content,
    languageIso,
    entityUri,
    translationContainer,
    entityType,
    property,
    entityId,
    entity,
    handleTranslationMutation,
    entityReferenceObject = undefined,
    translationKeyObject = undefined,
    loadData = true,
}) {
    const entityReference = useGetEntityReferenceForTranslation(
        translationContainer['@id'],
        entityUri,
        entityType,
        entityId,
        !loadData,
        entityReferenceObject,
    );

    const translationKey = useGetTranslationKeyForTranslation(
        entityReference,
        property,
        entityType,
        translationKeyObject,
    );

    if (entityReference === undefined) {
        return null;
    }

    return (
        <TranslationInner
            entityReference={entityReference}
            translationKey={translationKey}
            entity={entity}
            isHtml={isHtml}
            content={content}
            languageIso={languageIso}
            property={property}
            handleTranslationMutation={handleTranslationMutation}
            translationContainer={translationContainer}
        />
    );
}

export function TranslationInner({
    isHtml = false,
    content,
    languageIso,
    translationKey,
    entityReference,
    entity,
    handleTranslationMutation,
    translationContainer,
}) {
    const { sidebarFilters } = useSelector((state) => state.translation);
    const currentTranslationUser = useGetCurrentTranslationUser(translationContainer.id);
    const translation = useGetTranslation(translationKey, languageIso);

    const {
        showUntranslated = true,
        showUnverified = true,
        showVerified = true,
        showDisabledForTranslation = true,
        variantId,
    } = sidebarFilters;
    const enabledForTranslation = translationKey ? isEnabledForTranslation(translationKey, languageIso) : true;

    // Check filters
    if (enabledForTranslation) {
        if (showUntranslated === false && translation === undefined && showUnverified === true) {
            return null;
        }

        if (showUnverified === false && _.get(translation, 'unverified', false) === true) {
            return null;
        }

        if (showVerified === false && _.get(translation, 'unverified', true) === false) {
            return null;
        }
    }

    if (showDisabledForTranslation === false && enabledForTranslation === false) {
        return null;
    }

    // Check for variantId filter
    if (
        variantId > 0 &&
        entity.hasOwnProperty('documentVariants') &&
        entity.documentVariants.includes(variantId) === false
    ) {
        return null;
    }

    return (
        <TranslationInnerContent
            isHtml={isHtml}
            content={content}
            languageIso={languageIso}
            translationKey={translationKey}
            entityReference={entityReference}
            entity={entity}
            handleTranslationMutation={handleTranslationMutation}
            translationContainer={translationContainer}
            translation={translation}
            currentTranslationUser={currentTranslationUser}
            enabledForTranslation={enabledForTranslation}
        />
    );
}

function TranslationInnerContent({
    isHtml = false,
    content,
    languageIso,
    translationKey,
    entityReference,
    entity,
    handleTranslationMutation,
    translationContainer,
    translation,
    currentTranslationUser,
    enabledForTranslation,
}) {
    const { toggleEntity, getSelectedEntity, selectedEntities } = useContext(EditContext);
    const { toggleVisibility, sidebarFiltersAsString } = useContext(TranslationContext);
    const { t } = useTranslation('translations');

    useEffect(() => {
        toggleVisibility(entityReference.uri);
    }, [entityReference, sidebarFiltersAsString]);

    const { uri } = entityReference;
    const canTranslate =
        translationKey !== undefined &&
        currentTranslationUser.role !== Constants.translationContainerTeamRoles.spectator;

    const selectedEntity = getSelectedEntity(uri, translationKey?.property);
    const isSelected = selectedEntity !== undefined;
    const isTranslating = isSelected && _.get(selectedEntity, 'translate', false) === true;
    const contentHashChanged = _.get(translation, 'contentHashChanged', false);

    const contentHashMatch = useMemo(() => {
        if (translation === undefined) {
            return true;
        }

        return translation.contentHash === md5(content);
    }, [translation, content]);

    return (
        <div className="d-flex mb-5" style={{ minHeight: 208 }}>
            <input
                type="checkbox"
                id={`entity-check-${uri}`}
                checked={isSelected}
                disabled={
                    translationKey === undefined || selectedEntities.some((_entity) => _entity.translate === true)
                }
                onChange={() => {
                    toggleEntity(
                        {
                            id: uri,
                            entityReference,
                            translationKey,
                            entity,
                            translate: false,
                        },
                        true,
                    );
                }}
                className="mr-3"
                style={{ marginTop: 16 }}
                onClick={(event) => event.stopPropagation()}
            />

            <div className="flex-grow-1">
                <div
                    className={cx('dr-translation-item-wrapper', {
                        active: isTranslating,
                        enabled: enabledForTranslation,
                        'can-translate': canTranslate,
                        'translation-disabled': enabledForTranslation === false,
                        'has-translation': translation !== undefined && !isTranslating && enabledForTranslation,
                    })}
                    onClick={() => {
                        if (!isSelected && enabledForTranslation && canTranslate) {
                            toggleEntity({
                                id: uri,
                                entityReference,
                                translationKey,
                                entity,
                                translate: true,
                            });
                        }
                    }}
                >
                    {translationKey && (
                        <TranslationType
                            entityType={translationKey.entityType}
                            property={translationKey.property}
                            entity={entity}
                        />
                    )}

                    <RestrictedTranslationContent
                        id={translationContainer.id}
                        roles={[
                            Constants.translationContainerTeamRoles.manager,
                            Constants.translationContainerTeamRoles.finalEditor,
                            Constants.translationContainerTeamRoles.editor,
                        ]}
                    >
                        <TranslationActions
                            isEnabledForTranslation={enabledForTranslation}
                            translationKey={translationKey}
                            languageIso={languageIso}
                            isTranslating={isTranslating}
                            translationPerVariant={translationKey?.translationPerVariant}
                            contentHashChanged={contentHashChanged}
                            entityReference={entityReference}
                            contentHashMatch={contentHashMatch}
                            fromImport={translation?.fromImport ?? false}
                        />
                    </RestrictedTranslationContent>

                    <TranslationContent content={content} translationKey={translationKey} />

                    {!isTranslating && (
                        <div className="dr-translation-preview">
                            <div className="mb-3 d-flex align-items-center">
                                <div
                                    className="text-uppercase text-primary"
                                    style={{
                                        fontSize: 10,
                                    }}
                                >
                                    {getLanguageName(languageIso)}
                                </div>

                                <div className="ml-auto">
                                    {enabledForTranslation && (
                                        <>
                                            {translation !== undefined && translation.unverified && (
                                                <Tooltip
                                                    tooltip={t('translation.navbar.dashboard.translation.approval')}
                                                >
                                                    <PencilFill className="text-danger mr-2" size={16} />
                                                </Tooltip>
                                            )}

                                            {translationKey && (
                                                <TranslationCompleteIcon
                                                    translationKey={translationKey}
                                                    hasEntityReference={true}
                                                    languageIso={languageIso}
                                                    size={16}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            {translation === undefined ? (
                                <>
                                    <div className="text-muted">
                                        {translationKey === undefined ? (
                                            <Code height={36} />
                                        ) : (
                                            <>{t('translation.navbar.dashboard.translation.noTranslation')}</>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <RenderTranslationContent
                                    content={translation.translation}
                                    translationKey={translationKey}
                                />
                            )}
                        </div>
                    )}
                </div>

                {isTranslating && (
                    <div className="dr-edit-translation">
                        <EditTranslation
                            translation={translation}
                            close={() => {
                                toggleEntity({
                                    id: uri,
                                    entityReference,
                                });
                            }}
                            languageIso={languageIso}
                            translationKey={translationKey}
                            isHtml={isHtml}
                            handleTranslationMutation={(data) => handleTranslationMutation(data, contentHashMatch)}
                            content={content}
                            translationContainer={translationContainer}
                            contentHashMatch={contentHashMatch}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

function TranslationActions({
    isEnabledForTranslation,
    translationKey,
    languageIso,
    isTranslating,
    translationPerVariant,
    contentHashChanged,
    entityReference,
    contentHashMatch = true,
    fromImport = false,
}) {
    const [updateTranslationKey] = useUpdateTranslationKeyMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    const { parentEntityReference } = useGetEntityReferenceQuery(entityReference.parent, {
        selectFromResult: ({ data }) => ({
            parentEntityReference: data,
        }),
        skip: entityReference.hasOwnProperty('parent') === false || entityReference.parent === null,
    });

    return (
        <div className="dr-translation-actions">
            {isEnabledForTranslation && (
                <>
                    <Tooltip tooltip={t('translation.navbar.dashboard.document.btn.edit')}>
                        <Button variant="icon" className="btn-icon-only p-0" disabled={isTranslating}>
                            <PencilSquare size={16} className="text-body" />
                        </Button>
                    </Tooltip>
                    <Tooltip tooltip={t('translation.navbar.dashboard.document.actions.disableTranslation')}>
                        <Button
                            variant="icon"
                            className="btn-icon-only p-0"
                            disabled={isTranslating}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleExclusionUpdate(false);
                            }}
                        >
                            <XCircle size={16} className="text-danger" />
                        </Button>
                    </Tooltip>

                    {translationPerVariant && (
                        <Badge variant="warning" className="ml-2">
                            {t('translation.navbar.dashboard.translation.perVariant')}
                        </Badge>
                    )}

                    {(contentHashChanged === false && contentHashMatch === false) && (
                        <Badge variant="danger ml-2">
                            {t('translation.navbar.dashboard.translation.sourceUpdatedNotSynced')}
                        </Badge>
                    )}

                    {(contentHashChanged === true) && (
                        <Badge variant="danger ml-2">
                            {t('translation.navbar.dashboard.translation.sourceUpdated')}
                        </Badge>
                    )}

                    {fromImport && (
                        <Badge variant="danger ml-2">{t('translation.navbar.dashboard.translation.fromImport')}</Badge>
                    )}
                </>
            )}

            {isEnabledForTranslation === false && (
                <>
                    <Tooltip tooltip={t('translation.navbar.dashboard.document.actions.enableTranslation')}>
                        <Button
                            variant="icon"
                            className="btn-icon-only p-0"
                            disabled={isTranslating}
                            onClick={(event) => {
                                event.stopPropagation();
                                handleExclusionUpdate(true);
                            }}
                        >
                            <PlusCircle size={16} className="text-body" />
                        </Button>
                    </Tooltip>
                </>
            )}
        </div>
    );

    function handleExclusionUpdate(enabled = true) {
        updateTranslationKey({
            uri: translationKey['@id'],
            body: {
                enabledForTranslation: {
                    ...translationKey.enabledForTranslation,
                    [languageIso]: enabled,
                },
            },
        }).then(() => {
            dispatch(translationApi.util.invalidateTags([{ type: 'EntityReference', id: entityReference['@id'] }]));

            if (parentEntityReference) {
                dispatch(
                    translationApi.util.invalidateTags([{ type: 'EntityReference', id: parentEntityReference['@id'] }]),
                );
            }
        });
    }
}

function TranslationType({ entityType, property, entity }) {
    const type = getTypeAsString(property, entityType, entity);

    return <span className="dr-translation-type">{type}</span>;
}

function TranslationContent({ content, translationKey }) {
    return (
        <div className="dr-translation-item">
            <div
                style={{
                    fontSize: 10,
                }}
                className="text-uppercase text-primary mb-3"
            >
                Nederlands
            </div>

            {translationKey === undefined ? (
                <Code height={36} />
            ) : (
                <RenderTranslationContent content={content} translationKey={translationKey} />
            )}
        </div>
    );
}
