import { useGetCategoriesQuery } from 'features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetCategory(container) {
    const { categoryId } = container;

    const filterCategories = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, categoryId) => categoryId,
            (data, categoryId) => {
                if (data) {
                    return data.find((_item) => _item.id === categoryId);
                }

                return undefined;
            },
        );
    }, []);

    const { category } = useGetCategoriesQuery(undefined, {
        selectFromResult: (result) => ({
            category: filterCategories(result, categoryId),
        }),
    });

    return category;
}
