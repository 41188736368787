import { useGetTranslationKeysQuery } from '../../../features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetTranslationKeyForTranslation(
    entityReference,
    property,
    entityType,
    translationKeyObject = undefined,
) {
    const findTranslationKey = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, entityType) => entityType,
            (res, entityType, property) => property,
            (data, entityType, property) => {
                if (data) {
                    return data.find((item) => item.entityType === entityType && item.property === property);
                }

                return undefined;
            },
        );
    }, []);

    const { translationKey } = useGetTranslationKeysQuery(
        { uri: entityReference ? entityReference['@id'] : undefined },
        {
            selectFromResult: (result) => ({
                translationKey: findTranslationKey(result, entityType, property),
            }),
            skip: entityReference === undefined || translationKeyObject !== undefined,
        },
    );

    if (translationKeyObject !== undefined) {
        return translationKeyObject;
    }

    return translationKey;
}
