import { useGetOpListExportTokensQuery } from '../../../features/publications/publicationApi';
import { useEffect, useMemo, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import Constants from '../../../config/Constants';

const emptyArray = [];

export function useGetOpListExportTokens(opDepartmentId) {
    const [autoRefresh, setAutoRefresh] = useState(false);

    const filterTokens = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, opDepartmentId) => opDepartmentId,
            (data, opDepartmentId) => {
                if (data) {
                    return data
                        .filter(
                            (_entity) =>
                                _entity.opDepartmentId !== null &&
                                _entity.opDepartmentId === opDepartmentId &&
                                _entity.expired === false,
                        )
                        .reverse();
                }

                return emptyArray;
            },
        );
    }, []);

    const tokensArePending = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data) => {
                const pendingStatusCodes = [
                    Constants.opListExportTokenStatus.pending,
                    Constants.opListExportTokenStatus.processing,
                ];

                return data.some((_token) => {
                    return pendingStatusCodes.includes(_token.status);
                });
            },
        );
    }, []);

    const results = useGetOpListExportTokensQuery(opDepartmentId, {
        selectFromResult: (result) => {
            const exportTokens = filterTokens(result, opDepartmentId);

            return {
                ...result,
                exportTokens,
                hasPendingTokens: tokensArePending(exportTokens),
            };
        },
        pollingInterval: autoRefresh ? 5000 : 0,
    });

    const { hasPendingTokens } = results;

    useEffect(() => {
        setAutoRefresh(hasPendingTokens);
    }, [hasPendingTokens]);

    return results;
}
