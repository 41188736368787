import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth, providesList } from '../api';
import HelperFunctions from '../../pages/global/HelperFunctions';
import getEnv from '../../config/Env';

const baseUrl = getEnv('GUARD_BASE_URL');
const uriPrefix = '/api';

const guardApiQuery = fetchBaseQuery({
    baseUrl,
    // prepareHeaders,
});

export const guardApi = createApi({
    reducerPath: 'guardApi',
    baseQuery: (args, api, extraOptions) => baseQueryWithReauth(args, api, extraOptions, guardApiQuery),
    tagTypes: ['GuardEntity', 'GuardAlias', 'GuardAudit'],
    endpoints: (builder) => ({
        connect: builder.mutation({
            query: (body) => {
                return {
                    url: '/connect',
                    method: 'POST',
                    body,
                };
            },
        }),
        getGuardEntities: builder.query({
            query: ({ token, params }) => ({
                url: HelperFunctions.prepareQuery(`${uriPrefix}/guard_entities`, params),
                headers: prepareHeaders(token),
            }),
            transformResponse: (response) => response['member'],
            providesTags: (result) => providesList(result, 'GuardEntity'),
        }),
        getGuardAudits: builder.query({
            query: ({ uri, token, params }) => ({
                url: HelperFunctions.prepareQuery(`${uri}/guard_audits`, params),
                headers: prepareHeaders(token),
            }),
            providesTags: (result, error, arg) => {
                let tags = [{ type: arg.type, id: arg.uri }];

                if (arg.parentUri) {
                    tags.push({ type: 'GuardEntity', id: arg.parentUri });
                }

                return tags;
            },
        }),
        getGuardAliases: builder.query({
            query: ({ uri, token, params }) => ({
                url: HelperFunctions.prepareQuery(`${uri}/guard_aliases`, params),
                headers: prepareHeaders(token),
            }),
            providesTags: (result, error, arg) => {
                const tags = providesList(result['member'], 'GuardAlias');

                return tags.concat([{ type: 'GuardEntity', id: arg.uri }]);
            },
        }),
        getGuardEntity: builder.query({
            query: ({ id, token }) => ({
                url: `${uriPrefix}/guard_entities/${id}`,
                headers: prepareHeaders(token),
            }),
            providesTags: (result) => [{ type: 'GuardEntity', id: result['@id'] }],
        }),
        createGuardEntity: builder.mutation({
            query: ({ body, token }) => ({
                url: `${uriPrefix}/guard_entities`,
                method: 'POST',
                headers: prepareHeaders(token),
                body,
            }),
            invalidatesTags: () => [{ type: 'GuardEntity', id: 'LIST' }],
        }),
        updateGuardEntity: builder.mutation({
            query: ({ uri, body, token }) => ({
                url: uri,
                method: 'PATCH',
                headers: prepareHeaders(token, 'PATCH'),
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'GuardEntity', id: arg.uri }],
        }),
        updateAuditable: builder.mutation({
            query: ({ uri, body, token }) => ({
                url: uri,
                method: 'PATCH',
                headers: prepareHeaders(token, 'PATCH'),
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result) => [{ type: result['@type'], id: result['@id'] }],
        }),
        createGuardAudit: builder.mutation({
            query: ({ uri, body, token }) => ({
                url: `${uri}/audit`,
                method: 'POST',
                headers: prepareHeaders(token, 'POST'),
                body: JSON.stringify(body),
            }),
        }),
        createGuardAlias: builder.mutation({
            query: ({ uri, body, token }) => ({
                url: `${uriPrefix}/guard_aliases`,
                method: 'POST',
                headers: prepareHeaders(token, 'POST'),
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'GuardEntity', id: arg.uri },
                {
                    type: 'GuardAlias',
                    id: 'LIST',
                },
            ],
        }),
        createEntityToken: builder.mutation({
            query: ({ uri, type, body = {}, token }) => ({
                url: `${uri}/token`,
                method: 'POST',
                headers: prepareHeaders(token, 'POST'),
                body: JSON.stringify(body),
            }),
            invalidatesTags: (result, error, arg) => [{ type: arg['type'], id: arg['uri'] }],
        }),
    }),
});

export const {
    useConnectMutation,
    useGetGuardEntitiesQuery,
    useGetGuardEntityQuery,
    useCreateGuardEntityMutation,
    useUpdateGuardEntityMutation,
    useGetGuardAuditsQuery,
    useGetGuardAliasesQuery,
    useCreateGuardAuditMutation,
    useCreateGuardAliasMutation,
    useUpdateAuditableMutation,
    useCreateEntityTokenMutation,
} = guardApi;

function prepareHeaders(token, method) {
    let headers = {
        authorization: `Bearer ${token}`,
    };

    if (method === 'PATCH') {
        headers['content-type'] = 'application/merge-patch+json';
    }

    if (method === 'POST') {
        headers['content-type'] = 'application/json';
    }

    return headers;
}
