import { BooleanParam, useQueryParam } from 'use-query-params';
import { FormModal, ModalFooter } from 'pages/global/FormModal';
import { FieldArray, Form as FForm, Formik, useFormikContext } from 'formik';
import { useGetBaseVariant } from '../../../hooks/useGetBaseVariant';
import { IconButton, InfoButton } from 'components/Buttons';
import {
    DocumentVariantSelect,
    FieldSelect,
    FormField,
    Switch,
    Textarea,
} from 'pages/publications_v2/helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import { useGetVariant } from '../../../hooks/useGetVariant';
import { useEffect, useMemo, useState } from 'react';
import Constants from '../../../../../config/Constants';
import * as Yup from 'yup';
import { ipidApi } from 'features/documents/ipidApi';
import HelperFunctions from '../../../../global/HelperFunctions';
import { useGetDocument } from '../../../hooks/useGetDocument';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import _ from 'lodash';
import { useAddPublicationMutation } from 'features/publications/publicationApi';
import { useGetPublicationGroups } from 'pages/publications_v2/hooks/useGetPublicationGroups';
import { PlusCircleFill, TrashFill } from 'react-bootstrap-icons';
import { generatePath, useHistory } from 'react-router-dom';
import { INDEX_PATH } from 'scenes/PublicationsV2';
import { useGetUploadsQuery, useUploadFileMutation } from 'features/mediaLibrary/mediaApi';
import { useGetUploads } from 'pages/media_library/hooks/useGetUploads';
import { Badge } from 'react-bootstrap';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { entityTypes } from 'pages/translation/config/Constants';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';
import { useGetTemplateCategory } from 'pages/documents_v2/hooks/useGetTemplateCategory';
import { getChildVariants } from 'pages/documents_v2/views/edit/variants/useGetDocumentVariantPublications';
import { useGetDocumentPublications } from 'pages/documents_v2/views/edit/variants/useGetDocumentPublications';

export function PublicationsModal() {
    const { t } = useTranslation('documents');
    const [createPublication, setCreatePublication] = useQueryParam('createPublication', BooleanParam);
    const showModal = createPublication !== undefined;

    const hide = () => {
        setCreatePublication(undefined);
    };

    return (
        <FormModal
            scrollable={true}
            size="lg"
            onHide={hide}
            show={showModal}
            title={t('document.navbar.variants.publications.createPublicationsTitle')}
        >
            {showModal && <ModalContent hide={hide} />}
        </FormModal>
    );
}

function ModalContent({ hide }) {
    const { t } = useTranslation('documents');
    const history = useHistory();
    const currentOrganisation = useCurrentOrganisation();
    const baseVariant = useGetBaseVariant();
    const uploads = useGetUploads();
    const publicationDomains = useGetPublicationDomains();
    const { documentPublications, documentPublicationsIsLoading } = useGetDocumentPublications();
    const [addPublication] = useAddPublicationMutation();

    const document = useGetDocument();
    const templateCategory = useGetTemplateCategory(document);
    const sections = document?.sections ? document.sections.filter((section) => section.deletedAt === null) : [];

    const defaultSection = sections.length > 0 ? sections[0] : undefined;

    const restrictions = useEntityTemplateParameters(defaultSection, entityTypes.SECTION, document);
    const { canChangeTitle = true } = restrictions ?? {};

    const documentVariants = getChildVariants(baseVariant);

    const defaultPublications = useMemo(() => {
        if (documentVariants.length === 0) {
            return [];
        }

        const publications = [];

        documentVariants.forEach((variant) => {
            const variantHasPublications = documentPublications.some((publication) =>
                publication.documentVariantIds.includes(variant.id),
            );

            if (variant.enabled && !variantHasPublications) {
                publications.push({
                    domainId: '',
                    variantId: variant.id,
                    sectionId: defaultSection?.id,
                    name: '',
                    title: '',
                    variantName: '',
                    description: '',
                    htmlMetaFooter: '',
                    html: true,
                    htmlSlug: '',
                    pdf: true,
                    pdfFileName: '',
                    logo: undefined,
                    properties: {
                        canChangeTitle,
                    },
                });
            }
        });

        return publications;
    }, [documentVariants, documentPublications]);

    const domainOptions = getDomainOptions(templateCategory);

    if (documentPublicationsIsLoading) {
        return null;
    }

    return (
        <Formik
            initialValues={{
                publicationGroup: null,
                templateId: getDefaultTemplateId(),
                publications: defaultPublications,
            }}
            onSubmit={handleSubmit}
            validationSchema={PublicationSchema}
        >
            {({ values, isSubmitting, isValid, dirty }) => (
                <>
                    <FForm autoComplete="off" className="modal-body" id="link-document">
                        <div className="p-3">
                            <Layout />

                            <FieldArray name="publications">
                                {({ remove, push }) => (
                                    <div>
                                        {values.publications.length > 0 && (
                                            <>
                                                <hr />
                                                {values.publications.map((publication, index) => (
                                                    <Publication
                                                        publication={publication}
                                                        index={index}
                                                        sections={sections}
                                                        remove={() => remove(index)}
                                                        domainOptions={domainOptions}
                                                        document={document}
                                                        organisation={currentOrganisation}
                                                        key={`row-${index}`}
                                                    />
                                                ))}
                                            </>
                                        )}

                                        <InfoButton
                                            size="sm"
                                            className="d-flex align-items-center"
                                            onClick={() =>
                                                push({
                                                    domainId: '',
                                                    variantId: undefined,
                                                    sectionId: defaultSection?.id,
                                                    name: '',
                                                    title: '',
                                                    variantName: '',
                                                    description: '',
                                                    html: true,
                                                    htmlSlug: '',
                                                    pdf: true,
                                                    pdfFileName: '',
                                                    logo: undefined,
                                                    properties: {
                                                        cardId: null,
                                                        cardVersion: null,
                                                        iconShape: null,
                                                        canChangeTitle,
                                                    },
                                                })
                                            }
                                        >
                                            <PlusCircleFill className="mr-1" />
                                            {t('document.navbar.variants.publications.addRow')}
                                        </InfoButton>
                                    </div>
                                )}
                            </FieldArray>
                        </div>
                    </FForm>

                    <ModalFooter
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        dirty={dirty}
                        onHide={hide}
                        form="link-document"
                    />
                </>
            )}
        </Formik>
    );

    function getDefaultTemplateId() {
        const documentTemplates = currentOrganisation?.documentTemplates ?? [];

        if (documentTemplates.length === 0) {
            return undefined;
        }

        if (!_.isEmpty(templateCategory?.templateDir)) {
            const template = documentTemplates.find(
                (_template) => _template.templateDir === templateCategory.templateDir,
            );

            if (template) {
                return template.id;
            }
        }

        return documentTemplates[0].id;
    }

    function handleSubmit(values) {
        Promise.all(
            values.publications.map((publication) => {
                return new Promise((resolve) => {
                    let logo = null;

                    if (publication.logo?.fileName) {
                        const fileUpload = uploads.find(
                            (upload) => upload.deletedAt === null && upload.fileName === publication.logo.fileName,
                        );

                        if (fileUpload) {
                            logo = {
                                fileId: fileUpload.id,
                                url: fileUpload.url,
                            };
                        }
                    }

                    const formData = {
                        organisationId: currentOrganisation.id,
                        publicationGroup: values.publicationGroup === '' ? null : values.publicationGroup,
                        templateId: values.templateId,
                        domainId: publication.domainId,
                        name: publication.name,
                        title: publication.title,
                        description: publication.description,
                        variantName: publication.variantName,
                        html: publication.html,
                        htmlSlug: publication.htmlSlug,
                        pdf: publication.pdf,
                        pdfFileName: publication.pdfFileName,
                        properties: publication.properties,
                        htmlMetaFooter: publication.htmlMetaFooter,
                        logo,
                        contentDecoded: [
                            {
                                contentType: 'section',
                                type: 'default',
                                style: 'default',
                                documentId: document.id,
                                sectionId: publication.sectionId,
                                variantId: publication.variantId,
                            },
                        ],
                    };

                    addPublication(formData).then((result) => {
                        resolve([publication.variantId, result.data]);
                    });
                });
            }),
        ).finally(() => {
            // Redirect to publication (folder) page
            const groupId = values.publicationGroup ? values.publicationGroup.split('/').pop() : undefined;

            history.push(
                generatePath(INDEX_PATH, {
                    folder: groupId ? 'folder' : 'list',
                    folderId: groupId,
                }),
            );
        });
    }

    function getDomainOptions(templateCategory) {
        if (!_.isEmpty(templateCategory?.domain)) {
            const filteredDomains = publicationDomains.filter((publicationDomain) =>
                publicationDomain.domain.startsWith(templateCategory.domain),
            );

            return HelperFunctions.prepareDropdownData(filteredDomains, 'domain', 'id');
        }

        return HelperFunctions.prepareDropdownData(publicationDomains, 'domain', 'id');
    }
}

function Layout() {
    const { t } = useTranslation('publications');
    const currentOrganisation = useCurrentOrganisation();
    const groups = useGetPublicationGroups();
    const { values } = useFormikContext();
    const { publications = [] } = values;

    const { uploads, isLoading, isUninitialized, isFetching } = useGetUploadsQuery(currentOrganisation.id, {
        selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => ({
            uploads: data ?? [],
            isLoading,
            isUninitialized,
            isFetching,
        }),
    });

    const [uploadFile] = useUploadFileMutation();
    const [fileToUpload, setFileToUpload] = useState(undefined);

    useEffect(() => {
        if (publications.length > 0) {
            // Check if we need to upload files
            const filesToUpload = [];

            publications.forEach((publication) => {
                if (publication.logo?.fileId === null) {
                    filesToUpload.push(publication.logo);
                }
            });

            if (filesToUpload.length > 0) {
                setFileToUpload(filesToUpload[0]);
            } else {
                setFileToUpload(undefined);
            }
        } else {
            setFileToUpload(undefined);
        }
    }, [publications]);

    useEffect(() => {
        if (fileToUpload && !isUninitialized && !isLoading && !isFetching) {
            // Already uploaded?
            const existingUpload = uploads.find(
                (upload) => upload.deletedAt === null && upload.fileName === fileToUpload.fileName,
            );

            if (!existingUpload) {
                uploadFile({
                    url: fileToUpload.url,
                    organisationId: currentOrganisation.id,
                    folderId: null,
                });
            }
        }
    }, [fileToUpload, uploads, isLoading, isUninitialized, isFetching]);

    const groupOptions = groups.map((parentGroup) => {
        return {
            label: parentGroup.name,
            options: HelperFunctions.prepareDropdownData(parentGroup.children, 'name', '@id'),
        };
    });

    const documentTemplates = currentOrganisation?.documentTemplates ?? [];

    const templateOptions = HelperFunctions.prepareDropdownData(documentTemplates, 'name', 'id').sort(
        HelperFunctions.sortByString('name'),
    );

    return (
        <>
            <FieldSelect
                name="publicationGroup"
                label={t('publication.folder')}
                options={groupOptions}
                props={{ isClearable: true }}
            />
            <FieldSelect
                name="templateId"
                label={`${t('publication.settings.content.layout.titles.template')}`}
                options={templateOptions}
                props={{
                    required: true,
                }}
            />
        </>
    );
}

function Publication({ publication, index, sections, remove, domainOptions, document, organisation }) {
    const { t } = useTranslation('publications');
    const baseVariant = useGetBaseVariant(undefined, true);
    const variant = useGetVariant(publication.variantId);
    const { setFieldValue } = useFormikContext();
    const uploads = useGetUploads();

    const [getCard] = ipidApi.useLazyGetCardQuery();
    const [getCardData] = ipidApi.useLazyGetCardDataQuery();

    const cardId = variant?.ipidProperties?.cardId;
    const isIpidTemplate = cardId !== undefined;

    useEffect(() => {
        if (variant) {
            const cardId = variant?.ipidProperties?.cardId;
            const isIpidVerbond = !_.isEmpty(cardId);

            if (isIpidVerbond) {
                Promise.all([
                    new Promise((resolve) => {
                        getCard(cardId).then(({ data }) => resolve(data));
                    }),
                    new Promise((resolve) => {
                        getCardData({
                            id: cardId,
                            version: variant.ipidProperties.cardVersion,
                        }).then(({ data }) => resolve(data));
                    }),
                ]).then(([card, cardData = []]) => {
                    if (card) {
                        const description = _.trim(getProperty(cardData, 'title'));
                        const insurerName = _.trim(getProperty(cardData, 'insurer_name'));
                        let variantName = `${insurerName}`;
                        const typeInsurerVerbond = getInsurerType(card, cardData);

                        const iconShape = _.get(card, 'insurers.iconShapeEiopa', 'rounded');
                        const htmlMetaFooter = _.trim(_.get(card, 'insurers.googleAnalyticsVerbond', ''));

                        if (!_.isEmpty(typeInsurerVerbond)) {
                            variantName = variantName + `, ${typeInsurerVerbond}`;
                        }

                        let licenseNumberVerbond = getProperty(cardData, 'license_number_verbond');

                        if (licenseNumberVerbond === '') {
                            licenseNumberVerbond = _.get(card, 'insurers.licenseNumberVerbond', '');
                        }

                        if (!_.isEmpty(licenseNumberVerbond)) {
                            variantName = variantName + `, vergunning: ${licenseNumberVerbond}`;
                        }

                        variantName = variantName + ' (NL)';

                        const urlName = _.get(card, 'insurers.urlName', '');
                        let domainId = '';

                        if (!_.isEmpty(urlName)) {
                            const domain = findDomain(`verzekeringskaarten.nl/${urlName}`);

                            if (domain) {
                                domainId = domain.id;
                            }
                        }

                        let logo = undefined;
                        const companyLogoVerbond = _.get(card, 'insurers.companyLogoVerbond', '');

                        if (!_.isEmpty(companyLogoVerbond) && !_.isEmpty(urlName)) {
                            // Already uploaded?
                            const fileUpload = uploads.find(
                                (upload) => upload.deletedAt === null && upload.fileName === companyLogoVerbond,
                            );

                            if (fileUpload) {
                                logo = {
                                    fileId: fileUpload.id,
                                    url: fileUpload.url,
                                    urlName,
                                    fileName: fileUpload.fileName,
                                };
                            } else {
                                logo = {
                                    fileId: null,
                                    url: `https://verzekeringskaarten.nl/assets/images/${urlName}/${companyLogoVerbond}`,
                                    urlName,
                                    fileName: companyLogoVerbond,
                                };
                            }
                        }

                        setFieldValue(`publications.${index}`, {
                            ...publication,
                            domainId,
                            name: variant.name,
                            title: getCardTitle(card, cardData),
                            description,
                            variantName,
                            logo,
                            htmlMetaFooter,
                            htmlSlug: HelperFunctions.slugify(card.urlName ?? ''),
                            pdfFileName: HelperFunctions.slugify(card.urlName ?? ''),
                            properties: {
                                ...publication.properties,
                                cardId,
                                cardVersion: variant.ipidProperties.cardVersion,
                                iconShape,
                            },
                        });
                    }
                });
            } else {
                let domainId = '';

                // Find domain
                if (domainOptions.length > 0) {
                    domainId = domainOptions[0].id;
                }

                setFieldValue(`publications.${index}`, {
                    ...publication,
                    domainId,
                    name: variant.name,
                    title: document.name,
                    variantName: organisation.name,
                    description: variant.name,
                    htmlSlug: HelperFunctions.slugify(variant.name),
                    pdfFileName: HelperFunctions.slugify(variant.name),
                });
            }
        }
    }, [variant, uploads]);

    return (
        <div className="pb-2">
            <div className="d-flex align-items-center mb-3">
                <div className="font-weight-bold mr-2">
                    {t('publication.columnData.publication')} #{index + 1}
                </div>
                <IconButton icon={<TrashFill className="text-danger" />} onClick={remove} />
            </div>

            <div className="pl-4 pb-1">
                <DocumentVariantSelect
                    name={`publications.${index}.variantId`}
                    label="Variant"
                    baseVariant={baseVariant}
                    showBaseVariant={false}
                    props={{
                        required: true,
                        placeholder: `${t('publication.settings.content.summary.expand.section.titleSelect')}...`,
                    }}
                />
                <FieldSelect
                    name={`publications.${index}.domainId`}
                    label={`${t('publication.settings.content.layout.titles.domain')}`}
                    options={domainOptions}
                    props={{
                        required: true,
                    }}
                />
                <FieldSelect
                    label={t('publication.settings.content.summary.addChapterModal.chapter')}
                    name={`publications.${index}.sectionId`}
                    options={HelperFunctions.prepareDropdownData(sections, 'title')}
                    props={{
                        required: true,
                        placeholder: t('publication.settings.content.summary.addChapterModal.titleSelect'),
                    }}
                />

                <FormField
                    label={`${t('publication.settings.content.features.name')}`}
                    name={`publications.${index}.name`}
                    props={{ required: true }}
                />
                <Textarea
                    label={`${t('publication.settings.content.features.titlePublication')}`}
                    name={`publications.${index}.title`}
                    props={{ rows: 1, required: true, disabled: publication.properties?.canChangeTitle === false }}
                />
                <FormField
                    label={t('publication.settings.content.features_ipid.variantName')}
                    name={`publications.${index}.variantName`}
                    props={{
                        required: isIpidTemplate,
                        pattern: isIpidTemplate ? Constants.publication.patterns.ipidVerbondVariant : undefined,
                    }}
                    help={isIpidTemplate ? 'Moet voldoen aan <organisatie>, <type>, vergunning: ######## (NL)' : ''}
                />
                <Textarea
                    label={t('publication.settings.content.features_ipid.description')}
                    name={`publications.${index}.description`}
                    props={{
                        required: isIpidTemplate,
                        rows: 1,
                    }}
                />

                {publication.logo && (
                    <div className="mb-3 form-group row">
                        <label htmlFor="publications.0.logo" className="form-label col-form-label col-sm-4">
                            Logo
                            {publication.logo.fileId === null && (
                                <Badge className="ml-2" variant="warning">
                                    {t('publications.badges.new').toLowerCase()}
                                </Badge>
                            )}
                        </label>
                        <div className="col-sm-8">
                            <img
                                src={publication.logo.url}
                                style={{ backgroundColor: '#005ea3', maxHeight: 60 }}
                                className="img-fluid border-radius p-2"
                            />
                        </div>
                    </div>
                )}

                <div className="font-weight-bolder text-color font-size-sm">HTML / online</div>

                <Switch
                    name={`publications.${index}.html`}
                    label={t('publication.settings.content.layout.titles.htmlOnline')}
                />

                {publication.html && (
                    <>
                        <FormField
                            label="Url"
                            name={`publications.${index}.htmlSlug`}
                            props={{
                                required: publication.html,
                                onChange: (e) => {
                                    setFieldValue(
                                        `publications.${index}.htmlSlug`,
                                        HelperFunctions.slugify(e.target.value),
                                    );
                                },
                            }}
                        />
                        <FormField
                            label={t('publication.settings.content.layout.titles.htmlMetaFooter')}
                            name={`publications.${index}.htmlMetaFooter`}
                        />
                    </>
                )}

                <div className="font-weight-bolder text-color font-size-sm">Pdf</div>

                <Switch
                    name={`publications.${index}.pdf`}
                    label={t('publication.settings.content.layout.titles.pdfVersion')}
                />

                {publication.pdf && (
                    <FormField
                        label={`${t('publication.settings.content.layout.titles.fileName')}*`}
                        name={`publications.${index}.pdfFileName`}
                        props={{
                            required: publication.pdf,
                            onChange: (e) => {
                                setFieldValue(
                                    `publications.${index}.pdfFileName`,
                                    HelperFunctions.slugify(e.target.value),
                                );
                            },
                        }}
                    />
                )}
            </div>

            <hr />
        </div>
    );

    function getInsurerType(card, cardData = []) {
        const typeId = _.trim(getProperty(cardData, 'type_insurer_verbond', ''));

        if (typeId === '') {
            return _.get(card, 'insurers.typeInsurerVerbond.name', '');
        }

        switch (typeId) {
            case '1':
                return 'schadeverzekeraar';
            case '2':
                return 'levensverzekeraar';
            case '3':
                return 'zorgverzekeraar';
            case '4':
                return 'natura-uitvaartverzekeraar';
            case '5':
                return 'financieel dienstverlener';
            case '6':
                return 'gevolmachtigde agent';
            default:
                return typeId;
        }
    }

    function findDomain(domain) {
        return domainOptions.find((option) => option.domain === domain);
    }
}

function getCardTitle(card, cardData = []) {
    const isNicheTemplate = _.get(card, 'templates.urlName', '') === 'nicheverzekering';
    const isEnglishTemplate = _.get(card, 'templates.urlName', '') === 'name-insurance';

    if (isNicheTemplate || isEnglishTemplate) {
        return _.trim(getProperty(cardData, 'card_name'));
    }

    const cardTitle = _.trim(_.get(card, 'templates.cardName', card.name));

    if (cardTitle === 'Kortlopende fietsverzekering') {
        return 'Aflopende fietsverzekering';
    }

    return cardTitle;
}

const getProperty = (data = [], propName, defaultValue = '') => {
    const property = data.find((item) => item.title === propName);

    return property?.value ?? defaultValue;
};

const PublicationSchema = Yup.object().shape({
    templateId: Yup.number().required(),
    publications: Yup.array()
        .of(
            Yup.object().shape({
                domainId: Yup.number().required(),
                variantId: Yup.number().required(),
                sectionId: Yup.number().required(),
                publicationGroup: Yup.string(),
                name: Yup.string().required(),
                title: Yup.string().required(),
                variantName: Yup.string().required(),
                description: Yup.string().required(),
                html: Yup.boolean(),
                htmlSlug: Yup.string().when('html', {
                    is: true,
                    then: Yup.string().required(),
                }),
                pdf: Yup.boolean(),
                pdfFileName: Yup.string().when('pdf', {
                    is: true,
                    then: Yup.string().required(),
                }),
            }),
        )
        .min(1),
});
