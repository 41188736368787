import { Alert, Modal } from 'react-bootstrap';
import { Field, Form as FForm, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { closeModal, setSelectedRows } from 'features/publications/publicationsSlice';
import { useEffect } from 'react';
import Constants from '../../../config/Constants';
import { Trans, useTranslation } from 'react-i18next';
import { NumberParam, useQueryParam } from 'use-query-params';
import {
    useBatchPublishPublicationsMutation,
    usePublishPublicationMutation,
} from 'features/publications/publicationApi';
import { DateTime } from 'luxon';

export default function PublishModal() {
    const dispatch = useDispatch();
    const { t } = useTranslation('publications');
    const { publish } = useSelector((state) => state.publications.modals);
    const [, setShowPublishModalQuery] = useQueryParam('showPublishModal', NumberParam);

    const payload = publish;
    const showModal = !!publish;

    const { publication, publications = [], isBatch = false } = payload;

    return (
        <FormModal show={showModal} onHide={handleClose} title={getTitle()}>
            {showModal && <PublishModalBody payload={payload} handleClose={handleClose} />}
        </FormModal>
    );

    function getTitle() {
        if (!showModal) {
            return '';
        }

        if (isBatch) {
            return t('publications.bulkActions.publishModal.titleBatch', { count: publications.length });
        }

        return t('publications.bulkActions.publishModal.title', { name: publication.name });
    }

    function handleClose() {
        setShowPublishModalQuery(undefined);
        dispatch(closeModal('publish'));
    }
}

function PublishModalBody({ payload, handleClose }) {
    const { t } = useTranslation('publications');
    const [publishPublication] = usePublishPublicationMutation();
    const [batchPublishPublications] = useBatchPublishPublicationsMutation();
    const dispatch = useDispatch();

    const { publication, publications = [], isBatch = false } = payload;

    return (
        <Formik
            initialValues={{
                action: Constants.publicationToken.action_publish,
                publication: isBatch ? '' : publication['@id'],
                publications: publications,
                publicationStartValue: 'now',
                scheduledAt: '',
                publicationEndValue: 'never',
                publicationEnd: '',
                isBatch,
            }}
            validationSchema={PublishSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, values, setFieldValue }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <FormContent payload={payload} values={values} setFieldValue={setFieldValue} />
                    </Modal.Body>

                    <ModalFooter
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        onHide={handleClose}
                        btnSubmitText={t('publications.bulkActions.publishModal.btn.publish')}
                    />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        const formData = {
            ...values,
            scheduledAt: values.scheduledAt === '' ? null : values.scheduledAt,
            publicationEnd: values.publicationEnd === '' ? null : values.publicationEnd,
            publicationEndValue: values.publicationEndValue !== 'never',
        };

        if (values.isBatch) {
            batchPublishPublications(formData).then(() => {
                // Deselect everything
                dispatch(setSelectedRows([]));
                handleClose();
            });
        } else {
            publishPublication(formData).then(() => {
                handleClose();
            });
        }
    }
}

function FormContent({ payload, values, setFieldValue }) {
    const { publication, isBatch = false, publications = [] } = payload;
    const { publicationStartValue, publicationEndValue } = values;
    const { t, i18n } = useTranslation('publications');
    const [showPublishModal, setShowPublishModal] = useQueryParam('showPublishModal', NumberParam);

    useEffect(() => {
        if (!showPublishModal && !isBatch) {
            setShowPublishModal(publication.id);
        }
    }, [showPublishModal]);

    useEffect(() => {
        if (publicationStartValue === 'now') {
            setFieldValue('scheduledAt', '');
        }
    }, [publicationStartValue]);

    useEffect(() => {
        if (publicationEndValue === 'never') {
            setFieldValue('publicationEnd', '');
        }
    }, [publicationEndValue]);

    return (
        <div className="text-darkBlueAlt">
            <div className="font-weight-bold mb-3 font-size-sm">
                {t('publications.bulkActions.publishModal.publicationStartDate')}
            </div>

            <div className="mb-4">
                <div className="mb-2">
                    <div className="form-check">
                        <Field
                            className="form-check-input"
                            type="radio"
                            id="publicationStartNow"
                            name="publicationStartValue"
                            value="now"
                        />
                        <label htmlFor="publicationStartNow" className="form-check-label">
                            {t('publications.bulkActions.publishModal.publishNow')}
                        </label>
                    </div>
                </div>
                <div className="form-check">
                    <Field
                        className="form-check-input"
                        type="radio"
                        id="publicationStartCustom"
                        name="publicationStartValue"
                        value="custom"
                    />
                    <label htmlFor="publicationStartCustom" className="form-check-label mr-3">
                        {t('publications.bulkActions.publishModal.otherDate')}
                    </label>

                    <Field type="datetime-local" name="scheduledAt" disabled={publicationStartValue !== 'custom'} />
                </div>
            </div>

            <div className="mb-4">
                <div className="font-weight-bold mb-3 font-size-sm">
                    {t('publications.bulkActions.publishModal.endDate')}
                </div>

                <div className="mb-2">
                    <div className="form-check">
                        <Field
                            className="form-check-input"
                            type="radio"
                            id="publicationEndNever"
                            name="publicationEndValue"
                            value="never"
                        />
                        <label htmlFor="publicationEndNever" className="form-check-label">
                            {t('publications.bulkActions.publishModal.noEndDate')}
                        </label>
                    </div>
                </div>
                <div className="form-check">
                    <Field
                        className="form-check-input"
                        type="radio"
                        id="publicationEndCustom"
                        name="publicationEndValue"
                        value="custom"
                    />
                    <label htmlFor="publicationEndCustom" className="form-check-label mr-3">
                        {t('publications.bulkActions.publishModal.otherDate')}
                    </label>

                    <Field type="datetime-local" name="publicationEnd" disabled={publicationEndValue === 'never'} />
                </div>
            </div>

            <Alert variant="primary" className="mb-0">
                <>
                    {isBatch && publications.length > 1
                        ? t('publications.bulkActions.publishModal.publicationSummaryStartPlural', {
                              count: publications.length,
                          })
                        : t('publications.bulkActions.publishModal.publicationSummaryStart')}{' '}
                    {publicationStart()} {t('publications.bulkActions.publishModal.publishedAnd')}{' '}
                    {publicationEnd(isBatch && publications.length > 1)}.
                </>

                {values.scheduledAt && (
                    <p className="mt-3 mb-0">
                        <Trans
                            i18nKey="publications:publications.bulkActions.publishModal.customStartDateWarning"
                            components={{ bold: <strong /> }}
                        />
                    </p>
                )}
            </Alert>
        </div>
    );

    function publicationStart() {
        if (publicationStartValue === 'now' || !values.scheduledAt) {
            return (
                <Trans
                    i18nKey="publications:publications.bulkActions.publishModal.now"
                    components={{ bold: <strong /> }}
                />
            );
        }

        return (
            <Trans
                i18nKey="publications:publications.bulkActions.publishModal.customStartSummary"
                values={{
                    date: DateTime.fromISO(values.scheduledAt)
                        .setLocale(i18n.language)
                        .toLocaleString(DateTime.DATETIME_MED),
                }}
                components={{ bold: <strong /> }}
            />
        );
    }

    function publicationEnd(plural = false) {
        if (publicationEndValue === 'never' || !values.publicationEnd) {
            return (
                <Trans
                    i18nKey={`publications:publications.bulkActions.publishModal.${plural ? 'publicationSummaryEndPlural' : 'publicationSummaryEnd'}`}
                    components={{ bold: <strong /> }}
                />
            );
        }

        const endDate = DateTime.fromISO(values.publicationEnd)
            .setLocale(i18n.language)
            .toLocaleString(DateTime.DATETIME_MED);

        return (
            <Trans
                i18nKey="publications:publications.bulkActions.publishModal.customEndSummary"
                components={{ bold: <strong /> }}
                values={{
                    date: endDate,
                }}
            />
        );
    }
}

const PublishSchema = Yup.object().shape({
    publication: Yup.string(),
    scheduledAt: Yup.string().nullable().when('publicationStartValue', {
        is: 'custom',
        then: Yup.string().required(),
    }),
    publicationEnd: Yup.string().nullable().when('publicationEndValue', {
        is: 'custom',
        then: Yup.string().required(),
    }),
});
