import { Megaphone } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export function AuditableInCirculationIcon({ auditable, iconOnly = false, className = '' }) {
    const { t, i18n } = useTranslation();

    const inCirculation = isInCirculation();

    return (
        <div className="flex-center">
            <Megaphone
                data-uk-tooltip={t(
                    `publications:guard.tooltips.${inCirculation ? 'inCirculation' : 'notInCirculation'}`,
                )}
                size={14}
                className={cx('flex-shrink-0', className, {
                    'text-success': inCirculation,
                    'text-danger': !inCirculation,
                    'mr-2': !iconOnly,
                })}
            />

            {!iconOnly && (
                <div
                    className={cx('font-weight-bold', {
                        'text-success': inCirculation,
                        'text-danger': !inCirculation,
                    })}
                >
                    {t(inCirculation ? 'global:yes' : 'global:no')}
                </div>
            )}
        </div>
    );

    function isInCirculation() {
        if (!auditable.inCirculationSince) {
            return false;
        }

        const start = DateTime.fromISO(auditable.inCirculationSince).setLocale(i18n.language);
        const end = auditable.inCirculationEnd
            ? DateTime.fromISO(auditable.inCirculationEnd).setLocale(i18n.language)
            : undefined;
        const now = DateTime.now();

        if (now >= start && !end) {
            return true;
        }

        return now <= end;
    }
}
