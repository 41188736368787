import { useGetBaseVariant } from './useGetBaseVariant';
import { findDeep } from 'deepdash-es/standalone';

export function useGetVariant(id) {
    const baseVariant = useGetBaseVariant();

    if (!baseVariant) {
        return undefined;
    }

    const result = findDeep(
        [baseVariant],
        (value) => {
            return value.id === id;
        },
        {
            childrenPath: 'children',
        },
    );

    return result?.value;
}
