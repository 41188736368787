import { Accordion } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import React, { useContext } from 'react';
import Publication from './Publication';
import cx from 'classnames';
import { useHasPermission } from '../../../global/RestrictedContent';
import { Permissions } from '../../../../config/Constants';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useSelector } from 'react-redux';
import _ from 'lodash';

export default function GroupPublications({ folderId, publicationId, publications = [], hasMulti }) {
    const hasReadWritePermission = useHasPermission(Permissions.Publication['Write.All']);

    return (
        <Accordion defaultActiveKey={publicationId ? `publication-${publicationId}` : undefined}>
            <div className="data-table-items">
                {publications.map((publication, index) => (
                    <DraggablePublication
                        folderId={folderId}
                        publicationId={publicationId}
                        publication={publication}
                        index={index}
                        hasReadWritePermission={hasReadWritePermission}
                        eventKey={`publication-${publication.id}`}
                        hasMulti={hasMulti}
                        key={`publication-${publication.id}`}
                    />
                ))}
            </div>
        </Accordion>
    );
}

function DraggablePublication({ folderId, publicationId, publication, index, hasReadWritePermission, eventKey, hasMulti }) {
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const currentEventKey = useContext(AccordionContext);

    const isExpanded = currentEventKey === eventKey;
    const isFiltering = _.isInteger(documentId) || !_.isEmpty(search);
    const isDragDisabled = hasReadWritePermission === false || isExpanded || isFiltering;

    return (
        <Draggable draggableId={publication['@id']} index={index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => {
                return (
                    <div
                        id={`publication-item-${publication.id}`}
                        className={cx('data-table-item-wrapper', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Publication
                            folderId={folderId}
                            publicationId={publicationId}
                            publication={publication}
                            eventKey={eventKey}
                            isExpanded={isExpanded}
                            isDragDisabled={isDragDisabled}
                            hasMulti={hasMulti}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
}
