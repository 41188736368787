import { useParams } from 'react-router-dom';
import { translationApi, translationDocumentApi } from '../../../../../features/translations/translationApi';
import EntityTranslation from '../EntityTranslation';
import { TranslationContext } from '../Translate';
import { useContext, useEffect } from 'react';
import { setFilter } from '../../../../../features/translations/translationSlice';
import { useDispatch } from 'react-redux';
import { AreaBlocks } from './AreaBlocks';
import { useTranslation } from 'react-i18next';
import { useGetAreaData } from '../../../hooks/useGetAreaData';

export default function TranslateArea({ translationContainer, documentVariants }) {
    const { entityId } = useParams();
    const areaData = useGetAreaData(entityId, documentVariants.documentVariants.id);

    if (areaData === undefined) {
        return null;
    }

    return <AreaContent areaData={areaData} translationContainer={translationContainer} />;
}

function AreaContent({ areaData, translationContainer }) {
    const { area } = areaData;
    const { title } = area;
    const { entityType, language } = useParams();
    const translationContext = useContext(TranslationContext);
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');

    useEffect(() => {
        if (translationContext.title === '') {
            translationContext.setTitle(title);
        }
    }, [translationContext.title]);

    useEffect(() => {
        dispatch(setFilter({ key: 'entity', value: area }));
    }, [dispatch]);

    return (
        <div>
            <EntityTranslation
                content={area.title}
                languageIso={language}
                entityType="area"
                property="title"
                entityUri={`/api/area/${area.id}`}
                entityId={area.id}
                translationContainer={translationContainer}
                entity={{
                    title: t('translation.navbar.dashboard.translation.area.title'),
                }}
                handleTranslationMutation={handleTranslationMutation}
            />

            <AreaBlocks
                areaData={areaData}
                translationContainer={translationContainer}
                languageIso={language}
                handleTranslationMutation={handleTranslationMutation}
            />
        </div>
    );

    function handleTranslationMutation(data, contentHashMatch = true) {
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType,
                },
                { subscribe: false, forceRefetch: true },
            ),
        );

        if (data.unverified === false && !contentHashMatch) {
            dispatch(
                translationDocumentApi.util.invalidateTags([{ type: 'Area', id: area.id }]),
            );
        }
    }
}
