import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../features/publications/publicationsSlice';
import { FormModal, ModalFooter } from '../../global/FormModal';
import React from 'react';
import { Form as FForm, Formik } from 'formik';
import { FormFieldWithTitle } from '../helpers/FieldHelper';
import * as Yup from 'yup';
import Constants from '../../../config/Constants';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useCreatePreviewTokenMutation } from '../../../features/publications/publicationApi';

export function ExportChangesModal() {
    const { t } = useTranslation('publications');
    const dispatch = useDispatch();
    const { exportChanges } = useSelector((state) => state.publications.modals);
    const payload = exportChanges;
    const showModal = !!exportChanges;

    if (!showModal) {
        return null;
    }

    return (
        <FormModal show={true} onHide={handleClose} title={t('publication.dropdown.changesModal.title')}>
            <ModalContent publication={payload.publication} closeModal={handleClose} />
        </FormModal>
    );

    function handleClose() {
        dispatch(closeModal('exportChanges'));
    }
}

function ModalContent({ publication, closeModal }) {
    const { t } = useTranslation('publications');
    const [createPreviewToken] = useCreatePreviewTokenMutation();

    return (
        <Formik
            initialValues={{
                publication: publication['@id'],
                type: 'pdf',
                action: Constants.publicationToken.export_changes,
                since: '',
            }}
            enableReinitialize={true}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <FForm autoComplete="off">
                    <Modal.Body>
                        <div>
                            <FormFieldWithTitle
                                name="since"
                                label={t('publication.dropdown.changesModal.whatDateMessage')}
                                props={{
                                    required: true,
                                    type: 'datetime-local',
                                }}
                            />
                        </div>
                    </Modal.Body>

                    <ModalFooter
                        btnSubmitText={t('publication.dropdown.changesModal.btn.createDocument')}
                        isSubmitting={isSubmitting}
                        isValid={isValid}
                        dirty={dirty}
                        onHide={closeModal}
                    />
                </FForm>
            )}
        </Formik>
    );

    function handleSubmit(values) {
        createPreviewToken({
            ...values,
            since: DateTime.fromISO(values.since).toSeconds(),
        }).then(() => {
            closeModal();
        });
    }
}

const FormSchema = Yup.object().shape({
    since: Yup.string().required(),
});
