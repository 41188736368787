import { useSidebarContext } from '../hooks/useSidebarContext';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { Field, useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import { ArrowDown, ArrowUp, CaretDownFill } from 'react-bootstrap-icons';
import cx from 'classnames';
import HelperFunctions from '../../../pages/global/HelperFunctions';
import { LightOutlineButton } from '../../Buttons';
import { eachDeep, filterDeep } from 'deepdash-es/standalone';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { entityTypes } from 'pages/translation/config/Constants';
import { Checkbox, DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import Highlighter from 'react-highlight-words';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { TitlePrefixWithOptions } from './EditChapter';
import { useTranslation } from 'react-i18next';
import { useGetArea } from 'pages/documents_v2/hooks/useGetArea';

export function EditVariants() {
    const { documentId } = useSidebarContext();
    const documentBaseVariant = useGetBaseVariant(documentId, true);

    const topLevelGroups = useMemo(() => {
        return (
            documentBaseVariant?.children.filter((variant) => variant.variantGroup).map((variant) => variant.id) ?? []
        );
    }, [documentBaseVariant]);

    if (!documentBaseVariant) {
        return null;
    }

    return <EditVariantsInner documentBaseVariant={documentBaseVariant} topLevelGroups={topLevelGroups} />;
}

function EditVariantsInner({ documentBaseVariant, topLevelGroups }) {
    const { entity, entityType, documentId, areaId } = useSidebarContext();
    const { values, setFieldValue } = useFormikContext();
    const [expandedGroups, setExpandedGroups] = useState(topLevelGroups);
    const [searchValue, setSearchValue] = useState('');
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { t } = useTranslation('documents');

    const area = useGetArea(areaId, entityType !== entityTypes.BLOCK);

    const documentVariants = HelperFunctions.variantAsList(documentBaseVariant);

    const enabledVariants = useMemo(() => {
        if (entityType === entityTypes.AREA) {
            const section = document.sections.find((section) => section.id === entity.sectionId);

            return documentVariants
                .filter((_variant) => section.documentVariants.includes(_variant.id))
                .map((variant) => variant.id);
        }

        if (entityType === entityTypes.BLOCK) {
            if (!area) {
                return [];
            }

            return documentVariants
                .filter((_variant) => area.documentVariants.includes(_variant.id))
                .map((variant) => variant.id);
        }

        return documentVariants.map((variant) => variant.id);
    }, [entityType, document, area]);

    const baseVariant = useMemo(() => {
        return filterDeep(
            documentBaseVariant,
            (value) => {
                const variant = value;

                if (variant.variantGroup) {
                    return undefined;
                }

                return enabledVariants.includes(variant.id);
            },
            {
                childrenPath: 'children',
            },
        );
    }, [documentBaseVariant, enabledVariants]);

    const allGroupIds = useMemo(() => {
        if (!baseVariant) {
            return [];
        }

        const variants = [];

        eachDeep(
            baseVariant.children,
            (value) => {
                if (value.variantGroup) {
                    variants.push(value);
                }
            },
            {
                childrenPath: 'children',
            },
        );

        return variants.map((variant) => variant.id);
    }, [baseVariant]);

    const filteredVariant = useMemo(() => {
        if (searchValue === '') {
            return baseVariant;
        }

        const variantSearchFilter = _.lowerCase(searchValue);

        return filterDeep(
            baseVariant,
            (value) => {
                const variant = value;

                if (variant.variantGroup) {
                    return undefined;
                }

                return _.includes(_.lowerCase(variant.name + variant.prefix), variantSearchFilter);
            },
            {
                childrenPath: 'children',
            },
        );
    }, [baseVariant, searchValue]);

    const toggleGroup = (id) => {
        setExpandedGroups(_.xor(expandedGroups, [id]));
    };

    const showAllGroups = () => {
        setExpandedGroups(allGroupIds);
    };

    const hideAllGroups = () => {
        setExpandedGroups([]);
    };

    return (
        <>
            <VariantFilter
                showAllGroups={showAllGroups}
                hideAllGroups={hideAllGroups}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                hasGroups={allGroupIds.length > 0}
            />

            <div className="dr-side-container pt-4 px-3">
                <Container fluid>
                    <Row className="small mb-3">
                        <Col>
                            <div className="font-weight-bold mb-3">Variant</div>

                            <div className="flex-center">
                                <LightOutlineButton
                                    size="sm"
                                    className="mr-2"
                                    onClick={() => {
                                        const allVariants = HelperFunctions.variantAsList(baseVariant)
                                            .filter((variant) => !variant.variantGroup)
                                            .map((variant) => variant.id);

                                        setFieldValue('settings.documentVariants', allVariants.sort());
                                    }}
                                >
                                    {t('document.navbar.main.settingsSidebar.variants.btn.selectAll')}
                                </LightOutlineButton>
                                <LightOutlineButton
                                    size="sm"
                                    disabled={values.settings.documentVariants.length === 0}
                                    onClick={() => setFieldValue('settings.documentVariants', [baseVariant.id])}
                                >
                                    {t('document.navbar.main.settingsSidebar.variants.btn.unselectAll')}
                                </LightOutlineButton>
                            </div>
                        </Col>

                        {entityType === entityTypes.SECTION && restrictions?.canChangeCustomPrefix && (
                            <Col xs={4}>
                                <div className="font-weight-bold mb-3">
                                    {t('document.navbar.main.settingsSidebar.variants.prefix')}
                                </div>
                                <ClearPrefixesButton baseVariantId={baseVariant.id} property="titlePrefixes" />
                            </Col>
                        )}
                        {entityType === entityTypes.AREA && restrictions?.canChangeCustomPrefix && (
                            <Col xs={2}>
                                <div className="font-weight-bold mb-3">
                                    {t('document.navbar.main.settingsSidebar.variants.prefix')}
                                </div>
                                <ClearPrefixesButton baseVariantId={baseVariant.id} property="titlePrefixes" />
                            </Col>
                        )}
                        {entityType === entityTypes.AREA && <AreaColumns baseVariant={baseVariant} />}
                        {entityType === entityTypes.BLOCK && <BlockColumns baseVariant={baseVariant} />}
                    </Row>

                    {!filteredVariant && <div>{t('document.navbar.main.settingsSidebar.variants.noResult')}</div>}

                    {filteredVariant && (
                        <>
                            {filteredVariant.children.map((childVariant) => (
                                <VariantWrapper
                                    variant={childVariant}
                                    expandedGroups={expandedGroups}
                                    toggleGroup={toggleGroup}
                                    searchValue={searchValue}
                                    key={`variant-${childVariant.id}`}
                                />
                            ))}
                        </>
                    )}
                </Container>
            </div>
        </>
    );
}

function AreaColumns({ baseVariant }) {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { t } = useTranslation('documents');

    return (
        <>
            {restrictions?.canModifyCustomHeadingLevel && (
                <Col xs={2}>
                    <div className="font-weight-bold mb-3">
                        {t('document.navbar.main.settingsSidebar.variants.heading')}
                    </div>
                    <ClearPrefixesButton baseVariantId={baseVariant.id} property="headingLevels" />
                </Col>
            )}

            {restrictions?.canChangeCustomPosition && (
                <Col xs={2}>
                    <div className="font-weight-bold mb-3">
                        {t('document.navbar.main.settingsSidebar.variants.position')}
                    </div>
                    <ClearPrefixesButton baseVariantId={baseVariant.id} property="sortOrders" />
                </Col>
            )}

            {restrictions?.canForceChangeNewPdfPage && (
                <Col xs={2}>
                    <div className="font-weight-bold mb-3">
                        {t('document.navbar.main.settingsSidebar.variants.force')}
                    </div>
                    <ClearForceNewPageButton baseVariant={baseVariant} />
                </Col>
            )}
        </>
    );
}

function BlockColumns({ baseVariant }) {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { t } = useTranslation('documents');

    return (
        <>
            {restrictions?.canChangeForceBlockOnNewPageVariants && (
                <Col xs={4}>
                    <div className="font-weight-bold mb-3">
                        {t('document.navbar.main.settingsSidebar.variants.force')}
                    </div>
                    <ClearForceNewPageButton baseVariant={baseVariant} />
                </Col>
            )}
        </>
    );
}

function VariantFilter({ hideAllGroups, showAllGroups, searchValue, setSearchValue, hasGroups }) {
    const { t } = useTranslation('documents');

    return (
        <div className="mb-4">
            <div className="form-row">
                <div className="col-6">
                    <Form.Control
                        type="search"
                        placeholder={`${t('document.navbar.main.settingsSidebar.variants.selectTitle')}...`}
                        value={searchValue}
                        onChange={(event) => setSearchValue(event.target.value)}
                    />
                </div>
            </div>

            {hasGroups && (
                <div className="flex-center mt-3">
                    <LightOutlineButton size="sm" className="mr-2" onClick={() => showAllGroups()}>
                        <ArrowDown /> {t('document.navbar.main.settingsSidebar.variants.btn.showAll')}
                    </LightOutlineButton>
                    <LightOutlineButton size="sm" onClick={() => hideAllGroups()}>
                        <ArrowUp /> {t('document.navbar.main.settingsSidebar.variants.btn.hideAll')}
                    </LightOutlineButton>
                </div>
            )}
        </div>
    );
}

function VariantWrapper({ variant, expandedGroups, toggleGroup, searchValue }) {
    if (variant.variantGroup) {
        return (
            <Row>
                <Col className="mb-2">
                    <VariantGroup
                        variant={variant}
                        expandedGroups={expandedGroups}
                        toggleGroup={toggleGroup}
                        expanded={expandedGroups.includes(variant.id)}
                        searchValue={searchValue}
                    />
                </Col>
            </Row>
        );
    }

    return <Variant variant={variant} searchValue={searchValue} />;
}

function VariantGroup({ variant, toggleGroup, expanded, expandedGroups, searchValue }) {
    const { values, setFieldValue } = useFormikContext();

    const childVariants = variant.children;

    const nestedChildVariants = useMemo(() => {
        const variants = [];

        eachDeep(
            childVariants,
            (value) => {
                if (!value.variantGroup) {
                    variants.push(value);
                }
            },
            {
                childrenPath: 'children',
            },
        );

        return variants;
    }, [childVariants]);

    const allChecked = nestedChildVariants.every((childVariant) =>
        values.settings.documentVariants.includes(childVariant.id),
    );
    const allNotChecked = nestedChildVariants.every(
        (childVariant) => !values.settings.documentVariants.includes(childVariant.id),
    );
    const isIndeterminate = !allChecked && !allNotChecked;

    const handleChange = () => {
        const childIds = nestedChildVariants.map((childVariant) => childVariant.id);

        if (isIndeterminate || allNotChecked) {
            // Add all child variants
            const newVariants = [...values.settings.documentVariants, ...childIds];
            setFieldValue('settings.documentVariants', _.uniq(newVariants.sort()));
        } else {
            // Remove all child variants
            setFieldValue(
                'settings.documentVariants',
                values.settings.documentVariants.filter((v) => !childIds.includes(v)),
            );
        }
    };

    return (
        <>
            <div className="d-flex align-items-center pt-2 pb-3">
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id={`variant-${variant.id}`}
                        type="checkbox"
                        checked={allChecked}
                        onChange={handleChange}
                        ref={(el) => el && (el.indeterminate = isIndeterminate)}
                    />
                    <label className="custom-control-label" htmlFor={`variant-${variant.id}`} />
                </div>

                <div className="d-flex align-items-center cursor-pointer" onClick={() => toggleGroup(variant.id)}>
                    {variant.prefix && <div className="mr-1">{variant.prefix}</div>}
                    <div className="font-weight-bold">{variant.name}</div>

                    <CaretDownFill
                        size={16}
                        className={cx('ml-2', {
                            'rotate-180': !expanded,
                        })}
                    />
                </div>
            </div>

            <Collapse in={expanded}>
                <Row>
                    <Col>
                        <div className="pl-4">
                            {childVariants.map((childVariant) => (
                                <VariantWrapper
                                    variant={childVariant}
                                    expandedGroups={expandedGroups}
                                    toggleGroup={toggleGroup}
                                    searchValue={searchValue}
                                    key={`variant-${childVariant.id}`}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Collapse>
        </>
    );
}

function Variant({ variant, searchValue }) {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const { values, setFieldValue } = useFormikContext();
    const checked = values.settings.documentVariants.includes(variant.id);

    const handleChange = (e) => {
        const { checked, value } = e.target;
        const valueAsNumber = parseInt(value);

        if (checked) {
            setFieldValue(
                'settings.documentVariants',
                _.uniq([...values.settings.documentVariants, valueAsNumber].sort()),
            );
        } else {
            setFieldValue(
                'settings.documentVariants',
                values.settings.documentVariants.filter((v) => v !== valueAsNumber),
            );
        }
    };

    const handleVariantValueChange = (text, property) => {
        const path = `settings.properties.${property}`;
        const formValues = _.get(values, path, []);

        // Remove from formValues
        if (text === undefined || text === false || text === '') {
            const newFormValues = formValues.filter((value) => value.variantId !== variant.id);

            if (newFormValues.length === 0) {
                setFieldValue(path, undefined);
            } else {
                setFieldValue(
                    path,
                    formValues.filter((value) => value.variantId !== variant.id),
                );
            }

            return;
        }

        const newFormValues = HelperFunctions.upsert(
            formValues,
            {
                variantId: variant.id,
                text,
            },
            'variantId',
        );

        setFieldValue(path, newFormValues);
    };

    return (
        <Row className="mb-2">
            {restrictions?.canSeeVariantsSettings && (
                <>
                    <Col className="d-flex align-items-center">
                        <Checkbox
                            onChange={handleChange}
                            value={variant.id}
                            checked={checked}
                            id={`variant-${variant.id}`}
                            name="documentVariants"
                            disabled={restrictions?.canChangeVariants === false}
                            label={
                                <span className="small">
                                    {variant.prefix && (
                                        <Highlighter
                                            className="mr-1"
                                            highlightClassName="highlight-search"
                                            searchWords={searchValue.length > 1 ? [searchValue] : []}
                                            autoEscape={true}
                                            textToHighlight={variant.prefix}
                                        />
                                    )}

                                    <Highlighter
                                        className="font-weight-500"
                                        highlightClassName="highlight-search"
                                        searchWords={searchValue.length > 1 ? [searchValue] : []}
                                        autoEscape={true}
                                        textToHighlight={variant.name}
                                    />
                                </span>
                            }
                        />
                    </Col>

                    {entityType === entityTypes.SECTION && (
                        <>
                            {restrictions?.canChangeCustomPrefix && (
                                <>
                                    <Col xs={4}>
                                        <TitlePrefix
                                            variantId={variant.id}
                                            checked={checked}
                                            handleVariantValueChange={handleVariantValueChange}
                                        />
                                    </Col>
                                </>
                            )}
                        </>
                    )}

                    {entityType === entityTypes.AREA && (
                        <>
                            {restrictions?.canChangeCustomPrefix && (
                                <>
                                    <Col xs={2}>
                                        <TitlePrefix
                                            variantId={variant.id}
                                            checked={checked}
                                            handleVariantValueChange={handleVariantValueChange}
                                            document={document}
                                            restrictions={restrictions}
                                        />
                                    </Col>

                                    <AreaFields
                                        variant={variant}
                                        checked={checked}
                                        handleVariantValueChange={handleVariantValueChange}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {entityType === entityTypes.BLOCK && (
                        <BlockFields
                            variant={variant}
                            checked={checked}
                            handleVariantValueChange={handleVariantValueChange}
                        />
                    )}
                </>
            )}
        </Row>
    );
}

function useVariantValue(property, variantId) {
    const { values } = useFormikContext();

    const formValues = _.get(values.settings, `properties.${property}`, []);
    const titlePrefix = HelperFunctions.getByValue(formValues, 'variantId', variantId);

    return titlePrefix?.text ?? '';
}

function TitlePrefix({ variantId, checked, handleVariantValueChange, document, restrictions }) {
    const prefixValue = useVariantValue('titlePrefixes', variantId);

    if (document?.modelId && !_.isEmpty(restrictions?.prefixOptions)) {
        return (
            <TitlePrefixWithOptions
                prefixValue={prefixValue}
                prefixOptions={restrictions.prefixOptions}
                handleChange={(text) => handleVariantValueChange(text, 'titlePrefixes')}
            />
        );
    }

    return (
        <Form.Control
            value={prefixValue}
            onChange={(e) => {
                handleVariantValueChange(e.target.value, 'titlePrefixes');
            }}
            disabled={!checked}
        />
    );
}

function AreaFields({ variant, checked, handleVariantValueChange }) {
    const { entity, documentId, entityType } = useSidebarContext();
    const document = useGetDocument(documentId, true);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);
    const section = document?.sections.find((section) => section.id === entity.sectionId);

    const areas = useMemo(() => {
        return section?.areas.map((area) => {
            const areaHasVariant = area.documentVariants.includes(variant.id);

            return {
                label: area.title,
                value: area.id,
                isDisabled: !areaHasVariant || area.id === entity.id,
            };
        });
    }, [section]);

    return (
        <>
            {restrictions?.canModifyCustomHeadingLevel && (
                <HeadingLevel
                    variantId={variant.id}
                    checked={checked}
                    handleVariantValueChange={handleVariantValueChange}
                />
            )}

            {restrictions?.canChangeCustomPosition && (
                <SortOrder
                    variantId={variant.id}
                    checked={checked}
                    handleVariantValueChange={handleVariantValueChange}
                    areas={areas}
                />
            )}

            {restrictions?.canForceChangeNewPdfPage && (
                <ForceNewPageVariants
                    variantId={variant.id}
                    checked={checked}
                    handleVariantValueChange={handleVariantValueChange}
                />
            )}
        </>
    );
}

function BlockFields({ variant, checked, handleVariantValueChange }) {
    const { entity, entityType, documentId } = useSidebarContext();
    const document = useGetDocument(documentId);
    const restrictions = useEntityTemplateParameters(entity, entityType, document);

    return (
        <div>
            {restrictions?.canChangeForceBlockOnNewPageVariants && (
                <ForceNewPageVariants
                    variantId={variant.id}
                    checked={checked}
                    handleVariantValueChange={handleVariantValueChange}
                    colWidth={4}
                />
            )}
        </div>
    );
}

function HeadingLevel({ variantId, checked, handleVariantValueChange }) {
    const prefixValue = useVariantValue('headingLevels', variantId);

    return (
        <Col xs={2}>
            <DocRevSelect
                selectedValue={prefixValue}
                onChange={(selection) => handleVariantValueChange(selection?.value, 'headingLevels')}
                options={[
                    {
                        label: 'Kop 2',
                        value: '2',
                    },
                    {
                        label: 'Kop 3',
                        value: '3',
                    },
                    {
                        label: 'Kop 4',
                        value: '4',
                    },
                    {
                        label: 'Kop 5',
                        value: '5',
                    },
                ]}
                props={{
                    isClearable: true,
                    placeholder: '',
                    isDisabled: !checked,
                }}
            />
        </Col>
    );
}

function SortOrder({ variantId, checked, handleVariantValueChange, areas }) {
    const prefixValue = useVariantValue('sortOrders', variantId);
    const { t } = useTranslation('documents');
    return (
        <Col xs={2}>
            <div className="d-flex align-items-center">
                <div className="mr-2">{t('document.navbar.main.settingsSidebar.variants.after')}</div>

                <div className="flex-grow-1">
                    <DocRevSelect
                        selectedValue={prefixValue}
                        onChange={(selection) => handleVariantValueChange(selection?.value, 'sortOrders')}
                        options={areas}
                        props={{
                            isClearable: true,
                            placeholder: '',
                            isDisabled: !checked,
                        }}
                    />
                </div>
            </div>
        </Col>
    );
}

function ForceNewPageVariants({ variantId, checked, handleVariantValueChange, colWidth = 2 }) {
    const prefixValue = useVariantValue('forceNewPageVariants', variantId);
    const name = `force-new-page-variant-${variantId}`;

    return (
        <Col xs={colWidth}>
            <div className="col-form-label-switch">
                <Field
                    id={name}
                    name={name}
                    as={Form.Switch}
                    checked={!!prefixValue}
                    disabled={!checked}
                    onChange={(event) => handleVariantValueChange(event.target.checked, 'forceNewPageVariants')}
                />
            </div>
        </Col>
    );
}

function ClearPrefixesButton({ baseVariantId, property }) {
    const { values, setFieldValue } = useFormikContext();
    const path = `settings.properties.${property}`;

    const formValues = _.get(values, path, []);
    const titlePrefix = HelperFunctions.getByValue(formValues, 'variantId', baseVariantId);
    const { t } = useTranslation('documents');

    return (
        <LightOutlineButton
            disabled={formValues.length === 0}
            size="sm"
            onClick={() => {
                if (!titlePrefix) {
                    setFieldValue(path, undefined);
                } else {
                    setFieldValue(path, [titlePrefix]);
                }
            }}
        >
            {t('document.navbar.main.settingsSidebar.variants.btn.clear')}
        </LightOutlineButton>
    );
}

function ClearForceNewPageButton({ baseVariant, property = 'forceNewPageVariants' }) {
    const { values, setFieldValue } = useFormikContext();
    const path = `settings.properties.${property}`;
    const { t } = useTranslation('documents');

    const formValues = _.get(values, path, []);

    return (
        <div className="flex-center">
            <LightOutlineButton
                size="sm"
                className="mr-2"
                onClick={() => {
                    const allVariants = HelperFunctions.variantAsList(baseVariant)
                        .filter((variant) => variant.parentId !== null && !variant.variantGroup)
                        .map((variant) => ({
                            variantId: variant.id,
                            text: true,
                        }));

                    setFieldValue(path, allVariants);
                }}
            >
                {t('document.navbar.main.settingsSidebar.variants.btn.selectAll')}
            </LightOutlineButton>

            <LightOutlineButton
                disabled={formValues.length === 0}
                size="sm"
                onClick={() => {
                    setFieldValue(path, undefined);
                }}
            >
                {t('document.navbar.main.settingsSidebar.variants.btn.unselectAll')}
            </LightOutlineButton>
        </div>
    );
}
