import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { LoadingSpinner as LoadingSpinner2 } from '../global/Spinner';
import { WarningButton } from 'components/Buttons';
import { useGetDocument } from '../documents_v2/hooks/useGetDocument';
import { useGetBaseVariant } from '../documents_v2/hooks/useGetBaseVariant';
import { useGetDocumentVariants } from '../documents_v2/hooks/useGetDocumentVariants';
import { useImmer } from 'use-immer';
import { useAddTagMutation, useUpdateTagMutation } from 'features/documents/documents';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { TagRow } from './edit/TagRow';
import { useTranslation } from 'react-i18next';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useGetDocumentTagData } from 'pages/tags/hooks/useGetDocumentTag';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

export function TagModal({ documentId, filterByVariants = [] }) {
    const [editTag] = useQueryParam('editTag', NumberParam);
    const [editTagVariants, setEditTagVariants] = useQueryParam('variants', BooleanParam);
    const document = useGetDocument();
    const baseVariant = useGetBaseVariant();
    const documentVariants = useGetDocumentVariants();
    const activeOrganisation = useActiveOrganisation();
    const { t } = useTranslation('documents');

    const [initialItems, setInitialItems] = useState('');
    const [updateTag] = useUpdateTagMutation();
    const [addTag] = useAddTagMutation();

    const documentTagData = useGetDocumentTagData(documentId, editTag);
    const { isLoading, tagData } = documentTagData;

    const [state, setState] = useImmer({
        formData: {
            tagItems: [],
        },
        saveInProgress: false,
        showAllVariants: false,
    });

    const { formData, saveInProgress, showAllVariants } = state;

    const dirty = useMemo(() => {
        if (formData?.tagItems) {
            const currentValues = JSON.stringify(formData.tagItems).replace(':""', ':null');

            return currentValues !== initialItems;
        }

        return false;
    }, [formData?.tagItems]);

    useEffect(() => {
        if (tagData && !isLoading) {
            setState((draft) => {
                draft.formData = tagData;
            });

            setInitialItems(JSON.stringify(tagData.tagItems).replace(':""', ':null'));
        }
    }, [documentTagData]);

    if (!formData) {
        return null;
    }

    const { tagItems = [], tagValue, isOverride } = formData;

    const baseItem = document
        ? tagItems.find((_item) => _item.documentVariantId === (baseVariant?.id ?? ''))
        : undefined;

    const defaultItem = useMemo(() => {
        return {
            ...tagValue,
            ...baseItem,
        };
    }, [tagValue, baseItem]);

    return (
        <Modal show={true} onHide={closeModal} scrollable={true} className="modal-fullscreen">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('document.navbar.tags.editModal.editTag')}: {formData.name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="tag-variant-form" autoComplete="off">
                    <DocumentTagForm
                        documentId={documentId}
                        formData={formData}
                        handleFormChange={handleFormChange}
                        toggleVariantItem={toggleVariantItem}
                        copyItem={copyItem}
                        defaultItem={defaultItem}
                        filterByVariants={filterByVariants}
                        showAllVariants={showAllVariants}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <LoadingSpinner2 isSubmitting={saveInProgress} />
                <Button variant="secondary" onClick={closeModal}>
                    {t('btn.cancel')}
                </Button>
                <WarningButton
                    disabled={saveInProgress || !dirty || isLoading}
                    form="tag-variant-form"
                    onClick={handleFormSubmit}
                >
                    {t('btn.save')}
                </WarningButton>
            </Modal.Footer>
        </Modal>
    );

    function handleFormSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        setState((draft) => {
            draft.saveInProgress = true;
        });

        // Create new Tag for Document Tag?
        if (documentId && !state.formData.documentId) {
            handleCreateTag({
                name: state.formData.name,
                isOverride: true,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            }).then(({ data }) => {
                setState((draft) => {
                    draft.saveInProgress = false;
                    draft.formData = data;
                });
            });

            return;
        }

        updateTag({
            organisationId: activeOrganisation,
            id: state.formData.id,
            body: {
                name: state.formData.name,
                description: state.formData.description,
                tagValue: state.formData.tagValue,
                tagItems: state.formData.tagItems
                    ? state.formData.tagItems.map((tagItem) => {
                          return {
                              documentVariant: tagItem.documentVariantId,
                              text: tagItem.text,
                              url: tagItem.url,
                              tooltip: tagItem.tooltip,
                              crossReference: tagItem.crossReference,
                          };
                      })
                    : undefined,
            },
        }).then(() => {
            setState((draft) => {
                draft.saveInProgress = false;
            });
        });
    }

    function copyItem(item) {
        const newTagItems = [item];

        documentVariants.forEach((_variant) => {
            if (_variant.parentId !== null && _variant.enabled === true && _variant.variantGroup === false) {
                const currentItem = tagItems.find((_item) => _item.documentVariantId === _variant.id);
                const itemHasOverride = isOverride && currentItem !== undefined;

                if (isOverride === false || itemHasOverride === true) {
                    newTagItems.push({
                        documentVariantId: _variant.id,
                        text: item.text,
                        url: item.url,
                        tooltip: item.tooltip,
                        crossReference: item.crossReference,
                    });
                } else {
                    if (currentItem) {
                        newTagItems.push(currentItem);
                    }
                }
            }
        });

        updateTagItems(newTagItems);
    }

    function updateTagItems(newItems) {
        setState((draft) => {
            draft.formData.tagItems = newItems;
        });
    }

    function toggleVariantItem(checked, variant) {
        const currentItem = tagItems.find((_item) => _item.documentVariantId === variant.id);

        if (checked === true && currentItem === undefined) {
            // Item not defined yet
            updateTagItems(
                tagItems.concat([
                    {
                        documentVariantId: variant.id,
                        text: defaultItem?.text ?? '',
                        url: defaultItem?.url ?? '',
                        tooltip: defaultItem?.tooltip ?? '',
                        crossReference: defaultItem?.crossReference ?? '',
                    },
                ]),
            );

            return;
        }

        // Unchecked
        if (checked === false && currentItem !== undefined) {
            // Remove existing item
            updateTagItems(tagItems.filter((item) => item.documentVariantId !== variant.id));
        }
    }

    function handleFormChange(value, propName, variantId) {
        if (variantId) {
            // Store value for specific Variant
            const index = state.formData.tagItems
                ? state.formData.tagItems.findIndex((item) => item.documentVariantId === variantId)
                : -1;

            if (index === -1) {
                // Create new Variant Tag
                const newVariantTag = {
                    documentVariantId: variantId,
                    text: '',
                    url: '',
                    tooltip: '',
                    crossReference: '',
                };
                newVariantTag[propName] = value;

                setState((draft) => {
                    if (draft.formData.tagItems) {
                        draft.formData.tagItems.push(newVariantTag);
                    } else {
                        draft.formData.tagItems = [newVariantTag];
                    }
                });
            } else {
                // Update existing Variant
                setState((draft) => {
                    draft.formData.tagItems[index][propName] = value;
                });
            }
        } else {
            setState((draft) => {
                if (
                    propName === 'text' ||
                    propName === 'url' ||
                    propName === 'tooltip' ||
                    propName === 'crossReference'
                ) {
                    if (draft.formData.tagValue) {
                        draft.formData.tagValue[propName] = value;
                    } else {
                        draft.formData.tagValue = {
                            [propName]: value,
                        };
                    }
                } else {
                    draft.formData[propName] = value;
                }
            });
        }
    }

    function handleCreateTag(formData) {
        if (documentId) {
            formData['document'] = parseInt(documentId);
        }

        return addTag({ organisationId: activeOrganisation, body: formData });
    }

    function closeModal() {
        setEditTagVariants(undefined)
    }
}

function DocumentTagForm({
    documentId,
    formData,
    handleFormChange,
    toggleVariantItem,
    copyItem,
    defaultItem,
    filterByVariants,
    showAllVariants,
}) {
    const { t } = useTranslation('documents');
    const documentVariants = useGetDocumentVariants();
    const baseVariant = useGetBaseVariant();
    const { tagItems = [], isOverride } = formData;

    return (
        <Container fluid>
            <Row className="no-gutters font-weight-bold text-secondary mb-3">
                <Col sm={2}>Variant</Col>
                <Col className="pr-3">
                    <div className="d-flex align-items-baseline">
                        {t('document.navbar.tags.editModal.modal.text')}
                        <QuestionCircle
                            data-uk-tooltip="Je kan in het tekst-veld inhoud **bold** of *cursief* maken."
                            size={14}
                            className="text-secondary ml-1"
                        />
                    </div>
                </Col>
                <Col className="pr-3">{t('document.navbar.tags.editModal.modal.link')}</Col>
                <Col className="pr-3">Tooltip</Col>
                <Col>{t('document.navbar.tags.editModal.modal.reference')}</Col>
            </Row>

            {documentVariants
                .filter((variant) => {
                    if (!variant.enabled) {
                        return false;
                    }

                    if (filterByVariants.length === 0 || showAllVariants) {
                        return true;
                    }

                    if (variant.id === baseVariant.id) {
                        return false;
                    }

                    return filterByVariants.includes(variant.id);
                })
                .map((variant, index) => (
                    <TagRow
                        item={tagItems.find((_item) => _item.documentVariantId === variant.id)}
                        variant={variant}
                        isOverride={isOverride}
                        handleFormChange={handleFormChange}
                        defaultItem={defaultItem}
                        documentId={documentId}
                        toggleVariantItem={toggleVariantItem}
                        copyItem={copyItem}
                        baseVariantId={baseVariant.id}
                        key={`tag-${index}-variant-${variant.id}`}
                    />
                ))}
        </Container>
    );
}
