import { useGetAreaQuery } from '../../../features/translations/translationApi';

export function useGetAreaData(areaId, documentVariantId) {
    const { areaData } = useGetAreaQuery(
        {
            id: areaId,
            variantId: documentVariantId,
        },
        {
            selectFromResult: ({ data }) => ({
                areaData: data,
            }),
        },
    );

    return areaData;
}
