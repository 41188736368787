import { useGetDocumentSectionsEditsQuery } from 'features/documents/documents';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetSectionEdits(documentId, sectionId) {
    const selectSection = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, sectionId) => sectionId,
            (data, sectionId) => {
                if (data) {
                    return data.find((section) => section.id === sectionId);
                }

                return undefined;
            },
        );
    }, []);

    const { sectionEdits } = useGetDocumentSectionsEditsQuery(documentId, {
        selectFromResult: (result) => ({
            sectionEdits: selectSection(result, sectionId),
        }),
    });

    return sectionEdits;
}
