import { generatePath, NavLink, useParams } from 'react-router-dom';
import {
    translationApi,
    useGetEntityReference,
    useUpdateEntityReferenceMutation,
    useUpdateTranslationKeyMutation,
} from 'features/translations/translationApi';
import DocumentTranslate from './document/DocumentTranslate';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import { BASE_PATH, EDIT_PATH, VIEW_PATH } from 'scenes/Translations';
import { X } from 'react-bootstrap-icons';
import Tooltip from '../../../global/Tooltip';
import EditContextWrapper, { EditContext } from '../view/EditContextWrapper';
import ContextSidebar, { ToggleSidebarButton } from '../ContextSidebar';
import TranslationContainerSidebar from '../edit/TranslationContainerSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'features/translations/translationSlice';
import { entityTypes, translationContainerTypes, views } from '../../config/Constants';
import { ReimbursementTranslate } from './reimbursement/ReimbursementTranslate';
import EntityStatus from '../edit/EntityStatus';
import { NavBreadCrumbs } from 'pages/Navbar';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import EmptyContentMessage from '../edit/EmptyContentMessage';
import { getLanguageName } from '../../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';
import { useGetTranslationLanguage } from 'pages/translation/hooks/useGetTranslationLanguage';

export const TranslationContext = React.createContext({
    selectedItem: '',
    setSelectedItem: () => {},
    title: '',
    setTitle: () => {},
    visibleEntities: [],
    resetVisibleEntities: () => {},
    toggleVisibility: () => {},
    sidebarFiltersAsString: '',
});

export default function Translate() {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { sidebarFilters } = useSelector((state) => state.translation);
    const [showEmptyMessage, setShowEmptyMessage] = useState(false);
    const translationContainer = useGetTranslationContainer();
    const translationLanguage = useGetTranslationLanguage();

    useEffect(() => {
        dispatch(setFilter({ key: 'entity', value: {} }));
    }, [dispatch]);

    // State variables for the TranslationContext
    const [selectedItem, setSelectedItem] = useState('');
    const [title, setTitle] = useState('');
    const [visibleEntities, setVisibleEntities] = useState([]);

    // Set default values using the initial state values from store
    const filtersAsString = JSON.stringify({
        showDisabledForTranslation: sidebarFilters.showDisabledForTranslation,
        variantId: sidebarFilters.variantId,
        showUntranslated: sidebarFilters.showUntranslated,
        showUnverified: sidebarFilters.showUnverified,
        showVerified: sidebarFilters.showVerified,
        search: sidebarFilters.search,
    });
    const [sidebarFiltersAsString, setSidebarFiltersAsString] = useState(filtersAsString);

    useEffect(() => {
        // Store string value of the filters to allow the child component to refresh the visibility status
        if (sidebarFiltersAsString !== filtersAsString) {
            setSidebarFiltersAsString(filtersAsString);
            setShowEmptyMessage(true);
        }
    }, [filtersAsString]);

    const toggleVisibility = (uri) => {
        if (visibleEntities.includes(uri) === false) {
            setVisibleEntities((visibleEntities) => [...visibleEntities, uri]);
        }
    };

    if (!translationContainer || !translationLanguage) {
        return null;
    }

    return (
        <TranslationContext.Provider
            value={{
                selectedItem,
                setSelectedItem,
                title,
                setTitle,
                visibleEntities,
                resetVisibleEntities: () => {
                    setVisibleEntities([]);
                },
                toggleVisibility,
                sidebarFiltersAsString,
            }}
        >
            <EditContextWrapper>
                <TranslateNav
                    title={title}
                    translationLanguage={translationLanguage}
                    translationContainer={translationContainer}
                />

                <div className="content-static-body">
                    <div className="d-flex align-items-stretch h-100">
                        <div className="flex-grow-1 overflow-auto pt-4">
                            <Container>
                                <Row>
                                    <Col sm={3}>
                                        <div className="card dr-card mb-3 sticky-top">
                                            <div className="card-body dr-card-body px-4">
                                                <TranslationContainerSidebar
                                                    translationContainer={translationContainer}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="card dr-card mb-3">
                                            <div className="card-body dr-card-body">
                                                <div className="card-title mb-5">
                                                    {title}

                                                    {visibleEntities.length > 0 && (
                                                        <span className="small text-secondary ml-2">
                                                            {visibleEntities.length}{' '}
                                                            {visibleEntities.length > 1
                                                                ? t(
                                                                      'translation.navbar.dashboard.document.translate.blocks'
                                                                  )
                                                                : t(
                                                                      'translation.navbar.dashboard.document.translate.block'
                                                                  )}
                                                        </span>
                                                    )}
                                                </div>

                                                <TranslateEntityContent
                                                    translationContainer={translationContainer}
                                                    translationLanguage={translationLanguage}
                                                />

                                                {visibleEntities.length === 0 && showEmptyMessage && (
                                                    <EmptyContentMessage />
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <ContextSidebar />
                    </div>
                </div>
            </EditContextWrapper>
        </TranslationContext.Provider>
    );
}

function TranslateNav({ title, translationLanguage, translationContainer }) {
    const { entityId, entityType, translationContainerId } = useParams();
    const entityUri = `/api/${entityType}/${entityId}`;

    const entityReference = useGetEntityReference(translationContainer['@id'], entityType, entityUri);
    const { languageIso } = translationLanguage;
    const languageName = getLanguageName(languageIso);

    const showEntityStatus =
        entityReference !== undefined &&
        translationContainer['@type'] === translationContainerTypes.DOCUMENT &&
        entityType !== entityTypes.TAG_ITEM &&
        entityType !== entityTypes.VARIANT_PACKAGE;

    return (
        <nav
            className="bg-white border-bottom py-3 px-4 navbar navbar-expand navbar-light fixed-top"
            style={{ height: 73 }}
        >
            <EntityTitle
                title={title}
                languageName={languageName}
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
                entityType={entityType}
            />

            <div className="d-flex flex-shrink-0 align-items-center ml-auto">
                {showEntityStatus && (
                    <div className="mr-3">
                        <EntityStatus
                            entityReference={entityReference}
                            languageIso={languageIso}
                            tooltipPlacement="bottom"
                        />
                    </div>
                )}

                <RestrictedTranslationContent
                    id={translationContainer.id}
                    roles={[
                        Constants.translationContainerTeamRoles.manager,
                        Constants.translationContainerTeamRoles.finalEditor,
                        Constants.translationContainerTeamRoles.editor,
                    ]}
                >
                    <EntityActionsButton languageIso={languageIso} translationContainer={translationContainer} />
                </RestrictedTranslationContent>

                <ToggleSidebarButton />

                <div
                    style={{
                        borderRight: '1px solid #e5e5e5',
                        height: 32,
                        margin: '0 12px 0 8px',
                    }}
                >
                    &nbsp;
                </div>

                <Tooltip tooltip="Sluiten" placement="bottom">
                    <Button
                        as={NavLink}
                        variant="link"
                        className="text-secondary p-0"
                        to={generatePath(EDIT_PATH, {
                            translationContainerId,
                            translationLanguageId: translationLanguage.id,
                            view:
                                entityType === entityTypes.TAG
                                    ? views.TAGS
                                    : entityType === entityTypes.VARIANT_PACKAGE
                                    ? views.PRODUCTS
                                    : undefined,
                        })}
                    >
                        <X size={26} />
                    </Button>
                </Tooltip>
            </div>
        </nav>
    );
}

function EntityActionsButton({ languageIso, translationContainer }) {
    const [updateEntityReference] = useUpdateEntityReferenceMutation();
    const [updateTranslationKey] = useUpdateTranslationKeyMutation();
    const { selectedEntities, setSelectedEntities } = useContext(EditContext);
    const { setSelectedItem } = useContext(TranslationContext);
    const dispatch = useDispatch();
    const { entityType } = useParams();
    const { t } = useTranslation('translations');

    const numberOfSelectedEntities = selectedEntities.length;

    const handleExclusionUpdate = (enabled = true) => {
        Promise.all(
            selectedEntities.map(async ({ entityReference, translationKey }) => {
                return new Promise(async (resolve) => {
                    await updateTranslationKey({
                        uri: translationKey['@id'],
                        body: {
                            enabledForTranslation: {
                                ...translationKey.enabledForTranslation,
                                [languageIso]: enabled,
                            },
                        },
                    });

                    if (entityReference.entityType === 'block') {
                        await updateEntityReference({
                            uri: entityReference['@id'],
                            body: {
                                enabledForTranslation: {
                                    ...entityReference.enabledForTranslation,
                                    [languageIso]: enabled,
                                },
                            },
                        });
                    }

                    resolve();
                });
            })
        ).then(() => {
            setSelectedEntities([]);

            dispatch(
                translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                    {
                        uri: translationContainer['@id'],
                        entityType,
                    },
                    { subscribe: false, forceRefetch: true }
                )
            );
        });
    };

    const handleAction = (eventKey) => {
        switch (eventKey) {
            case 'include':
                handleExclusionUpdate(true);
                break;
            case 'exclude':
                setSelectedItem('');
                handleExclusionUpdate(false);
                break;
        }
    };

    return (
        <>
            {numberOfSelectedEntities > 0 && (
                <div className="mr-2 font-weight-bold small">
                    {numberOfSelectedEntities} {t('translation.navbar.dashboard.document.actions.selected')}
                </div>
            )}

            <DropdownButton
                id="dropdown-bulk"
                title={t('translation.navbar.dashboard.document.actions.actions')}
                disabled={numberOfSelectedEntities === 0}
                onSelect={handleAction}
                className="mr-3"
            >
                <Dropdown.Item eventKey="include">
                    {t('translation.navbar.dashboard.document.actions.enableTranslation')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="exclude">
                    {t('translation.navbar.dashboard.document.actions.disableTranslation')}
                </Dropdown.Item>
            </DropdownButton>
        </>
    );
}

function EntityTitle({ languageName, title, translationContainer, translationLanguage, entityType }) {
    const { t } = useTranslation('translations');

    return (
        <div
            className="d-flex flex-wrap align-items-center text-primary"
            style={{
                fontSize: '1.2rem',
            }}
        >
            <NavBreadCrumbs
                pages={[
                    {
                        title: t('breadcrumb'),
                        url: BASE_PATH,
                    },
                    {
                        title: translationContainer.name,
                        url: generatePath(VIEW_PATH, {
                            translationContainerId: translationContainer.id,
                        }),
                    },
                    {
                        title: languageName,
                        url: generatePath(EDIT_PATH, {
                            translationContainerId: translationContainer.id,
                            translationLanguageId: translationLanguage.id,
                            view:
                                entityType === entityTypes.TAG
                                    ? views.TAGS
                                    : entityType === entityTypes.VARIANT_PACKAGE
                                    ? views.PRODUCTS
                                    : undefined,
                        }),
                    },
                    {
                        title,
                    },
                ]}
            />
        </div>
    );
}

function TranslateEntityContent({ translationContainer, translationLanguage }) {
    if (translationContainer['@type'] === translationContainerTypes.DOCUMENT) {
        return (
            <DocumentTranslate translationContainer={translationContainer} translationLanguage={translationLanguage} />
        );
    }

    if (translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT) {
        return (
            <ReimbursementTranslate
                translationContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return null;
}
