import { TableRowSkeleton } from '../../../../components/Skeleton';
import { ArrowUpShort, BoxArrowUpRight, ClockHistory } from 'react-bootstrap-icons';
import { useGetPreviewTokens } from '../../hooks/useGetPreviewTokens';
import { Badge } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useGetUserFullName } from '../../../../hooks/useGetUserFullName';
import Constants from '../../../../config/Constants';
import getEnv from '../../../../config/Env';
import { PreviewActions } from './PreviewActions';
import { LinkButton } from '../../../../components/Buttons';

export function PublicationPreviews({ publication, isExpanded = false }) {
    const { t } = useTranslation('publications');
    const { previewTokens, isLoading } = useGetPreviewTokens(publication['@id'], !isExpanded);

    return (
        <div className="mb-3">
            <div className="font-weight-bolder ml-1 mb-2">{t('publication.titles.previews')}</div>

            <div className="dr-container px-3 pt-3 pb-2">
                <PreviewActions publication={publication} />

                <table className="table dr-table">
                    <thead>
                        <tr>
                            <th>
                                # <ArrowUpShort />
                            </th>
                            <th>{t('publication.columnTitles.view')}</th>
                            <th>{t('publication.columnTitles.type')}</th>
                            <th>{t('publication.columnTitles.kind')}</th>
                            <th>{t('publication.columnTitles.status')}</th>
                            <th>{t('publication.columnTitles.createdBy')}</th>
                            <th>{t('publication.columnTitles.expires')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <TableRowSkeleton rowCount={1} cellCount={7} />
                        ) : (
                            <>
                                {previewTokens.length > 0 ? (
                                    <>
                                        {previewTokens.map((token, index) => (
                                            <PreviewToken
                                                token={token}
                                                number={previewTokens.length - index}
                                                key={`token-${token.id}`}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td colSpan={7} className="text-muted">
                                                {t('publication.columnTitles.noPreviews')}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function PreviewToken({ token, number }) {
    const { t, i18n } = useTranslation('publications');
    const expiresAt = DateTime.fromISO(token.expiresAt);
    const formattedExpiresAt = expiresAt.setLocale(i18n.language).toRelative({ unit: 'minutes' });
    const creatorName = useGetUserFullName(token.createdBy, false);

    const url = `${getEnv('PUBLICATION_API_URL')}/api/publication_preview_tokens/preview/${token.token}`;

    return (
        <tr>
            <td className="text-secondary">#{number}</td>
            <td>
                <LinkButton
                    className="flex-center text-left text-darkBlueAlt font-weight-bolder p-0"
                    style={{ fontSize: 12 }}
                    disabled={token.status !== Constants.publicationPreviewTokenStatus.processed}
                    onClick={() => window.open(url, '_blank')}
                >
                    {t('publication.btn.openPreview')}
                    <BoxArrowUpRight size={12} className="ml-1 mr-0 flex-shrink-0" />
                </LinkButton>
            </td>
            <td>{getTokenType()}</td>
            <td>
                {token.action === Constants.publicationToken.export_changes && (
                    <div>{t('publication.columnData.changes')}</div>
                )}
                {token.action === Constants.publicationToken.preview && (
                    <div>{t('publication.columnData.preview')}</div>
                )}
            </td>
            <td>{renderStatusBadge()}</td>
            <td>{creatorName}</td>
            <td>
                <div className="flex-center">
                    <ClockHistory className="mr-1" />
                    {formattedExpiresAt}
                </div>
            </td>
        </tr>
    );

    function renderStatusBadge() {
        const statusTranslation = {
            [Constants.publicationPreviewTokenStatus.processed]: t('publication.columnData.badges.ready'),
            [Constants.publicationPreviewTokenStatus.pending]: t('publication.columnData.badges.inQueue'),
            [Constants.publicationPreviewTokenStatus.processing]: `${t('publication.columnData.badges.preparing')}...`,
            [Constants.publicationPreviewTokenStatus.failed]: t('publication.columnData.badges.failed'),
        };

        return (
            <Badge variant={getStatusVariant()} className="m-0">
                {statusTranslation[token.status] ?? t('publication.columnData.unknown')}
            </Badge>
        );
    }

    function getStatusVariant() {
        switch (token.status) {
            case Constants.publicationPreviewTokenStatus.processed:
                return 'success';
            case Constants.publicationPreviewTokenStatus.pending:
                return 'secondary';
            case Constants.publicationPreviewTokenStatus.processing:
                return 'warning';
            case Constants.publicationPreviewTokenStatus.failed:
                return 'danger';
            default:
                return 'muted';
        }
    }

    function getTokenType() {
        switch (token.type) {
            case 'html':
                return 'Html';
            case 'pdf':
                return 'Pdf';
            case 'print':
                return 'Print Pdf';
            case 'xml':
                return 'XML';
        }

        return 'Unknown type';
    }
}
