import { combineReducers } from 'redux';
import securityReducer from '../features/security/securitySlice';
import documentReducer from '../features/documents/documentsSlice';
import documentV2Reducer from '../features/documents/documentsV2Slice';
import reimbursementReducer from '../features/reimbursements/reimbursementSlice';
import appReducer from '../features/app/appSlice';
import operationsListReducer from '../features/operationsList/operationsListSlice';
import taskReducer from '../features/operationsList/taskSlice';
import publicationReducer from '../features/publications/publicationsSlice';
import translationReducer from '../features/translations/translationSlice';
import mediaLibraryReducer from '../features/mediaLibrary/mediaLibrarySlice';
import {
    translationApi,
    translationDocumentApi,
    translationReimbursementApi,
} from 'features/translations/translationApi';
import { templatesApi } from 'features/templates/templatesApi';
import { reimbursementApi } from 'features/reimbursements/reimbursements';
import { metaApi } from 'features/metadata/metadata';
import { documentApi } from 'features/documents/documents';
import { ipidApi } from 'features/documents/ipidApi';
import { publicationApi } from 'features/publications/publicationApi';
import { aiApi } from 'features/openai/openAIApi';
import { commentsApi } from 'features/comments/commentApi';
import { authApi } from 'features/security/authApi';
import { mediaApi } from 'features/mediaLibrary/mediaApi';
import { guardApi } from 'features/publications/guardApi';

export default combineReducers({
    app: appReducer,
    security: securityReducer,
    documents: documentReducer,
    documents_v2: documentV2Reducer,
    reimbursements: reimbursementReducer,
    [reimbursementApi.reducerPath]: reimbursementApi.reducer,
    [metaApi.reducerPath]: metaApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [ipidApi.reducerPath]: ipidApi.reducer,
    operations: operationsListReducer,
    tasks: taskReducer,
    publications: publicationReducer,
    mediaLibrary: mediaLibraryReducer,
    translation: translationReducer,
    [translationApi.reducerPath]: translationApi.reducer,
    [translationDocumentApi.reducerPath]: translationDocumentApi.reducer,
    [translationReimbursementApi.reducerPath]: translationReimbursementApi.reducer,
    [publicationApi.reducerPath]: publicationApi.reducer,
    [guardApi.reducerPath]: guardApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [aiApi.reducerPath]: aiApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
});
