import { useSelector } from 'react-redux';
import { Button, Collapse } from 'react-bootstrap';
import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'react-bootstrap-icons';
import cx from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import GroupPublications from './Groups';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { GroupHeader } from './Publications';
import { AiFillCaretRight } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useGetPublications } from '../../hooks/useGetPublications';

export function RootPublications({ folderId, publicationId }) {
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const publications = useGetPublications(null, documentId, search);

    if (publications.length === 0) {
        return null;
    }

    const hasMulti = publications.some((publication) => publication.multiVersions);

    return (
        <Droppable isDropDisabled droppableId="main">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group data-table-group-droppable dr-container', {
                        'dragging-over': snapshot.isDraggingOver,
                    })}
                >
                    <div className="bg-white mb-2 pb-1">
                        <GroupHeader groupUri={null} hasMulti={hasMulti} />

                        <GroupPublications
                            folderId={folderId}
                            publicationId={publicationId}
                            publications={publications}
                            hasMulti={hasMulti}
                        />

                        {provided.placeholder}
                    </div>
                </div>
            )}
        </Droppable>
    );
}

export function RootLevelGroup({ folderId, publicationId, group, level, handleMove, index, isLast }) {
    const [open, setOpen] = useState(true);
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const { publications, isLoading } = useGetPublications(group['@id'], documentId, search, true);
    const { t } = useTranslation('publications');

    const { children = [] } = group;

    if (search !== '' && publications.length === 0 && level > 0) {
        return null;
    }

    return (
        <Droppable droppableId={group['@id']} isDropDisabled={level === 0}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={cx('data-table-group', 'data-table-group-level-' + level, {
                        'dragging-over': snapshot.isDraggingOver && group.parent !== null,
                        'data-table-group-expanded': open,
                        'dr-container mb-4 pb-3': level === 1,
                    })}
                >
                    <div className="data-table-group-header d-flex align-items-center">
                        {level === 0 ? (
                            <>
                                <div className="cursor-pointer mr-2" onClick={toggleGroup}>
                                    {group.name}
                                </div>

                                <AiFillCaretRight size={14} className="cursor-pointer chevron" onClick={toggleGroup} />
                                <Button
                                    variant="icon"
                                    size="sm"
                                    className="text-secondary btn-icon-only ml-2 px-1 py-0"
                                    data-uk-tooltip={t('publications.tooltip.moveUp')}
                                    onClick={() => handleMove('up', group, index)}
                                >
                                    <ArrowUp size={12} />
                                </Button>
                                <Button
                                    variant="icon"
                                    size="sm"
                                    className="text-secondary btn-icon-only px-1 py-0"
                                    data-uk-tooltip={t('publications.tooltip.moveDown')}
                                    onClick={() => {
                                        if (!isLast) handleMove('down', group, index);
                                    }}
                                >
                                    <ArrowDown size={12} />
                                </Button>
                            </>
                        ) : (
                            <div style={{ fontWeight: 500 }}>{group.name}</div>
                        )}
                    </div>

                    <Collapse in={open}>
                        <div>
                            {children.length > 0 && (
                                <>
                                    {children.map((_group, groupIndex) => (
                                        <RootLevelGroup
                                            publicationId={publicationId}
                                            group={_group}
                                            level={level + 1}
                                            index={groupIndex}
                                            handleMove={handleMove}
                                            isLast={children.length - 1 === groupIndex}
                                            key={`group-${_group.id}`}
                                        />
                                    ))}
                                </>
                            )}

                            {level > 0 && (
                                <>
                                    <LevelOnePublications
                                        publicationId={publicationId}
                                        publications={publications}
                                        folderId={folderId}
                                        isLoading={isLoading}
                                        group={group}
                                    />
                                    {publications.length > 0 && <>{provided.placeholder}</>}
                                </>
                            )}
                        </div>
                    </Collapse>
                </div>
            )}
        </Droppable>
    );

    function toggleGroup() {
        setOpen(!open);
    }
}

function LevelOnePublications({ group, isLoading, publications, folderId, publicationId }) {
    const { t } = useTranslation('publications');

    const hasMulti = publications.some((publication) => publication.multiVersions);

    return (
        <>
            <GroupHeader groupUri={group['@id']} hasMulti={hasMulti} />

            {publications.length === 0 ? (
                <div className="p-3">
                    {isLoading ? (
                        <LoadingSpinner inline size="sm" />
                    ) : (
                        <div className="small text-secondary" style={{ lineHeight: '21px', marginLeft: '33px' }}>
                            <div style={{ marginLeft: '30px' }}>{t('publications.noPublications')}</div>
                        </div>
                    )}
                </div>
            ) : (
                <GroupPublications
                    folderId={folderId}
                    publicationId={publicationId}
                    publications={publications}
                    hasMulti={hasMulti}
                />
            )}
        </>
    );
}
