import { useGetTranslationLanguagesQuery } from 'features/translations/translationApi';
import { useParams } from 'react-router-dom';

const emptyTranslationLanguages = [];

export function useGetTranslationLanguages() {
    const { translationContainerId } = useParams();

    return useGetTranslationLanguagesQuery(translationContainerId, {
        selectFromResult: ({ data, isLoading }) => ({
            translationLanguages: data ?? emptyTranslationLanguages,
            isLoading,
        }),
    });
}
