import { useMemo, useState } from 'react';
import { createOpListEntry } from 'features/operationsList/operationsListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Form, Modal, SplitButton } from 'react-bootstrap';
import { FormModal, ModalFooter } from '../../global/FormModal';
import { Field, Form as FForm, Formik } from 'formik';
import Select from 'react-select';
import Constants from '../../../config/Constants';
import * as Yup from 'yup';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import _ from 'lodash';
import { useGetOpLists } from '../hooks/useGetOpLists';
import { useGetDocumentVariants } from '../../documents_v2/hooks/useGetDocumentVariants';
import {
    documentApi,
    useGetAreaRevisionOperationsVariantGroupsQuery,
    useUpdateAreasMutation,
} from 'features/documents/documents';
import { useGetBaseVariant } from '../../documents_v2/hooks/useGetBaseVariant';
import { useTranslation } from 'react-i18next';
import { useGetArea } from 'pages/documents_v2/hooks/useGetArea';

export function AddToOpListBtn({ document, areaId }) {
    const { t } = useTranslation('changelist');
    const dispatch = useDispatch();
    const opLists = useGetOpLists();
    const [showOpListModal, setShowOpListModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [updateAreas] = useUpdateAreasMutation();

    const area = useGetArea(areaId);

    const isLinkedToOpList = useMemo(() => {
        return opLists.some((opList) => opList.documentIds.includes(document.id));
    }, [opLists, document]);

    if (!isLinkedToOpList || !area) {
        return null;
    }

    const { hasChanges } = area;

    return (
        <>
            <SplitButton
                variant="info"
                id={`addToOplist-btn-${area.id}`}
                title={t('changelist.editArea.place')}
                onClick={() => setShowOpListModal(true)}
                className="ml-3 mr-3"
            >
                {hasChanges && (
                    <Dropdown.Item
                        onClick={() => {
                            updateAreas({
                                documentId: document.id,
                                body: [
                                    {
                                        id: area.id,
                                        hasChanges: false,
                                    },
                                ],
                            }).then(() => {
                                // Invalidate cache
                                dispatch(documentApi.util.invalidateTags([{ type: 'Area', id: areaId }]));
                            });
                        }}
                    >
                        {t('changelist.editArea.dropdown.markArticle')}
                    </Dropdown.Item>
                )}
                {!hasChanges && (
                    <Dropdown.Item
                        onClick={() => {
                            updateAreas({
                                documentId: document.id,
                                body: [
                                    {
                                        id: area.id,
                                        hasChanges: true,
                                    },
                                ],
                            }).then(() => {
                                // Invalidate cache
                                dispatch(documentApi.util.invalidateTags([{ type: 'Area', id: areaId }]));
                            });
                        }}
                    >
                        {t('changelist.editArea.dropdown.markArticleAsModified')}
                    </Dropdown.Item>
                )}
            </SplitButton>

            {hasChanges && (
                <span className="text-warning mr-4">
                    <ExclamationTriangleFill
                        style={{ fontSize: '1.4rem' }}
                        data-uk-tooltip={t('changelist.editArea.tooltip.changes')}
                    />
                </span>
            )}

            {showOpListModal && (
                <AddToOpListModal
                    area={area}
                    close={(result) => {
                        setShowOpListModal(false);

                        if (result && result.hasOwnProperty('payload')) {
                            setConfirmationModal(Object.values(result.payload.opEntries)[0]);
                        }
                    }}
                    document={document}
                    show={true}
                    revisionId={document.activeRevisionId}
                />
            )}

            {confirmationModal !== false && (
                <ConfirmationModal close={() => setConfirmationModal(false)} opEntry={confirmationModal} />
            )}
        </>
    );
}

function AddToOpListModal({ area, close, document, revisionId, show }) {
    const dispatch = useDispatch();
    const { activeOrganisation } = useSelector((state) => state.security);
    const opLists = useGetOpLists();
    const documentVariants = useGetDocumentVariants();
    const baseVariant = useGetBaseVariant();
    const [error, setError] = useState(false);
    const { t } = useTranslation('changelist');

    const { operationVariantGroups } = useGetAreaRevisionOperationsVariantGroupsQuery(
        { areaId: area.id, revisionId },
        {
            selectFromResult: ({ data }) => ({
                operationVariantGroups: data,
            }),
        },
    );

    if (error) {
        return (
            <FormModal show={show} onHide={close} title={error.title}>
                <div className="small px-3 py-4">{error.description}</div>
            </FormModal>
        );
    }

    const handleSubmit = (values, { setSubmitting }) => {
        let variantIds =
            '5' !== values.variantGroup
                ? values.variantIds
                : values.variantIds.filter((_variant) => !_variant.variantGroup).map((_variant) => _variant.value);

        const baseVarId = baseVariant.id;
        if (!variantIds.includes(baseVarId)) {
            variantIds = variantIds.concat([baseVariant.id]);
        }

        const formData = {
            ...values,
            variantIds,
        };

        dispatch(createOpListEntry({ formData })).then((result) => {
            setSubmitting(false);

            // Invalidate cache
            dispatch(documentApi.util.invalidateTags([{ type: 'Area', id: area.id }]));

            // If noEntryLabel error has been returned, show error message
            if (_.get(result, 'error.name') === 'OpEntry-NoOpEntryLabels') {
                setError({
                    title: t('changelist.editArea.failed'),
                    description: t('changelist.editArea.noCorresponding'),
                });
                return;
            }

            close(result);
        });
    };

    const opListOptions = opLists
        .filter((_opList) => _opList.documentIds.includes(document.id))
        .map((_opList) => {
            return {
                value: _opList['@id'],
                label: _opList.name,
            };
        });

    const typeOfEditOptions = Object.keys(Constants.opEntry.typeOfEdits).map((_type) => {
        return {
            value: _type,
            label: t('changelist.view.opEntry.typeOfEdits.' + _type),
        };
    });

    // Group 1 contains the currently linked variants and the ones removed/unlinked in this revision
    const allAllowedVariantIds = _.get(operationVariantGroups, '1', []);
    const variantOptions = variantAsList(
        documentVariants.filter((_variant) => _variant.parentId === null || _variant.parentId === baseVariant?.id),
        allAllowedVariantIds,
    );

    return (
        <FormModal show={show} onHide={close} title={t('changelist.editArea.place')}>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    opList: opListOptions.length === 1 ? opListOptions[0].value : '',
                    typeOfEdit: typeOfEditOptions[0]['value'],
                    typesOfEdit: [],
                    variantGroup: '1',
                    variantIds: _.get(operationVariantGroups, '1', []),
                    organisationId: activeOrganisation,
                    documentId: document.id,
                    revisionId,
                    areaId: area.id,
                    areaTitle: area.title,
                    areaIsNew: area.new,
                }}
                validationSchema={NewOpEntrySchema}
                onSubmit={handleSubmit}
                validateOnMount
            >
                {({ setFieldValue, isSubmitting, values, isValid }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label htmlFor="opList">{t('changelist.editArea.list')}</Form.Label>
                                <Field
                                    as={Select}
                                    id="opList"
                                    name="opList"
                                    placeholder={`${t('changelist.editArea.titleSelect')}...`}
                                    options={opListOptions}
                                    value={opListOptions.find((_option) => _option.value === values.opList)}
                                    onChange={(selectedOptions) => {
                                        setFieldValue('opList', selectedOptions?.value ?? []);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="typesOfEdit">{t('changelist.editArea.typesChange')}</Form.Label>
                                <Field
                                    as={Select}
                                    isMulti
                                    id="typesOfEdit"
                                    name="typesOfEdit"
                                    placeholder={`${t('changelist.editArea.titleSelect')}...`}
                                    options={typeOfEditOptions}
                                    value={typeOfEditOptions.filter((_option) =>
                                        values.typesOfEdit.includes(_option.value),
                                    )}
                                    onChange={(selectedOptions) => {
                                        setFieldValue(
                                            'typesOfEdit',
                                            selectedOptions?.map((selectedOption) => selectedOption.value) ?? [],
                                        );
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label htmlFor="explanation">
                                    {t('changelist.editArea.additionalNotes')}
                                </Form.Label>
                                <Field
                                    as={'textarea'}
                                    id="explanation"
                                    name="explanation"
                                    className="form-control"
                                    rows={4}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{t('changelist.editArea.forWhichVariants')}</Form.Label>
                                <div
                                    onChange={(e) => {
                                        const group = e.target.value;

                                        // Set the variantIds from the selected group
                                        setFieldValue('variantGroup', group);
                                        setFieldValue('variantIds', _.get(operationVariantGroups, group, []));
                                    }}
                                >
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="variantGroupSelection"
                                            id="variantGroupSelection1"
                                            value={1}
                                            defaultChecked={true}
                                        />
                                        <label className="form-check-label" htmlFor="variantGroupSelection1">
                                            {t('changelist.editArea.allDeletedVariants')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="variantGroupSelection"
                                            id="variantGroupSelection2"
                                            value={2}
                                        />
                                        <label className="form-check-label" htmlFor="variantGroupSelection2">
                                            {t('changelist.editArea.deletedOnly')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="variantGroupSelection"
                                            id="variantGroupSelection3"
                                            value={3}
                                        />
                                        <label className="form-check-label" htmlFor="variantGroupSelection3">
                                            {t('changelist.editArea.currentVariants')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="variantGroupSelection"
                                            id="variantGroupSelection4"
                                            value={4}
                                        />
                                        <label className="form-check-label" htmlFor="variantGroupSelection4">
                                            {t('changelist.editArea.newVariants')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="variantGroupSelection"
                                            id="variantGroupSelection5"
                                            value={5}
                                        />
                                        <label className="form-check-label" htmlFor="variantGroupSelection5">
                                            {t('changelist.editArea.selfSelect')}
                                        </label>
                                    </div>
                                </div>
                            </Form.Group>
                            {'5' === values.variantGroup && (
                                <Form.Group>
                                    <Form.Label htmlFor="variantIds">{t('changelist.editArea.variants')}</Form.Label>
                                    <Field
                                        isMulti
                                        as={Select}
                                        id="variantIds"
                                        name="variantIds"
                                        placeholder="Selecteer..."
                                        options={variantOptions}
                                        value={values.variantIds}
                                        onChange={(selectedOptions) => {
                                            setFieldValue('variantIds', selectedOptions ?? []);
                                        }}
                                    />
                                </Form.Group>
                            )}
                        </Modal.Body>
                        <ModalFooter isSubmitting={isSubmitting} isValid={isValid} onHide={close} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );
}

const getLocalVariants = (variants, enabledVariants = []) => {
    return variants
        .filter((_variant) => _variant.enabled)
        .filter((_variant) => {
            // Show all
            if (enabledVariants.length === 0) {
                return true;
            }

            return _variant.variantGroup || enabledVariants.includes(_variant.id);
        });
};

export const variantAsList = (variants, enabledVariants = [], results = []) => {
    const localVariants = getLocalVariants(variants, enabledVariants);

    localVariants.forEach((_variant) => {
        if (_variant.variantGroup) {
            const childVariants = getLocalVariants(_variant.children, enabledVariants).filter(
                (_childVariant) => !_childVariant.variantGroup,
            );
            const childVariantGroups = getLocalVariants(_variant.children, enabledVariants).filter(
                (_childVariant) => _childVariant.variantGroup,
            );

            if (childVariants.length > 0) {
                results.push({
                    value: _variant.id,
                    label: _variant.name,
                    options: childVariants.map((_childVariant) => {
                        return {
                            value: _childVariant.id,
                            label: (_childVariant.prefix ? '' + _childVariant.prefix + ' - ' : '') + _childVariant.name,
                        };
                    }),
                });
            }

            if (childVariantGroups.length > 0) {
                variantAsList(childVariantGroups, enabledVariants, results);
            }
        } else {
            results.push({
                value: _variant.id,
                label:
                    (_variant.prefix ? _variant.prefix + ' - ' : '') +
                    (_variant.parentId === null ? 'Basisvariant' : _variant.name),
            });
        }
    });

    return results;
};

function ConfirmationModal({ opEntry, close }) {
    if (!opEntry || !opEntry.hasOwnProperty('entryNumber')) {
        return null;
    }
    const { t } = useTranslation('changelist');

    return (
        <Modal show={true} onHide={close}>
            <Modal.Body>
                {t('changelist.editArea.articleSuccessful')}
                {''}
                <span className="font-weight-bold">#{opEntry.entryNumber}</span> {t('changelist.editArea.received')}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={close}>
                    {t('btn.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const NewOpEntrySchema = Yup.object().shape({
    opList: Yup.string().required(),
    typeOfEdit: Yup.string().required(),
    typesOfEdit: Yup.array().min(1),
    variantIds: Yup.array().min(1),
    organisationId: Yup.number().required(),
    documentId: Yup.number().required(),
    revisionId: Yup.number().required(),
    areaId: Yup.number().required(),
    areaTitle: Yup.string().required(),
});
