import {
    useUpdateEntityReferenceMutation,
    useUpdateTranslationKeyMutation,
} from '../../../../features/translations/translationApi';
import { useContext } from 'react';
import { EditContext } from '../view/EditContextWrapper';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function EntityActionsButton({ languageIso }) {
    const [updateEntityReference] = useUpdateEntityReferenceMutation();
    const [updateTranslationKey] = useUpdateTranslationKeyMutation();
    const { selectedEntities, setSelectedEntities } = useContext(EditContext);
    const { t } = useTranslation('translations');

    const numberOfSelectedEntities = selectedEntities.length;

    const handleExclusionUpdate = (enabled = true) => {
        Promise.all(
            selectedEntities.map(({ entityReference, translationKey }) => {
                if (translationKey !== undefined) {
                    updateTranslationKey({
                        uri: translationKey['@id'],
                        body: {
                            enabledForTranslation: {
                                ...translationKey.enabledForTranslation,
                                [languageIso]: enabled,
                            },
                        },
                    });
                } else {
                    updateEntityReference({
                        uri: entityReference['@id'],
                        body: {
                            enabledForTranslation: {
                                ...entityReference.enabledForTranslation,
                                [languageIso]: enabled,
                            },
                        },
                    });
                }
            })
        ).then(() => {
            setSelectedEntities([]);
        });
    };

    const handleAction = (eventKey) => {
        switch (eventKey) {
            case 'include':
                handleExclusionUpdate(true);
                break;
            case 'exclude':
                handleExclusionUpdate(false);
                break;
        }
    };

    return (
        <>
            {numberOfSelectedEntities > 0 && (
                <div className="mr-2 font-weight-bold small">
                    {numberOfSelectedEntities} {t('translation.navbar.dashboard.document.actions.selected')}
                </div>
            )}

            <DropdownButton
                id="dropdown-bulk"
                title={t('translation.navbar.dashboard.document.actions.actions')}
                disabled={numberOfSelectedEntities === 0}
                onSelect={handleAction}
                className="mr-2"
            >
                <Dropdown.Item eventKey="include">
                    {t('translation.navbar.dashboard.document.actions.enableTranslation')}
                </Dropdown.Item>
                <Dropdown.Item eventKey="exclude">
                    {t('translation.navbar.dashboard.document.actions.disableTranslation')}
                </Dropdown.Item>
            </DropdownButton>
        </>
    );
}
