import MainContentNav from '../../Navbar';
import Filters from './index/Filters';
import { Button, Col, Container, Dropdown, Row, SplitButton } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import NewTranslationModal from '../modals/NewTranslationModal';
import { translationApi } from 'features/translations/translationApi';
import LoadingSpinner from '../../global/LoadingSpinner';
import { generatePath, NavLink, useHistory } from 'react-router-dom';
import { GearWideConnected } from 'react-bootstrap-icons';
import { VIEW_PATH } from 'scenes/Translations';
import { teamRoles, translationContainerTypes, translationContainerTypesValues } from '../config/Constants';
import { useDispatch, useSelector } from 'react-redux';
import RestrictedTranslationContent, { useGetCurrentTranslationUser } from '../RestrictedTranslationContent';
import { getLanguageName } from '../helpers/LanguageHelper';
import { DuplicateModal } from '../modals/DuplicateModal';
import { useHasPermission } from '../../global/RestrictedContent';
import Constants, { Permissions } from '../../../config/Constants';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainers } from 'pages/translation/hooks/useGetTranslationContainers';
import { useGetDocument } from 'pages/translation/hooks/useGetDocument';
import { useGetCategory } from 'pages/translation/hooks/useGetCategory';
import { ActionLinkButton } from 'components/Buttons';

export default function Index() {
    const { t } = useTranslation('translations');
    const [showNewTranslationModal, setShowNewTranslationModal] = useState(false);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);

    return (
        <>
            <MainContentNav title={t('breadcrumb')} />

            <div className="content-static-body">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto" style={{ marginTop: 68 }}>
                        <div className="pt-4 pb-3">
                            <IndexContent setShowDuplicateModal={setShowDuplicateModal} />
                        </div>
                    </div>
                </div>
            </div>

            <Filters setShowNewTranslationModal={setShowNewTranslationModal} />

            <NewTranslationModal
                showModal={showNewTranslationModal}
                handleClose={() => setShowNewTranslationModal(false)}
            />

            <DuplicateModal
                showModal={!!showDuplicateModal}
                translationContainer={showDuplicateModal}
                handleClose={() => setShowDuplicateModal(false)}
            />
        </>
    );
}

function IndexContent({ setShowDuplicateModal }) {
    const { search } = useSelector((state) => state.translation.sidebarFilters);
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');
    const { translationContainers, isLoading } = useGetTranslationContainers(search);

    const hasDuplicatingItems =
        translationContainers.filter((_container) => _container.cloneTaskId !== null).length > 0;

    useEffect(() => {
        const interval = setInterval(() => {
            if (hasDuplicatingItems) {
                // Refresh every 5 seconds when there are duplicating containers
                dispatch({
                    type: `${translationApi.reducerPath}/invalidateTags`,
                    payload: [
                        {
                            type: 'TranslationContainer',
                            id: 'LIST',
                        },
                    ],
                });
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [hasDuplicatingItems]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className="data-table data-table-translations">
                        <div className="dr-container mb-2">
                            <div className="data-table-header d-flex align-items-center pl-3 py-3">
                                <div className="item-col-title d-flex align-items-center">{t('columnTitles.name')}</div>

                                <div className="item-col-type d-flex align-items-center">{t('columnTitles.type')}</div>

                                <div className="item-col-name d-flex align-items-center">{t('columnTitles.docRe')}</div>

                                <div className="item-col-languages d-flex align-items-center">
                                    {t('columnTitles.languages')}
                                </div>

                                <div className="item-col-role d-flex align-items-center">{t('columnTitles.role')}</div>

                                <div className="item-col-actions">&nbsp;</div>
                            </div>

                            {translationContainers.map((_container) => (
                                <TranslationContainer
                                    container={_container}
                                    setShowDuplicateModal={setShowDuplicateModal}
                                    key={`container-${_container.id}`}
                                />
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

function TranslationContainer({ container, setShowDuplicateModal }) {
    const currentTranslationUser = useGetCurrentTranslationUser(container.id);
    const { t } = useTranslation('translations');
    const history = useHistory();

    if (currentTranslationUser === undefined) {
        return null;
    }

    const { name, translationLanguages, cloneTaskId } = container;
    const { role } = currentTranslationUser;
    const containerLanguages = translationLanguages
        .map((_language) => _language.languageIso)
        .map((_languageIso) => getLanguageName(_languageIso))
        .sort();

    return (
        <div className="data-table-item">
            <div className="data-table-item-header d-flex align-items-stretch p-3">
                <div className="item-col-title item-col-title-content d-flex align-items-center">
                    <div className="field-title">
                        <ActionLinkButton
                            onClick={() => {
                                history.push(
                                    generatePath(VIEW_PATH, {
                                        translationContainerId: container.id,
                                    }),
                                );
                            }}
                            className="font-weight-bold"
                        >
                            {name}
                        </ActionLinkButton>

                        {cloneTaskId !== null && (
                            <div className="d-flex align-items-center mt-1">
                                <GearWideConnected size={14} className="icon-spin text-warning mr-1" />
                                <div className="text-warning small">Bezig met dupliceren</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="item-col-type item-col-type-content d-flex align-items-center">
                    {translationContainerTypesValues[container['@type']] ?? t('errorMessage.unknownType')}
                </div>
                <div className="item-col-name item-col-name-content d-flex align-items-center">
                    <ContainerEntityName container={container} />
                </div>
                <div className="item-col-languages item-col-title-languages d-flex align-items-center">
                    {containerLanguages.join(', ')}
                </div>
                <div className="item-col-role item-col-role-content d-flex align-items-center">
                    {t(teamRoles[role])}
                </div>
                <div className="item-col-actions item-col-actions-content d-flex align-items-center">
                    <TranslationContainerActions container={container} setShowDuplicateModal={setShowDuplicateModal} />
                </div>
            </div>
        </div>
    );
}

function TranslationContainerActions({ container, setShowDuplicateModal }) {
    const history = useHistory();
    const hasCreatePermission = useHasPermission(Permissions.Translation['Create']);
    const { t } = useTranslation('translations');

    const { id } = container;

    if (!hasCreatePermission) {
        return (
            <Button
                as={NavLink}
                variant="primary"
                to={generatePath(VIEW_PATH, {
                    translationContainerId: id,
                })}
            >
                {t('btn.open')}
            </Button>
        );
    }

    return (
        <>
            <RestrictedTranslationContent
                id={id}
                roles={[
                    Constants.translationContainerTeamRoles.spectator,
                    Constants.translationContainerTeamRoles.editor,
                    Constants.translationContainerTeamRoles.finalEditor,
                ]}
            >
                <Button
                    as={NavLink}
                    variant="primary"
                    to={generatePath(VIEW_PATH, {
                        translationContainerId: id,
                    })}
                >
                    {t('btn.open')}
                </Button>
            </RestrictedTranslationContent>

            <RestrictedTranslationContent id={id} roles={[Constants.translationContainerTeamRoles.manager]}>
                <SplitButton
                    id={`${id}-btn`}
                    title={t('btn.open')}
                    variant="primary"
                    menuAlign="right"
                    onClick={() => {
                        history.push(
                            generatePath(VIEW_PATH, {
                                translationContainerId: id,
                            }),
                        );
                    }}
                >
                    <Dropdown.Item onClick={handleDuplicate}>Dupliceren&hellip;</Dropdown.Item>
                </SplitButton>
            </RestrictedTranslationContent>
        </>
    );

    function handleDuplicate(event) {
        event.preventDefault();
        setShowDuplicateModal(container);
    }
}

function ContainerEntityName({ container }) {
    if (container['@type'] === translationContainerTypes.DOCUMENT) {
        return <DocumentContainerName container={container} />;
    }

    if (container['@type'] === translationContainerTypes.REIMBURSEMENT) {
        return <ReimbursementContainerName container={container} />;
    }

    return null;
}

function DocumentContainerName({ container }) {
    const document = useGetDocument(container);

    return <>{document?.name}</>;
}

function ReimbursementContainerName({ container }) {
    const category = useGetCategory(container);

    return <>{category?.name}</>;
}
