import { SettingOverrideIcon } from 'pages/documents_v2/views/view/AreaTitleRow';
import { CaretDownFill, CaretRightFill, Pencil } from 'react-bootstrap-icons';
import cx from 'classnames/bind';
import CustomIcons from '../../../global/CustomIcons';
import Constants from '../../../../config/Constants';
import DocumentHelper from '../../../global/DocumentHelper';
import React from 'react';
import SettingsSidebarButton from '../../../../components/SettingsSidebar/SettingsSidebarButton';
import { useDispatch, useSelector } from 'react-redux';
import { set } from 'features/app/appSlice';
import { entityTypes } from 'pages/translation/config/Constants';
import { idToUri } from 'pages/global/UriHelper';
import { useTranslation } from 'react-i18next';
import {
    useDeleteSectionMutation,
    useSyncSectionsMutation,
    useUpdateSectionsMutation,
} from 'features/documents/documents';
import { useGetTemplate } from 'pages/documents_v2/hooks/useGetTemplate';
import HelperFunctions from '../../../global/HelperFunctions';
import { useGetBaseVariant } from 'pages/documents_v2/hooks/useGetBaseVariant';
import { useEntityTemplateParameters } from 'pages/documents_v2/hooks/useEntityTemplateParameters';
import { useGetDocument } from 'pages/documents_v2/hooks/useGetDocument';
import { useGetSectionEdits } from 'pages/documents_v2/hooks/useGetSectionEdits';
import { useGetSectionLabelEntities } from 'pages/documents_v2/hooks/useGetSectionLabelEntities';

export default function SectionTitleRow({ section, expanded, toggleAuditTrail }) {
    const document = useGetDocument();
    const documentLevelRestrictions = useEntityTemplateParameters(document, entityTypes.DOCUMENT, document);
    const restrictions = useEntityTemplateParameters(section, entityTypes.SECTION, document);
    const entityLabelEntities = useGetSectionLabelEntities(document.id, section.id);
    const [syncSections] = useSyncSectionsMutation();
    const dispatch = useDispatch();
    const { t } = useTranslation('documents');
    const [updateSections] = useUpdateSectionsMutation();
    const [deleteSection] = useDeleteSectionMutation();
    const baseVariant = useGetBaseVariant();
    const template = useGetTemplate(document?.modelId, document?.modelSource) ?? [];
    const { sidebarFilters } = useSelector((state) => state.documents);

    const { showHeading, showEdits, showDeletedChapters, showLabels, showNumbering } = sidebarFilters;

    if (!showDeletedChapters && section.scheduledForDeletion) {
        return null;
    }

    const { currentUserRole } = document;
    const { areas = [] } = section;

    const showSectionOptions =
        document.status !== Constants.documentStatus.reviewFinished &&
        currentUserRole !== Constants.userDocumentRole.spectator &&
        section.deletedAt === null;

    // Has prefix or auto numbering?
    const autoNumbering = section.exportProperties.useAutoNumbering ?? false;
    const titlePrefix = DocumentHelper.getPrefix(section, baseVariant.id);

    const canOnlyUseAsWhole = documentLevelRestrictions?.composition
        ? documentLevelRestrictions?.composition?.includes('canOnlyUseAsWhole')
        : true;

    return (
        <div className="section-row d-flex mb-2 py-1">
            <div
                className={cx('d-flex align-items-center', {
                    'uk-text-muted': section.scheduledForDeletion,
                })}
            >
                {titlePrefix && (
                    <div className="uk-text-primary uk-margin-small-right uk-text-xsmall">{titlePrefix}</div>
                )}

                <div className="uk-text-primary">{section.title}</div>

                {expanded ? (
                    <CaretDownFill className="ml-3" size={14} />
                ) : (
                    <CaretRightFill className="ml-3" size={14} />
                )}
                {section.deletedAt === null ? (
                    <>
                        {section.scheduledForDeletion ? (
                            <div className="uk-margin-small-left uk-text-xsmall uk-text-danger">
                                {t('document.navbar.main.tooltips.chapterDeletion')}
                            </div>
                        ) : (
                            <>
                                {section.new && (
                                    <div className="uk-margin-small-left uk-text-xsmall uk-text-warning">
                                        {t('document.navbar.main.tooltips.chapterNew')}
                                    </div>
                                )}
                                {section.titleEdited && (
                                    <div className="uk-margin-small-left uk-text-xsmall uk-text-warning">
                                        {t('document.navbar.main.tooltips.chapterTitleEdited')}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <div className="uk-margin-small-left uk-text-xsmall uk-text-danger">
                        {t('document.navbar.main.tooltips.chapterRemoved')}
                    </div>
                )}

                {showHeading && (
                    <div className="uk-label uk-margin-small-left uk-text-xsmall uk-background-muted uk-text-muted">
                        <span>H1</span>
                    </div>
                )}

                {showNumbering && (
                    <SettingOverrideIcon
                        enabled={autoNumbering}
                        tooltip={t('document.navbar.main.tooltips.numbering')}
                        icon="numbering"
                        size={20}
                        handleSettingsIconClick={handleSettingsIconClick}
                    />
                )}
                {showLabels && (
                    <SettingOverrideIcon
                        enabled={entityLabelEntities.length > 0}
                        tooltip={t('document.navbar.main.tooltips.hasLabels')}
                        icon="labels"
                        size={20}
                        handleSettingsIconClick={handleSettingsIconClick}
                    />
                )}

                {showEdits && <SectionEdits sectionId={section.id} documentId={document.id} />}

                {showSectionOptions && (
                    <div
                        className="section-options d-flex flex-shrink-0 align-items-center uk-animation-fade uk-animation-fast uk-margin-left"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {section.scheduledForDeletion && (
                            <button
                                className="uk-button uk-button-icon"
                                onClick={handleRestoreSection}
                                data-uk-tooltip={t('document.navbar.main.tooltips.chapterRestore')}
                            >
                                <CustomIcons className="uk-text-success" icon="undo" />
                            </button>
                        )}

                        {documentLevelRestrictions.canMoveChapters && (
                            <button
                                className="uk-button"
                                onClick={() => moveSection('up')}
                                data-uk-tooltip={t('document.navbar.main.tooltips.chapterUp')}
                                uk-icon="icon: arrow-up; ratio: 1.1"
                            />
                        )}

                        {documentLevelRestrictions.canMoveChapters && (
                            <button
                                className="uk-button"
                                onClick={() => moveSection('down')}
                                data-uk-tooltip={t('document.navbar.main.tooltips.chapterDown')}
                                uk-icon="icon: arrow-down; ratio: 1.1"
                            />
                        )}

                        <button
                            className="uk-button"
                            onClick={toggleAuditTrail}
                            data-uk-tooltip={t('document.navbar.main.tooltips.chapterTrail')}
                            uk-icon="icon: history; ratio: 0.8"
                        />

                        {restrictions.canDeleteChapters && (
                            <button
                                className="uk-button uk-text-danger"
                                onClick={handleDeleteSection}
                                data-uk-tooltip={t('document.navbar.main.tooltips.chapterDelete')}
                                uk-icon="icon: trash; ratio: 0.9"
                            />
                        )}

                        {canOnlyUseAsWhole && document?.modelId && (
                            <button
                                className="uk-button uk-text-danger"
                                onClick={() => handleSectionSync(section)}
                                data-uk-tooltip={t('Sync chapter')}
                                uk-icon="icon: refresh; ratio: 0.9"
                            />
                        )}
                    </div>
                )}
            </div>

            <div className="flex-shrink-0 ml-auto">
                <SettingsSidebarButton
                    context={{
                        entity: section,
                        entityType: 'section',
                        documentId: document.id,
                    }}
                />
            </div>
        </div>
    );

    function moveSection(direction) {
        return new Promise((resolve) => {
            const result = HelperFunctions.moveItem(section.id, document.sections, direction);

            if (!result) {
                resolve([]);
            }

            const sectionsToUpdate = result.updated.map((section) => {
                return {
                    id: section.id,
                    sortOrder: section.sortOrder,
                };
            });

            updateSections({
                documentId: document.id,
                body: sectionsToUpdate,
            })
                .then((result) => {
                    resolve(result.data);
                })
                .catch(() => {
                    resolve([]);
                });
        });
    }

    function handleRestoreSection() {
        if (section.scheduledForDeletion) {
            updateSections({
                documentId: document.id,
                body: [
                    {
                        id: section.id,
                        scheduledForDeletion: false,
                    },
                ],
            });
        }
    }

    function handleSettingsIconClick(icon) {
        dispatch(
            set({
                key: 'sidebarContext',
                value: {
                    type: 'sidebarSettings',
                    context: {
                        entity: section,
                        entityType: entityTypes.SECTION,
                        documentId: document.id,
                        uri: idToUri(section.id, entityTypes.SECTION),
                        parentUri: idToUri(document.id, entityTypes.DOCUMENT),
                        landingTab: icon === 'labels' ? 'labels' : undefined,
                    },
                },
            }),
        );
    }

    function handleDeleteSection() {
        if (section.scheduledForDeletion) {
            const hasLockedAreas = areas.some((area) => area.locked);

            if (hasLockedAreas) {
                HelperFunctions.confirmModal(
                    `${section.title} bevat artikelen die vergrendeld zijn. Niet-opgeslagen wijzigingen kunnen mogelijk verloren gaan. Hoofdstuk toch verwijderen?`,
                    'danger',
                    false,
                    'Ja, verwijder',
                ).then(() => {
                    deleteSection({ id: section.id, documentId: document.id, forceDelete: true });
                });
            } else {
                // really remove the section
                HelperFunctions.confirmModal('confirmDeleteSection', 'danger').then(() => {
                    deleteSection({ id: section.id, documentId: document.id });
                });
            }
        } else {
            // schedule for deletion
            // Delete is not final so set forceDelete to true
            deleteSection({ id: section.id, documentId: document.id, forceDelete: true });
        }
    }

    function handleSectionSync(section) {
        const body = {
            documentId: document.id,
            body: {
                section: section,
                template: template,
            },
        };

        syncSections(body);
    }
}

function SectionEdits({ sectionId, documentId }) {
    const { t } = useTranslation('documents');
    const sectionEdits = useGetSectionEdits(documentId, sectionId);

    const unprocessedEdits = sectionEdits?.unprocessedEdits ?? 0;

    if (!unprocessedEdits || unprocessedEdits === 0) {
        return null;
    }

    return (
        <div
            className="text-warning bg-transparent-orange d-flex small flex-shrink-0 align-items-center font-weight-bold ml-2 pr-1 no-content-wrap"
            data-uk-tooltip={t('document.navbar.main.tooltips.chapterEdits')}
        >
            <Pencil className="mr-2 pl-1" size={18} />
            {unprocessedEdits}
        </div>
    );
}
