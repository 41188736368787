import { useGetPublicationPreviewTokensQuery } from 'features/publications/publicationApi';
import { useEffect, useMemo, useState } from 'react';
import Constants from '../../../config/Constants';
import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const emptyArray = [];

export function useGetPreviewTokens(publicationUri, skip = false) {
    const [autoRefresh, setAutoRefresh] = useState(false);

    const tokensArePending = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data) => {
                const pendingStatusCodes = [
                    Constants.publicationPreviewTokenStatus.pending,
                    Constants.publicationPreviewTokenStatus.processing,
                ];

                return data.some((_token) => {
                    return pendingStatusCodes.includes(_token.status);
                });
            },
        );
    }, []);

    const filterExpiredTokens = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data) => {
                if (!data) {
                    return emptyArray;
                }

                const now = DateTime.now();

                return data
                    .filter((token) => {
                        const expiresAt = DateTime.fromISO(token.expiresAt);

                        return now.toUnixInteger() < expiresAt.toUnixInteger();
                    })
                    .reverse();
            },
        );
    }, []);

    const queryResults = useGetPublicationPreviewTokensQuery(publicationUri, {
        selectFromResult: ({ currentData, isUninitialized, isLoading }) => {
            const previewTokens = filterExpiredTokens(currentData);

            return {
                previewTokens,
                hasPendingTokens: tokensArePending(previewTokens),
                isLoading: isUninitialized || (isLoading && previewTokens.length === 0),
            };
        },
        skip,
        pollingInterval: autoRefresh ? 10000 : 0,
    });

    const { hasPendingTokens } = queryResults;

    useEffect(() => {
        setAutoRefresh(hasPendingTokens);
    }, [hasPendingTokens]);

    return queryResults;
}
