import { useGetTranslationContainerEntityReferencesQuery } from '../../../features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

export function useGetEntityReferenceForTranslation(
    containerUri,
    entityUri,
    entityType,
    entityId,
    skip = false,
    entityReferenceObject = undefined,
) {
    const findEntityReference = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, entityUri) => entityUri,
            (data, entityUri) => {
                if (data) {
                    return data.find((item) => item.uri === entityUri);
                }

                return undefined;
            },
        );
    }, []);

    const { entityReference } = useGetTranslationContainerEntityReferencesQuery(
        {
            uri: containerUri,
            entityType,
            entityId, // include entityId here for performance reasons
        },
        {
            selectFromResult: (result) => ({
                entityReference: findEntityReference(result, entityUri),
            }),
            skip: skip || entityReferenceObject !== undefined,
        },
    );

    if (entityReferenceObject) {
        return entityReferenceObject;
    }

    return entityReference;
}
