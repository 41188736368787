import { useCurrentOrganisation } from '../../../../../hooks/useCurrentOrganisation';
import { useGetGuardAliasesQuery } from '../../../../../features/publications/guardApi';
import HelperFunctions from '../../../../global/HelperFunctions';

export function useGetGuardAliases(guardEntityUri) {
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;

    let params = {};

    return useGetGuardAliasesQuery(
        { uri: guardEntityUri, token: guardApiToken, params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result, false),
            skip: !guardEntityUri,
        },
    );
}
