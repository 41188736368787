import { useParams } from 'react-router-dom';
import { useGetTranslationContainerQuery } from 'features/translations/translationApi';

export function useGetTranslationContainer() {
    const { translationContainerId } = useParams();

    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data,
        }),
    });

    return translationContainer;
}
