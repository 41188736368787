import { Col, Row } from 'react-bootstrap';
import cx from 'classnames';
import { LastResultsBars } from './LastResultsBars';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { IconButton, SecondaryButton, WarningButton } from 'components/Buttons';
import { Pencil } from 'react-bootstrap-icons';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useUpdateAuditableMutation } from 'features/publications/guardApi';
import { useState } from 'react';
import { Field, Form as FForm, Formik } from 'formik';

export function AuditableDashboard({ auditable, guardEntity, parentUri }) {
    const { t, i18n } = useTranslation('publications');

    const lastResultChange = DateTime.fromISO(auditable.lastResultChange)
        .setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_MED);
    const isValid = auditable.lastResult === 'valid';

    return (
        <Row className="mb-3">
            <Col className="dr-container ml-3">
                <div className="py-3">
                    <div className="font-weight-bold">{t('publications:guard.headings.currentStatus')}</div>
                    <div className="mb-2" style={{ fontSize: '1.25rem' }}>
                        {guardEntity.watchEnabled ? (
                            <span
                                className={cx('font-weight-bold', {
                                    'text-success': isValid,
                                    'text-danger': !isValid,
                                })}
                            >
                                {isValid ? t('publications:guard.status.up') : t('publications:guard.status.error')}
                            </span>
                        ) : (
                            t('publications:guard.status.paused')
                        )}
                    </div>
                    <div className="text-muted">
                        {guardEntity.watchEnabled ? (
                            <>{t('publications:guard.messages.sinceTimestamp', { timestamp: lastResultChange })}</>
                        ) : (
                            <>-</>
                        )}
                    </div>
                </div>
            </Col>
            <Col className="dr-container mx-3">
                <div className="py-3">
                    <div className="font-weight-bold mb-2">{t('publications:guard.headings.latestResults')}</div>
                    <LastResultsBars auditable={auditable} numberOfChecks={20} parentUri={parentUri} />
                    <div className="text-muted" style={{ marginTop: 10 }}>
                        {nextCheckDate()}
                    </div>
                </div>
            </Col>
            <Col className="dr-container mr-3">
                <InCirculationBlock auditable={auditable} />
            </Col>
        </Row>
    );

    function nextCheckDate() {
        if (!guardEntity.nextCheckDate || !guardEntity.watchEnabled) {
            return '-';
        }

        const timestamp = DateTime.fromISO(guardEntity.nextCheckDate);
        const now = DateTime.now();

        if (timestamp < now) {
            return <>{t('publications:guard.messages.nextCheckSoon')}</>;
        }

        return (
            <>
                {t('publications:guard.messages.nextCheckDate', {
                    date: timestamp.setLocale(i18n.language).toRelative(),
                })}
            </>
        );
    }
}

function InCirculationBlock({ auditable }) {
    const { t, i18n } = useTranslation('publications');
    const [showInCirculationForm, setShowInCirculationForm] = useState(false);

    const inCirculation = isInCirculation();

    return (
        <div className="py-3">
            <div className="font-weight-bold mr-2">{t('publications:guard.headings.inCirculation')}</div>

            {showInCirculationForm ? (
                <InCirculationForm auditable={auditable} hideForm={() => setShowInCirculationForm(false)} />
            ) : (
                <>
                    <div className="mb-2">
                        <div className="flex-center mb-2" style={{ fontSize: '1.25rem' }}>
                            <div
                                className={cx('font-weight-bold mr-2', {
                                    'text-success': inCirculation,
                                    'text-danger': !inCirculation,
                                })}
                            >
                                {inCirculation ? t('global:yes') : t('global:no')}
                            </div>

                            <IconButton
                                tooltip={t('publications:guard.tooltips.editInCirculation')}
                                icon={<Pencil className="flex-shrink-0" size={14} />}
                                onClick={() => setShowInCirculationForm(!showInCirculationForm)}
                            />
                        </div>
                    </div>

                    <div className="text-muted">{renderInCirculationTimestamp()}</div>
                </>
            )}
        </div>
    );

    function isInCirculation() {
        if (!auditable.inCirculationSince) {
            return false;
        }

        const start = DateTime.fromISO(auditable.inCirculationSince).setLocale(i18n.language);
        const end = auditable.inCirculationEnd
            ? DateTime.fromISO(auditable.inCirculationEnd).setLocale(i18n.language)
            : undefined;
        const now = DateTime.now();

        if (now >= start && !end) {
            return true;
        }

        return now <= end;
    }

    function renderInCirculationTimestamp() {
        const { inCirculationSince, inCirculationEnd } = auditable;

        if (!inCirculationSince) {
            return null;
        }

        const start = inCirculationSince
            ? DateTime.fromISO(inCirculationSince).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)
            : '-';
        const end = inCirculationEnd
            ? DateTime.fromISO(inCirculationEnd).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)
            : '-';

        return <div>{t('publications:guard.messages.dateRangeFromTo', { start, end })}</div>;
    }
}

function InCirculationForm({ auditable, hideForm }) {
    const { t } = useTranslation();
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [updateAuditable] = useUpdateAuditableMutation();

    const hasNft = auditable.hasOwnProperty('guardNftToken') || auditable.inToken === true;

    const inCirculationSince = auditable.inCirculationSince
        ? DateTime.fromISO(auditable.inCirculationSince).toFormat("yyyy-MM-dd'T'HH:mm")
        : '';

    const inCirculationEnd = auditable.inCirculationEnd
        ? DateTime.fromISO(auditable.inCirculationEnd).toFormat("yyyy-MM-dd'T'HH:mm")
        : '';

    return (
        <div>
            <Formik
                initialValues={{
                    inCirculationSince,
                    inCirculationEnd,
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, dirty, values }) => (
                    <FForm>
                        <div className="mt-2 mb-3">
                            <div className="form-group row mb-2">
                                <label htmlFor="inCirculationSince" className="col-sm-2 col-form-label">
                                    {t('publications:guard.labels.from')}:
                                </label>
                                <div className="col-sm-10">
                                    <Field
                                        disabled={hasNft}
                                        className="form-control"
                                        type="datetime-local"
                                        name="inCirculationSince"
                                    />
                                </div>
                            </div>

                            <div className="form-group row mb-2">
                                <label htmlFor="inCirculationEnd" className="col-sm-2 col-form-label">
                                    {t('publications:guard.labels.until')}:
                                </label>
                                <div className="col-sm-10">
                                    <Field
                                        className="form-control"
                                        type="datetime-local"
                                        name="inCirculationEnd"
                                        disabled={values.inCirculationSince === ''}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="text-right">
                            <SecondaryButton size="sm" className="border-0 mr-1" onClick={hideForm}>
                                {t('global:btn.cancel')}
                            </SecondaryButton>

                            <WarningButton size="sm" disabled={isSubmitting || !dirty} type="submit">
                                {t('global:btn.save')}
                            </WarningButton>
                        </div>
                    </FForm>
                )}
            </Formik>
        </div>
    );

    function handleSubmit(values) {
        updateAuditable({
            uri: auditable['@id'],
            body: {
                inCirculationSince: values.inCirculationSince === '' ? null : values.inCirculationSince,
                inCirculationEnd: values.inCirculationEnd === '' ? null : values.inCirculationEnd,
            },
            token: guardApiToken,
        }).then(() => {
            hideForm();
        });
    }
}
