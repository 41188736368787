import { idToUri } from '../../../global/UriHelper';
import { useGetPublicationGroup } from '../../hooks/useGetPublicationGroup';
import { Skeleton } from '../../../../components/Skeleton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetPublications } from '../../hooks/useGetPublications';
import { Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import GroupPublications from './Groups';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { GroupHeader } from './Publications';

export function PublicationGroup({folderId, publicationId}) {
    return (
        <div className="dr-container">
            <GroupTitle groupId={folderId} />
            <Publications
                publicationId={publicationId}
                folderId={folderId}
                groupUri={idToUri(folderId, 'PublicationGroup')}
            />
        </div>
    )
}

function GroupTitle({ groupId }) {
    const folder = useGetPublicationGroup(groupId);

    return (
        <div
            className="data-table-group-header d-flex align-items-center"
            style={{
                backgroundColor: '#f3f5f7',
                fontWeight: 500,
                padding: '0.55rem 0 0.55rem 1.2rem',
            }}
        >
            <div>{folder ? <>{folder.name}</> : <Skeleton />}</div>
        </div>
    );
}

function Publications({ folderId, publicationId, groupUri }) {
    const { t } = useTranslation('publications');
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const { publications, isLoading } = useGetPublications(groupUri, documentId, search, true);

    const hasMulti = publications.some((publication) => publication.multiVersions);

    return (
        <div className="mb-4">
            <Droppable droppableId={groupUri}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={cx('data-table-group', 'data-table-group-level-1', {
                            'dragging-over': snapshot.isDraggingOver,
                        })}
                    >
                        <GroupHeader groupUri={groupUri} hasMulti={hasMulti} />

                        {publications.length > 0 && (
                            <GroupPublications
                                folderId={folderId}
                                publicationId={publicationId}
                                publications={publications}
                                hasMulti={hasMulti}
                            />
                        )}

                        {isLoading && publications.length === 0 && (
                            <div className="p-3">
                                <LoadingSpinner inline size="sm" />
                            </div>
                        )}

                        {!isLoading && publications.length === 0 && (
                            <div className="p-3">
                                <div
                                    className="small text-secondary"
                                    style={{ lineHeight: '21px', marginLeft: '33px' }}
                                >
                                    <span style={{ marginLeft: '30px' }}>{t('publications.noPublications')}</span>
                                </div>
                            </div>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}
