import getEnv from '../config/Env';
import baseAPI from './ApiConnector_v2';

const baseUrl = getEnv('PUBLICATION_API_URL');
const apiUrl = baseUrl + '/api';

const PublicationApi = {
    compareVersions(formData) {
        return baseAPI.post(apiUrl + '/publication_versions/compare', formData, {
            responseType: 'blob',
        });
    },
    downloadPublications(formData) {
        return baseAPI.post(apiUrl + '/publications/download', formData, {
            responseType: 'blob',
        });
    },
    comparePublications(formData) {
        return baseAPI.post(apiUrl + '/publications/compare', formData, {
            responseType: 'blob',
        });
    },
    exportPublicationUsers(formData) {
        return baseAPI.post(apiUrl + '/publication_users/export', formData, {
            responseType: 'blob',
        });
    },
    exportPublicationUserGroups(formData) {
        return baseAPI.post(apiUrl + '/user_groups/export', formData, {
            responseType: 'blob',
        });
    },
};

export default PublicationApi;
