import HelperFunctions from '../../../../../global/HelperFunctions';
import EntityTranslation from '../../EntityTranslation';
import { Col, Form, Row, Table } from 'react-bootstrap';
import Parser from 'html-react-parser';
import { useSelector } from 'react-redux';
import {
    useGetDocumentVariantsQuery,
    useGetTranslationContainerQuery,
} from '../../../../../../features/translations/translationApi';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useContext } from 'react';
import { FormikContext } from 'formik';
import { HtmlEditor } from '../../../../forms/FieldHelper';
import { entityTypes } from '../../../../config/Constants';
import { useGetTableTemplatesQuery } from '../../../../../../features/documents/documents';
import { useActiveOrganisation } from '../../../../../../hooks/useActiveOrganisation';
import { useGetDocument } from '../../../../../documents_v2/hooks/useGetDocument';
import { useTranslation } from 'react-i18next';

export default function TableBlock({ block, languageIso, handleTranslationMutation, translationContainer }) {
    const { t } = useTranslation('translations');

    return (
        <EntityTranslation
            isHtml={true}
            content={block.baseContent}
            languageIso={languageIso}
            entityUri={`/api/${entityTypes.BLOCK}/${block.id}`}
            translationContainer={translationContainer}
            entityType={entityTypes.BLOCK}
            entityId={block.id}
            property="data"
            entity={{
                ...block,
                title: t('translation.navbar.dashboard.translation.tableBlock.table'),
            }}
            handleTranslationMutation={handleTranslationMutation}
        />
    );
}

export function TableData({ content, translationKey }) {
    const { translationContainerId } = useParams();
    const { translationContainer } = useGetTranslationContainerQuery(translationContainerId, {
        selectFromResult: ({ data }) => ({
            translationContainer: data,
        }),
    });
    const tableData = HelperFunctions.tryParseJSON(content, {});

    return (
        <RenderTableData
            tableData={tableData}
            translationKey={translationKey}
            translationContainer={translationContainer}
        />
    );
}

function RenderTableData({ tableData, translationKey, translate = false, translationContainer }) {
    const { title, templateId, data = [] } = tableData;
    const { t } = useTranslation('translations');

    const { translationPerVariant = false } = translationKey;
    const { documentId } = translationContainer;
    const { variantId } = useSelector((state) => state.translation.sidebarFilters);
    const activeOrganisation = useActiveOrganisation();

    const { tableTemplate } = useGetTableTemplatesQuery(activeOrganisation, {
        selectFromResult: ({ data }) => ({
            tableTemplate: data ? data.find((_template) => _template.id === templateId) : undefined,
        }),
    });

    const { baseVariant } = useGetDocumentVariantsQuery(documentId, {
        selectFromResult: ({ data }) => ({
            baseVariant: data?.documentVariants ?? undefined,
        }),
    });

    let variant = baseVariant;

    if (translationPerVariant && variantId !== 0) {
        variant = HelperFunctions.getByValueRecursive(baseVariant.children, variantId);
    }

    if (variant === undefined) {
        return null;
    }

    const variantData = data.find((_item) => _item.variantId === variant.id) ?? {
        variantId: variant.id,
        rows: [],
    };

    return (
        <div className="pt-2">
            {translate ? (
                <TranslateTitle />
            ) : (
                <>
                    {title && (
                        <div className="mb-3" style={{ fontSize: 12 }}>
                            <span className="font-weight-bold">
                                {t('translation.navbar.dashboard.translation.tableBlock.tablaTitle')}:
                            </span>{' '}
                            {title}
                        </div>
                    )}
                </>
            )}

            {translationPerVariant && <CurrentVariantName variant={variant} />}

            {tableTemplate && (
                <RenderTableVariantData
                    variantData={variantData}
                    translationKey={translationKey}
                    translate={translate}
                    tableTemplate={tableTemplate}
                    documentId={documentId}
                />
            )}
        </div>
    );
}

function RenderTableVariantData({ variantData, translationKey, translate, tableTemplate, documentId }) {
    const { rows = [], variantId } = variantData;
    const { content, columns } = tableTemplate;

    if (content === 'dynamic' && columns === 'packages') {
        return (
            <RenderPackageGroupsTable
                variantData={variantData}
                translationKey={translationKey}
                translate={translate}
                tableTemplate={tableTemplate}
                documentId={documentId}
            />
        );
    }

    return (
        <>
            {rows.map((_row, ind) => {
                const { cells = [] } = _row;
                const cellsAreEmpty = cells.every((_cell) => _cell.latestContent === '');

                if (cellsAreEmpty) {
                    return null;
                }

                return (
                    <Row
                        style={{
                            marginBottom: -1,
                        }}
                        key={`table-${translationKey.id}-row-${ind}`}
                    >
                        {cells.map((_cell, colIndex) => {
                            return (
                                <Col
                                    className="border-muted border"
                                    style={{ marginRight: -1 }}
                                    key={`table-${translationKey.id}-row-${ind}-col-${colIndex}`}
                                >
                                    <div className="py-3">
                                        <TableCell
                                            cell={_cell}
                                            translate={translate}
                                            rowId={_row.id}
                                            variantId={variantId}
                                            templateId={tableTemplate.id}
                                        />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </>
    );
}

function RenderPackageGroupsTable({ variantData, translationKey, translate, tableTemplate, documentId }) {
    const document = useGetDocument(documentId, true);

    const { rows = [], variantId, packageGroupId } = variantData;
    const packageGroup = document?.packageGroups.find((group) => group.id === packageGroupId);

    if (packageGroup === undefined) {
        return null;
    }

    const { packages = [] } = packageGroup;

    return (
        <Table size="sm" className="mb-0">
            <tbody>
                {packages.map((_package, index) => {
                    return (
                        <tr key={`key-${translationKey.id}-package-${_package.id}-${index}`}>
                            <td width="20%" className="text-secondary text-uppercase" style={{ fontSize: 11 }}>
                                {_package.name}
                            </td>
                            {tableTemplate.rows.map((templateRow, index2) => {
                                const row = rows.find((_row) => _row.id === templateRow.id);
                                const cell = row
                                    ? row.cells.find((_cell) => _cell.entityId === _package.id)
                                    : undefined;

                                return (
                                    <td
                                        style={{ fontSize: 11 }}
                                        key={`key-${translationKey.id}-package-${_package.id}-${index2}`}
                                    >
                                        {cell && (
                                            <TableCell
                                                cell={cell}
                                                translate={translate}
                                                rowId={row.id}
                                                variantId={variantId}
                                                packageGroupId={packageGroup.id}
                                                templateId={tableTemplate.id}
                                            />
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

function CurrentVariantName({ variant }) {
    const { t } = useTranslation('translations');

    if (variant === undefined) {
        return null;
    }

    if (variant.parentId === null) {
        return <>{t('translation.navbar.dashboard.translation.tableBlock.basicVariant')}</>;
    }

    return <>{variant.name}</>;
}

function TableCell({ cell, translate = false, rowId, variantId, packageGroupId, templateId }) {
    const { latestContent } = cell;

    return (
        <>
            {translate ? (
                <TranslateCell
                    cell={cell}
                    variantId={variantId}
                    rowId={rowId}
                    cellId={cell.entityId}
                    packageGroupId={packageGroupId}
                    templateId={templateId}
                />
            ) : (
                <div className="rendered-content">{Parser(latestContent)}</div>
            )}
        </>
    );
}

export function EditTableTranslationContent({ translationKey, content, translationContainer }) {
    const tableData = HelperFunctions.tryParseJSON(content, {});

    return (
        <RenderTableData
            tableData={tableData}
            translationKey={translationKey}
            translate={true}
            translationContainer={translationContainer}
        />
    );
}

function TranslateTitle() {
    const { values, setFieldValue } = useContext(FormikContext);
    const { translation } = values;
    const { title = '' } = translation;
    const { t } = useTranslation('translations');

    const handleChange = (newTitle) => {
        setFieldValue('translation', {
            ...translation,
            title: newTitle,
        });
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label htmlFor="title">
                {t('translation.navbar.dashboard.translation.tableBlock.tablaTitle')}:
            </Form.Label>
            <Form.Control
                value={title}
                onChange={({ target }) => {
                    handleChange(target.value);
                }}
            />
        </Form.Group>
    );
}

function TranslateCell({ cell, variantId, rowId, cellId, packageGroupId, templateId }) {
    const { values, setFieldValue } = useContext(FormikContext);
    const { translation } = values;
    const { data = [] } = translation;

    // Find value in data
    let content = '';

    data.forEach((_item) => {
        if (_item.variantId === variantId) {
            _item.rows.forEach((_row) => {
                if (_row.id === rowId) {
                    _row.cells.forEach((_cell) => {
                        if (_cell.entityId === cellId) {
                            content = _cell.latestContent;
                        }
                    });
                }
            });
        }
    });

    const handleChange = (newCell) => {
        // Find variantData
        const dataCopy = _.cloneDeep(data);
        const variantDataIndex = dataCopy.findIndex((_item) => _item.variantId === variantId);

        if (variantDataIndex < 0) {
            dataCopy.push({
                variantId,
                rows: [],
            });
        }

        const variantData = dataCopy.find((_item) => _item.variantId === variantId);

        if (packageGroupId && !variantData.hasOwnProperty('packageGroupId')) {
            variantData.packageGroupId = packageGroupId;
        }

        // Find row
        const rowIndex = variantData.rows.findIndex((_row) => _row.id === rowId);

        if (rowIndex < 0) {
            variantData.rows.push({
                id: rowId,
                cells: [],
            });
        }

        const rowData = variantData.rows.find((_row) => _row.id === rowId);

        // Insert new cell data
        if (newCell.latestContent === '') {
            // Try to remove it
            rowData.cells = rowData.cells.filter((_cell) => _cell.entityId !== newCell.entityId);
        } else {
            rowData.cells = _.unionBy([newCell], rowData.cells, 'entityId').sort(
                HelperFunctions.dynamicSort('entityId')
            );
        }

        // Remove empty rows
        variantData.rows = variantData.rows
            .filter((_row) => _row.cells.length > 0)
            .sort(HelperFunctions.dynamicSort('id'));

        setFieldValue('translation', {
            ...translation,
            templateId,
            data: dataCopy.filter((_data) => _data.rows.length > 0).sort(HelperFunctions.dynamicSort('variantId')),
        });
    };

    return (
        <HtmlEditor
            name={`variant-${variantId}-row-${rowId}-cell-${cellId}`}
            initialValue={content}
            onEditorChange={(value) => {
                handleChange({
                    entityId: cell.entityId,
                    latestContent: value,
                });
            }}
        />
    );
}
