import { Check2Circle, ClockHistory, DashCircle, ExclamationCircle } from 'react-bootstrap-icons';
import Tooltip from '../../../global/Tooltip';
import cx from 'classnames';
import { isEnabledForTranslation } from '../../helpers/TranslationKeyHelper';
import { useTranslation } from 'react-i18next';
import { useGetTranslation } from '../../hooks/useGetTranslation';
import { DateTime } from 'luxon';

export default function TranslationCompleteIcon({
    translationKey,
    languageIso,
    hasEntityReference = false,
    size = 18,
    className = '',
}) {
    const { t, i18n } = useTranslation('translations');
    const enabledForTranslation = isEnabledForTranslation(translationKey, languageIso);
    const skip = enabledForTranslation === false || hasEntityReference === false;
    const translation = useGetTranslation(translationKey, languageIso, skip);

    if (enabledForTranslation === false) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.document.status.excluded')}>
                <DashCircle size={size} className={cx('text-muted', className)} />
            </Tooltip>
        );
    }

    if (translation === undefined) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.translation.noTranslation')}>
                <ExclamationCircle size={size} className={cx('text-danger', className)} />
            </Tooltip>
        );
    }

    const { unverified = true } = translation;

    if (unverified) {
        return (
            <Tooltip tooltip={t('translation.navbar.dashboard.constants.translationInProgress')}>
                <ClockHistory size={size} className={cx('text-warning', className)} />
            </Tooltip>
        );
    }

    return (
        <Tooltip tooltip={getCompletedTooltip()}>
            <Check2Circle size={size} className={cx('text-success', className)} />
        </Tooltip>
    );

    function getCompletedTooltip() {
        if (translation.verifiedAt) {
            const timestamp = DateTime.fromISO(translation.verifiedAt)
                .setLocale(i18n.language)
                .toLocaleString(DateTime.DATETIME_MED);

            return `Goedgekeurd op ${timestamp}`;
        }

        return t('translation.navbar.dashboard.document.status.translationComplete');
    }
}
