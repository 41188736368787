import Language from '../../../../language/Language';
import Parser from 'html-react-parser';
import {
    ChangeField,
    ReimbursementProduct,
    ReimbursementProductFieldHistory,
    ReimbursementProductHistory,
} from '../../../../models/reimbursement.models';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import RestrictedReimbursementContent from '../../RestrictedReimbursementContent';
import {
    useGetReimbursementProductChangesQuery,
    useUpdateReimbursementChangesMutation,
} from '../../../../features/reimbursements/reimbursements';
import { useContext } from 'react';
import { HistoryContext } from './ReimbursementProduct';
import { canAcceptChangesRoles } from '../../config/permissions';
import { useTranslation } from 'react-i18next';

export default function FieldHistory({
    reimbursementProductUri,
    reimbursementId,
    entity,
    className,
    propName,
    markChanges = true,
    history = undefined,
}: {
    reimbursementProductUri: string | undefined;
    reimbursementId: number;
    entity: Partial<ReimbursementProduct> | ReimbursementProductFieldHistory;
    className: string;
    propName: string;
    markChanges?: boolean;
    history?: ReimbursementProductHistory;
}) {
    const { endGroupId } = useContext(HistoryContext);
    const activeVersionIsMostRecent = endGroupId === 0;

    const { field }: { field: ChangeField | undefined } = useGetReimbursementProductChangesQuery(
        reimbursementProductUri,
        {
            selectFromResult: ({ data }) => ({
                field:
                    data?.fields.find(
                        (field: ChangeField) =>
                            field.class === className && field.id === entity.id && field.property === propName,
                    ) ?? undefined,
            }),
            skip: reimbursementProductUri === undefined,
        },
    );

    const showAcceptRejectButtons =
        reimbursementProductUri !== undefined && activeVersionIsMostRecent && field && field.changes.length > 0;

    return (
        <div>
            <FieldHistoryContent
                entity={entity}
                propName={propName}
                markChanges={markChanges}
                history={history}
                showAcceptRejectButtons={showAcceptRejectButtons}
            />

            {showAcceptRejectButtons && (
                <RestrictedReimbursementContent roles={canAcceptChangesRoles}>
                    <div className="d-flex flex-row-reverse align-items-center mt-2">
                        <AcceptAndRejectButtons reimbursementId={reimbursementId} field={field} />
                    </div>
                </RestrictedReimbursementContent>
            )}
        </div>
    );
}

export function AcceptAndRejectButtons({ reimbursementId, field }: { reimbursementId: number; field: ChangeField }) {
    const [updateReimbursementChanges] = useUpdateReimbursementChangesMutation();
    const { t } = useTranslation('reimbursements');

    const processChanges = (accept: boolean) => {
        const processedChanges = field.changes.map((_change) => ({
            ..._change,
            accepted: accept,
            processed: true,
        }));

        updateReimbursementChanges({ id: reimbursementId, ...processedChanges });
    };

    return (
        <div className="d-flex flex-row-reverse123 align-items-center">
            <Button
                className="d-flex align-items-center mr-2"
                size="sm"
                variant="outline-danger"
                data-uk-tooltip={t('product.fieldHistory.rejectChange')}
                onClick={() => processChanges(false)}
            >
                <XCircleFill className="mr-1" />
                {t('product.fieldHistory.btn.reject')}
            </Button>
            <Button
                className="d-flex align-items-center"
                size="sm"
                variant="outline-success"
                data-uk-tooltip={t('product.fieldHistory.acceptChange')}
                onClick={() => processChanges(true)}
            >
                <CheckCircleFill className="mr-1" />
                {t('product.fieldHistory.btn.accept')}
            </Button>
        </div>
    );
}

function FieldHistoryContent({
    entity,
    propName,
    markChanges = true,
    showAcceptRejectButtons = false,
    history = undefined,
}: {
    entity: Partial<ReimbursementProduct> | ReimbursementProductFieldHistory;
    propName: string;
    markChanges?: boolean;
    showAcceptRejectButtons?: boolean;
    history?: ReimbursementProductHistory;
}) {
    const propNameRendered = `${propName}Rendered`;

    if (history && _.has(history, propNameRendered)) {
        entity = history;
    }

    let content =
        markChanges && _.has(entity, propNameRendered) ? _.get(entity, propNameRendered) : _.get(entity, propName);

    // Dirty fix
    if (propName === 'coverage') {
        content = translateCoverage(String(content));
    }

    if (_.isEmpty(content)) {
        return null;
    }

    const propNameAccepted = `${propName}Accepted`;
    let showAcceptedContentError = false;

    if (!showAcceptRejectButtons && _.has(entity, propNameAccepted) && _.get(entity, propNameAccepted) === null) {
        showAcceptedContentError = true;
    }

    return <RenderedContent content={content} showAcceptedContentError={showAcceptedContentError} />;

    function translateCoverage(text: string): string {
        if (text.length === 1) {
            return Language.getTranslation(text, 'dynamic', 'editorModal');
        }

        const regex = /<(del|ins)>(.*?)<\/(del|ins)>/gm;

        return text.replace(regex, (item1, item2, item3) => {
            return item1.replace(item3, Language.getTranslation(item3, 'dynamic', 'editorModal'));
        });
    }
}

export function RenderedContent({
    content,
    isActive = false,
    showAcceptedContentError = false,
}: {
    content: string;
    isActive?: boolean;
    showAcceptedContentError?: boolean;
}) {
    return (
        <>
            <div
                style={{
                    border: showAcceptedContentError ? '1px solid #f5c6cb' : '1px solid #eaecef',
                    borderRadius: 4,
                    padding: '0.6rem 0.9rem',
                    color: showAcceptedContentError ? '#721c24' : isActive ? '#666' : '#8c8c8c',
                    backgroundColor: showAcceptedContentError ? '#f8d7da' : isActive ? '#fff' : '#f7f7f7',
                }}
            >
                <div className="rendered-content diff-content">{Parser(content)}</div>
            </div>

            {showAcceptedContentError && (
                <div className="small form-text text-muted">
                    Let op: inhoud niet correct geaccepteerd. Pas de inhoud aan en accepteer de wijzigingen opnieuw.
                </div>
            )}
        </>
    );
}
