import { useParams } from 'react-router-dom';
import { useGetTranslation } from 'pages/translation/hooks/useGetTranslation';
import _ from 'lodash';
import { useMemo } from 'react';
import { md5 } from 'js-md5';
import { useGetBlockAtTimestamp } from 'pages/documents_v2/hooks/useGetBlockAtTimestamp';
import RenderTranslationContent from 'pages/translation/views/translate/RenderTranslationContent';
import { useGetTranslationLanguage } from 'pages/translation/hooks/useGetTranslationLanguage';
import { DateTime } from 'luxon';
import { Skeleton } from 'components/Skeleton';

export function BlockHistory({ selectedEntity }) {
    const { language } = useParams();
    const { entity, translationKey } = selectedEntity;
    const translation = useGetTranslation(translationKey, language);
    const translationLanguage = useGetTranslationLanguage();

    const content = entity.baseContent;
    const contentHashChanged = _.get(translation, 'contentHashChanged', false);

    const contentHashMatch = useMemo(() => {
        if (translation === undefined) {
            return true;
        }

        return translation.contentHash === md5(content);
    }, [translation, content]);

    if (!contentHashChanged && contentHashMatch) {
        return null;
    }

    return (
        <div className="mb-3">
            <div
                style={{
                    color: '#5f6368',
                }}
                className="mb-2"
            >
                Wijzigingsgeschiedenis
            </div>
            <CurrentBlockContent
                blockId={entity.id}
                translationKey={translationKey}
                timestamp={getTimestamp()}
            />
        </div>
    );

    function getTimestamp() {
        if (translation.verifiedAt) {
            return DateTime.fromISO(translation.verifiedAt).toUnixInteger();
        }

        return DateTime.fromISO(translationLanguage.syncedAt).toUnixInteger();
    }
}

function CurrentBlockContent({ blockId, translationKey, timestamp }) {
    const blockAtTimestamp = useGetBlockAtTimestamp(blockId, timestamp);

    if (!blockAtTimestamp) {
        return <Skeleton count={2} />;
    }

    return (
        <div className="dr-card border bg-white p-3">
            <RenderTranslationContent content={blockAtTimestamp.diffContent} translationKey={translationKey} />
        </div>
    );
}
