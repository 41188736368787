import MainContentNav from '../../../Navbar';
import { useTranslation } from 'react-i18next';
import SubNav from '../SubNav';
import { useCurrentOrganisation } from '../../../../hooks/useCurrentOrganisation';
import { useGetGuardEntities } from './hooks/useGetGuardEntities';

export function Index() {
    const { t } = useTranslation('publications');
    const currentOrganisation = useCurrentOrganisation();

    return (
        <>
            <MainContentNav title={t('tabs.guard')} />
            <SubNav />

            <div className="content-static-body has-subnav">
                <div className="d-flex align-items-stretch h-100">
                    <div className="flex-grow-1 overflow-auto">
                        <div className="p-4">
                            {currentOrganisation.guardConnected === false ? (
                                <div>Error: Guard not connected!</div>
                            ) : (
                                <GuardEntities />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function GuardEntities() {
    const guardEntities = useGetGuardEntities();
    console.log(guardEntities);

    return <div>test</div>;
}
