import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import {
    useGetPublicationUser,
    useGetUserPasswordActions,
    useGetUserPublicationPasswords,
} from 'pages/publications_v2/hooks/publicationUsers';
import { parseInt } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ArrowDownShort, CaretDownFill, CaretRightFill, ChevronRight } from 'react-bootstrap-icons';
import { USER_GROUPS_PATH } from 'scenes/PublicationsV2';
import cx from 'classnames';
import { DateTime } from 'luxon';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { DeleteButton } from 'components/ActionButtons';
import HelperFunctions from 'pages/global/HelperFunctions';
import {
    useAddPublicationUserPasswordMutation,
    useDeletePublicationUserMutation,
    useDeletePublicationUserPasswordMutation,
} from 'features/publications/publicationApi';
import { SendLoginButton, UserPublicationSwitch } from 'pages/publications_v2/forms/users/PublicationUsers';
import Constants from 'config/Constants';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';
import { DocRevSelect } from 'pages/publications_v2/helpers/FieldHelper';
import { useGetPublications } from 'pages/publications_v2/hooks/useGetPublications';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Dropdown, SplitButton } from 'react-bootstrap';

export function ViewUser() {
    const { t } = useTranslation();
    const { id } = useParams();
    const publicationUser = useGetPublicationUser(parseInt(id));
    const [, setEditUserId] = useQueryParam('euid', NumberParam);
    const [deletePublicationUser] = useDeletePublicationUserMutation();
    const history = useHistory();

    if (!publicationUser) {
        return null;
    }

    return (
        <div className="dr-container p-4">
            <div className="subheader justify-content-between">
                <h3>
                    <Link
                        className="text-muted"
                        to={generatePath(USER_GROUPS_PATH, {
                            page: 'users',
                        })}
                    >
                        {t('publications:accessControl.users')}
                    </Link>
                    <ChevronRight className="text-muted mx-2" />
                    {publicationUser.fullName}
                </h3>

                <SplitButton
                    id="btn-edit-user"
                    title={t('global:btn.edit')}
                    variant="primary"
                    menuAlign="right"
                    onClick={() => setEditUserId(publicationUser.id)}
                    onSelect={handleSelect}
                >
                    <Dropdown.Item eventKey="delete">
                        <span className="text-danger">{t('global:btn.delete')}&hellip;</span>
                    </Dropdown.Item>
                </SplitButton>
            </div>

            <PublicationsAsTable publicationUser={publicationUser} />
        </div>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'delete':
                handleUserDelete();
                break;
            default:
                break;
        }
    }

    function handleUserDelete() {
        HelperFunctions.confirmModal(
            t('global:confirm.confirmDelete', { name: publicationUser.fullName }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deletePublicationUser(publicationUser['@id']).then(() => {
                    history.push(
                        generatePath(USER_GROUPS_PATH, {
                            page: 'users',
                        }),
                    );
                });
            })
            .catch(() => {});
    }
}

function PublicationsAsTable({ publicationUser }) {
    const { t } = useTranslation();
    const userPublicationPasswords = useGetUserPublicationPasswords(publicationUser.id);

    return (
        <>
            <AddPublication publicationUser={publicationUser} userPasswords={userPublicationPasswords} />
            <div className="table-responsive">
                <table className="table dr-table">
                    <thead>
                        <tr>
                            <th>
                                {t('publications:accessControl.headers.publication')} <ArrowDownShort />
                            </th>
                            <th>{t('publications:accessControl.headers.login')}</th>
                            <th>{t('global:btn.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userPublicationPasswords.map((userPassword) => (
                            <UserPublicationRow
                                publicationUser={publicationUser}
                                userPassword={userPassword}
                                key={`user-publication-${userPassword.id}`}
                            />
                        ))}

                        {userPublicationPasswords.length === 0 && (
                            <tr>
                                <td colSpan={3}>{t('publications:accessControl.userNotLinkedToPublication')}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function UserPublicationRow({ publicationUser, userPassword }) {
    const { t } = useTranslation();
    const { publication } = userPassword;
    const [deletePublicationUserPassword] = useDeletePublicationUserPasswordMutation();
    const [expanded, setExpanded] = useQueryParam('expand', NumberParam);

    const isExpanded = expanded === userPassword.id;

    return (
        <>
            <tr className={cx({ 'is-expanded': isExpanded })}>
                <td>
                    <a
                        href="#"
                        className="d-flex align-items-center row-toggle"
                        onClick={(event) => {
                            event.preventDefault();
                            setExpanded(isExpanded ? undefined : userPassword.id);
                        }}
                    >
                        {isExpanded ? <CaretDownFill /> : <CaretRightFill />}
                        <div className="ml-2">{publication.name}</div>
                    </a>
                </td>
                <td>
                    <UserPublicationSwitch
                        publicationUser={publicationUser}
                        userPassword={userPassword}
                        publication={publication}
                    />
                </td>
                <td className="actions">
                    <div>
                        <SendLoginButton
                            publicationUser={publicationUser}
                            userPassword={userPassword}
                            isDisabled={publication.status !== Constants.publicationStatus.published}
                        />
                        <DeleteButton onClick={handleUserDelete} />
                    </div>
                </td>
            </tr>

            {isExpanded && <ExpandedUserRow userPassword={userPassword} />}
        </>
    );

    function handleUserDelete() {
        HelperFunctions.confirmModal(
            t('publications:accessControl.confirmRemoveFromPublication', { name: publication.name }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deletePublicationUserPassword(userPassword['@id']);
            })
            .catch(() => {});
    }
}

export function ExpandedUserRow({ userPassword, colSpan = 3 }) {
    const { t } = useTranslation('publications');
    const userPasswordActions = useGetUserPasswordActions(userPassword ? userPassword['@id'] : undefined);

    return (
        <tr className="expanded-row-details">
            <td colSpan={colSpan}>
                <div className="px-3 pb-3">
                    <div className="border-top pt-3">
                        <div className="docrev-flex-table">
                            <div className="font-weight-bold text-darkBlueAlt mb-2">
                                {t('publications:accessControl.actions')}
                            </div>
                            <div>
                                {userPasswordActions.map((action) => (
                                    <Action action={action} key={`action-${action.id}`} />
                                ))}

                                {userPasswordActions.length === 0 && (
                                    <div>{t('publications:accessControl.noActions')}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

function Action({ action }) {
    const { t, i18n } = useTranslation('publications');
    const createdAt = DateTime.fromISO(action.createdAt).setLocale(i18n.language).toRelative();
    const creatorName = useGetUserFullName(action.createdBy, false);

    return (
        <div className="mb-2">
            <div className="">{t(`publications.userPasswords.actions.${action.action}`)}</div>
            <div className="text-muted small">
                {createdAt}
                {action.createdBy !== '' && <>&nbsp;&bull;&nbsp;{creatorName}</>}
            </div>
        </div>
    );
}

function AddPublication({ publicationUser, userPasswords = [] }) {
    const { t } = useTranslation();
    const [addPublicationUserPassword] = useAddPublicationUserPasswordMutation();
    const organisationId = useActiveOrganisation();
    const allPublications = useGetPublications();

    const publicationIds = userPasswords.map((userPassword) => userPassword.publication['@id']);

    const publicationOptions = HelperFunctions.prepareDropdownData(
        allPublications.filter(
            (publication) => publication.accessControlEnabled && !publicationIds.includes(publication['@id']),
        ),
    );

    return (
        <div className="mb-4">
            <label className="mb-2 font-weight-bolder label-title small">Publicaties</label>
            <DocRevSelect
                props={{
                    placeholder: t('publications:accessControl.addPublicationPlaceholder'),
                }}
                name="add-new-publication"
                options={publicationOptions}
                selectedValue=""
                onChange={handleFormChange}
            />
        </div>
    );

    function handleFormChange(data) {
        addPublicationUserPassword({
            organisationId,
            publication: data['@id'],
            publicationUser: publicationUser['@id'],
            enabled: true,
        });
    }
}
