import { useCurrentOrganisation } from '../../../../../hooks/useCurrentOrganisation';
import { useGetGuardAuditsQuery } from '../../../../../features/publications/guardApi';
import HelperFunctions from '../../../../global/HelperFunctions';
import { NumberParam, useQueryParam } from 'use-query-params';

export function useGetGuardAudits(uri, type, queryParams = {}, parentUri) {
    const currentOrganisation = useCurrentOrganisation();
    const { guardApiToken } = currentOrganisation;
    const [page = 1] = useQueryParam('page', NumberParam);

    const params = {
        page,
        ...queryParams,
    };

    return useGetGuardAuditsQuery(
        { uri, type, token: guardApiToken, params, parentUri },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result, false),
        },
    );
}
