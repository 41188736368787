import { useGetDocumentsQuery } from '../../../features/documents/documents';
import Constants from '../../../config/Constants';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetDocuments(folderId = undefined, search = '', pollingInterval) {
    const activeOrganisation = useActiveOrganisation();

    const filterDocuments = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data, folderId) => folderId,
            (data, folderId, search) => search,
            (data, folderId, search) => {
                if (!data) {
                    return emptyArray;
                }

                const sortedDocuments = getDocumentsFromFolder(data, folderId);

                if (search !== '') {
                    return filterSearch(sortedDocuments, search);
                }

                return sortedDocuments;
            },
        );
    }, []);

    const { documents } = useGetDocumentsQuery(
        {
            organisationId: activeOrganisation,
            deleted: folderId === Constants.folders.trash,
        },
        {
            selectFromResult: ({ data }) => ({
                documents: filterDocuments(data, folderId, search),
            }),
            pollingInterval,
        },
    );

    return documents;
}

const getDocumentsFromFolder = (documents, folderId) => {
    if (folderId === undefined) {
        // Return all Documents
        return documents;
    }

    // Return deleted Documents
    if (folderId === Constants.folders.trash) {
        return documents
            .filter((document) => document.deletedAt !== null)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    // Return top-level Documents
    if (folderId === null) {
        return documents
            .filter((document) => document.deletedAt === null && document.documentGroup === null)
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    // Return Documents from group/folder
    const folderIdAsInt = parseInt(folderId);

    return documents
        .filter((document) => document.deletedAt === null && document.documentGroup === folderIdAsInt)
        .sort(HelperFunctions.dynamicSort('sortOrder'));
};

const filterSearch = (documents, search) => {
    const searchValueClean = search.trim().toLowerCase();

    return documents.filter((document) => document.name.toLowerCase().includes(searchValueClean));
};
