import React from 'react';
import Modal from '../../global/Modal';
import PageTitle from '../../global/PageTitle';
import _ from 'lodash';
import { Field, Form as FForm, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Constants from '../../../config/Constants';
import { useParams } from 'react-router-dom';
import Spinner from '../../global/Spinner';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { useCreateOpListExportTokenMutation } from '../../../features/publications/publicationApi';

export default function CreateExportModal({ showModal, handleClose, payload, departmentId }) {
    const { t } = useTranslation('changelist');
    const { id } = useParams();
    const [createOpListExportToken] = useCreateOpListExportTokenMutation();

    if (!showModal) {
        return null;
    }

    const { type } = payload;
    const isPdf = type === 'pdf';

    function submit(values) {
        let filters = values;
        if (isPdf) {
            // Only submit shown filters for pdf (for excel all filters are shown)
            filters = _.pick(values, ['filter-5']);
        }

        createOpListExportToken({
            opListId: id,
            opDepartmentId: departmentId,
            type,
            action: Constants.publicationToken.preview,
            filters,
        }).then(() => {
            handleClose();
        });
    }

    return (
        <Modal opened={true} onRequestClose={handleClose} modalStyle="centeredAutoWidth">
            <div className="uk-padding-medium" style={{ width: 500 }}>
                <Formik
                    initialValues={{
                        'filter-2': false,
                        'filter-5': false,
                    }}
                    onSubmit={submit}
                >
                    {({ setFieldValue, values, submitForm, isSubmitting }) => {
                        return (
                            <div className="uk-position-relative">
                                <PageTitle
                                    pageTitle={_.capitalize(type) + t('changelist.export.createExport.title')}
                                    marginTop="none"
                                    marginBottom="none"
                                />

                                <FForm autoComplete="off">
                                    <div className="mb-3">
                                        <div className="font-weight-bold mb-1">
                                            {t('changelist.export.createExport.filters')}
                                        </div>

                                        <div className="small">
                                            {!isPdf && (
                                                <div className="mb-2">
                                                    <Field
                                                        as={Form.Check}
                                                        type="checkbox"
                                                        disabled={isSubmitting}
                                                        name="filter-2"
                                                        id="filter-2"
                                                        label={t('changelist.export.createExport.showArchived')}
                                                        onChange={() => setFieldValue('filter-2', !values['filter-2'])}
                                                    />
                                                </div>
                                            )}
                                            <div className="mb-2">
                                                <Field
                                                    as={Form.Check}
                                                    type="checkbox"
                                                    disabled={isSubmitting}
                                                    name="filter-5"
                                                    id="filter-5"
                                                    label={t('changelist.export.createExport.hideExpired')}
                                                    onChange={() => setFieldValue('filter-5', !values['filter-5'])}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </FForm>
                                <div className="d-flex align-items-center justify-content-end">
                                    {isSubmitting && <Spinner />}
                                    <SecondaryButton onClick={handleClose} disabled={isSubmitting} className="mr-2">
                                        {t('btn.close')}
                                    </SecondaryButton>
                                    <PrimaryButton onClick={submitForm} disabled={isSubmitting}>
                                        {t('changelist.export.createExport.btn.export')}
                                    </PrimaryButton>
                                </div>
                            </div>
                        );
                    }}
                </Formik>
            </div>
        </Modal>
    );
}
