import cx from 'classnames';
import { Editor } from 'components/Editor';
import { useGetTinyConfig } from 'hooks/useGetTinyConfig';
import Parser from 'html-react-parser';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Language from '../../../../language/Language';
import EditorHelper from '../../../global/EditorHelper';
import HelperFunctions from '../../../global/HelperFunctions';
import CrossRefModal from './CrossRefModal';

export default function BasicEditor({
    showRef = true,
    tags = [],
    name,
    onChange,
    value,
    id,
    placeholder = '',
    forceMinHeight = false,
}) {
    const [editorLoaded, setEditorLoaded] = useState(false);
    const [showEditor, setShowEditor] = useState(false);

    const wrapperClassName = cx('area-text-block basic-editor-text-block', {
        'area-text-block-has-editor': showEditor && editorLoaded,
        'area-text-block-no-editor': !showEditor || !editorLoaded,
        'area-text-block-min-height': forceMinHeight,
    });

    return (
        <div
            className={wrapperClassName}
            onClick={() => {
                if (!showEditor) {
                    setShowEditor(true);
                }
            }}
        >
            <BasicEditorInner
                value={value}
                name={name}
                id={id}
                onChange={onChange}
                showRef={showRef}
                tags={tags}
                showEditor={showEditor}
                editorLoaded={editorLoaded}
                setEditorLoaded={setEditorLoaded}
                setShowEditor={setShowEditor}
                placeholder={placeholder}
            />
        </div>
    );
}

export function BasicEditorInner({
    showRef,
    tags = [],
    name,
    onChange,
    value,
    id,
    showEditor,
    editorLoaded,
    setEditorLoaded,
    setShowEditor,
    placeholder = '',
}) {
    const [showCrossRefModal, setShowCrossRefModal] = useState(false);
    const editorRef = useRef(null);
    const tinyConfig = useGetTinyConfig();
    const [options, setOptions] = useState(false);

    useEffect(() => {
        if (showEditor && !options) {
            const tinyOptions = tinyMceOptions(tags, showRef, toggleCrossRefModal);

            setOptions({
                ...tinyOptions,
                ...tinyConfig,
            });
        }
    }, [showEditor]);

    useEffect(() => {
        if (editorLoaded && showEditor && editorRef.current) {
            // Move cursor to end of content
            editorRef.current.selection.select(editorRef.current.getBody(), true);
            editorRef.current.selection.collapse(false);
        }
    }, [showEditor, editorLoaded]);

    const contentClassName = cx('mce-content-body', {
        'd-none': showEditor && !!options && editorLoaded,
    });
    const editorClassName = cx({
        'd-none': !showEditor || !options || !editorLoaded,
    });

    return (
        <>
            <div className={contentClassName}>
                {Parser(
                    (_.isNil(value) || value === '') && placeholder !== ''
                        ? `<p class="text-muted">${placeholder}</p>`
                        : value,
                )}
            </div>
            <div className={editorClassName}>
                {!!options && (
                    <>
                        {showCrossRefModal && (
                            <CrossRefModal editor={editorRef.current} toggleCrossRefModal={toggleCrossRefModal} />
                        )}
                        <Editor
                            id={'jsTinymceEditor-' + id}
                            init={options}
                            value={value}
                            onEditorChange={handleEditorChange}
                            onInit={(event, editor) => {
                                setEditorLoaded(true);
                                editorRef.current = editor;
                            }}
                            onBlur={() => setShowEditor(false)}
                        />
                    </>
                )}
            </div>
        </>
    );

    function handleEditorChange(content) {
        onChange({
            target: {
                name: name,
                value: content,
            },
        });
    }

    function toggleCrossRefModal() {
        setShowCrossRefModal(!showCrossRefModal);
    }
}

export const tinyMceOptions = (tags = [], showRef = true, toggleCrossRefModal) => {
    // define block type specific config sets or configs that need dynamic content/functions
    return {
        setup: (editor) => {
            if (tags.length > 0) {
                editor.ui.registry.addMenuButton('tags', {
                    text: 'Tags',
                    fetch: function (callback) {
                        const items = EditorHelper.generateTagMenu(tags, editor);
                        callback(items);
                    },
                });
            }
            if (showRef) {
                editor.ui.registry.addButton('ref', {
                    text: Language.getTranslation('crossReference', 'buttons', 'editor'),
                    onAction: () => {
                        toggleCrossRefModal();
                    },
                });
            }
            editor.ui.registry.addButton('startNumber', {
                text: 'Begin lijst met...',
                onAction: function () {
                    const parent = editor.dom.getParent(editor.selection.getNode(), 'ol');

                    parent !== null &&
                        editor.windowManager.open({
                            title: 'Start getal',
                            body: {
                                type: 'panel',
                                items: [
                                    {
                                        type: 'input',
                                        inputMode: 'text',
                                        name: 'startNumber',
                                        label: 'Start getal',
                                    },
                                ],
                            },
                            buttons: [
                                {
                                    type: 'submit',
                                    text: 'OK',
                                },
                            ],
                            onSubmit: function (dialog) {
                                const data = dialog.getData();
                                const number = HelperFunctions.filterNumbers(data.startNumber);
                                parent.setAttribute('start', number);

                                dialog.close();
                            },
                        });
                },
                onSetup: (buttonApi) => {
                    // Make button disabled when no "ol" element is selected
                    editor.on('NodeChange', function (e) {
                        const isOrderedList =
                            e.element.nodeName.toLowerCase() === 'li' &&
                            e.parents[1] &&
                            e.parents[1].nodeName.toLowerCase() === 'ol';
                        buttonApi.setEnabled(isOrderedList);
                    });
                },
            });
        },
    };
};
