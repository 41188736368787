import { generatePath, useParams } from 'react-router-dom';
import { BASE_PATH, VIEW_PATH } from 'scenes/Translations';
import MainContentNav from '../../../Navbar';
import { Col, Container, Form, Row } from 'react-bootstrap';
import DocumentContainer from '../edit/documentContainer/DocumentContainer';
import { FilterSearch } from '../../forms/FieldHelper';
import ContextSidebar, { ToggleSidebarButton } from '../ContextSidebar';
import TranslationContainerSidebar from '../edit/TranslationContainerSidebar';
import EditContextWrapper from './EditContextWrapper';
import { translationContainerTypes, views } from '../../config/Constants';
import { ReimbursementContainer } from '../edit/reimbursementContainer/ReimbursementContainer';
import EntityActionsButton from '../edit/EntityActionsButton';
import { SubNav } from '../edit/SubNav';
import cx from 'classnames';
import Constants from '../../../../config/Constants';
import RestrictedTranslationContent from '../../RestrictedTranslationContent';
import { getLanguageName } from '../../helpers/LanguageHelper';
import { useTranslation } from 'react-i18next';
import { useGetTranslationContainer } from 'pages/translation/hooks/useGetTranslationContainer';
import HelperFunctions from 'pages/global/HelperFunctions';
import { CloudDownload } from 'react-bootstrap-icons';
import { LightOutlineButton } from 'components/Buttons';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { ExportModal } from 'pages/translation/modals/ExportModal';

export default function Edit() {
    const { translationLanguageId } = useParams();
    const { t } = useTranslation('translations');
    const translationContainer = useGetTranslationContainer();

    if (!translationContainer) {
        return null;
    }

    const translationLanguage = HelperFunctions.getByValue(
        translationContainer.translationLanguages,
        'id',
        translationLanguageId,
    );

    if (!translationLanguage) {
        return null;
    }

    const languageName = getLanguageName(translationLanguage.languageIso);
    const hasSubNav =
        translationContainer['@type'] === translationContainerTypes.DOCUMENT ||
        translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT;

    return (
        <EditContextWrapper>
            <MainContentNav
                pages={[
                    { title: t('translation.navbar.dashboard.document.breadcrumb'), url: BASE_PATH },
                    {
                        title: translationContainer.name,
                        url: generatePath(VIEW_PATH, {
                            translationContainerId: translationContainer.id,
                        }),
                    },
                    { title: languageName },
                ]}
            />

            <ExportModal />
            <SubNav translationContainer={translationContainer} translationLanguage={translationLanguage} />

            <div
                className={cx('content-static-body', {
                    'has-subnav': hasSubNav,
                })}
            >
                <div className="d-flex align-items-stretch h-100">
                    <div className="content-sidebar overflow-auto bg-light">
                        <div className="pt-4 pl-3 pr-2 pb-2">
                            <TranslationContainerSidebar translationContainer={translationContainer} />
                        </div>
                    </div>

                    <div className="flex-grow-1" style={{ marginTop: 75 }}>
                        <div className="d-flex h-100">
                            <div className="flex-grow-1 overflow-auto">
                                <div className="">
                                    <MainContent
                                        translationContainer={translationContainer}
                                        translationLanguage={translationLanguage}
                                    />
                                </div>
                            </div>

                            <ContextSidebar />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={cx('content-static-filters bg-bgLight px-4 py-3', {
                    'has-subnav': hasSubNav,
                })}
            >
                <TranslationContainerFilters
                    translationContainer={translationContainer}
                    languageIso={translationLanguage.languageIso}
                />
            </div>
        </EditContextWrapper>
    );
}

function MainContent({ translationContainer, translationLanguage }) {
    return (
        <div className="py-3 px-4">
            <Container>
                <Row>
                    <Col>
                        <div className="card dr-card">
                            <div className="card-body dr-card-body">
                                <TranslationContainerContent
                                    translationContainer={translationContainer}
                                    translationLanguage={translationLanguage}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

function TranslationContainerContent({ translationContainer, translationLanguage }) {
    if (translationContainer['@type'] === translationContainerTypes.DOCUMENT) {
        return <DocumentContainer documentContainer={translationContainer} translationLanguage={translationLanguage} />;
    }

    if (translationContainer['@type'] === translationContainerTypes.REIMBURSEMENT) {
        return (
            <ReimbursementContainer
                reimbursementContainer={translationContainer}
                translationLanguage={translationLanguage}
            />
        );
    }

    return <div>Type '{translationContainer['@type']}' is not supported.</div>;
}

function TranslationContainerFilters({ languageIso, translationContainer }) {
    const { view } = useParams();
    const { t } = useTranslation('translations');
    const [, setShowExportModal] = useQueryParam('export', BooleanParam);

    return (
        <Form.Row style={{ minHeight: 42 }}>
            {translationContainer['@type'] === translationContainerTypes.DOCUMENT && view !== views.PRODUCTS && (
                <Col sm={3} md={5} lg={4} xl={4}>
                    <FilterSearch placeholder={`${t('translation.navbar.dashboard.document.titleSearch')}...`} />
                </Col>
            )}
            <Col className="col-auto d-flex align-items-center ml-auto">
                <RestrictedTranslationContent
                    id={translationContainer.id}
                    roles={[
                        Constants.translationContainerTeamRoles.manager,
                        Constants.translationContainerTeamRoles.finalEditor,
                        Constants.translationContainerTeamRoles.editor,
                    ]}
                >
                    <>
                        <EntityActionsButton languageIso={languageIso} />

                        <LightOutlineButton onClick={() => setShowExportModal(true)} className="mr-3">
                            <CloudDownload />
                            <div>{t('global:btn.export')}</div>
                        </LightOutlineButton>

                        <div
                            style={{
                                borderRight: '1px solid #e5e5e5',
                                height: 32,
                                marginRight: 12,
                            }}
                        >
                            &nbsp;
                        </div>
                    </>
                </RestrictedTranslationContent>

                <ToggleSidebarButton />
            </Col>
        </Form.Row>
    );
}
