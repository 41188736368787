import { ProductFruits as ReactProductFruits } from 'react-product-fruits';
import { useUserProfile } from 'hooks/useUserProfile';
import { useGetOrganisationUser } from 'hooks/useGetOrganisationUser';
import getEnv from 'config/Env';
import { useCurrentOrganisation } from 'hooks/useCurrentOrganisation';
import { useTranslation } from 'react-i18next';

export function ProductFruits() {
    const { i18n } = useTranslation();
    const organisation = useCurrentOrganisation();
    const userProfile = useUserProfile();
    const organisationUser = useGetOrganisationUser(userProfile.userId);
    const workspaceCode = getEnv('PRODUCT_FRUITS_WORKSPACE_CODE');
    const env = getEnv('APP_ENV');
    const docrevEnv = getEnv('DOCREV_ENV');

    if (_.isEmpty(workspaceCode) || _.isEmpty(docrevEnv)) {
        return null;
    }

    const userInfo = getUserInfo();
    const language = getLanguage();

    return <ReactProductFruits workspaceCode={workspaceCode} language={language} user={userInfo} debug={env === 'dev'} />;

    function getUserInfo() {
        return {
            username: userProfile.userId,
            firstname: userProfile.firstName,
            signUpAt: organisationUser.createdAt,
            props: {
                hostname: window.location.hostname,
                docrev_env: docrevEnv,
                docrev_organisation: `${docrevEnv}_${organisation.id}`,
            },
        };
    }

    function getLanguage() {
        if (docrevEnv === 'ipidcreator') {
            return 'en';
        }

        return i18n.language;
    }
}
