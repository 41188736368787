import { useParams } from 'react-router-dom';
import { translationApi, useGetTagQuery } from '../../../../../features/translations/translationApi';
import EntityTranslation from '../EntityTranslation';
import { TranslationContext } from '../Translate';
import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { entityTypes } from '../../../config/Constants';
import { getTypeAsString } from '../../../helpers/EntityHelper';
import { InView } from 'react-intersection-observer';
import isEqual from 'react-fast-compare';
import { useGetTranslationKey } from '../../../hooks/useGetTranslationKey';
import { useGetTagEntityReference } from '../../../hooks/useGetTagEntityReference';
import { useGetTagItemEntityReferences } from '../../../hooks/useGetTagItemEntityReferences';

export default function TranslateTag({ translationContainer, translationLanguage }) {
    const { entityId } = useParams();
    const { documentId } = translationContainer;

    const { tag } = useGetTagQuery(
        {
            documentId,
            tagId: entityId,
        },
        {
            selectFromResult: ({ data }) => ({
                tag: data,
            }),
        },
    );

    const entityReference = useGetTagEntityReference(translationContainer['@id'], entityId, tag === undefined);
    const tagItemEntityReferences = useGetTagItemEntityReferences(translationContainer['@id'], entityReference);

    if (tag === undefined || entityReference === undefined || tagItemEntityReferences.length === 0) {
        return null;
    }

    // Get all TranslationKeys from the tagItemEntityReferences and make unique array of uris
    const tagItemTranslationKeys = _.uniq([].concat(...tagItemEntityReferences.map((_ref) => _ref.translationKeys)));

    return (
        <TagContent
            translationContainer={translationContainer}
            translationLanguage={translationLanguage}
            tag={tag}
            tagItemEntityReferences={tagItemEntityReferences}
            tagItemTranslationKeys={tagItemTranslationKeys}
        />
    );
}

function TagContent({
    tag,
    translationContainer,
    translationLanguage,
    tagItemEntityReferences = [],
    tagItemTranslationKeys = [],
}) {
    const translationContext = useContext(TranslationContext);
    const { name } = tag;

    useEffect(() => {
        if (translationContext.title === '') {
            translationContext.setTitle(name);
        }
    }, [translationContext.title]);

    return (
        <>
            {tagItemTranslationKeys.map((_uri) => (
                <MemoizedTagItemTranslationKey
                    translationKeyUri={_uri}
                    translationContainer={translationContainer}
                    translationLanguage={translationLanguage}
                    tagItemEntityReferences={tagItemEntityReferences.filter((_ref) =>
                        _ref.translationKeys.includes(_uri),
                    )}
                    tag={tag}
                    key={`key-${_uri}`}
                />
            ))}
        </>
    );
}

const MemoizedTagItemTranslationKey = React.memo(TagItemTranslationKey, isEqual);

function TagItemTranslationKey({
    translationKeyUri,
    tag,
    translationContainer,
    translationLanguage,
    tagItemEntityReferences,
}) {
    return (
        <InView threshold={0.75} delay={700} triggerOnce={true}>
            {({ inView, ref }) => (
                <div ref={ref}>
                    <TagItemTranslationKeyInner
                        inView={inView}
                        tag={tag}
                        tagItem={
                            tag?.tagItems.find(
                                (_tagItem) => _.toString(_tagItem.id) === tagItemEntityReferences[0].entityId,
                            ) ?? undefined
                        }
                        translationKeyUri={translationKeyUri}
                        translationLanguage={translationLanguage}
                        translationContainer={translationContainer}
                        entityReference={tagItemEntityReferences[0]}
                    />
                </div>
            )}
        </InView>
    );
}

function TagItemTranslationKeyInner({
    inView = false,
    translationKeyUri,
    tag,
    tagItem,
    translationContainer,
    translationLanguage,
    entityReference,
}) {
    const dispatch = useDispatch();
    const [loadData, setLoadData] = useState(false);
    const translationKey = useGetTranslationKey(translationKeyUri, !loadData || tagItem === undefined);

    useEffect(() => {
        if (inView && loadData === false) {
            setLoadData(true);
        }
    }, [inView]);

    if (tagItem === undefined) {
        return null;
    }

    return (
        <EntityTranslation
            content={_.get(tagItem, translationKey?.property ?? '', '')}
            languageIso={translationLanguage.languageIso}
            entityUri={`/api/${entityTypes.TAG_ITEM}/${tagItem.id}`}
            translationContainer={translationContainer}
            translationKeyObject={translationKey}
            entityReferenceObject={entityReference}
            loadData={loadData}
            entityType={entityTypes.TAG_ITEM}
            entityId={tagItem.id}
            property={translationKey?.property}
            entity={{
                ...tagItem,
                tag,
                title: translationKey ? getTypeAsString(translationKey.property, entityReference.entityType) : '',
            }}
            handleTranslationMutation={handleTranslationMutation}
            key={`entityReference-${entityReference.id}`}
        />
    );

    function handleTranslationMutation() {
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType: entityTypes.TAG_ITEM,
                },
                { subscribe: false, forceRefetch: true },
            ),
        );
        dispatch(
            translationApi.endpoints.getTranslationContainerEntityReferences.initiate(
                {
                    uri: translationContainer['@id'],
                    entityType: entityTypes.TAG,
                },
                { subscribe: false, forceRefetch: true },
            ),
        );
    }
}
