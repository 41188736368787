import React, { useState } from 'react';
import { CaretRightFill, CollectionFill, PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Button, Dropdown, Nav } from 'react-bootstrap';
import { generatePath, useHistory } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import HelperFunctions from '../../../global/HelperFunctions';
import cx from 'classnames';
import Constants, { Permissions } from '../../../../config/Constants';
import RestrictedContent, { useHasPermission } from '../../../global/RestrictedContent';
import DropdownMenu from '../../../global/DropdownMenu';
import { BASE_PATH, INDEX_PATH } from '../../../../scenes/PublicationsV2';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useTranslation } from 'react-i18next';
import { useGetPublicationGroups } from '../../hooks/useGetPublicationGroups';
import { useDeletePublicationGroupMutation } from '../../../../features/publications/publicationApi';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../../features/publications/publicationsSlice';

export default function Sidebar({ folderId, onGroupDragEnd, handleMove }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const rootLevelGroups = useGetPublicationGroups();
    const [isDragging, setDragging] = useState(false);
    const { t } = useTranslation('global');

    return (
        <div className="pt-4">
            <Nav className="folder-nav folder-nav-publications flex-column mb-3">
                <Nav.Link
                    className="d-flex align-items-center"
                    active={!folderId}
                    onClick={() => {
                        history.push(generatePath(BASE_PATH));
                    }}
                >
                    <div className="font-weight-bolder">
                        <CollectionFill size={14} className="mr-2" />
                        {t('contentSidebar.all')}
                    </div>

                    <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                        <Button
                            variant="link"
                            className="ml-auto text-body p-0"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                dispatch(
                                    setModal({
                                        key: 'group',
                                        value: {
                                            rootLevel: true,
                                            sortOrder:
                                                rootLevelGroups.length > 0
                                                    ? rootLevelGroups[rootLevelGroups.length - 1].sortOrder + 1
                                                    : 0,
                                        },
                                    }),
                                );
                            }}
                            data-uk-tooltip={`${t('contentSidebar.map.chaptersMap')}...`}
                        >
                            <PlusCircle size={14} />
                        </Button>
                    </RestrictedContent>
                </Nav.Link>
            </Nav>

            <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setDragging(true)}>
                {rootLevelGroups.map((_rootGroup, index) => {
                    const childGroups = _rootGroup.children ?? [];

                    return (
                        <RootGroup
                            group={_rootGroup}
                            isDragging={isDragging}
                            handleMove={handleMove}
                            index={index}
                            isLast={rootLevelGroups.length - 1 === index}
                            initialShowContents={
                                folderId ? childGroups.some((childGroup) => childGroup.id === folderId) : false
                            }
                            folderId={folderId}
                            key={`root-group-${_rootGroup.id}`}
                        />
                    );
                })}
            </DragDropContext>
        </div>
    );

    function onDragEnd(result) {
        setDragging(false);
        onGroupDragEnd(result);
    }
}

function RootGroup({ group, isDragging, handleMove, index, isLast, initialShowContents = false, folderId }) {
    const groups = group?.children ?? [];
    const [showContents, setShowContents] = useState(initialShowContents);

    return (
        <div className="mb-3">
            <div
                className="nav-dropdown-hover d-flex align-items-center mb-1"
                style={{
                    padding: '0.6rem 1.15rem 0.4rem 1.5rem',
                }}
                onClick={toggleContents}
            >
                <div className="font-weight-bolder d-flex justify-content-between cursor-pointer">
                    <div className="sidebar-titles">{group.name}</div>
                    <CaretRightFill
                        size={14}
                        className={cx('sidebar-icons ml-2', {
                            'rotate-90': showContents,
                        })}
                    />
                </div>

                <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                    <div className="ml-auto flex-shrink-0 mr-1">
                        <GroupActions
                            folder={group}
                            groups={groups}
                            handleMove={handleMove}
                            index={index}
                            isLast={isLast}
                        >
                            <DropDownToggle folderId={group.id} />
                        </GroupActions>
                    </div>
                </RestrictedContent>
            </div>

            {showContents && (
                <Droppable droppableId={group['@id'] === null ? 'default' : group['@id']}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={{
                                minHeight: 36,
                            }}
                            {...provided.droppableProps}
                        >
                            <Nav
                                className={cx('folder-nav folder-nav-publications flex-column', {
                                    'dragging-over': snapshot.isDraggingOver,
                                    'is-dragging': isDragging,
                                })}
                            >
                                {groups.map((group, childIndex) => (
                                    <Folder
                                        index={childIndex}
                                        folder={group}
                                        isLast={groups.length - 1 === childIndex}
                                        isActive={folderId === group.id}
                                        key={`sidebar-group-${group.id}`}
                                    />
                                ))}
                                {provided.placeholder}
                            </Nav>
                        </div>
                    )}
                </Droppable>
            )}
        </div>
    );

    function toggleContents() {
        setShowContents((prevState) => !prevState);
    }
}

function Folder({ folder, index, isLast, isActive = false }) {
    const hasReadWritePermission = useHasPermission(Permissions.Publication['Write.All']);
    const history = useHistory();

    const { publications = [] } = folder;

    return (
        <Draggable draggableId={folder['@id']} index={index} isDragDisabled={hasReadWritePermission === false}>
            {(provided, snapshot) => {
                return (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Nav.Link
                            as="div"
                            className={cx('d-flex align-items-center position-relative nav-dropdown-hover', {
                                'is-dragging': snapshot.isDragging,
                            })}
                            active={isActive}
                            onClick={() => {
                                if (isActive === false) {
                                    history.push(
                                        generatePath(INDEX_PATH, {
                                            folder: Constants.folders.folder,
                                            folderId: folder.id,
                                        }),
                                    );
                                }
                            }}
                        >
                            <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                                <RxDragHandleDots2 size={16} className="flex-shrink-0 text-color icon-grip" />
                            </RestrictedContent>

                            <div className="small">{folder.name}</div>

                            <div className="d-flex align-items-center ml-auto">
                                <RestrictedContent permission={Permissions.PublicationFolder['Write.All']}>
                                    <GroupActions folder={folder} showMoveItems={false} isLast={isLast} index={index}>
                                        <DropDownToggle folderId={folder.id} />
                                    </GroupActions>
                                </RestrictedContent>

                                <div className="text-center small" style={{ minWidth: 16 }}>
                                    {publications.length}
                                </div>
                            </div>
                        </Nav.Link>
                    </div>
                );
            }}
        </Draggable>
    );
}

function GroupActions({ folder, groups = [], children, handleMove, isLast, index, showMoveItems = true }) {
    const dispatch = useDispatch();
    const [dropdownIsOpen, setDropDownOpen] = useState(false);
    const history = useHistory();
    const { t } = useTranslation('global');
    const [deletePublicationGroup] = useDeletePublicationGroupMutation();

    return (
        <Dropdown
            className={cx('d-flex align-items-center cursor-pointer', {
                'dropdown-open': dropdownIsOpen,
            })}
            onSelect={handleSelect}
            onToggle={(isOpen, event) => {
                event.stopPropagation();
                setDropDownOpen(isOpen);
            }}
        >
            {children}

            <DropdownMenu>
                {folder.rootLevel && (
                    <Dropdown.Item eventKey="insert">{t('contentSidebar.map.menu.subfolder')}&hellip;</Dropdown.Item>
                )}

                <Dropdown.Item eventKey="edit">{t('contentSidebar.map.menu.changeName')}</Dropdown.Item>

                {folder.rootLevel === false && (
                    <Dropdown.Item eventKey="duplicate" disabled={!canDuplicate()}>
                        {t('contentSidebar.map.menu.duplicate')}&hellip;
                    </Dropdown.Item>
                )}

                {showMoveItems && (
                    <>
                        <Dropdown.Item eventKey="moveUp" disabled={index === 0}>
                            {t('contentSidebar.map.menu.moveUp')}
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="moveDown" disabled={isLast}>
                            {t('contentSidebar.map.menu.moveDown')}
                        </Dropdown.Item>
                    </>
                )}

                <Dropdown.Divider />

                <Dropdown.Item eventKey="delete" disabled={!canDelete()}>
                    <span className={canDelete() ? 'text-danger' : 'text-muted'}>
                        {t('contentSidebar.map.menu.delete')}&hellip;
                    </span>
                </Dropdown.Item>
            </DropdownMenu>
        </Dropdown>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'insert':
                dispatch(
                    setModal({
                        key: 'group',
                        value: {
                            parent: folder['@id'],
                            sortOrder: groups.length > 0 ? groups[groups.length - 1].sortOrder + 1 : 0,
                        },
                    }),
                );
                break;
            case 'edit':
                dispatch(
                    setModal({
                        key: 'group',
                        value: folder,
                    }),
                );
                break;
            case 'duplicate':
                dispatch(
                    setModal({
                        key: 'duplicate',
                        value: {
                            publications: folder.publications,
                            folder,
                        },
                    }),
                );
                break;
            case 'delete':
                HelperFunctions.confirmModal(
                    `${t('contentSidebar.map.confirmDelete', { name: folder.name })}`,
                    'danger',
                    false,
                    t('contentSidebar.map.yesDelete'),
                    t('btn.cancel'),
                )
                    .then(() => {
                        deletePublicationGroup(folder['@id']).then(() => {
                            // Redirect to main page
                            history.push(generatePath(BASE_PATH));
                        });
                    })
                    .catch(() => {});
                break;
            case 'moveUp':
                handleMove('up', folder, index);
                break;
            case 'moveDown':
                handleMove('down', folder, index);
                break;
        }
    }

    function canDelete() {
        return folder.publications.length === 0 && groups.length === 0;
    }

    function canDuplicate() {
        return folder.publications.length > 0;
    }
}

function DropDownToggle({ folderId }) {
    return (
        <Dropdown.Toggle
            bsPrefix="docrev-dropdown-toggle"
            as={ThreeDotsVertical}
            size={16}
            id={`folder-${folderId}-dropdown`}
        />
    );
}
