import { useGetAreaQuery } from 'features/documents/documents';

/**
 * @param {number} [areaId]
 * @param {boolean} [skip]
 */
export function useGetArea(areaId, skip = false) {
    const { area } = useGetAreaQuery(areaId, {
        selectFromResult: ({ currentData }) => ({
            area: currentData,
        }),
        skip: !areaId || skip,
    });

    return area;
}
