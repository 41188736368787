import { useGetTranslationContainersQuery } from 'features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useActiveOrganisation } from 'hooks/useActiveOrganisation';

/**
 * @param {string} [search]
 */
export function useGetTranslationContainers(search = '') {
    const organisationId = useActiveOrganisation();

    const filterTranslationContainers = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (res, organisationId, search) => search,
            (data, organisationId, search) => {
                if (data) {
                    const translationContainers = data.filter(
                        (translationContainer) => translationContainer.organisationId === organisationId,
                    );

                    if (search !== '') {
                        const searchValueClean = search.trim().toLowerCase();

                        return translationContainers.filter((translationContainer) => {
                            return translationContainer.name.toLowerCase().includes(searchValueClean);
                        });
                    }

                    return translationContainers;
                }

                return emptyArray;
            },
        );
    }, []);

    return useGetTranslationContainersQuery(undefined, {
        selectFromResult: (result) => ({
            ...result,
            translationContainers: filterTranslationContainers(result, organisationId, search),
        }),
    });
}
