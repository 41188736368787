import getEnv from 'config/Env';
import { useEffect } from 'react';
import { commentsApi } from 'features/comments/commentApi';
import { useDispatch } from 'react-redux';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useUserProfile } from 'hooks/useUserProfile';
import { publicationApi } from '../../../features/publications/publicationApi';

export function EventListener() {
    const dispatch = useDispatch();
    const hub = getEnv('MERCURE_URL');
    const topicUrl = getEnv('PUBLICATION_API_URL') + '/api';
    const { userId } = useUserProfile();

    useEffect(() => {
        const url = new URL(hub);
        const topics = ['/publications/{id}', '/publication_preview_tokens/{id}'];

        topics.forEach(function (topic) {
            url.searchParams.append('topic', topicUrl + topic);
        });

        const eventSource = new EventSource(url);

        eventSource.onmessage = function (event) {
            update(event);
        };

        return () => eventSource.close();
    }, []);

    return null;

    function update(event) {
        const data = HelperFunctions.tryParseJSON(event.data);

        if (data) {
            if (data.hasOwnProperty('@type') && data.hasOwnProperty('@id')) {
                if (data['@type'] === 'Publication') {
                    if (data.hasOwnProperty('updatedBy') && data.updatedBy === userId) {
                        // Event originated from the same user, no need to invalidate the cache
                        return;
                    }

                    dispatch(
                        publicationApi.util.invalidateTags([
                            {
                                type: data['@type'],
                                id: data.mercureAction === 'create' ? 'LIST' : data['@id'],
                            },
                        ]),
                    );
                }

                if (data['@type'] === 'PublicationPreviewToken') {
                    dispatch(
                        publicationApi.util.invalidateTags([
                            {
                                type: 'PublicationPreview',
                                id: data.publication['@id'],
                            },
                        ]),
                    );
                }
            }
        }
    }
}
