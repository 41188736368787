import { Gem } from 'react-bootstrap-icons';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetGuardAliases } from 'pages/publications_v2/views/guard/hooks/useGetGuardAliases';

export function AuditableNftTokenIcon({ auditable, iconOnly = false, className = '' }) {
    if (auditable['@type'] === 'GuardAlias') {
        return <AliasTokenIcon guardAlias={auditable} iconOnly={iconOnly} className={className} />;
    }

    return <GuardEntityTokenIcon guardEntity={auditable} iconOnly={iconOnly} className={className} />;
}

function GuardEntityTokenIcon({ guardEntity, className = '', iconOnly = false }) {
    const { t } = useTranslation();
    const { items } = useGetGuardAliases(guardEntity['@id']);
    const hasNft = guardEntity.hasOwnProperty('guardNftToken');

    const hasAliasesNotInToken = items.some((alias) => alias.inToken === false);

    return (
        <div className="flex-center">
            <Gem
                data-uk-tooltip={t(`publications:guard.tooltips.${getTooltip()}`)}
                size={14}
                className={cx('flex-shrink-0', className, {
                    'text-success': hasNft && !hasAliasesNotInToken,
                    'text-danger': !hasNft,
                    'text-warning': hasAliasesNotInToken,
                    'mr-2': !iconOnly,
                })}
            />

            {!iconOnly && (
                <div
                    className={cx('font-weight-bold', {
                        'text-success': hasNft && !hasAliasesNotInToken,
                        'text-danger': !hasNft,
                        'text-warning': hasAliasesNotInToken,
                    })}
                >
                    {t(hasNft ? 'global:yes' : 'global:no')}
                </div>
            )}
        </div>
    );

    function getTooltip() {
        if (hasAliasesNotInToken) {
            return 'hasAliasesNotInToken';
        }

        return hasNft ? 'hasNft' : 'noNft'
    }
}

function AliasTokenIcon({ guardAlias, className = '', iconOnly = false }) {
    const { t } = useTranslation();
    const inToken = guardAlias.inToken === true;

    return (
        <div className="flex-center">
            <Gem
                data-uk-tooltip={t(`publications:guard.tooltips.${inToken ? 'inToken' : 'notInToken'}`)}
                size={14}
                className={cx('flex-shrink-0', className, {
                    'text-success': inToken,
                    'text-danger': !inToken,
                    'mr-2': !iconOnly,
                })}
            />

            {!iconOnly && (
                <div
                    className={cx('font-weight-bold', {
                        'text-success': inToken,
                        'text-danger': !inToken,
                    })}
                >
                    {t(inToken ? 'global:yes' : 'global:no')}
                </div>
            )}
        </div>
    );
}
